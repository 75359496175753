import Checkbox from "@mui/material/Checkbox";
import mappingTranslateDefault from "../Main/MappingTranslateCommon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { withTranslation } from "react-i18next";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import axios from "axios";
import API from "../../Config/API";
import CancelIcon from "@mui/icons-material/Cancel";
import AuthService from "../../Service/Auth";
import Loading from "../Loading";
import QRMFADialog from "./QRMFADialog";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import OtpDialog from "../Auth/OtpDialog";
import { blue } from "@mui/material/colors";
export default withTranslation()(function ProfileMFA({ t }) {
  const [currentQrCode, setCurrentQrCode] = useState("");
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [changedHistory, setChangedHistory] = useState({});
  const [openOtpDialog, setOpenOtpDialog] = useState(false);

  const [mfaTypeEmail, setMfaTypeEmail] = useState(false);
  const [mfaTypeSoftware, setMfaTypeSoftware] = useState(false);
  const [isCancelMFA, setIsCancelMFA] = useState(false);

  const [applyMFALoginSoftware, setApplyMFALoginSoftware] = useState(false);
  const [applyMFALoginEmail, setApplyMFALoginEmail] = useState(false);

  const [applyMFADocumentSoftware, setApplyMFADocumentSoftware] = useState(
    false
  );
  const [applyMFADocumentEmail, setApplyMFADocumentEmail] = useState(false);
  const callbackQrDialog = async (closeDialog, error, verifyDone) => {
    setOpenQrDialog(closeDialog);
    if (error) {
      setIsUpdated(true);
      let change = { ...changedHistory };
      change["mfaTypeSoftware"] = false;
      setChangedHistory(change);
      if (!closeDialog) {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser();

        let preferred = await Auth.getPreferredMFA(user, {
          // Optional, by default is false.
          // If set to true, it will get the MFA type from server side instead of from local cache.
          bypassCache: false,
        });
        if (preferred !== "NOMFA") await Auth.setPreferredMFA(user, "NOMFA");
        setLoading(false);
      }
    } else {
      setLoading(true);
      apiSaveMfa()
        .then(() => {
          setLoading(false);
          setIsUpdated(false);
          let change = { ...changedHistory };
          change["applyMFALoginEmail"] = applyMFALoginEmail;
          change["applyMFADocumentEmail"] = applyMFADocumentEmail;
          change["mfaTypeEmail"] = mfaTypeEmail;
          change["mfaTypeSoftware"] = mfaTypeSoftware;
          change["applyMFALoginSoftware"] = applyMFALoginSoftware;
          change["applyMFADocumentSoftware"] = applyMFADocumentSoftware;
          change["mfaTypeSoftware"] = mfaTypeSoftware;
          change["mfaTypeEmail"] = mfaTypeEmail;
          setChangedHistory(change);
        })
        .catch(() => {
          setLoading(false);
          setIsUpdated(true);
        });
    }
    // setLoading(false);
  };
  // get is mfa enabled
  const getCurrentUserAttribute = async () => {
    const user = await AuthService.GetCurrentLogin();
    // console.log(attributes);
    /**
     * @params email,login = user mfa type email and enable only email
     * @params email,login,document = user mfa type email and enable both email and document
     * @params email,document = user mfa type email and enable only document
     * @params email = user mfa type email and disable both email and document
     * @params software,login = user mfa type software_ and enable only email
     * @params software,login,document = user mfa type software_ and enable both email and document
     * @params software,document = user mfa type software_ and enable only document
     * @params software = user mfa type software and disable both email and document
     */
    if (!user?.attributes["custom:mfa_email_auth"]) {
      await Auth.updateUserAttributes(user, {
        "custom:mfa_email_auth": "disable",
      });
    }
    if (!user?.attributes["custom:mfa_software_auth"]) {
      await Auth.updateUserAttributes(user, {
        "custom:mfa_software_auth": "disable",
      });
    }

    if (!user?.attributes["custom:mfa_type"]) {
      await Auth.updateUserAttributes(user, {
        "custom:mfa_type": "disable",
      });
    }
    return user.attributes;
  };
  const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  };

  const checkUserIsEnableMFA = () => {
    AuthService.GetCurrentLogin()
      .then((user) => {
        Auth.getPreferredMFA(user, {
          // Optional, by default is false.
          // If set to true, it will get the MFA type from server side instead of from local cache.
          bypassCache: false,
        }).then(async (data) => {
          let userAttributes = await getCurrentUserAttribute();
          userAttributes = (
            await Auth.currentAuthenticatedUser({
              bypassCache: true,
            })
          )?.attributes;
          // console.log(userAttributes);
          let arrUserAttr =
            userAttributes["custom:mfa_software_auth"]?.split(",") || [];
          let arrUserAttrEmail =
            userAttributes["custom:mfa_email_auth"]?.split(",") || [];
          if (arrUserAttr.length > 3) {
            let checkDup = findDuplicates(arrUserAttr);
            if (checkDup.length > 0) {
              let newAttribute = arrUserAttr.filter(
                (a) => !checkDup.includes(a)
              );
              newAttribute.push(...checkDup);
              Auth.updateUserAttributes(user, {
                "custom:mfa_software_auth": newAttribute.join(","),
              });
            }
          }

          if (arrUserAttrEmail.length > 3) {
            let checkDupEmail = findDuplicates(arrUserAttrEmail);
            if (checkDupEmail.length > 0) {
              let newAttribute = arrUserAttr.filter(
                (a) => !checkDupEmail.includes(a)
              );
              newAttribute.push(...checkDupEmail);
              Auth.updateUserAttributes(user, {
                "custom:mfa_email_auth": newAttribute.join(","),
              });
            }
          }

          if (
            !mfaTypeEmail &&
            !mfaTypeSoftware &&
            !userAttributes["custom:mfa_type"]
          ) {
            await Auth.updateUserAttributes(user, {
              "custom:mfa_type": "disable",
            });
          }
          if (
            userAttributes["custom:mfa_email_auth"] &&
            userAttributes["custom:mfa_email_auth"].includes("login")
          ) {
            setApplyMFALoginEmail(true);
          } else {
            setApplyMFALoginEmail(false);
          }
          if (
            userAttributes["custom:mfa_email_auth"] &&
            userAttributes["custom:mfa_email_auth"].includes("document")
          ) {
            setApplyMFADocumentEmail(true);
          } else {
            setApplyMFADocumentEmail(false);
          }
          if (
            userAttributes["custom:mfa_software_auth"] &&
            userAttributes["custom:mfa_software_auth"].includes("document")
          ) {
            setApplyMFADocumentSoftware(true);
          } else {
            setApplyMFADocumentSoftware(false);
          }
          if (
            userAttributes["custom:mfa_software_auth"] &&
            userAttributes["custom:mfa_software_auth"].includes("login")
          ) {
            setApplyMFALoginSoftware(true);
          } else {
            setApplyMFALoginSoftware(false);
          }
          switch (data) {
            case "NOMFA":
              if (
                !userAttributes["custom:mfa_software_auth"]?.includes(
                  "login"
                ) &&
                !userAttributes["custom:mfa_software_auth"]?.includes(
                  "document"
                )
              ) {
                setMfaTypeSoftware(false);
              }
              if (
                !userAttributes["custom:mfa_email_auth"]?.includes("login") &&
                !userAttributes["custom:mfa_email_auth"]?.includes("document")
              ) {
                setMfaTypeEmail(false);
              }
              if (
                userAttributes["custom:mfa_type"] &&
                userAttributes["custom:mfa_type"] === "disable"
              ) {
                setMfaTypeSoftware(false);
                setMfaTypeEmail(false);
              }
              if (
                userAttributes["custom:mfa_type"] &&
                userAttributes["custom:mfa_type"] === "software"
              ) {
                setMfaTypeSoftware(true);
              }
              if (
                userAttributes["custom:mfa_type"] &&
                userAttributes["custom:mfa_type"] === "email"
              ) {
                setMfaTypeEmail(true);
              }
              break;
            default:
              if (
                userAttributes["custom:mfa_software_auth"]?.includes("login") &&
                userAttributes["custom:mfa_type"] !== "software"
              ) {
                await Auth.setPreferredMFA(user, "NOMFA");
              }

              if (
                !userAttributes["custom:mfa_software_auth"]?.includes("login")
              ) {
                await Auth.setPreferredMFA(user, "NOMFA");
              }

              if (
                userAttributes["custom:mfa_type"] &&
                userAttributes["custom:mfa_type"] === "email"
              ) {
                setMfaTypeEmail(true);
                setMfaTypeSoftware(false);
              }
              if (
                userAttributes["custom:mfa_type"] &&
                userAttributes["custom:mfa_type"] === "software"
              ) {
                setMfaTypeSoftware(true);
                setMfaTypeEmail(false);
              }
              break;
          }
        });
      })
      .catch(async (err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    checkUserIsEnableMFA();
  }, []);

  const generateOtpDocument = async () => {
    const uri = API.url + "/mfa-otp/generate.json";
    const tokenID = await AuthService.GetTokenID();
    const resp = await axios
      .post(
        uri,
        {},
        {
          headers: {
            Authorization: tokenID,
          },
        }
      )
      .catch((e) => {
        console.log(e);
        // reject(error);
      });

    setLoading(false);
  };

  const apiSaveMfa = async () => {
    const uri = API.url + "/mfa-save.json";
    const tokenID = await AuthService.GetTokenID();
    return axios.post(
      uri,
      {
        mfaTypeEmail,
        mfaTypeSoftware,
        applyMFALoginSoftware,
        applyMFALoginEmail,
        applyMFADocumentSoftware,
        applyMFADocumentEmail,
      },
      {
        headers: {
          Authorization: tokenID,
        },
      }
    );
  };
  const onSaveMfa = async () => {
    let user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    setLoading(false);
    let isOpenMfa =
      mfaTypeSoftware && user.attributes["custom:mfa_type"] !== "software";
    if (isOpenMfa) {
      setOpenQrDialog(true);
      Auth.setupTOTP(user).then((code) => {
        const str =
          "otpauth://totp/Paperlogic:" +
          (user.username || user.email) +
          "?secret=" +
          code +
          "&issuer=" +
          (user.issuer || "Paperlogic");
        setCurrentQrCode(encodeURI(str));
      });
    } else {
      setLoading(true);
      apiSaveMfa()
        .then(() => {
          setIsUpdated(false);
          setLoading(false);
          let change = { ...changedHistory };
          change["applyMFALoginEmail"] = applyMFALoginEmail;
          change["applyMFADocumentEmail"] = applyMFADocumentEmail;
          change["mfaTypeEmail"] = mfaTypeEmail;
          change["mfaTypeSoftware"] = mfaTypeSoftware;
          change["applyMFALoginSoftware"] = applyMFALoginSoftware;
          change["applyMFADocumentSoftware"] = applyMFADocumentSoftware;
          change["mfaTypeSoftware"] = mfaTypeSoftware;
          change["mfaTypeEmail"] = mfaTypeEmail;
          setChangedHistory(change);
        })
        .catch(() => {
          setIsUpdated(true);
          setLoading(false);
        });
    }
  };
  if (loading) {
    return (
      <div className="profile-box">
        <Loading className="mfa_loading_full" />
      </div>
    );
  }
  return (
    <div className="profile-box">
      <div className="box-head">
        <div className="box-title">
          <b>二要素認証</b>
        </div>

        <div className="box-menu">
          {isUpdated
            ? [
                <Button
                  color="primary"
                  size="small"
                  aria-label="CancelIcon"
                  key="ec-cancel"
                  startIcon={<CancelIcon />}
                  variant="outlined"
                  onClick={(ev) => {
                    // console.log(changedHistory);
                    // Revert by history
                    Object.keys(changedHistory).forEach((v) => {
                      switch (v) {
                        case "mfaTypeEmail":
                          setMfaTypeEmail(changedHistory["mfaTypeEmail"]);
                          break;
                        case "mfaTypeSoftware":
                          setMfaTypeSoftware(changedHistory["mfaTypeSoftware"]);
                          break;
                        case "applyMFALoginSoftware":
                          setApplyMFALoginSoftware(
                            changedHistory["applyMFALoginSoftware"]
                          );
                          break;
                        case "applyMFALoginEmail":
                          setApplyMFALoginEmail(
                            changedHistory["applyMFALoginEmail"]
                          );
                          break;
                        case "applyMFADocumentSoftware":
                          setApplyMFADocumentSoftware(
                            changedHistory["applyMFADocumentSoftware"]
                          );
                          break;
                        case "applyMFADocumentEmail":
                          setApplyMFADocumentEmail(
                            changedHistory["applyMFADocumentEmail"]
                          );
                          break;
                        default:
                          break;
                      }
                    });
                    setChangedHistory({});
                    setIsUpdated(false);
                  }}
                >
                  {t("common:general.cancel")}
                </Button>,
                <span key="ec-space">&nbsp;&nbsp;</span>,
                <Button
                  color="primary"
                  size="small"
                  aria-label="SaveIcon"
                  key="ec-save"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={async (ev) => {
                    // console.log(ev);
                    setLoading(true);
                    if (!mfaTypeEmail && !mfaTypeSoftware) {
                      setIsCancelMFA(true);
                      setOpenOtpDialog(true);
                      generateOtpDocument();
                      return;
                    }
                    onSaveMfa();
                    setIsUpdated(false);
                    // setLoading(false);
                  }}
                >
                  {t("common:general.save")}
                </Button>,
              ]
            : ""}
        </div>
      </div>
      <div className="box-body">
        <div className="lz-m-20">
          <table className="userInfoTree">
            <tbody>
              <tr data-row="">
                <td>
                  <div>
                    {mappingTranslateDefault(
                      t,
                      "common:general.mfa-auth.profile-mfa-email",
                      "メールで認証"
                    )}
                  </div>
                  {mfaTypeEmail ? (
                    <div>
                      <FormControlLabel
                        label={mappingTranslateDefault(
                          t,
                          "common:general.mfa-auth.checkbox-email",
                          "ログイン時"
                        )}
                        control={
                          <Checkbox
                            sx={{
                              color: blue[400],
                              "&.Mui-checked": {
                                color: blue[400],
                              },
                            }}
                            checked={applyMFALoginEmail}
                            onChange={(e) => {
                              setIsUpdated(true);
                              if (!("applyMFALoginEmail" in changedHistory)) {
                                let change = { ...changedHistory };
                                change[
                                  "applyMFALoginEmail"
                                ] = applyMFALoginEmail;
                                setChangedHistory(change);
                              }
                              setApplyMFALoginEmail(!applyMFALoginEmail);
                            }}
                          />
                        }
                      />
                    </div>
                  ) : null}
                  {mfaTypeEmail ? (
                    <div>
                      <FormControlLabel
                        label={mappingTranslateDefault(
                          t,
                          "common:general.mfa-auth.checkbox-document",
                          "署名時"
                        )}
                        control={
                          <Checkbox
                            sx={{
                              color: blue[400],
                              "&.Mui-checked": {
                                color: blue[400],
                              },
                            }}
                            checked={applyMFADocumentEmail}
                            onChange={(e) => {
                              setIsUpdated(true);
                              if (
                                !("applyMFADocumentEmail" in changedHistory)
                              ) {
                                let change = { ...changedHistory };
                                change[
                                  "applyMFADocumentEmail"
                                ] = applyMFADocumentEmail;
                                setChangedHistory(change);
                              }
                              setApplyMFADocumentEmail(!applyMFADocumentEmail);
                            }}
                          />
                        }
                      />
                    </div>
                  ) : null}
                </td>
                <td>
                  <Switch
                    checked={mfaTypeEmail}
                    onChange={async (e) => {
                      setIsUpdated(true);
                      let change = { ...changedHistory };
                      if (!("mfaTypeEmail" in changedHistory)) {
                        change["mfaTypeEmail"] = mfaTypeEmail;
                        setChangedHistory({ ...change });
                      }
                      if (!mfaTypeEmail && mfaTypeSoftware) {
                        if (!("mfaTypeSoftware" in changedHistory)) {
                          change["mfaTypeSoftware"] = mfaTypeSoftware;
                          setChangedHistory(change);
                        }
                        setMfaTypeSoftware(false);
                      }
                      setMfaTypeEmail(!mfaTypeEmail);
                    }}
                  />
                  {mfaTypeEmail ? (
                    <>
                      <div></div>
                      <div></div>
                    </>
                  ) : null}
                </td>
              </tr>
              <tr data-row="">
                <td>
                  <div>
                    {mappingTranslateDefault(
                      t,
                      "common:general.mfa-auth.profile-mfa-software",
                      "アプリで認証（Microsoft Authenticator）"
                    )}
                  </div>
                  {mfaTypeSoftware ? (
                    <>
                      <div>
                        <FormControlLabel
                          label={mappingTranslateDefault(
                            t,
                            "common:general.mfa-auth.checkbox-email",
                            "ログイン時"
                          )}
                          control={
                            <Checkbox
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                              }}
                              checked={applyMFALoginSoftware}
                              onChange={(e) => {
                                if (
                                  !("applyMFALoginSoftware" in changedHistory)
                                ) {
                                  let change = { ...changedHistory };
                                  change[
                                    "applyMFALoginSoftware"
                                  ] = applyMFALoginSoftware;
                                  setChangedHistory(change);
                                }
                                setIsUpdated(true);
                                setApplyMFALoginSoftware(
                                  !applyMFALoginSoftware
                                );
                              }}
                            />
                          }
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          label={mappingTranslateDefault(
                            t,
                            "common:general.mfa-auth.checkbox-document",
                            "署名時"
                          )}
                          control={
                            <Checkbox
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                              }}
                              checked={applyMFADocumentSoftware}
                              onChange={(e) => {
                                if (
                                  !(
                                    "applyMFADocumentSoftware" in changedHistory
                                  )
                                ) {
                                  let change = { ...changedHistory };
                                  change[
                                    "applyMFADocumentSoftware"
                                  ] = applyMFADocumentSoftware;
                                  setChangedHistory(change);
                                }
                                setIsUpdated(true);
                                setApplyMFADocumentSoftware(
                                  !applyMFADocumentSoftware
                                );
                              }}
                            />
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </td>
                <td>
                  <Switch
                    checked={mfaTypeSoftware}
                    onChange={async (e) => {
                      setIsUpdated(true);
                      let change = { ...changedHistory };
                      if (!("mfaTypeSoftware" in changedHistory)) {
                        change["mfaTypeSoftware"] = mfaTypeSoftware;
                        setChangedHistory(change);
                      }
                      // setLoading(true);
                      // let user = await getCurrentAuthUser();
                      if (mfaTypeEmail && !mfaTypeSoftware) {
                        if (!("mfaTypeEmail" in changedHistory)) {
                          change["mfaTypeEmail"] = mfaTypeEmail;
                          setChangedHistory(change);
                        }
                        setMfaTypeEmail(false);
                      }
                      setMfaTypeSoftware(!mfaTypeSoftware);

                      // setLoading(false);
                    }}
                  />

                  {mfaTypeSoftware ? (
                    <>
                      <div></div>
                      <div></div>
                    </>
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <OtpDialog
        openOtpDialog={openOtpDialog}
        isVerifyEmailInDocument={true}
        isCancelMFA={isCancelMFA}
        mfaChallengeName={""}
        mfaUser={""}
        resendEmailCode={generateOtpDocument}
        callback={(isVerified) => {
          if (!isVerified) {
            setIsUpdated(true);
            setIsCancelMFA(true);
            return;
          }

          onSaveMfa();
          let change = {};
          setIsUpdated(false);
          setOpenOtpDialog(false);
          setChangedHistory(change);
        }}
      />
      <QRMFADialog
        openQrDialog={openQrDialog}
        callback={callbackQrDialog}
        qrValue={currentQrCode}
      />
      {/* <OtpDialog /> */}
    </div>
  );
});
