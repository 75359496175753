import React from "react";
// import { Link } from "react-router-dom";
import Layout from "../../Layout";
import MainMenu from "../Main/Menu";
import { withTranslation } from "react-i18next";
import Service from "../../Service";
import Loading from "../Loading";
// import Icons from '../Assets/MaterialIcons';
import SVGIcons from "../Assets/SVGIcons";
import {
  // Col,
  // Form,
  Nav,
  NavDropdown,
  // Button,
  // Container,
  Table,
} from "react-bootstrap";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Error from "../Error";
import DocumentDetail from "../Documents/DocumentDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BodyDetail from "../Main/BodyDetail";
import Search from "../Main/Search";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { jsonToCSV } from "react-papaparse";

class SearchResult extends Layout {
  constructor(props) {
    super(props);
    this.state.page = "SearchResult";
    // this.state.loading = true;
    this.state.documents = null;
    this.state.data = null;
    this.state.info = null;
    this.state.services = null;
    this.state.accounts = null;
    this.state.allServiceLabel = "";
    this.state.action = props.action || null;
    // this.state.formData = {};
    this.state.Currencies = [];
    this.state.PageData = {};
    // this.state.formData.meta_attributes = [];
    this.state.searchOption = this.props.searchOption;
    this.state.searchConditionTrue = false;
  }

  Menu = () => {
    return <MainMenu {...this.props} page={this.state.page} />;
  };

  componentDidMountExtends = () => {
    Service.GetUserList("user_type_name", ["hosts", "guests", "groups"]).then((resp) => {
      this.setState({ users: resp }, () => {
        let { searchOption } = this.state;
        this.GetDocumentListData(searchOption);
      });
    }).catch((err) => {
      console.log(err.response);
    });
  };

  componentDidUpdate = () => {
    // this.GetSearchDataAllItems();
  };

  ServiceIcon = (props) => {
    // console.log(props)
    if (props.code) {
      let Icon = SVGIcons[props.code];
      return <Icon {...props} />;
    }
    return null;
  };

  formSubmitCallback = (ev) => {
    this.setFormData(this.state.formData);
    this.formSubmitSearch();
  };

  PageCalculation = (searchOption) => {
    searchOption.current = Math.ceil(searchOption.skip / searchOption.take) + 1;
    searchOption.page = Math.ceil(
      searchOption.total_records / searchOption.take
    );
    // console.log(searchOption);
    return searchOption;
  };

  GetDocumentListData = (searchOption) => {
    searchOption = this.PageCalculation(searchOption);
    this.setState({ documents: null, searchOption });
    Service.getSearchDocumentList(searchOption)
      .then((resp) => {
        let { documents, link_url } = this.state;
        documents = resp.records;
        link_url = resp.link_url;
        searchOption.total_records = resp.total_records;
        searchOption = this.PageCalculation(searchOption);

        this.setState({ documents, searchOption, link_url });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  onPageChangeHandler = (ev) => {
    let page = parseInt(ev);
    let { searchOption } = this.state;
    let nextSkip = (page - 1) * searchOption.take;
    if (nextSkip !== searchOption.skip) {
      searchOption.skip = nextSkip;
      this.GetDocumentListData(searchOption);
    }
  };

  onRecordLimitChangeHandler = (ev) => {
    let limit = parseInt(ev);
    let { searchOption } = this.state;
    if (limit !== searchOption.take) {
      let cursor = (searchOption.current - 1) * searchOption.take;
      let page = Math.floor(cursor / limit);
      searchOption.skip = page * limit;
      searchOption.take = limit;
      this.GetDocumentListData(searchOption);
    }
  };

  onSortingHandler = (ev) => {
    let sort = parseInt(ev);
    let { searchOption } = this.state;
    if (sort !== searchOption.sorting_code) {
      searchOption.sorting_code = sort;
      this.GetDocumentListData(searchOption);
    }
  };

  prevPageData = (ev) => {
    let { searchOption } = this.state;
    let nextSkip = searchOption.skip - searchOption.take;
    if (nextSkip >= 0) {
      searchOption.skip = nextSkip;
      this.GetDocumentListData(searchOption);
    }
  };

  nextPageData = (ev) => {
    let { searchOption } = this.state;
    let nextSkip = searchOption.skip + searchOption.take;
    if (nextSkip < searchOption.total_records) {
      searchOption.skip = nextSkip;
      this.GetDocumentListData(searchOption);
    }
  };

  ClearSeletedDoc = (ev) => {
    this.setState({ showDetail: false });
    this.setState({ currentDocInfo: null });
  };

  DocumentDetailBody = (props) => {
    // let { t } = this.props;
    let { currentDocInfo, users } = this.state;
    let { ReturnButton } = this;

    if (!currentDocInfo) {
      return <Loading />;
    }
    if (currentDocInfo === false) {
      return <Error />;
    }
    // console.log(currentDocInfo);
    return (
      <DocumentDetail
        docInfo={currentDocInfo}
        users={users}
        action={ReturnButton}
      />
    );
  };

  showDetailPage = (ev, docInfo) => {
    // console.log(docInfo);
    this.setState({ showDetail: true, currentDocInfo: null });
    Service.getDocumentInfo(docInfo.document_id).then((resp) => {
      // console.log(resp);
      this.setState({ currentDocInfo: resp });
    }).catch((err) => {
      console.log(err.response);
    });
  };

  close = (ev) => {
    ev.target.closest(".document-detail").classList.remove("active");
    this.ClearSeletedDoc();
  };

  ReturnButton = () => {
    // let { t } = this.props;
    return (
      <IconButton onClick={this.close}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  ReturnSearchConditionButton = () => {
    // let { t } = this.props;
    return (
      <IconButton onClick={this.returnSearchCondition}>
        <ArrowBackIcon />
      </IconButton>
    );
  };

  /** [Event] */
  returnSearchCondition = () => {
    let searchConditionTrue = true;
    this.setState({ searchConditionTrue });
  };

  DocumentDetailPage = (props) => {
    let { showDetail } = this.state;
    let { DocumentDetailBody } = this;
    return (
      <BodyDetail active={showDetail}>
        <DocumentDetailBody />
      </BodyDetail>
    );
  };

  RocordSorting = () => {
    let { t } = this.props;
    let { searchOption } = this.state;
    let sortList = {
      2: "common:search.sorting.decending.by-issued-date",
      1: "common:search.sorting.ascending.by-issued-date",
    };
    let sortItems = [];

    for (let i in sortList) {
      sortItems.push(
        <NavDropdown.Item key={i} eventKey={i}>
          {t(sortList[i])}
        </NavDropdown.Item>
      );
    }

    return (
      <Nav
        activeKey={searchOption.sorting_code}
        onSelect={this.onSortingHandler}
        className="mr-auto"
      >
        <NavDropdown
          title={t(sortList[searchOption.sorting_code])}
          id="basic-nav-dropdown"
        >
          {sortItems}
        </NavDropdown>
      </Nav>
    );
  };

  AdditionalButtons = () => {
    //let { t } = this.props;
    let { searchOption } = this.state;
    let ButtonList = [];

    ButtonList.push(
      <Tooltip key={0} title="書類のリストをCSVでダウンロード" arrow>
        <Button
          sx={{ mr: 2, mt: 1 }}
          key="list-download"
          variant="outlined"
          size="small"
          startIcon={<DownloadIcon />}
          onClick={this.onListDownloadRequestHandler}
        >
          {"CSVダウンロード"}
          {/* {t('common:main.general.list-download')} */}
        </Button>
      </Tooltip>
    );
    if (searchOption.total_records === 0) {
      ButtonList = [];
    }

    return ButtonList;
  };

  onListDownloadRequestHandler = () => {
    let { documents, link_url } = this.state;
    let rows = [];

    for (let doc of documents) {

      let row = {
        "書類ID": doc.document_id,
        "登録書類": doc.function_name,
        "ステータス": doc.status_name,
        "書類の種類": doc.doc_type_name,
        "書類タイトル": doc.title,
        "保存要件": doc.preservation_require,
        "金額": doc.amount,
        "通貨": doc.currency,
        "契約開始日": doc.contract_date,
        "契約満了日": doc.expiry_date,
        "通知タイミング（日前）": doc.dateofnoti,
        "満了日前通知設定": doc.select_datenoti,
        "受領日": doc.received_date,
        "商品名": doc.product_name,
        "お客様書類ID": doc.reference_document,
        "取引先名": doc.counter_party_name,
        "摘要": doc.meta_attributes,
        "署名方法": doc.sign_level,
        "作成者": doc.creator_name,
        "作成日時": doc.create_datetime,
        "更新者": doc.updater_name,
        "更新日時": doc.update_datetime,
        "URL": link_url.replace("{document}", doc.document_id),
      };

      rows.push(row)
    }

    const results = jsonToCSV(rows);

    // console.log(results);
    let newDate = new Date().toISOString().replace(/-|:|T/g, "").substr(0, 14);

    let file_name = "{created_dt}_Export.csv";
    file_name = file_name.replace("{created_dt}", newDate)

    let BOM = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8 BOM

    let blob = new Blob([BOM, results], { type: "text/csv;charset=utf-8;" });
    // if (navigator.msSaveBlob) { // IE 10+
    // 	navigator.msSaveBlob(blob, exportedFilenmae);
    // } else {
    let link = document.createElement("a");
    // if (link.download !== undefined) { // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", file_name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  PageListNavigator = () => {
    let { searchOption } = this.state;
    let navItems = [];

    for (let i = 0; i < searchOption.page; i++) {
      let page = i + 1;
      let from = i * searchOption.take;
      let to = page * searchOption.take;
      navItems.push(
        <NavDropdown.Item
          key={page}
          eventKey={page}
        >{`${page} : ${from}-${to}`}</NavDropdown.Item>
      );
    }

    return (
      <Nav
        activeKey={searchOption.current}
        onSelect={this.onPageChangeHandler}
        className="mr-auto"
      >
        <NavDropdown
          title={`${searchOption.current}/${searchOption.page}`}
          id="basic-nav-dropdown"
        >
          {navItems}
        </NavDropdown>
      </Nav>
    );
  };

  DocumentDetailPage = (props) => {
    let { showDetail } = this.state;
    let { DocumentDetailBody } = this;
    return (
      <BodyDetail active={showDetail}>
        <DocumentDetailBody />
      </BodyDetail>
    );
  };

  ItemsList = () => {
    let { t } = this.props;
    let { documents } = this.state;

    if (documents === null) {
      return <Loading />;
    }

    if (documents.length === 0) {
      return (
        <Table>
          <tbody>
            <tr className="document-row-list error-tr">
              <td className="record-not-found">
                <Error msg={t("common:message.record.not-found")} />
              </td>
            </tr>
          </tbody>
        </Table>
      );
    }

    let ItemsList = [];
    for (let doc of documents) {
      let btnDetailClass = ["sign-status"];

      if (doc.sign_permission === 1) {
        if (doc.signed === 1) {
          btnDetailClass.push("signed");
        } else {
          btnDetailClass.push("wait-sign");
        }
      }
      if (doc.function_type_id === 4 && doc.status_id === 14) {
        // doc.status_label = "document.status.cannot-timestamp";
        // doc.status_code = "error";
        doc.status_label = "document.status.protected-PDF-supported";
        doc.status_code = "confirmed";
      }
      if (doc.remarks === "protected" && [1, 2, 3].includes(doc.function_type_id)) {
        // if (doc.status_id === 14 && [1, 2, 3].includes(doc.function_type_id)) {
        doc.status_code = "error";
      }

      let Amount = (doc.amount || "0").toString();
      Amount = Amount.split(".");
      Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Amount = Amount.join(".") + " " + (doc.currency || "JPY");

      let CounterParty = () => {
        if (doc.function_type_id === 4) {
          return (
            <td className="counter-party-info">
              <div className="inner-container">
                <span>{doc.counter_party_name}</span>
              </div>
            </td>
          );
        } else {
          return (
            <td className="counter-party-info">
              <div className="inner-container"></div>
            </td>
          );
        }
      };

      let PreservationRequire = () => {
        let item_preservation_require = "";

        if (parseInt(doc.preservation_require) === 1) {
          item_preservation_require = "common:documents.document-info.other";
        } else if (parseInt(doc.preservation_require) === 2) {
          item_preservation_require = "common:documents.document-info.new-article-seven";
        } else if (parseInt(doc.preservation_require) === 3) {
          item_preservation_require = "common:documents.document-info.scanner-storage";
        }

        return [
          <td
            key={9}
            className="function-type-info clickable"
            onClick={(ev) => {
              this.showDetailPage(ev, doc);
            }}
          >
            <div className="inner-container">
              <span>{t(item_preservation_require)}</span>
            </div>
          </td>,
        ];
      };

			let linkageClass = "";
			if (doc.master_document_id) {
				linkageClass = " has-linkage";
			}

      ItemsList.push(
        <tr
          key={doc.id}
          className={`document-row-list clickable${linkageClass}`}
          onClick={(ev) => {
            this.showDetailPage(ev, doc);
          }}
        >
          <td className="creator-info">
            <div className="inner-container">
              <span>{doc.full_name}</span>
            </div>
          </td>
          <td className="function-type-info">
            <div className="inner-container">
              <span>{t(`common:${doc.function_type}`)}</span>
            </div>
          </td>
          <PreservationRequire />
          <td className="title-info">
            <div className="inner-container">
              <span>{doc.id + " - " + doc.title}</span>
              <div className={"document-progress " + doc.status_code}>
                <span className="status-label">{t(`common:${doc.status_label}`)}</span>
              </div>
            </div>
          </td>
          {/* <td className="title-info">
						<div className="inner-container pdf_name">
							
							<div className={"document-files"}>
								<PDFFilesInDocument files={doc.files} />
							</div>
						</div>
					</td> */}
          <CounterParty />
          <td className="amount-info">
            <div className="inner-container">
              <span>{Amount}</span>
            </div>
          </td>
          <td className="document-created-date">
            <div className="inner-container">
              <span>{doc.issued_date}</span>
            </div>
          </td>
          {/* <td className="document-delete doc-row-action">
						<DeleteAction />
					</td> */}
        </tr>
      );
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>
              <div>
                <span>{t("common:documents.creator")}</span>
              </div>
            </th>
            <th>
              <div>
                <span>{t("common:documents.document-info.function-type")}</span>
              </div>
            </th>
            <th>
              <div>
                <span>
                  {t("common:documents.document-info.preservation-require")}
                </span>
              </div>
            </th>
            <th>
              <div>
                <span>{"書類情報"}</span>
              </div>
            </th>
            {/* <th>
						<div>
						<span>{t("common:documents.document-info.file-name")}</span>
						</div>
					</th> */}
            <th>
              <div>
                <span>{t("common:documents.document-info.counter-party")}</span>
              </div>
            </th>
            <th>
              <div>
                <span>{t("common:documents.document-info.amount")}</span>
              </div>
            </th>
            <th>
              <div>
                <span>{t("common:documents.issued-date")}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>{ItemsList}</tbody>
      </Table>
    );
  };

  render() {
    let { t } = this.props;
    let { searchConditionTrue, searchOption } = this.state;
    const {
      Main,
      ItemsList,
      DocumentDetailPage,
      PageListNavigator,
      RocordSorting,
      ReturnSearchConditionButton,
      AdditionalButtons,
    } = this;

    if (searchConditionTrue) {
      return <Search formData={searchOption} />;
    }

    return (
      <Main>
        <DocumentDetailPage />

        <div className="search-container">
          <div className="header-wrapper">
            <div className="header-left">
              <ReturnSearchConditionButton />
              <AdditionalButtons />
            </div>

            <div className="header-right">
              <RocordSorting />

              <Nav
                activeKey={searchOption.take}
                onSelect={this.onRecordLimitChangeHandler}
                className="mr-auto"
              >
                <NavDropdown
                  title={
                    searchOption.take +
                    " " +
                    t("common:search.view.record-per-page")
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item eventKey={50}>
                    {"50 " + t("common:search.view.record-per-page")}
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey={100}>
                    {"100 " + t("common:search.view.record-per-page")}
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey={200}>
                    {"200 " + t("common:search.view.record-per-page")}
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey={500}>
                    {"500 " + t("common:search.view.record-per-page")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>

              <div className="pagination">
                <PageListNavigator />

                <button className="btn-pagination" onClick={this.prevPageData}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <button className="btn-pagination" onClick={this.nextPageData}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </div>

          <div className="body-wrapper">
            <ItemsList />
          </div>
        </div>
      </Main>
    );
  }
}

export default withTranslation()(SearchResult);
