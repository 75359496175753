import { useTranslation } from "react-i18next";
import PDFViewer from "./index";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

// import {
//     Form,
//     Button,
// } from 'react-bootstrap';
// import CryptoJS from 'crypto-js';
// import Layout from "../../../Layout";


function FullPreview(props) {
    const { t } = useTranslation('common');
    let { files, file, active, onClose } = props;

    if (!files || !active) {
        return <div className="pdf-full-preview-wrapper"></div>;
    }

    let viewProps = {
        files: files,
        file: file,
        upload: false,
        showAll: true,
        fit: true,
        controller: true,
        info: true,
        full: true,
    };

    if (Object.keys(files).length > 1) {
        viewProps.list = true;
        viewProps.info = false;
    }

    return (
        <div className="pdf-full-preview-wrapper active">
            <div className="header-wrapper sticky">
                <div className="header-left">
                    {/* <button className="btn-return" onClick={onClose}>
                        <FontAwesomeIcon icon={faShareSquare} className="fa-flip-horizontal" title={t("common:document.return")} />
                    </button> */}
                </div>
                <div className="header-center document-title">
                    {/* {`${docInfo.id}: ${docInfo.title}`} */}
                </div>
                <div className="header-right">
                    <IconButton className="btn-return" onClick={onClose}>
                        <CloseIcon className="fa-flip-horizontal" title={t("common:document.close")} />
                    </IconButton>
                </div>
            </div>
            <div className="body-wrapper">
                <PDFViewer {...viewProps} />
            </div>
        </div>
    );

}

export default FullPreview;
