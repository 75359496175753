import React from 'react';
import { Link } from "react-router-dom";

import {
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	Form,
	Button,
	InputGroup,
	Dropdown,
} from 'react-bootstrap';
import CompanyForm from './CompanyForm';
import CountryCode from '../../../Config/CountryCode';

class UserForm {

	static get key() {
		return "user";
	}
	static get name() {
		return "UserForm";
	}
	static get code() {
		return UserForm.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = UserForm.key;
		this.name = UserForm.name;
		this.code = UserForm.code;
		this.formData = {
			lastname: "common:auth.registration.user.lastname",
			firstname: "common:auth.registration.user.firstname",
			lastname_kana: "common:auth.registration.user.lastname-kana",
			firstname_kana: "common:auth.registration.user.firstname-kana",
			email: "common:auth.registration.user.email",
			mobile: "common:auth.registration.user.mobile",
			password: "common:auth.general.password",
			password_confirm: "common:auth.registration.user.comfirm-password",
			country_code: "common:auth.registration.user.country-code",
			full_mobile: "common:auth.registration.user.mobile",
		};
		this.initialValues = {
			country_code: 81,
		}

		this.objMain.setPageObject(this);
	}

	changeCountryCode = (ev) => {
		this.formData.country_code = parseInt(ev.target.dataset.code);
		// console.log(this.formData.country_code);
		this.objMain.setFormData(this.formData);
	}

	CountryPhoneCode = () => {
		let PhoneCode = [];
		CountryCode.sort(function (a, b) {
			if (a.hasOwnProperty("sequence")) {
				if (b.hasOwnProperty("sequence")) {
					if (a.sequence < b.sequence) {
						return -1;
					} else {
						return 1;
					}
				} else {
					return -1;
				}
			} else {
				return 0;
			}
		});
		// console.log(CountryCode);
		let defaultEnd = false;
		for (let i in CountryCode) {
			let CC = CountryCode[i];
			if (!defaultEnd && !CC.hasOwnProperty("sequence")) {
				break;
				// PhoneCode.push(<Dropdown.Divider key="separetor" />);
				// defaultEnd = true;
			}
			PhoneCode.push(<Dropdown.Item key={i} onClick={this.changeCountryCode} data-code={CC.code}>{`+${CC.code} - ${CC.country}`}</Dropdown.Item>);
		}

		return PhoneCode;
	}

	passowrdValidity = (form, fieldValidate, message) => {
		if (message) {
			form.password.setCustomValidity(message);
			form.password_confirm.setCustomValidity(message);
			fieldValidate[this.name].password = message;
			fieldValidate[this.name].password_confirm = message;
		} else {
			form.password.setCustomValidity("");
			form.password_confirm.setCustomValidity("");
		}
	}

	additionalValidity = (form) => {
		// console.dir(form);
		let { fieldValidate } = this.objMain.state;
		// form.firstname.setCustomValidity(true);
		// fieldValidate[this.name]["firstname"] = "custom error.";

		if (form.password.value !== form.password_confirm.value) {
			this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.not-match");
		} else {
			if (form.password.value.length < 12) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.length-too-short");
			} else if (form.password.value.length > 32) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.length-too-long");
			} else if (!(/[A-Z]/).test(form.password.value)) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
			} else if (!(/[a-z]/).test(form.password.value)) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
			} else if (!(/[0-9]/).test(form.password.value)) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
			} else if (!(/!|@|#|&|%|,|_|~|<|>|=|\$|\^|\*|\(|\)|\?|\.|\||\[|\]|\{|\}|-|\+|\//).test(form.password.value)) {
				// } else if (!(/[!@#&%,_~<>=\$\^\*\(\)\?\.\|\[\]\{\}\-\+\/]/).test(form.password.value)) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
			} else if ((/`|;|:|"|'|\\/).test(form.password.value)) {
				// } else if ((/[`;:\"\'\\]/).test(form.password.value)) {
				this.passowrdValidity(form, fieldValidate, "message.errors.password.validate.wrong-policy");
			} else {
				this.passowrdValidity(form, fieldValidate);
			}
		}

		this.objMain.setState({ fieldValidate });

		if (Object.keys(fieldValidate[this.name]).length > 0) {
			return false;
		} else {
			return true;
		}
	};

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;
		form.password.setCustomValidity("");
		form.password_confirm.setCustomValidity("");

		this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback)
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;
		if (this.additionalValidity(form) === false) {
			ev.stopPropagation();
		} else {
			this.formData.full_mobile = "+" + this.formData.country_code + this.formData.mobile;
			this.objMain.setFormData(this.formData);
			this.objMain.setActivePage(CompanyForm);
		}
	};

	render() {

		let { t } = this.props;
		let { formValidate } = this.objMain.state;
		let { FieldControl } = this.objMain;
		let { CountryPhoneCode, fields } = this;

		return (

			<Form
				validated={formValidate[this.name]}
				id={"form_" + this.name}
				onSubmit={this.formPrepareBeforeSubmit}
				noValidate
			>

				<div className="fields-container">

					<Form.Row>
						{FieldControl({ field: fields["lastname"], required: true })}
						{FieldControl({ field: fields["firstname"], required: true })}
					</Form.Row>

					<Form.Row>
						{FieldControl({ field: fields["lastname_kana"] })}
						{FieldControl({ field: fields["firstname_kana"] })}
					</Form.Row>

					<Form.Row>
						{FieldControl({
							field: fields["email"],
							required: true,
							prepend: (props) => {
								return (
									<InputGroup.Prepend>
										<InputGroup.Text id="email_prepend">
											<span>@</span>
										</InputGroup.Text>
									</InputGroup.Prepend>
								);
							}
						})}
					</Form.Row>

					<Form.Row>
						{FieldControl({
							field: fields["mobile"],
							required: true,
							prepend: (props) => {
								return (
									<Dropdown className="input-group-prepend">
										{/* <Dropdown.Toggle id="dropdown-basic" className="standard-prepend-append">
											<span>+{this.formData.country_code}</span>
										</Dropdown.Toggle> */}

										<Dropdown.Menu>
											<CountryPhoneCode />
										</Dropdown.Menu>
									</Dropdown>
								);
							}
						})}
					</Form.Row>

					<Form.Row>
						{FieldControl({ field: fields["password"], type: 'password', required: true })}
						{FieldControl({ field: fields["password_confirm"], type: 'password', required: true })}
					</Form.Row>

					<Form.Row>
						<div controlId="password_message" className="custom-form-outline col">
							<div>
								{t("common:message.general.password-input-rule")}
							</div>
						</div>
					</Form.Row>

				</div>

				<div className="form-bottom">
					<div className="wrapper-button-cancel">
						<Link className="btn btn-secondary" to="/login">{t('common:auth.general.cancel')}</Link>
					</div>
					<div className="wrapper-button-submit">
						<Button variant="primary" type='submit'>
							<span>{t('common:auth.general.next')}</span>
							<FontAwesomeIcon icon={faChevronRight} />
						</Button>
					</div>
				</div>

			</Form>
		);

	}

}

export default UserForm;
