import React from 'react';

import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	Form,
	Col,
	Button,
} from 'react-bootstrap';

import UserForm from './UserForm';
import InfoConfirm from './InfoConfirm';
import CountryCode from '../../../Config/CountryCode';

class CompanyForm {

	static get key() {
		return "company";
	}
	static get name() {
		return "CompanyForm";
	}
	static get code() {
		return CompanyForm.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = CompanyForm.key;
		this.name = CompanyForm.name;
		this.code = CompanyForm.code;
		this.formData = {
			company_name: "common:auth.registration.company.name",
			company_name_kana: "common:auth.registration.company.name-kana",
			company_address: "common:auth.registration.company.address",
			company_telephone: "common:auth.registration.company.telephone",
			company_country: "common:auth.registration.company.country",
		};
		this.initialValues = {
			company_country: "Japan",
		}

		this.objMain.setPageObject(this);
	}

	CountrySelection = () => {
		let PhoneCode = [];
		CountryCode.sort(function (a, b) {
			if (a.hasOwnProperty("sequence")) {
				if (b.hasOwnProperty("sequence")) {
					if (a.sequence < b.sequence) {
						return -1;
					} else {
						return 1;
					}
				} else {
					return -1;
				}
			} else {
				return 0;
			}
		});
		// console.log(CountryCode);
		let defaultEnd = false;
		for (let i in CountryCode) {
			let CC = CountryCode[i];
			if (!defaultEnd && !CC.hasOwnProperty("sequence")) {
				break;
				// PhoneCode.push(<Dropdown.Divider key="separetor" />);
				// defaultEnd = true;
			}
			PhoneCode.push(<option key={i} value={CC.country}>{CC.country}</option>);
		}

		return PhoneCode;
	}

	formSubmitCallback = (ev) => {
		this.objMain.setFormData(this.formData);
		this.objMain.setActivePage(InfoConfirm);
	};

	render() {

		let { t } = this.props;
		let { formValidate } = this.objMain.state;
		let { FieldControl } = this.objMain;
		let { CountrySelection, fields } = this;

		// console.log(fields);
		// console.log(formData);

		return (

			<Form
				validated={formValidate[this.name]}
				id={"form_" + this.name}
				onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
				noValidate
			>

				<div className="fields-container">

					<Form.Row>
						{FieldControl({ field: fields["company_name"], required: true })}
					</Form.Row>

					<Form.Row>
						{FieldControl({ field: fields["company_name_kana"] })}
					</Form.Row>

					<Form.Row>
						{FieldControl({ field: fields["company_address"], required: true })}
					</Form.Row>

					<Form.Row>
						{/* {FieldControl({ field: fields["company_address"], options: CountrySelection, required: true })} */}
						<Form.Group as={Col} controlId="company_country" className="custom-form-outline">
							<Form.Label>{this.fields["company_country"].label}</Form.Label>
							<Form.Control as="select"
								name='company_country'
								value={this.formData.company_country}
								onChange={this.objMain.onChangeHandler}
								required
							>
								<CountrySelection />
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: this.fields["company_country"].label })}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>

					<Form.Row>
						{FieldControl({ field: fields["company_telephone"], required: true })}
					</Form.Row>

				</div>

				<div className="form-bottom">
					<div className="wrapper-button-cancel">
						<Button variant="secondary" onClick={(ev) => this.objMain.setActivePage(UserForm)}>
							<FontAwesomeIcon icon={faChevronLeft} />
							<span>{t('common:auth.general.back')}</span>
						</Button>
					</div>
					<div className="wrapper-button-submit">
						<Button variant="primary" type='submit'>
							<span>{t('common:auth.general.next')}</span>
							<FontAwesomeIcon icon={faChevronRight} />
						</Button>
					</div>
				</div>

			</Form>

		);

	}

}

export default CompanyForm;