import React from 'react';
import {
	Form,
	Col,
	Container,
	InputGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faPlus, faQuestion, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from '../Loading';
import FileExplorer from '../Fragments/FileExplorer';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default class DocumentMultipleInfo {

	static get key() {
		return "document-info";
	}
	static get name() {
		return "DocumentMultipleInfo";
	}
	static get code() {
		return DocumentMultipleInfo.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = DocumentMultipleInfo.key;
		this.name = DocumentMultipleInfo.name;
		this.code = DocumentMultipleInfo.code;
		this.formData = {
			document_type: "common:documents.document-info.document-type",
			doc_title: "common:documents.document-info.doc-title",
			amount: "common:documents.document-info.amount",
			currency: "common:documents.document-info.currency",
			sign_level: "common:documents.document-info.sign-level",
			display_imprint: "common:documents.document-info.display-imprint",
			contract_date: "common:documents.document-info.contract-date",
			expiry_date: "common:documents.document-info.expiry-date",
			document_create_date: "common:documents.document-info.document-create-date",
			// received_date: "common:documents.document-info.received-date",
			received_date: "common:documents.document-info.deal-date",
			// deal_date: "common:documents.document-info.deal-date",
			counter_party_name: "common:documents.document-info.counter-party-name",
			contract_counter_party_name: "common:documents.document-info.contract-counter-party-name",
			product: "common:documents.document-info.product",
			reference: "common:documents.document-info.reference",
			filebox_shortcut: "common:documents.document-info.shortcut-to-filebox",
			doc_attributes: "common:documents.document-info.attributes",
			content: "common:documents.document-info.content",
			preservation_require: "common:documents.document-info.preservation-require",
		};
		this.tempSelectedFilebox = null;
		this.initialValues = {
			// document_type: "contract",
			// currency: "JPY",
			display_imprint: true,
			doc_attributes: [],
			// doc_title: "Document Title",
			// amount: 1234234.234,
			filebox_shortcut: "",
		}

		this.objMain.setPageObject(this);
	}

	customValidity = (ev = null) => {
		let { PDFFiles, PDFFile, formData, creationType } = this.objMain.state;
		let allValidity = true;

		for (let key in formData) {
			PDFFile.formData[key] = formData[key];
		}
		// PDFFile.formData.doc_title = formData.doc_title;
		// PDFFile.formData.amount = formData.amount;
		// PDFFile.formData.received_date = formData.received_date;
		// PDFFile.formData.counter_party_name = formData.counter_party_name;

		for (let key in PDFFiles) {
			let PDFFile = PDFFiles[key];
			if (!PDFFile.formData) {
				PDFFile.validity = false;
				allValidity = false;
			} else {
				PDFFile.validity = true;
				if (creationType !== "verification" || ![1, "1"].includes(PDFFile.formData.preservation_require)) {
					let validate = [];
					validate.push(PDFFile.formData.doc_title ? true : false);
					validate.push(PDFFile.formData.amount ? true : false);
					validate.push(PDFFile.formData.received_date ? true : false);
					validate.push(PDFFile.formData.counter_party_name ? true : false);
					if (validate.includes(false)) {
						PDFFile.validity = false;
						allValidity = false;
					}
				}
			}
		}

		this.objMain.updateState({ PDFFiles });

		return allValidity;
	}

	formSubmitCallback = (ev) => {
		if (this.customValidity(ev)) {
			let { pageState } = this.objMain.state;
			pageState[this.name].completed = true;
			this.objMain.setFormData(this.formData);
			this.objMain.updateState({ pageState: pageState });
			this.objMain.nextPageStep(ev, this.constructor);
		}
	}

	/** */
	addAttribute = (ev) => {
		/* */
		// let key = "Note" + this.formData.doc_attributes.length;
		this.formData.doc_attributes.push({ key: "", value: "" });
		this.objMain.setFormData(this.formData);
	}

	removeAttribute = (ev) => {
		/* */
		// console.log(ev.target.closest(".remove").dataset);
		let row = parseInt(ev.target.closest(".remove").dataset.row);
		this.formData.doc_attributes.splice(row, 1);
		this.objMain.setFormData(this.formData);
	}

	setAttibute = (ev) => {
		// console.log(ev);
		// console.log(ev.target.value);
		let row = parseInt(ev.target.dataset.row);
		let type = ev.target.dataset.type;
		if (type === "key") {
			this.formData.doc_attributes[row].key = ev.target.value;
		} else {
			this.formData.doc_attributes[row].value = ev.target.value;
		}
		this.objMain.setFormData(this.formData);
	}

	renderAttributes = (ev) => {
		let { t } = this.props;
		let { fieldValidate } = this.objMain.state;
		let attrRows = [];
		// console.log(this.formData.doc_attributes);
		for (let i in this.formData.doc_attributes) {
			let attr = this.formData.doc_attributes[i];
			attrRows.push(
				<Form.Row key={i}>
					{/* {FieldControl({ name: "amount", type: "number", step: "0.01", xs: 3, md: 6, required: true, validate: "true" })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, required: true, children: this.CurrencyItems() })} */}

					<Form.Group xs={4} as={Col} controlId={"key[" + i + "]"} className={fieldValidate[this.name]}>
						<Form.Control
							name={"key[" + i + "]"}
							value={attr.key}
							data-row={i}
							data-type="key"
							onChange={this.setAttibute}
							placeholder={t("common:documents.document-info.key")}
							required
						/>
						<Form.Control.Feedback type="invalid">
							{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group xs={8} as={Col} controlId={"value[" + i + "]"} className={fieldValidate[this.name]}>
						<InputGroup hasValidation>
							<Form.Control
								name={"value[" + i + "]"}
								value={attr.value}
								data-row={i}
								data-type="value"
								onChange={this.setAttibute}
								placeholder={t("common:documents.document-info.value")}
								required
							/>
							<button type="button" data-row={i} className="btn-icon remove" onClick={this.removeAttribute}>
								<FontAwesomeIcon className="file-remove" icon={faTrash} />
							</button>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</InputGroup>
					</Form.Group>

				</Form.Row>

			);
		}

		return attrRows;
	}

	showFileboxPathSelection = async (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			"dialogClassName": "modal-full zero-padding",
			"centered": true,
		}
		modalTitle = "common:documents.document-info.filebox-select-path";
		ModalAction = [];
		ModalBody = Loading;
		ModalAction.push(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				// this.autoAddImprints(ev);
				this.formData.filebox_shortcut = this.tempSelectedFilebox;
				this.objMain.setFormData(this.formData);
				this.objMain.modalToggle(false);
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);
		this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps });
		this.fileBoxSelectionBody({
			path: "/",
			type: "D",
		});

	}

	fileBoxSelectionCallback = (resp) => {
		// console.log(resp);
		this.tempSelectedFilebox = resp;
	}

	fileBoxSelectionBody = (options) => {
		let { ModalBody } = this.objMain.state;

		ModalBody = () => {
			return <FileExplorer type="D" readOnly={true} responseCallback={this.fileBoxSelectionCallback} />;
		};
		this.objMain.updateState({ ModalBody });
		// this.setState({ FileboxStorage });
	}

	CurrencyItems = () => {
		let { PageData } = this.objMain.state;
		let { currencies } = PageData;
		let OptionItems = [];
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { doc_types } = PageData;

		let ItemElements = [];
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	PreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	SignLevelItems = () => {
		let { t } = this.props;
		let items = {
			0: "soft",
			1: "mixed",
			2: "strong",
		}

		let ItemElements = [];
		for (let i in items) {
			ItemElements.push(<option key={i} value={i}>{t(`common:documents.document-info.sign-level-${items[i]}`)}</option>);
		}

		return ItemElements;
	}

	SignLevelCallback = () => {
		let { formData } = this.objMain.state;

		// console.log(formData.sign_level);
		if (formData.sign_level.toString() === "0") {
			formData.use_digital_certificate = false;
		} else if (formData.sign_level.toString() === "2") {
			formData.use_digital_certificate = true;
		}
		// console.log(formData.use_digital_certificate);
		this.objMain.setFormData(formData);
	}

	FileInfoForm = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;

		let { creationType } = this.objMain.state;
		let { formData } = this;
		// let { Form }
		// let { PDFFile } = this.objMain.state;

		let required = true;
		if (creationType === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		return (

			<div className="form-body">

				<Form.Row>
					{FieldControl({ name: "document_type", as: "select", xs: 12, md: 6, required, validate: "true", children: this.DocumentTypeItems() })}
					{FieldControl({ name: "preservation_require", as: "select", xs: 12, md: 6, required, validate: "true", children: this.PreservationRequireItems(), change: this.customValidity })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "doc_title", xs: 12, md: 6, required, validate: "true", change: this.customValidity })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "amount", type: "number", step: "0.01", xs: 8, md: 6, required, validate: "true", change: this.customValidity })}
					{FieldControl({ name: "currency", as: "select", xs: 4, md: 2, required, validate: "true", children: this.CurrencyItems() })}
				</Form.Row>

				{/* for verification */}
				<Form.Row>
					{FieldControl({ name: "received_date", type: "date", xs: 12, md: 4, required, validate: "true", change: this.customValidity })}
					{/* {FieldControl({ name: "deal_date", type: "date", xs: 12, md: 4, required, validate: "true" })} */}
					{FieldControl({ name: "counter_party_name", xs: 12, md: 8, required, validate: "true", change: this.customValidity })}
				</Form.Row>

				<Form.Row>
					{FieldControl({ name: "product", xs: 12, md: 6 })}
					{FieldControl({ name: "reference", xs: 12, md: 6 })}
				</Form.Row>

				<Form.Row>
					{FieldControl({
						name: "filebox_shortcut", xs: 12, md: 12, readOnly: true,
						className: "filebox-shortcut-path",
						prepend: () => {
							return (
								<InputGroup.Prepend className="mb-3">
									<Button variant="secondary" className="input-group-child" onClick={this.showFileboxPathSelection}>
										<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
									</Button>
								</InputGroup.Prepend>
							)
						}
					})}
				</Form.Row>


				<div className="attributes-wrapper">
					{this.renderAttributes()}
				</div>
				<Form.Row className="attributes-wrapper">

					<Form.Group as={Col} controlId="attributes">
						<Button variant="outlined" onClick={this.addAttribute} className="btn-custom attributes" >
							<FontAwesomeIcon icon={faPlus} />
							<span className="label">{t('common:documents.document-info.attributes')}</span>
						</Button>
					</Form.Group>

				</Form.Row>



			</div>
		);
	}

	PDFFileList = () => {
		let { PDFFiles, selectedFile } = this.objMain.state;
		let fileKeys = Object.keys(PDFFiles);

		let fileList = fileKeys.map((key) => {
			let PDFFile = PDFFiles[key];
			let classlist = ["file-item",];
			if (key === selectedFile) {
				classlist.push("active");
			}

			let iconProps = {};
			if (!PDFFile.hasOwnProperty("validity")) {
				iconProps.className = "file-form-validate";
				iconProps.icon = faQuestion;
			} else if (PDFFile.validity === true) {
				iconProps.className = "file-form-validate-success";
				iconProps.icon = faCheck;
			} else {
				iconProps.className = "file-form-validate-fail";
				iconProps.icon = faExclamationTriangle;
			}

			return (
				<li key={key} data-file={key} className={classlist.join(" ")}>
					<span className="file-name" title={PDFFile.name} onClick={(ev) => {
						let { PDFFiles, PDFFile, formData, originalFormData } = this.objMain.state;
						// console.log(key);
						// console.log(PDFFile.hash);
						// console.log(formData);
						PDFFiles[PDFFile.hash].formData = JSON.parse(JSON.stringify(formData));
						PDFFile = PDFFiles[key];
						formData = JSON.parse(JSON.stringify(PDFFile.formData || originalFormData));
						selectedFile = key;
						this.objMain.updateState({ PDFFiles, PDFFile, formData, selectedFile });
					}}>{PDFFile.name}</span>
					<FontAwesomeIcon {...iconProps} />
				</li >
			);
		});

		return fileList;
	}

	formPrepareBeforeSubmit = (ev) => {
		this.customValidity(ev);
		this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback);
	}

	render() {
		let { t } = this.props;
		let { PDFFileList } = this;
		// console.log(this.objMain.state.PDFFiles);

		return (
			<div className="full-right-body-with-file-list">
				<div className="form-container-with-file-list">
					< Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >

						<Form
							validated={true}
							id={"form_" + this.name}
							onSubmit={this.formPrepareBeforeSubmit}
							// noValidate={false}
							className="full-form"
						>

							<div className="layout-right-title form-header" >
								<h2>{this.headerInfo}</h2>
							</div>

							{this.FileInfoForm()}

							<div className="form-foot">

							</div>

							<div className="page-action-bottom-relative">
								<div className="wrapper-button-cancel">
									<Button
										variant="contained"
										color="info"
										size='large'
										startIcon={<ChevronLeftIcon />}
										onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
										<span className="btn-label">{t('common:documents.general.back')}</span>
									</Button>
								</div>
								<div className="wrapper-button-submit">
									<Button
										variant="contained"
										type="submit"
										size='large'
										endIcon={<ChevronRightIcon />}
									>
										<span className="btn-label">{t('common:documents.general.next')}</span>
									</Button>
								</div>
							</div>

						</Form>

					</Container >
				</div>
				<div className="pdf-info-section file-panel slide">
					<div className="file-upload-list">
						<ul>
							<PDFFileList />
						</ul>
					</div>
				</div>
			</div>

		);

	}

}
