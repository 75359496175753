import React from 'react';
import { withTranslation } from "react-i18next";

import OtpDialog from '../Auth/OtpDialog';
import Layout from '../../Layout';
import Service from '../../Service';
import DocumentDetail from './DocumentDetail';
import Error from '../Error';

class DocumentSign extends Layout {

	constructor(props) {
		super(props);

		this.state.loading = true;
		this.state.isVerifiedGuest = true;
		this.state.document_id = props.match.params.document_id;
		this.state.token = props.match.params.token;
		this.state.docInfo = null;
		this.state.openOtpDialog = false;
		this.state.verified = true;
		this.state.msgPageError = null;
	}

	componentDidMount() {
		console.log("DocumentSign->componentDidMount");
		let { document_id, token } = this.state;
		if (document_id) {
			this.GetDocumentInfo(document_id);
		} else if (token) {
			Service.GetGuestUserInfo(token).then(resp => {
				if (resp.is_applying_guest_mfa) {
					this.setState({ loading: false, verified: true, UserInfo: resp, openOtpDialog: true, isVerifiedGuest: false }, () => { });
				} else {
					this.setState({ UserInfo: resp, verified: true }, () => {
						this.GetGuestDocumentInfo(token);
					});
				}
			}).catch(err => {
				console.log(err.response);
				let msgPageError = "auth.general.expired-token-url";
				if (err.response.data.hasOwnProperty("error")) {
					msgPageError = err.response.data.error;
				}
				this.setState({ verified: false, loading: false, msgPageError });
			});
		}
	}

	GetDocumentInfo = (document_id) => {
		Service.getDocumentInfo(document_id).then(resp => {
			// console.log(resp);
			this.setState({ loading: false, docInfo: resp }, () => {
				// Service.GetUserInfo().then(resp => {
				// 	this.setState({ UserInfo: resp }, () => {
				// 	});
				// }).catch((error) => {
				// 	//console.log("error", error.response);
				// });
			});
		}).catch(err => {
			console.log(err.response);
			let msgPageError = "message.errors.document-not-found";
			if (err.response.data.hasOwnProperty("error")) {
				msgPageError = err.response.data.error;
			} else if (err.response.data.hasOwnProperty("message")) {
				msgPageError = err.response.data.message;
			}
			this.setState({ loading: false, msgPageError });
		});
	}

	GetGuestDocumentInfo = (token) => {
		Service.getGuestDocumentInfo(token).then(resp => {
			this.setState({ loading: false, docInfo: resp });
		}).catch(err => {
			console.log(err.response);
			let msgPageError = "message.errors.document-not-found";
			if (err.response.data.hasOwnProperty("error")) {
				msgPageError = err.response.data.error;
			} else if (err.response.data.hasOwnProperty("message")) {
				msgPageError = err.response.data.message;
			}
			this.setState({ loading: false, msgPageError });
		});
	}

	BodyWrapper = (props) => {
		// let { t } = this.props;
		if (!props.docInfo) {
			return null;
		}
		return (
			<div className={"document-detail static"}>
				{props.docInfo && <DocumentDetail {...props} />}
			</div>
		);
	}

	Nav = (props) => {
		return null;
	}

	handleConfirmOtpGuest(isDocumentVerifyEmail, resp) {
		if (!isDocumentVerifyEmail) {
			return this.setState({
				// openOtpDialog: true,
				isVerifiedGuest: false,
			});
		}
		// let token = this.state.token;
		this.setState({ loading: false, docInfo: resp, isVerifiedGuest: true, openOtpDialog: false });

	}

	onResendUserMail() {
		Service.GetGuestUserInfo(this.state.token).bind(this);
	}

	render() {
		let { t } = this.props;
		let { docInfo, token, loading, verified, isVerifiedGuest, openOtpDialog, msgPageError } = this.state;
		let { MainLoading, Main, BodyWrapper } = this;

		if (loading) {
			return <MainLoading />;
		}

		if (!isVerifiedGuest) {
			return <OtpDialog resendEmailCode={this.onResendUserMail.bind(this)} tokenGuest={token}
				openOtpDialog={openOtpDialog} isVerifyGuestDocument={true} mfaChallengeName={''}
				mfaUser={''} callback={this.handleConfirmOtpGuest.bind(this)} />;
		}

		if (!verified) {
			return <Error code={404} msg={t(`common:${msgPageError}`)} layout={!token} />;
		}

		if (!docInfo) {
			return <Error code={404}
				msg={t('common:general.error-404.signed-in-with-another-account')}
				isCustom={true} layout={true} />
		}
		if (!docInfo) {
			return <Error code={404}
			msg={t('common:general.error-404.signed-in-with-another-account')}
			isCustom={true} layout={true} />
		}

		return (
			<>
				<div className="d-none d-sm-block">
					<Main>
						<BodyWrapper docInfo={docInfo} token={token} />
					</Main>
				</div>
				<div className="main contrainer-wrapper d-block d-sm-none">
					<div className="layout">
						<BodyWrapper docInfo={docInfo} token={token} />
					</div>
				</div>
			</>
		);

	}

}

export default withTranslation()(DocumentSign);