import React from "react";
import { Link } from "react-router-dom";
import Main from "./Main";
import { withTranslation } from "react-i18next";
import { Box, Select, MenuItem } from '@mui/material'
import Service from '../../Service';

import {
	Nav,
	NavDropdown,
	Form,
	Table,
	// Col,
	// InputGroup,
} from 'react-bootstrap';

import {
	faCheckCircle,
	faChevronLeft,
	faChevronRight,
	faExclamationCircle,
	faExclamationTriangle,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from "../Loading";
import LoadingList from '../LoadingList';
import Error from '../Error';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

class Evaluation extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Evaluation";
		this.state.code = "evaluation";
		this.state.function_type = 7;
		this.state.searchOption.function_type = 7;
		this.state.searchOption.status_id = null;
		this.state.evaluates = null;
		this.state.evaluateDocList = {};

		this.state.fields = {};
		this.state.formData = {};
		this.state.keyFields = {
			function_type: "function-type",
			document_type: "document-type",
			doc_title: "doc-title",
			min_amount: "min-amount",
			max_amount: "max-amount",
			currency: "currency",
			display_imprint: "display-imprint",
			contract_date: "contract-date",
			expiry_date: "expiry-date",
			// min_received_date: "min-received-date",
			// max_received_date: "max-received-date",
			min_received_date: "min-deal-date",
			max_received_date: "max-deal-date",
			counter_party_name: "counter-party-name",
			product: "product",
			reference: "reference",
			status_id: "document-status",
			filebox_shortcut: "shortcut-to-filebox",
			doc_attributes: "attributes",
			function_type_list: "function-type-list",
		}
		// console.log(props);
		// JSON.parse(JSON.stringify(this.state.searchOption));
		this.state.docSearchOption = {};
		for (let key in this.state.searchOption) {
			this.state.docSearchOption[key] = this.state.searchOption[key];
		}
		this.state.docSearchOption.status_id = null;
		this.state.docSearchOption.function_type = null;
		this.state.docSearchOption.random = 25;

		// for (let i of QueryParams.keys()) {
		// console.log(this.state.searchOption);

		this.setStateFields();
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:documents.document-info.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			} else {
				formData[key] = fields[key].value;
			}
		}

		this.setState({ fields }, cb);
	}

	CurrencyItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { currencies } = PageData;
		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	RandomSizeItems = (props) => {
		let { t } = this.props;
		let { name } = props;
		let { docSearchOption } = this.state;
		let random_size = [25, 50, 0];

		let OptionItems = [];
		for (let size of random_size) {
			let label = t("common:documents.evaluate.random-size-label", { size });
			if (size === 0) {
				label = t("common:documents.evaluate.manual");
			}
			let radioProps = {
				"name": name,
				"onChange": (ev) => {
					let { docSearchOption, evaluateDocList } = this.state;
					console.log(ev.target.value);
					docSearchOption.random = parseInt(ev.target.value);
					if (docSearchOption.random !== 0) {
						evaluateDocList = {};
					}
					this.setState({ docSearchOption, evaluateDocList });
				},
			};
			// <Form.Check key={size} type="radio" id={`inline-radio-${size}`}
			// 	value={size} label={label} name={name} inline
			// 	onChange={(ev) => {
			// 		console.log(ev.target.value);
			// 		docSearchOption.random = parseInt(ev.target.value);
			// 		this.setState({ docSearchOption });
			// 	}}
			// 	checked={parseInt(docSearchOption.random) === parseInt(size)} />
			OptionItems.push(
				<FormControlLabel key={`random-size-${size}`} value={size} label={label} control={<Radio {...radioProps} />} />
			);
		}

		return (
			<RadioGroup row name="row-radio-buttons-group" aria-labelledby="demo-row-radio-buttons-group-label" value={docSearchOption.random}>
				{OptionItems}
			</RadioGroup>
		);
	}

	FunctionTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { function_types } = PageData;

		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:document.function-type.all")}</option>);
		for (let func of function_types) {
			OptionItems.push(<option key={func.id} value={func.id}>{t(`common:document.function-type.${func.code}`)}</option>);
		}

		return OptionItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { doc_types } = PageData;

		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			OptionItems.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return OptionItems;
	}

	StatusItems = () => {
		let { t } = this.props;
		let { docStatus } = this.state;

		let OptionItems = [];
		OptionItems.push(<option key={"empty"} value="">{t("common:general.all")}</option>);
		for (let i in docStatus) {
			let status = docStatus[i];
			OptionItems.push(<option key={status.id} value={status.id}>{t("common:" + status.label)}</option>);
		}

		return OptionItems;
	}

	SearchForm = () => {
		let { PageData } = this.state;
		let { FieldControl, FunctionTypeItems, DocumentTypeItems, CurrencyItems, StatusItems } = this;

		if (!PageData) {
			return <Loading />;
		}

		return (
			<div className="form-container">

				<Form.Row>
					<FieldControl name="function_type" as="select" xs={12} md={3}>
						<FunctionTypeItems />
					</FieldControl>
					<FieldControl name="document_type" as="select" xs={12} md={3}>
						<DocumentTypeItems />
					</FieldControl>
					<FieldControl name="doc_title" xs={12} md={6} />
				</Form.Row>

				{/* <span>~</span> */}
				<Form.Row>
					<FieldControl name="min_amount" type="number" step="0.01" xs={6} md={5} />
					<FieldControl name="max_amount" type="number" step="0.01" xs={6} md={5} />
					<FieldControl name="currency" as="select" xs={12} md={2}>
						<CurrencyItems />
					</FieldControl>
				</Form.Row>

				{/* for verification */}
				<Form.Row>
					<FieldControl name="min_received_date" type="date" xs={6} md={4} />
					<FieldControl name="max_received_date" type="date" xs={6} md={4} />
					<FieldControl name="status_id" as="select" xs={12} md={4}>
						<StatusItems />
					</FieldControl>
				</Form.Row>

				<Form.Row>
					<FieldControl name="counter_party_name" xs={12} md={12} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="product" xs={12} md={6} />
					<FieldControl name="reference" xs={12} md={6} />
				</Form.Row>

			</div>
		);
	}

	BasicInfoActionButtons = () => {
		let { t } = this.props;
		let { PageData } = this.state;

		let Buttons = [];
		if (PageData) {
			Buttons.push(
				<Button key="manual" type="submit" name="manual_submit" sx={{ ml: 1 }} variant="contained" onClick={this.SubmitButtonHandler}>
					<span>{t("common:documents.evaluate.manual-selection")}</span>
				</Button>
			);
			// Buttons.push(
			// 	<Button key="random" type="submit" name="random_submit" sx={{ ml: 1 }} variant="contained" onClick={this.SubmitButtonHandler}>
			// 		<span>{t("common:documents.evaluate.random-selection", { random_value: docSearchOption.random })}</span>
			// 	</Button>
			// );
		}

		return Buttons;
	}

	SubmitButtonHandler = (ev) => {
		ev.target.closest("form").submited = ev.target.closest("[type=submit]").getAttribute("name");
	}

	HiddenMenuBody = () => {
		let { t } = this.props;
		let { evaluateDocList } = this.state;

		let selectedDocs = Object.keys(evaluateDocList);

		let DocItems = selectedDocs.map((key) => {
			let doc = evaluateDocList[key];

			return (
				<li key={key} className="document-selected-item">
					<div className="document-info" >
						<span>{`${doc.id}: ${doc.title}`}</span>
					</div>
					<div className="document-action" >
						<CancelOutlinedIcon
							className="btn-remove" onClick={(ev) => {
								let { evaluateDocList } = this.state;
								if (evaluateDocList[doc.id]) {
									delete evaluateDocList[doc.id];
								}
								this.setState({ evaluateDocList });
							}} />
					</div>
				</li>
			);
		});

		return [(
			<div key="selected-header" className="document-selected-header">
				{t("common:documents.evaluate.selected-list")}
			</div>
		), (
			<ul key="selected-boby" className="document-selected-list">
				{DocItems}
			</ul>
		)];
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;
		let { formData, docSearchOption } = this.state;
		for (let i in formData) {
			docSearchOption[i] = formData[i];
		}

		console.log(form.submited);
		console.log(docSearchOption);
		// ev.stopPropagation();
		if (form.submited === "random_submit") {
			this.setState({ docSearchOption }, this.EvaluateSaveRandomConfirm);
		} else /* for manual selection */ {
			let { modal } = this.state;
			modal.onClose = null;
			this.setState({ modal }, () => {
				let { modal } = this.state;
				modal.props.show = false;
				this.setState({ modal, docSearchOption, showDetail: true, showHiddenMenu: true }, this.SearchDocumentListData);
			});
		}
	}

	SearchDialog = () => {
		let { t } = this.props;
		let { modal } = this.state;

		modal.props = {
			show: true,
			centered: true,
			size: "lg",
		};

		modal.title = t('common:documents.evaluated-filter');

		modal.form = {
			id: "user-info-form",
			onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
			noValidate: true,
		};

		modal.action = this.BasicInfoActionButtons;
		modal.body = this.SearchForm;
		modal.onClose = (ev) => {
			let { modal, formData, docSearchOption } = this.state;

			for (let key in docSearchOption) {
				formData[key] = docSearchOption[key];
			}

			modal.onClose = null;
			this.setState({ modal, formData });
		};


		this.setState({ modal, formValidate: false }, () => {
			if (!this.state.PageData) {
				// for fix status list not show in search dialog
				// Service.GetPageData("verification").then(resp => {
				Service.GetPageData("evaluation").then(resp => {
					resp.func_types_index = {};
					resp.func_types_index[0] = {
						"id": 0,
						"code": "all",
					};
					if (resp.function_types) {
						for (let func of resp.function_types) {
							resp.func_types_index[func.id] = func;
						}
					}
					this.setState({ PageData: resp });
				});
			}
		});
	}

	GetDocumentListData = (searchOption) => {
		searchOption = this.PageCalculation(searchOption);
		this.setState({ evaluates: null, searchOption }, () => {
			// console.log(searchOption);
			Service.getEvaluateHistory(searchOption).then(resp => {
				let { evaluates } = this.state;
				evaluates = resp.records;
				searchOption.total_records = resp.total_records;
				searchOption = this.PageCalculation(searchOption);
				// console.log(evaluates);
				this.setState({ evaluates, searchOption });
			}).catch(err => {
				//console.log(err.response);
			});
		});
	}

	SearchDocumentListData = (docSearchOption) => {
		let { function_type, formData } = this.state;
		if (!docSearchOption) {
			docSearchOption = this.state.docSearchOption;
		}
		docSearchOption = this.PageCalculation(docSearchOption);
		if (docSearchOption.function_type) {
			docSearchOption.function_type_list = "";
		} else {
			if (function_type === 7) {
				docSearchOption.function_type_list = "[1,2,3,4]";
			} else {
				docSearchOption.function_type_list = "[1,2,4]";
			}
		}

		for (let key in docSearchOption) {
			formData[key] = docSearchOption[key];
		}

		this.setState({ documents: null, docSearchOption, formData }, () => {
			// console.log(docSearchOption);
			Service.getDocumentList(docSearchOption).then(resp => {
				let { documents } = this.state;
				documents = resp.records;
				docSearchOption.total_records = resp.total_records;
				docSearchOption = this.PageCalculation(docSearchOption);
				// console.log(documents);
				this.setState({ documents, docSearchOption });
			}).catch(err => {
				//console.log(err.response);
			});
		});
	}

	EvaluateSaveRandomConfirm = () => {
		let { t } = this.props;
		let { modal } = this.state;

		modal.props = {
			show: true,
			centered: true,
		};

		modal.form = {};
		modal.action = [];
		modal.title = t('common:documents.evaluated-confirm');
		modal.body = t('common:documents.evaluated-confirm-question');
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				let { modal } = this.state;
				modal.title = t('common:documents.evaluated-on-process');
				modal.body = Loading;
				modal.form = {};
				modal.action = [];
				this.setState({ modal }, this.EvaluateSaveRandom);
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		this.setState({ modal });
	}

	EvaluateSaveRandom = () => {
		let { t } = this.props;
		let { modal, docSearchOption } = this.state;

		Service.startEvaluateRandom(docSearchOption).then(resp => {
			let { searchOption } = this.state;

			console.log(resp);

			modal.props.show = false;
			this.setState({ modal, showDetail: false, showHiddenMenu: false, documents: null }, () => {
				this.GetDocumentListData(searchOption);
			});
		}).catch(err => {
			console.log(err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			modal.title = t('common:documents.evaluated-error');
			modal.body = t(`common:${errMessage}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.title = t('common:documents.evaluated-on-process');
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.EvaluateSaveRandom);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	onDocumentFunctionTypeChangeHandler = (ev) => {
		let selected = ev;
		// console.log(selected);
		selected = parseInt(ev) || null;

		let { docSearchOption } = this.state;
		if (selected !== docSearchOption.function_type) {
			docSearchOption.function_type = selected;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentStatusChangeHandler = (ev) => {
		let status = ev;
		if (status !== "all") {
			status = parseInt(ev);
		} else {
			status = null;
		}
		let { docSearchOption } = this.state;
		if (status !== docSearchOption.status_id) {
			docSearchOption.status_id = status;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentSortingHandler = (ev) => {
		let sort = parseInt(ev);
		let { docSearchOption } = this.state;
		if (sort !== docSearchOption.sorting_code) {
			docSearchOption.sorting_code = sort;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListChangeHandler = (ev) => {
		let page = parseInt(ev.target.value);
		let { docSearchOption } = this.state;
		let nextSkip = (page - 1) * docSearchOption.take;
		if (nextSkip !== docSearchOption.skip) {
			docSearchOption.skip = nextSkip;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	onDocumentListLimitChangeHandler = (ev) => {
		let limit = parseInt(ev.target.value);
		let { docSearchOption } = this.state;
		if (limit !== docSearchOption.take) {
			let cursor = (docSearchOption.current - 1) * docSearchOption.take;
			let page = Math.floor(cursor / limit);
			docSearchOption.skip = page * limit;
			docSearchOption.take = limit;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	DocumentFunctionTypeFilter = () => {
		let { t } = this.props;
		let { docSearchOption, PageData } = this.state;
		let { function_types, func_types_index } = PageData;

		let FilterItems = [];
		FilterItems.push(<NavDropdown.Item key={0} eventKey={0}>{t("common:document.function-type.all")}</NavDropdown.Item>);
		for (let func of function_types) {
			FilterItems.push(<NavDropdown.Item key={func.id} eventKey={func.id}>{t(`common:document.function-type.${func.code}`)}</NavDropdown.Item>);
		}

		let funcActive = func_types_index[docSearchOption.function_type || 0];
		console.log(funcActive);

		return (
			<Nav activeKey={funcActive.id} onSelect={this.onDocumentFunctionTypeChangeHandler} className="mr-auto">
				<NavDropdown title={t(`common:document.function-type.${funcActive.code}`)} id="basic-nav-dropdown">
					{FilterItems}
				</NavDropdown>
			</Nav>
		);
	}

	DocumentStatusListFilter = () => {
		let { t } = this.props;
		let { docSearchOption, docStatus } = this.state;
		if (Object.keys(docStatus).length === 0) {
			return null;
		}


		let statusItems = [];
		statusItems.push(<NavDropdown.Item key="all" eventKey="all">{t("common:document.status.all")}</NavDropdown.Item>);
		for (let i in docStatus) {
			let status = docStatus[i];
			statusItems.push(<NavDropdown.Item key={status.id} eventKey={status.id}>{t(`common:${status.label}`)}</NavDropdown.Item>)
		}

		let active = docSearchOption.status_id;
		let title = t("common:document.status.all");
		if (isNaN(parseInt(active))) {
			active = "all";
		} else {
			let currentStatus = docStatus[active];
			title = t(`common:${currentStatus.label}`);
		}

		return (
			<Nav activeKey={active} onSelect={this.onDocumentStatusChangeHandler} className="mr-auto">
				<NavDropdown title={title} id="basic-nav-dropdown">
					{statusItems}
				</NavDropdown>
			</Nav>
		);
	}

	DocumentListSorting = () => {
		let { t } = this.props;
		let { docSearchOption } = this.state;
		let sortList = {
			2: "common:search.sorting.decending.by-issued-date",
			1: "common:search.sorting.ascending.by-issued-date",
		};
		let sortItems = [];

		for (let i in sortList) {
			sortItems.push(<NavDropdown.Item key={i} eventKey={i}>{t(sortList[i])}</NavDropdown.Item>)
		}

		return (
			<Nav activeKey={docSearchOption.sorting_code} onSelect={this.onDocumentSortingHandler} className="mr-auto">
				<NavDropdown title={t(sortList[docSearchOption.sorting_code])} id="basic-nav-dropdown">
					{sortItems}
				</NavDropdown>
			</Nav>
		);
	}

	docListPrevPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextSkip = docSearchOption.skip - docSearchOption.take;
		if (nextSkip >= 0) {
			docSearchOption.skip = nextSkip;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	docListNextPageData = (ev) => {
		let { docSearchOption } = this.state;
		let nextSkip = docSearchOption.skip + docSearchOption.take;
		if (nextSkip < docSearchOption.total_records) {
			docSearchOption.skip = nextSkip;
			this.SearchDocumentListData(docSearchOption);
		}
	}

	// DocumentListNavigator = () => {
	// 	let { docSearchOption } = this.state;
	// 	let navItems = [];

	// 	for (let i = 0; i < docSearchOption.page; i++) {
	// 		let page = i + 1;
	// 		let from = i * docSearchOption.take;
	// 		let to = page * docSearchOption.take;
	// 		navItems.push(<NavDropdown.Item key={page} eventKey={page}>{`${page} : ${from}-${to}`}</NavDropdown.Item>)
	// 	}

	// 	return (
	// 		<Nav activeKey={docSearchOption.current} onSelect={this.onDocumentListChangeHandler} className="mr-auto">
	// 			<NavDropdown title={`${docSearchOption.current}/${docSearchOption.page}`} id="basic-nav-dropdown">
	// 				{navItems}
	// 			</NavDropdown>
	// 		</Nav>
	// 	);
	// }

	DocumentListNavigator = () => {
		let { docSearchOption } = this.state;
		let navItems = [];
		let currentFrom = 0;
		let pageTo = 0;
		let pageFrom = 0;
		let currentTo = 0;
		let total = docSearchOption.total_records;
		let take = docSearchOption.take;
		let current = docSearchOption.current;
		if (!docSearchOption.total_records) { return <CircularProgress size="1rem" /> }
		for (let i = 0; i < docSearchOption.page; i++) {
			let page = i + 1;
			if (total === 0) {
				pageFrom = 0;
			} else {
				pageFrom = ((page - 1) * take) + 1;
			}
			if (total < page * take) {
				pageTo = total
			} else {
				pageTo = page * take;

			}
			navItems.push(
				<MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
			);
		}
		if (total === 0) {
			currentFrom = 0;
		} else {
			currentFrom = ((current - 1) * take) + 1;
		}
		if (total < current * take) {
			currentTo = total
		} else {
			currentTo = current * take;
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={current}
					// onChange={this.onPageChangeHandler}
					onChange={this.onDocumentListChangeHandler}
					renderValue={() => `${currentFrom} - ${currentTo} / ${docSearchOption.total_records}`}
					variant={"standard"}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: 250,
								minWidth: 130
							},
						},
					}}
					style={{ color: "#007BFF" }}>
					{navItems}
				</Select>
			</Box>
		);
	}

	SelectAllHandler = (ev) => {
		let { documents, evaluateDocList } = this.state;
		if (documents && evaluateDocList) {
			for (let doc of documents) {
				evaluateDocList[doc.id] = doc;
			}
			this.setState({ evaluateDocList });
		}
	}

	UnselectAllHandler = (ev) => {
		let { documents, evaluateDocList } = this.state;
		if (documents && evaluateDocList) {
			for (let doc of documents) {
				delete evaluateDocList[doc.id];
			}
			this.setState({ evaluateDocList });
		}
	}

	EvaluateConfirm = () => {
		let { t } = this.props;
		let { modal, evaluateDocList, docSearchOption } = this.state;

		let selectedDocs = Object.keys(evaluateDocList);

		modal.props = {
			show: true,
			centered: true,
		};

		modal.form = {};
		modal.action = [];
		if (docSearchOption.random === 0 && selectedDocs.length === 0) {
			modal.title = t('common:documents.evaluated-warning');
			modal.body = t('common:documents.evaluated-warning-please-select');
		} else {
			modal.title = t('common:documents.evaluated-confirm');
			modal.body = t('common:documents.evaluated-confirm-question');
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal, docSearchOption } = this.state;
					modal.title = t('common:documents.evaluated-on-process');
					modal.body = Loading;
					modal.form = {};
					modal.action = [];
					if (docSearchOption.random === 0) {
						this.setState({ modal }, this.StartEvaluate);
					} else {
						this.setState({ modal }, this.EvaluateSaveRandom);
					}
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			)];
		}

		this.setState({ modal });
	}

	StartEvaluate = (ev) => {
		let { t } = this.props;
		let { modal, evaluateDocList } = this.state;

		Service.startEvaluate({
			"documents": Object.keys(evaluateDocList),
		}).then(resp => {
			// console.log(resp);
			let { modal, searchOption } = this.state;
			modal.props.show = false;
			this.setState({ modal, showDetail: false, showHiddenMenu: false, documents: null, evaluateDocList: {} }, () => {
				this.GetDocumentListData(searchOption);
			});
		}).catch(err => {
			//console.log("error", err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			modal.body = t(`common:${errMessage}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, this.StartEvaluate);
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	MoreActionButton = () => {
		let { t } = this.props;
		let { showHiddenMenu, docSearchOption } = this.state;
		let { RandomSizeItems } = this;

		if (!showHiddenMenu) {
			return null;
		}

		let ActionButtons = [];
		ActionButtons.push(
			<Tooltip key="btn-filter" title={"再検索をする"} arrow>
				<IconButton size="small" color="primary" onClick={this.SearchDialog}>
					<ManageSearchIcon fontSize="large" />
				</IconButton>
			</Tooltip>
		);
		ActionButtons.push(
			<FormControl key="evaluate-type" className="evaluate-option">
				<RandomSizeItems />
			</FormControl>
		);
		ActionButtons.push(
			<Button key="btn-confirm"
				sx={{ ml: 1 }}
				variant="contained"
				onClick={this.EvaluateConfirm}
				startIcon={<SecurityOutlinedIcon />}
				style={{ marginRight: "10px" }}
			>
				{t("common:documents.evaluate.start-evaluate")}
			</Button>
		);

		if (docSearchOption.random === 0) {
			ActionButtons.push(
				<Tooltip key="btn-select-all" title={"全てチェックする"} arrow>
					<IconButton size="small" color="primary" onClick={this.SelectAllHandler}>
						<CheckBoxOutlinedIcon fontSize="large" />
					</IconButton>
				</Tooltip>
			);
			ActionButtons.push(
				<Tooltip key="btn-unselect-all" title={"全てのチェックを外す"} arrow>
					<IconButton size="small" color="primary" onClick={this.UnselectAllHandler}>
						<CheckBoxOutlineBlankOutlinedIcon fontSize="large" />
					</IconButton>
				</Tooltip>
			);
		}

		return ActionButtons;
	}

	DocumentDetailHeaderRight = () => {
		let { t } = this.props;
		let { docSearchOption, showHiddenMenu } = this.state;
		let { DocumentListNavigator, DocumentFunctionTypeFilter, DocumentStatusListFilter, DocumentListSorting } = this;

		if (!showHiddenMenu) {
			return null;
		}

		return (
			<div className="header-right">

				<DocumentFunctionTypeFilter />

				<DocumentStatusListFilter />

				<DocumentListSorting />

				<Box py={1} px={2}>
					<Select
						value={docSearchOption.take}
						onChange={this.onDocumentListLimitChangeHandler}
						renderValue={() => docSearchOption.take + " " + t("common:search.view.record-per-page")}
						variant={"standard"}
						style={{ color: "#007BFF" }}
					>
						{[100, 200, 500, 1000].map((count, index) =>
							<MenuItem value={count} key={index} >{count + t("common:search.view.record-per-page")}</MenuItem>
						)}
					</Select>
				</Box>

				{/* <Nav activeKey={docSearchOption.take} onSelect={this.onDocumentListLimitChangeHandler} className="mr-auto">
					<NavDropdown title={docSearchOption.take + " " + t("common:search.view.record-per-page")} id="basic-nav-dropdown">
						<NavDropdown.Item eventKey={10}>{"10 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={20}>{"20 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={50}>{"50 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
						<NavDropdown.Item eventKey={100}>{"100 " + t("common:search.view.record-per-page")}</NavDropdown.Item>
					</NavDropdown>
				</Nav> */}

				<div className="pagination">

					<DocumentListNavigator />

					<button className="btn-pagination" onClick={this.docListPrevPageData}>
						<FontAwesomeIcon icon={faChevronLeft} />
					</button>

					<button className="btn-pagination" onClick={this.docListNextPageData}>
						<FontAwesomeIcon icon={faChevronRight} />
					</button>
				</div>
			</div>);
	}

	DocumentDetailBody = (props) => {
		let { t } = this.props;
		let { DocumentList, DocumentDetailHeaderRight, MoreActionButton } = this;

		return (
			<div className="document-detail-wrapper">
				<div className="header-wrapper sticky">
					<div className="header-left">
						<IconButton className="btn-return btn-custom"
							onClick={(ev) => {
								this.setState({ showDetail: false, showHiddenMenu: false, documents: null, evaluateDocList: {} });
							}}>
							<ArrowBackIcon title={t("common:document.return-to-list-page")} />
						</IconButton>

						<MoreActionButton />

					</div>

					<div className="header-center document-title"></div>

					<DocumentDetailHeaderRight />

				</div>

				<div className="body-wrapper" style={{ overflow: "unset" }}>
					<DocumentList />
				</div>

			</div>
		);
	}

	CSVDownload = (items) => {
		let { t } = this.props;
		// console.log(items);
		let headers = {
			"doc_type_name": { // 書類種別
				"label": t("common:documents.evaluate.csv-header.document-type"),
			},
			"received_date": { // 取引年月日
				"label": t("common:documents.evaluate.csv-header.deal-date"), // received-date
			},
			"counter_party_name": { // 相手先
				"label": t("common:documents.evaluate.csv-header.counter-party-name"),
			},
			"title": { // タイトル
				"label": t("common:documents.evaluate.csv-header.title"),
			},
			"amount": { // 金額
				"label": t("common:documents.evaluate.csv-header.amount"),
				"prefix": "currency",
			}, // with currency // "currency",
			"product_name": { // 商品名
				"label": t("common:documents.evaluate.csv-header.product-name"),
			},
			"creator_name": { // 書類登録者
				"label": t("common:documents.evaluate.csv-header.creator-name"),
			},
			"issued_date": { // 書類入力日
				"label": t("common:documents.evaluate.csv-header.issued-date"),
			},
			"confirmed_user_name": { // 書類確認者
				"label": t("common:documents.evaluate.csv-header.confirmed-user"),
			},
			"confirmed_date": { // 書類確認日
				"label": t("common:documents.evaluate.csv-header.confirmed-date"),
			},
			"reference_document": { // お客様書類ID
				"label": t("common:documents.evaluate.csv-header.customer-document-id"),
			},
			"eval_data": { // メタデータ
				"label": t("common:documents.evaluate.csv-header.eval-metadata"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_data)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						if (data.data_missing) {
							let data_missing = [];
							for (let missing of data.data_missing) {
								data_missing.push(t(`common:documents.evaluate.data-missing.${missing}`));
							}
							if (data_missing.length === 3) {
								return t("common:documents.evaluate.data-missing.all");
							} else if (data_missing.length > 0) {
								return t("common:documents.evaluate.data-missing-prefix", { missing_field: data_missing.join(", ") });
							} else {
								return t("common:documents.evaluate.ok");
							}
						} else {
							return null;
						}
					}
				},
			},
			"width": { // 横幅
				"label": t("common:documents.evaluate.csv-header.width"),
			},
			"height": { // 縦幅
				"label": t("common:documents.evaluate.csv-header.height"),
			},
			"dpi": { // DPI
				"label": t("common:documents.evaluate.csv-header.dpi"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_dpi)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.dpi;
					}
				},
			},
			"color_depth": { // 色深度
				"label": t("common:documents.evaluate.csv-header.color-depth"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_color_depth)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.color_depth;
					}
				},
			},
			"eval_dpi": { // 画像情報
				"label": t("common:documents.evaluate.csv-header.eval-dpi"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_dpi)) {
						return t("common:documents.evaluate-skip-check");
					} else if (data.eval_dpi === 1) {
						return t("common:documents.evaluate.ok");
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_color_depth": { // 色深度
				"label": t("common:documents.evaluate.csv-header.eval-color-depth"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_color_depth)) {
						return t("common:documents.evaluate-skip-check");
					} else if (data.eval_color_depth === 1) {
						return t("common:documents.evaluate.ok");
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_timestamp_intime": { // TS付与期限
				"label": t("common:documents.evaluate-timestamp-period"),
				"value": (data) => {
					if ([null, undefined].includes(data.eval_timestamp_intime)) {
						return t("common:documents.evaluate-skip-check");
					} else {
						return data.timestamp_period;
					}
				},
			},
			"eval_timestamp_result": { // タイムスタンプ
				"label": t("common:documents.evaluate-timestamp"),
				"value": (data) => {
					if (data.eval_timestamp_exist) {
						if (data.eval_timestamp_hash) {
							return data.timestamp_date;
						} else {
							return t("common:documents.evaluate.timestamp.hash-not-same");
						}
					} else {
						return t("common:documents.evaluate.timestamp.not-exists");
					}
				},
			},
			"eval_timestamp_validity": { // TS日時/有効期限
				"label": t("common:documents.evaluate-timestamp-date"),
				"value": (data) => {
					if (data.eval_timestamp_validity === 1) {
						return data.timestamp_expired;
					} else {
						return t("common:documents.evaluate.ng");
					}
				},
			},
			"eval_status": { // 一括検証結果
				"label": t("common:documents.evaluate-status"),
				"value": {
					0: t("common:documents.evaluate.ng"),
					1: t("common:documents.evaluate.ok"),
				},
			},
		}

		let rows = [];
		let headerLabel = [];
		for (let col in headers) {
			let header = headers[col];
			headerLabel.push(header.label);
		}
		rows.push(`"${headerLabel.join('","')}"`);

		for (let item of items) {
			let row = [];
			for (let col in headers) {
				let header = headers[col];
				let colVal = item[col];
				if (header.hasOwnProperty("value")) {
					if (typeof header.value == "function") {
						row.push(header.value(item));
					} else if (header.value instanceof Object) {
						row.push(header.value[colVal] || colVal);
					} else {
						row.push(colVal);
					}
				} else if (header.hasOwnProperty("prefix")) {
					if (item.hasOwnProperty(header.prefix)) {
						row.push(`${colVal} ${item[header.prefix]}`);
					} else {
						row.push(`${colVal} ${header.prefix}`);
					}
				} else if (header.hasOwnProperty("join")) {
					let values = [colVal];
					for (let jCol of header.join.columns) {
						values.push(item[jCol]);
					}
					row.push(values.join(header.join.separator));
				} else {
					row.push(colVal);
				}
			}
			rows.push(`"${row.join('","')}"`);
		}
		// console.log(rows);

		let file_name = 'export.csv';
		let BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
		let csv = rows.join("\r\n");

		let blob = new Blob([BOM, csv], { type: 'text/csv;charset=utf-8;' });
		// if (navigator.msSaveBlob) { // IE 10+
		// 	navigator.msSaveBlob(blob, exportedFilenmae);
		// } else {
		let link = document.createElement("a");
		// if (link.download !== undefined) { // feature detection
		// Browsers that support HTML5 download attribute
		let url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", file_name);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		// }
		// }
	}

	ItemsList = () => {
		let { t } = this.props;
		let { evaluates } = this.state;
		if (evaluates === null) {
			return <LoadingList />;
		}

		if (evaluates.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list error-tr">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let ItemsList = [];
		// console.log(evaluates);
		for (let eva of evaluates) {
			//let btnDetailClass = ["sign-status"];

			let statusIconProps = {
				className: `icon ${eva.status_code}`,
				icon: faQuestionCircle,
			};

			if (eva.evaluate_status === 1) {
				statusIconProps.icon = faCheckCircle;
			} else if (eva.evaluate_status === 0) {
				statusIconProps.icon = faExclamationCircle;
			}

			ItemsList.push(
				<tr key={eva.id} className="document-row-list" onClick={(ev) => {
					// let target = ev.target.closest(".document-row-list");
					// if(target.classList.contains("selected")){
					// 	target.classList.remove("selected");
					// }else{
					// 	target.classList.add("selected");
					// }
				}}>

					<td className="evaluated-date"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container clickable">

							<span>{eva.evaluate_date}</span>
						</div>
					</td>
					<td className="evaluator-info"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container clickable">

							<span>{eva.evaluator_name}</span>
						</div>
					</td>
					{/* <td className="evaluated-status">
						<div className="inner-container">
							<span>{t("common:documents.evaluated-status")}</span>
							<div className={"document-evaluated-status " + eva.status_code}>
								<FontAwesomeIcon {...statusIconProps} />
								<span className="status-label">{t(`common:documents.evaluated-${eva.status_code}`)}</span>
							</div>
						</div>
					</td> */}
					{/* <td className="evaluation-summary-info clickable"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container">
							<span>{`${eva.total_documents}${t("common:documents.evaluated-total-documents")}`}</span>
							<span>{`${eva.total_files}${t("common:documents.evaluated-total-files")}`}</span>
							<span>{`${eva.total_pages || "?! "}${t("common:documents.evaluated-total-pages")}`}</span>
						</div>
					</td> */}
					<td className="evaluation-summary-info clickable"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container">
							<span>{t("common:documents.evaluated.summary-total-files-count", { file_count: eva.total_files })}</span>
							<span>{t("common:documents.evaluated.summary-ok-count", { ok_count: eva.total_ok })}</span>
							<span>{t("common:documents.evaluated.summary-ng-count", { ng_count: eva.total_ng })}</span>
						</div>
					</td>
					<td className="doc-row-action clickable"
						onClick={(ev) => { this.setState({ showDetail: true, documents: eva.detail }); }} >
						<div className="inner-container">
							<div className="action-icon evaluated-status">
								<FontAwesomeIcon {...statusIconProps} />
							</div>
						</div>
					</td>
					<td className="stamp-box">
						<Tooltip title={"結果をCSVでダウンロード"} arrow>
							<span>
								<IconButton
									color="primary"
									onClick={(ev) => {
										// this.DeleteDocumentConfirmation(ev, doc.id);
										this.CSVDownload(eva.detail);
									}} >
									<DownloadIcon />
								</IconButton>
							</span>
						</Tooltip>
					</td>
				</tr>
			);
		}

		return (
			<Table>
				<thead>
					<tr style={{ zIndex: 2 }}>
						<th>
							<div>
								<span>{t("common:documents.evaluated-date")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.evaluator")}</span>
							</div>
						</th>
						<th>
							<div>

							</div>
						</th>
						<th>
							<div>
								<span>{"検証結果"}</span>
							</div>
						</th>
						<th className="stamp-box">
							<div>
								<span>{"CSV"}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</Table>
		);
	}

	PDFFilesInDocument = (props) => {
		let { t } = this.props;
		let { files } = props;
		let fileKeys = Object.keys(files || {});
		if (fileKeys.length > 0) {
			let firstKey = fileKeys.shift();
			let firstFile = files[firstKey];
			let FileLabel = [];

			FileLabel.push(
				<span key="first-file" className="file-name-label">{`${firstFile.name || firstKey}`}</span>
			)

			if (fileKeys.length > 0) {
				let labelMore = t("common:documents.and-one-more-file");
				if (fileKeys.length > 1) {
					labelMore = t("common:documents.and-more-files", { count: fileKeys.length });
				}
				FileLabel.push(
					<div key="more-files" className="more-files">
						<Link to="#" className="more-files-link">
							<span>{labelMore}</span>
						</Link>
						<div className="more-files-block">
							{fileKeys.map(hash => {
								let file = files[hash];
								return (
									<li key={hash} className="file-label">{file.name || hash}</li>
								);
							})}
						</div>
					</div>
				)
			}

			return FileLabel;
		} else {
			return null;
		}
	}

	StatusIconElement = (props) => {
		let { status, mini, medium, className } = props;
		// doc.hasOwnProperty("eval_status")
		// if ([0, 1].includes(status)) {
		let classStatus = "processing";
		let IconStatus = faQuestionCircle;

		if (status === 1) {
			classStatus = "ok";
			IconStatus = faCheckCircle;
		} else if (status === 0 && !mini) {
			classStatus = "ng";
			IconStatus = faExclamationTriangle;
		}

		let additionalClassName = className ? ` ${className}` : "";

		if (mini) {
			return <div className={`evaluated-status-mark-mini ${classStatus}${additionalClassName}`}></div>;
		} else if (medium) {
			return <div className={`evaluated-status-mark-medium ${classStatus}${additionalClassName}`}></div>;
		} else {
			return <FontAwesomeIcon icon={IconStatus} className={`icon ${classStatus}${additionalClassName}`} />;
		}
		// } else {
		// 	return null;
		// }
	}

	DocumentListAsNewEvaluate = (props) => {
		let { t } = this.props;
		let { documents, evaluateDocList, docSearchOption } = this.state;
		let { PDFFilesInDocument } = this;

		let selectedDocs = Object.keys(evaluateDocList);
		let ItemsList = [];
		for (let doc of documents) {

			let Amount = (doc.amount || "0").toString();
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			Amount = Amount.join(".") + " " + (doc.currency || "JPY");

			let selectedClass = "";
			if (selectedDocs.includes(doc.id.toString())) {
				selectedClass = " selected";
			}
			let clickableClass = "";
			if (docSearchOption.random === 0) {
				clickableClass = " clickable";
			}

			// console.log(doc);

			ItemsList.push(
				<tr key={doc.id} className={`document-row-list${clickableClass}${selectedClass}`} onClick={(ev) => {
					let { evaluateDocList, docSearchOption } = this.state;
					if (docSearchOption.random === 0) {
						if (Object.keys(evaluateDocList).includes(doc.id.toString())) {
							delete evaluateDocList[doc.id];
						} else {
							evaluateDocList[doc.id] = doc;
						}
						this.setState({ evaluateDocList });
					}
				}}>
					<td className="sign-status">
						<Tooltip title="書類を見る" arrow>
							<IconButton color="primary" href={`/documents/detail/${doc.id}`} target="_blank">
								<FileOpenIcon title={t("common:document.show-detail-page") + " " + doc.id} />
							</IconButton>
						</Tooltip>
					</td>
					<td className="creator-info">
						<div className="inner-container">
							<span>{doc.creator_name}</span>
						</div>
					</td>
					<td className="function-type-info">
						<div className="inner-container">
							<span>{t(`common:${doc.function_type}`)}</span>
						</div>
					</td>
					<td className="document-info">
						<div className="inner-container">
							<span>{t(`common:${doc.document_type}`)}</span>
							<div className={"document-progress " + doc.status_code}>
								<span className="status-label">{t(`common:${doc.status_label}`)}</span>
							</div>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							<div className={"document-files"}>
								<PDFFilesInDocument files={doc.files} />
							</div>
						</div>
					</td>
					<td className="counter-party-info">
						<div className="inner-container">
						</div>
					</td>
					<td className="amount-info">
						<div className="inner-container">
							<span>{Amount}</span>
						</div>
					</td>
					<td className="document-created-date">
						<div className="inner-container">
							<span>{doc.issued_date}</span>
						</div>
					</td>
					<td className="document-confirmed-date">
						<div className="inner-container">
							<span>{doc.confirmed_date}</span>
						</div>
					</td>
				</tr>
			);
		}

		return (
			<Table>
				<thead>
					<tr style={{ top: 0, zIndex: 2 }}>
						<th>
							<div>

							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.creator")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.document-info.function-type")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{"書類情報"}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{"書類タイトル / ファイル名"}</span>
							</div>
						</th>
						<th>
							<div>

							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.document-info.amount")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.issued-date")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.confirmed-date")}</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</Table>
		);
	}

	DocumentListAsReadOnly = (props) => {
		let { t } = this.props;
		let { documents } = this.state;
		let { StatusIconElement } = this;

		let ItemsList = [];
		for (let doc of documents) {
			let data_missing = [];
			if (doc.data_missing) {
				for (let missing of doc.data_missing) {
					data_missing.push(t(`common:documents.evaluate.data-missing.${missing}`));
				}
				if (data_missing.length === 3) {
					data_missing = t("common:documents.evaluate.data-missing.all");
				} else if (data_missing.length > 0) {
					data_missing = t("common:documents.evaluate.data-missing-prefix", { missing_field: data_missing.join(", ") });
				}
			} else {
				data_missing = null;
			}

			let SingleValueBlock = (props) => {
				let { value } = props;

				if (!value) {
					value = "documents.evaluate-not-available";
				}
				return (
					<div className="evaluate-detail evaluated-status">
						<span>{value}</span>
					</div>
				);
			}

			let DatetimeNewLineBlock = (props) => {
				let { date } = props;

				if (!date) {
					return null;
				}

				let arrDatetime = date.split(" ");

				return (
					<div className="evaluate-detail evaluated-status" style={{
						flexDirection: "column",
						alignItems: "center",
						height: "32px",
						fontSize: "12px",
					}}>
						<span style={{ width: "auto" }}>{arrDatetime[0]}</span>
						<span style={{ width: "auto" }}>{arrDatetime[1]}</span>
					</div>
				);
			}

			let SkipCheckItemBlock = (props) => {
				let { value, label } = props;

				if (![null, undefined].includes(value)) {
					return [
						<StatusIconElement key="icon" status={value} mini={true} />,
						<SingleValueBlock key="label" value={label || t("common:documents.evaluate-skip-check")} />
					];
				} else {
					return <SingleValueBlock value={t("common:documents.evaluate-skip-check")} />;
				}
			}

			let TimeStampSignDate = (props) => {
				let { doc } = props;

				if (doc.eval_timestamp_exist) {
					if (doc.eval_timestamp_hash) {
						return <DatetimeNewLineBlock date={doc.timestamp_date} />;
					} else {
						return <SingleValueBlock value={t("common:documents.evaluate.timestamp.hash-not-same")} />;
					}
				} else {
					return <SingleValueBlock value={t("common:documents.evaluate.timestamp.not-exists")} />;
				}
			}

			ItemsList.push(
				<tr key={`${doc.id}-${doc.file_info.hash}`} className={`document-row-list`}>
					<td className="sign-status">
						<Tooltip title="書類を見る" arrow>
							<IconButton href={`/documents/detail/${doc.id}`} target="_blank">
								<FileOpenIcon color="primary" title={t("common:document.show-detail-page") + " " + doc.id} />
							</IconButton>
						</Tooltip>
					</td>
					<td className="document-info">
						<div className="inner-container">
							<span>{t(`common:${doc.document_type}`)}</span>
							<div className={"document-progress " + doc.status_code}>
								<span className="status-label">{t(`common:${doc.status_label}`)}</span>
							</div>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							<span className="file-name-label">{`${t("common:documents.file")}: ${doc.file_info.name}`}</span>
						</div>
					</td>
					<td className="document-created-date">
						<div className="inner-container">
							<span>{doc.issued_date}</span>
						</div>
					</td>
					<td className="preservation-require">
						<div className="inner-container">
							<span>{doc.preservation_require !== null ? t(`common:${doc.preservation_require_label}`) : t("common:documents.evaluate-not-available")}</span>
						</div>
					</td>
					<td className="confirm-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc.eval_data} label={data_missing} />
						</div>
					</td>
					<td className="dpi-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc.eval_dpi} label={doc.dpi} />
						</div>
					</td>
					<td className="color-depth-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc.eval_color_depth} label={doc.color_depth} />
						</div>
					</td>
					<td className="timestamp-evaluate-info">
						<div className="inner-container">
							<SkipCheckItemBlock value={doc.eval_timestamp_intime} label={doc.timestamp_period} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<StatusIconElement status={doc.eval_timestamp_result} medium={true} />
							<TimeStampSignDate doc={doc} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<StatusIconElement status={doc.eval_timestamp_validity} medium={true} />
							<DatetimeNewLineBlock date={doc.timestamp_expired} />
						</div>
					</td>
					<td className="evaluate-info bg-lightblue">
						<div className="inner-container flex-center">
							<div className="action-icon evaluated-status">
								<StatusIconElement status={doc.eval_status} />
							</div>
						</div>
					</td>
					<td className="doc-row-action">
					</td>
				</tr>
			);
		}

		return (
			<Table>
				<thead>
					<tr style={{ top: 0, zIndex: 2 }}>
						<th>
							<div>

							</div>
						</th>
						<th>
							<div>
								<span>{"書類情報"}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{"書類タイトル / ファイル名"}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.issued-date")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.document-info.preservation-require")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.evaluate-metadata")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.evaluate-dpi")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.evaluate-color-depth")}</span>
							</div>
						</th>
						<th>
							<div>
								<span>{t("common:documents.evaluate-timestamp-period")}</span>
							</div>
						</th>
						<th className="evaluate-header bg-lightblue">
							<div>
								<span>{t("common:documents.evaluate-timestamp")}</span>
							</div>
						</th>
						<th className="evaluate-header bg-lightblue">
							<div>
								<span>{t("common:documents.evaluate-timestamp-date")}</span>
							</div>
						</th>
						<th className="evaluate-header bg-lightblue">
							<div>
								<span>{t("common:documents.evaluate-status")}</span>
							</div>
						</th>
						<th>
							<div>

							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{ItemsList}
				</tbody>
			</Table>
		);
	}

	DocumentList = () => {
		let { t } = this.props;
		let { documents, showHiddenMenu } = this.state;

		// console.log(documents);
		if (documents === null) {
			return <LoadingList />;
		}

		if (documents.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let { DocumentListAsNewEvaluate, DocumentListAsReadOnly } = this;

		if (showHiddenMenu) {
			return <DocumentListAsNewEvaluate />;
		} else {
			return <DocumentListAsReadOnly />;
		}
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { searchOption, evaluates } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Button
				sx={{ ml: 1, borderRadius: 19 }}
				key="btn-new-evaluate"
				variant="contained"
				onClick={this.SearchDialog}
				startIcon={<AddIcon />}
			>
				{t('common:main.general.new-evaluate')}
			</Button>
		)

		ButtonList.push(
			<IconButton
				sx={{ ml: 2, mr: 2, mt: 1 }}
				key="list-reload"
				color="primary"
				style={{
					margin: "0 8px", padding: "4px"
				}}
				disabled={evaluates ? false : true}
				onClick={(ev) => {
					this.GetDocumentListData(searchOption);
				}}
			>
				{evaluates ? <RefreshIcon sx={{ fontSize: 36 }} /> : <CircularProgress size="1.4em" />}
			</IconButton>
		);

		return ButtonList;
	}

	AdditionalButtons = () => {
		//let { t } = this.props;
		// let { searchOption, evaluates } = this.state;
		let ButtonList = [];

		return ButtonList;
	}

}

export default withTranslation()(Evaluation);
