import axios from 'axios';
import Auth from "./Auth";
import API from '../Config/API';
import CryptoJS from 'crypto-js';

const Service = class {

	static TemporaryTokenVerification = async (token) => {
		const uri = API.url + "/token/verify.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static deleteUserControl = async (userEmail = null) => {
		let email = '';

		if (userEmail !== null) {
			email = userEmail;
		} else {
			email = await Auth.GetUserAttributes("email");
		}

		const tokenID = await Auth.GetTokenID();
		const uri = API.url + "/logincontrol/deleteItem.json";

		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("mail", CryptoJS.MD5(email).toString());
			let url = uri + "?" + queryParams.toString();

			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getUserControl = async (email, token) => {
		const uri = API.url + "/logincontrol/getItem.json";

		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("mail", CryptoJS.MD5(email).toString());
			let url = uri + "?" + queryParams.toString();

			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static SaveUserInfoToLocalStorage = (userInfo) => {
		localStorage.setItem("user-info", JSON.stringify(userInfo));
	}

	static GetUserInfo = async (force = false) => {

		const uri = API.url + "/user/info.json";
		const email = await Auth.GetUserAttributes("email");
		const tokenID = await Auth.GetTokenID();
		const userCache = localStorage.getItem("user-info");

		// console.log(userCache);
		if (userCache) {
			return JSON.parse(userCache);
		}

		const userControlData = await this.getUserControl(email, tokenID).catch((err) => {
			force = true;
		});

		if (!force && userControlData && userControlData.data.mail !== '' && userCache) {
			return JSON.parse(decodeURIComponent(userControlData.data.body));
		}

		return await new Promise((resolve, reject) => {
			if (!email) {
				reject(false);
			}
			// console.log(tokenID);
			let queryParams = new URLSearchParams();
			queryParams.set("email", email);
			let url = uri + "?" + queryParams.toString();
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then((resp) => {
				let userinfoMap = {};
				console.log(resp.data.language);

				localStorage.setItem("user-info", JSON.stringify(resp.data));
				for (let [key, value] of Object.entries(resp.data)) {
					if (['profile_image'].includes(key)) {
						localStorage.setItem(key, value);
						continue;
					}
					userinfoMap[key] = value;
				}

				// console.log(userinfoMap);
				const putUri = API.url + "/logincontrol/putItem.json";
				let putQueryParams = new URLSearchParams();
				putQueryParams.set("mail", CryptoJS.MD5(email).toString());
				putQueryParams.set("body", encodeURIComponent(JSON.stringify(userinfoMap)));
				let putUrl = putUri + "?" + putQueryParams.toString();
				axios.get(putUrl, {
					headers: {
						Authorization: tokenID,
					},
				}).then((resp) => {
					resolve(resp);
				}).catch((error) => {
					reject(error);
				});
				resolve(resp.data);

			}).catch((error) => {
				reject(error);
			});
		});
	}

	/** Update UserInfo */
	static SetUserInfo = async (data) => {
		const url = new URL(API.url + "/user/info.json");
		const tokenID = await Auth.GetTokenID();

		/* */
		return await new Promise((resolve, reject) => {
			if (!tokenID) {
				reject("token.get-fail");
			}
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static GetDocumentStoragePath = async (path, type) => {
		const uri = API.url + "/document/getPath.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url.searchParams.set("type", type);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetFileboxStoragePath = async (path, type, linkage) => {
		const uri = API.url + "/filebox/getPath.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url.searchParams.set("type", type);
		url.searchParams.set("linkage", linkage);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxSearchFiles = async (search_text) => {
		const uri = API.url + "/filebox/search.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let data = {
			search_text: search_text,
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static CreateFileBoxNewFolder = async (path) => {
		const uri = API.url + "/filebox/newFolder.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDownload = async (path) => {
		const uri = API.url + "/filebox/downloadRequest.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDeleteFile = async (path) => {
		const uri = API.url + "/filebox/deleteFile.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static fileboxDeleteFolder = async (path) => {
		const uri = API.url + "/filebox/deleteFolder.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static documentDownload = async (path) => {
		const uri = API.url + "/document/downloadRequest.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("path", path);
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetPageData = async (doc_type) => {
		const uri = API.url + "/page/data.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let queryParams = new URLSearchParams();
		queryParams.set("tenant_id", UserInfo.tenant_id);
		queryParams.set("doc_type", doc_type);
		let url = uri + "?" + queryParams.toString();
		// console.log(url);
		// console.log(UserInfo);

		let result = await axios.get(url, {
			headers: {
				Authorization: tokenID,
			},
		});
		return result.data;
	}

	static GetUserList = async (groupby = null, forceGroup = [], status = 0) => {
		const uri = API.url + "/user/list.json";
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		url.searchParams.set("status", status);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				if (groupby) {
					if (resp.data.length === 0) {
						resolve({});
					} else {
						let firstRecord = resp.data[0];
						if (Object.keys(firstRecord).includes(groupby)) {
							let result = {};
							for (let g of forceGroup) {
								result[g] = {};
							}
							for (let record of resp.data) {
								let groupKey = record[groupby];
								if (!result.hasOwnProperty(groupKey)) {
									result[groupKey] = {};
								}
								result[groupKey][record.user_id] = record;
							}
							resolve(result);
						} else {
							resolve(resp.data);
						}
					}
				} else {
					resolve(resp.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	static CreateDocument = async (data) => {
		const uri = API.url + "/document/create.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static UpdateDocumentViewers = async (data) => {
		const uri = API.url + "/document/update/viewers.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static SaveDocument = async (data) => {
		// const uri = API.url + "/document/save.json";
		const uri = API.url + "/sqs/document-save.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				// Support SQS
				if (resp.data.hasOwnProperty('Error')) {
					reject({
						"response": {
							"data": {
								"error": resp.data.Error.Code,
								"message": resp.data.Error.Message,
								"sqsInfo": resp.data,
							},
						},
					});
				} else {
					resolve(resp.data);
				}
			}).catch(error => {
				reject(error);
			});
		});
	}

	static BulkSaveDocument = async (data) => {
		const uri = API.url + "/document/bulkSave.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"doc_info_list": data,
			}, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static Word2PDF = async (data) => {
		const uri = API.url + "/document/word2pdf.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static Word2PDFBulkSave = async (data) => {
		const uri = API.url + "/document/word2pdf-save.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DeleteDocument = async (document_id) => {
		const uri = API.url + "/document/delete.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, { document_id: document_id }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static FileUpload = async (file, path = "", file_info = "") => {
		const uri = API.url + "/upload.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let file_name = "";
		let file_size = 0;

		if (file_info !== "") {
			file_name = file_info.name;
			file_size = file_info.size;
		}

		return await new Promise(async (resolve, reject) => {
			axios.post(uri, { path, file_name, file_size }, {
				headers: {
					Authorization: tokenID,
				}
			}).then(resp => {
				let tempInfo = resp.data;
				let tempUrl = tempInfo.url;
				let fields = tempInfo.fields;
				let formData = new FormData();
				for (let i in fields) {
					formData.set(i, fields[i]);
				}
				formData.set("file", file);

				axios.post(tempUrl, formData).then(resp => {
					resolve(tempInfo);
				}).catch(error => {
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
			// console.log(uploadInfo);
		});

	}

	static signGuestDocument = async (token) => {
		const uri = API.url + "/guest/document/sign.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, null, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DocumentForwardSign = async (user) => {
		const uri = API.url + "/document/forward.json";
		const tokenID = await Auth.GetTokenID();

		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, user, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static signDocument = async (document_id) => {
		// const uri = API.url + "/document/sign.json";
		const uri = API.url + "/sqs/document-sign.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static saveDocumentlog = async (document_id) => {
		// const uri = API.url + "/document/sign.json";
		const uri = API.url + "/document/signProtectPDF.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static verifyDocument = async (document_id) => {
		const uri = API.url + "/document/verify.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(UserInfo.user_id),
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getGuestDocumentFile = async (token, file_name) => {
		const uri = `${API.media}/document/guest/${file_name}`;

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
					Accept: "application/pdf",
				},
				responseType: 'blob',
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentFile = async (document_id, file_name) => {
		const uri = `${API.media}/document/${document_id}/${file_name}`;
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: tokenID,
					Accept: "application/pdf",
				},
				responseType: 'blob',
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static ConvertDoc2PDF = async (data) => {
		const uri = `${API.media}/document/word2pdf.json`;
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}
		// console.log(data);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getGuestDocumentInfo = async (token, otpCode, countIncorrect) => {
		const uri = API.url + "/guest/document/info.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
				params: {
					otpCode,
					countIncorrect
				}
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentInfo = async (document_id) => {
		const uri = API.url + "/document/info.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}
	static UpdateLanguage = async (language) => {

		const uri = API.url + "/user/language.json";
		const tokenID = await Auth.GetTokenID();

		if (!tokenID) {
			return false;
		}
		console.log(language);
		// return;
		return await new Promise((resolve, reject) => {
			axios.post(uri, { language: language }, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});



	}

	static DocumentInfoSave = async (data) => {
		const uri = API.url + "/document/info.json";
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					"Accept": "application/json",
					"Content-type": "application/json",
					"Authorization": tokenID,
				}
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static guestDownloadAllAsZip = async (token) => {
		const uri = API.url + "/guest/document/download.json";
		// console.log(token);
		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static downloadAllAsZip = async (document_id) => {
		const uri = API.url + "/document/download.json";
		const tokenID = await Auth.GetTokenID();

		let queryParams = new URLSearchParams();
		queryParams.set("document_id", document_id);
		let url = uri + "?" + queryParams.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDashboardData = async () => {
		const uri = API.url + "/dashboard.json";
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentStatusList = async (data) => {
		const uri = API.url + "/master/document/status.json";
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("function_type", data.function_type);
		for (let i in data.function_type) {
			url.searchParams.set(i, data[i]);
			console.log(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentList = async (data) => {
		const uri = API.url + "/document/list.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		// url.searchParams.set("function_type", data.function_type);
		// url.searchParams.set("status_id", data.status_id);
		// url.searchParams.set("sorting_code", data.sorting_code);
		// url.searchParams.set("skip", data.skip);
		// url.searchParams.set("take", data.take);
		for (let i in data) {
			let value = data[i];
			if (value instanceof Object) {
				value = JSON.stringify(value);
			}
			url.searchParams.set(i, value);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getDocumentListDownload = async (data) => {
		const uri = API.url + "/document/listDownload.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);

		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static getEvaluateHistory = async (data) => {
		const uri = API.url + "/evaluation/list.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static startEvaluate = async (data) => {
		const uri = API.url + "/evaluation/save.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		data["tenant_id"] = UserInfo.tenant_id;

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static startEvaluateRandom = async (data) => {
		const uri = API.url + "/evaluation/random.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static verificationBulkRegister = async (data) => {
		const uri = API.url + "/verification/bulkRegister.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		data["tenant_id"] = UserInfo.tenant_id;

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/**  */
	static getCertificatesList = async () => {

		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		let url = new URL(API.url + "/certificates/list.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static guestUpdateCertificate = async (token, data) => {
		/* */
		const url = new URL(API.url + "/guest/certificates/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: token,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static updateCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static deleteCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/delete.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static downloadCertificate = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/certificates/download.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** Create HostUser (NOTE: insert only) */
	static setUserHost = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/save.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** Create HostUser (NOTE: update only [status, organizations]) */
	static updateUserHost = async (data) => {


		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/update.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getUserHostList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/list.json");

		/* */
		url.searchParams.set('tenant_id', UserInfo['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static DeleteInactiveUser = async (data) => {
		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/delete.json");

		/* */
		url.searchParams.set('user_id', data['user_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.delete(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static UserHostResendMail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/resendMail.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static UploadCsvHostUser = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/upload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static DownloadCsvHostListUser = async (data) => {
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		const userInfo = await Service.GetUserInfo();

		let url = new URL(API.url + "/user/host/csvDownload.json");

		/* */
		url.searchParams.set('tenant_id', userInfo['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);


		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static UserHostSendMail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/host/sendMail.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static resendEmail = async (document_id, user_id) => {
		// const uri = API.url + "/document/resendEmail.json";
		const uri = API.url + "/sqs/document-resendmail.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let data = {
			document_id: document_id,
			user_id: parseInt(user_id),
			send_user_id: UserInfo.user_id,
		};
		// console.log(data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static EmailControllerSave = async (payload) => {
		const uri = API.url + "/email/controller.json";
		const tokenID = await Auth.GetTokenID();

		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				"email_control": payload,
			}, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}




	/* Select List of GuestUser's */
	static getUserGuestList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* Set default value */
		if (data['tenant_id'] == null) {
			data['tenant_id'] = UserInfo['tenant_id'];
		}

		/* Prepare URL */
		const url = new URL(API.url + "/guest/list.json");

		/* Convert RequestBody to URL-QueryString */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* Wating for HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/* Insert/Update GuestUser */
	static setUserGuest = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if (data["tenant_id"] == null) {
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/guest/save.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static UploadCsvGuestUser = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/guest/csvUpload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static userGenerateApiToken = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/generate-api-token-key.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				UserInfo["api_token_key"] = resp.data["token"];
				this.SaveUserInfoToLocalStorage(UserInfo);
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static userChangePassword = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/password-change.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static userChangeProfileImage = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/user/profile-image-change.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	static GetSearchData = async () => {
		const uri = API.url + "/search.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		if (!UserInfo || !tokenID) {
			return false;
		}
		let queryParams = new URLSearchParams();
		queryParams.set("user_id", UserInfo.user_id);
		let url = uri + "?" + queryParams.toString();
		// console.log(url);
		// console.log(UserInfo);

		let result = await axios.get(url, {
			headers: {
				Authorization: tokenID,
			},
		});
		return result.data;
	}

	static getSearchDocumentList = async (data) => {
		// const uri = API.url + "/document/list.json";
		const uri = API.url + "/search/data.json";
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		let url = new URL(uri);
		url.searchParams.set("tenant_id", UserInfo.tenant_id);
		url.searchParams.set("sorting_code", data.sorting_code);
		url.searchParams.set("skip", data.skip);
		url.searchParams.set("take", data.take);
		url.searchParams.set("function_type", data.function_type);
		url.searchParams.set("status", data.status);
		url.searchParams.set("document_type", data.document_type);
		url.searchParams.set("title", data.title);
		url.searchParams.set("preservation_require", data.preservation_require);
		url.searchParams.set("amount_from", data.amount_from.replaceAll(",", ""));
		url.searchParams.set("amount_to", data.amount_to.replaceAll(",", ""));
		url.searchParams.set("currency", data.currency);
		url.searchParams.set("contract_date_start", data.contract_date_from);
		url.searchParams.set("contract_date_end", data.contract_date_to);
		url.searchParams.set("expiry_date_start", data.expire_date_from);
		url.searchParams.set("expiry_date_end", data.expire_date_to);
		url.searchParams.set("effective_date_from", data.effective_date_from);
		url.searchParams.set("effective_date_to", data.effective_date_to);
		url.searchParams.set("circle_finish_date_from", data.circle_finish_date_from);
		url.searchParams.set("circle_finish_date_to", data.circle_finish_date_to);
		url.searchParams.set("product_name", data.product_name);
		url.searchParams.set("reference_document", data.reference_document);
		url.searchParams.set("counter_party_name", data.counter_party_name);
		url.searchParams.set("file_name", data.file_name);
		url.searchParams.set("meta_attributes", JSON.stringify(data.meta_attributes));
		url.searchParams.set("create_datetime_start", data.create_date_from);
		url.searchParams.set("create_datetime_end", data.create_date_to);
		url.searchParams.set("deal_datetime_start", data.deal_date_from);
		url.searchParams.set("deal_datetime_end", data.deal_date_to);
		url.searchParams.set("dateofnoti", data.dateofnoti);
		url.searchParams.set("select_datenoti1", data.select_datenoti1);
		url = url.toString();

		// console.log("data", data);
		// console.log(url);

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getAppCertList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/list.json");

		/* */
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getAppCertUserList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/user.json");

		/* */
		url.searchParams.set('condition', data['search']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getAppCertDetail = async (data, mail_flg = false) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/detail.json");

		/* */
		url.searchParams.set('application_date', data.application_date);
		url.searchParams.set('mail_redirect', mail_flg);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	static getGuestAppCertDetail = async (token, data = {}) => {
		const uri = API.url + "/guest/appcert/detail.json";

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}
		// url = url.toString();

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static UpdateAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static SaveAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	static GuestUpdateAppCert = async (token, data) => {
		const uri = API.url + "/guest/appcert/update.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, JSON.stringify(data), {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static GuestSaveAppCert = async (token, data) => {
		const uri = API.url + "/guest/appcert/save.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestFileUpload = async (token, file, path = "") => {
		const uri = API.url + "/guest/upload.json";
		// const tokenID = await Auth.GetTokenID();
		// if (!tokenID) {
		// 	return false;
		// }

		return await new Promise(async (resolve, reject) => {
			axios.post(uri, { path }, {
				headers: {
					Authorization: token,
				}
			}).then(resp => {
				let tempInfo = resp.data;
				let tempUrl = tempInfo.url;
				let fields = tempInfo.fields;
				let formData = new FormData();
				for (let i in fields) {
					formData.set(i, fields[i]);
				}
				formData.set("file", file);

				axios.post(tempUrl, formData).then(resp => {
					resolve(tempInfo);
				}).catch(error => {
					reject(error);
				});
			}).catch(error => {
				reject(error);
			});
			// console.log(uploadInfo);
		});

	}

	static GetGuestUserInfo = async (token, data = {}) => {
		const uri = API.url + "/guest/info.json";

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GetUserGuestInfo = async (data) => {
		const uri = API.url + "/user/guest/info.json";
		const tokenID = await Auth.GetTokenID();
		if (!tokenID) {
			return false;
		}

		let url = new URL(uri);
		for (let i in data) {
			url.searchParams.set(i, data[i]);
		}

		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then((resp) => {
				resolve(resp.data);
			}).catch((error) => {
				reject(error);
			});
		});
	}

	static GuestLinkageUserInfo = async (token) => {
		const uri = API.url + "/guest/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.get(uri, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestLinkageUserApprove = async (token, data) => {
		const uri = API.url + "/guest/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static GuestForwardSign = async (token, data) => {
		const uri = API.url + "/guest/document/forward.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static DocumentLinkage = async (token, data) => {
		const uri = API.url + "/guest/document/linkage.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, data, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static DeleteAppCert = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/appcert/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	// static appcertEmail = async (data = {}) => {
	static appcertEmail = async (data = {}) => {
		const uri = API.url + "/appcert/sendEmail.json";
		// const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();
		// console.log("data", data);

		return await new Promise((resolve, reject) => {
			axios.post(uri, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static getStampList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/list.json");

		/* */
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getStampUserGroupList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/userGroup.json");

		/* */
		url.searchParams.set('condition', data['search']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static getStampDetail = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/detail.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('group_id', data['group_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/** */
	static SaveStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static UpdateStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static DeleteStamp = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/stamp/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static getTenantList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/list.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);
		url.searchParams.set('status', data['status']);
		url.searchParams.set('sorting_code', data['sorting_code']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static getTenantServiceList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/serviceList.json");

		/* */
		url.searchParams.set('tenant_id', data['tenant_id']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/** */
	static SaveTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/save.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	static getTenant = async (data) => {
		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const uri = new URL(API.url + "/tenants/info.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {
			let queryParams = new URLSearchParams();
			queryParams.set("id", data.tenant_id);
			let url = uri + "?" + queryParams.toString();
			/* Send HTTP-Request */
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static UpdateTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/update.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static DeleteTenant = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/delete.json ");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			// axios.post(url, JSON.stringify(data), {
			axios.post(url, JSON.stringify(data), {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static TenantSwitch = async (data) => {

		/* */
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/tenants/switch.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});
	}

	/** */
	static getCounterPartyList = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/counter/list.json");

		/* */
		url.searchParams.set('status', data['status']);
		url.searchParams.set('search', data['search']);
		url.searchParams.set('skip', data['skip']);
		url.searchParams.set('take', data['take']);

		/* */
		return await new Promise((resolve, reject) => {
			axios.get(url, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

	/* Insert/Update GuestUser */
	static setCounterParty = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		if (data["tenant_id"] == null) {
			data["company_id"] = UserInfo["company_id"];
			data["tenant_id"] = UserInfo["tenant_id"];
		}

		/* */
		const url = new URL(API.url + "/counter/save.json");

		/* Waiting HTTP-Response before return */
		return await new Promise((resolve, reject) => {

			/* Send HTTP-Request */
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				},
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});

		});

	}

	/** */
	static UploadCsvCounterParty = async (data) => {

		/* */
		const UserInfo = await Service.GetUserInfo();
		const tokenID = await Auth.GetTokenID();

		/* */
		if (!UserInfo || !tokenID) {
			return false;
		}

		/* */
		const url = new URL(API.url + "/counter/csvUpload.json");

		/* */
		return await new Promise((resolve, reject) => {
			axios.post(url, data, {
				headers: {
					Authorization: tokenID,
				}
			}).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});

	}

}

export default Service;
