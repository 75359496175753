import React from 'react';
import { withTranslation } from "react-i18next";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
// 	faShareSquare,
// } from '@fortawesome/free-solid-svg-icons';

class BodyDetail extends React.Component {

	constructor(props) {
		super(props);
		this.state = {}
	}

	close = (ev) => {
		ev.target.closest(".document-detail").classList.remove("active");
		this.props.closeCallback();
	}

	ActionBar = () => {
		let { action } = this.props;
		if (!action) {
			return null;
		}
		return action;
	}

	// Body = () => {
	// 	let { t, active, children, action } = this.props;
	// 	let ActionBar = action;
	// 	return (
	// 		<div className="document-detail-wrapper">
	// 			<div className="header-wrapper sticky">
	// 				<div className="header-left">
	// 					<button className="btn-return" onClick={this.close}>
	// 						<FontAwesomeIcon icon={faShareSquare} className="fa-flip-horizontal" title={t("common:document.return-to-list-page")} />
	// 					</button>
	// 				</div>
	// 				<div className="header-right">
	// 					<ActionBar />
	// 				</div>
	// 			</div>
	// 			<div className="body-wrapper">
	// 			</div>
	// 		</div>
	// 	);
	// }

	render() {
		let { active, children } = this.props;
		// let ActionBar = action;

		return (
			<div className={"document-detail" + (active ? " active" : "")}>
				{children}
			</div>

		);

	}

}

export default withTranslation()(BodyDetail);