// import PDFViewer from "../Fragments/PDFViewer";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
	Form,
	Button,
	Col,
	// InputGroup,
} from 'react-bootstrap';
import CryptoJS from 'crypto-js';
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import FileViewer from 'react-file-viewer';

export default class TemplateConfirm {

	static get key() {
		return "template-confirm";
	}
	static get name() {
		return "TemplateConfirm";
	}
	static get code() {
		return TemplateConfirm.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = TemplateConfirm.key;
		this.name = TemplateConfirm.name;
		this.code = TemplateConfirm.code;

		this.formData = {
			search_key: "common:documents.template-selection.search",
		};

		this.objMain.setPageObject(this);
	}

	registerFiles = (ev) => {
		let files = ev.target.files;
		for (let file of files) {
			let reader = new FileReader();
			reader.onload = (ev) => {
				// console.log(ev);
				let content = CryptoJS.enc.Latin1.parse(reader.result);
				var fileKey = CryptoJS.MD5(content).toString();
				let { PDFFiles } = this.objMain.state;
				PDFFiles[fileKey] = {
					file: file,
					name: file.name,
					type: file.type,
					size: file.size,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
				};
				this.objMain.updateState({ PDFFiles });
				this.objMain.updateState({ SelectedFile: fileKey });
				// console.log(this.objMain.state.PDFFiles);
			};
			reader.onerror = () => {
				//console.log(reader.error);
			};
			reader.readAsBinaryString(file);
		}
	}

	formSubmitValidation = (ev, PDFFiles) => {
		ev.preventDefault();

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState, PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	PDFViewerSubmitCallBack = (ev, files) => {
		// console.log(files);
		let { pageState, PDFFiles, PDFFile, selectedFile, formData, originalFormData, creationMode } = this.objMain.state;
		// let formData = {}
		// console.log(formData);
		pageState[this.name].completed = true;
		PDFFiles = files;

		if (!PDFFile || Object.keys(PDFFile).length === 0) {
			PDFFile = Object.values(PDFFiles)[0];
			selectedFile = PDFFile.hash;
		}

		if (creationMode === "multiple") {

			for (let key in PDFFiles) {
				let oFile = PDFFiles[key];
				if (!oFile.formData) {
					oFile.formData = {}

					oFile.formData.doc_title = (oFile.info && oFile.info.Title) || "";
					oFile.formData.document_type = originalFormData.document_type;
					oFile.formData.amount = "";
					oFile.formData.currency = originalFormData.currency;
					oFile.formData.product = "";
					oFile.formData.reference = "";
					oFile.formData.filebox_shortcut = "";
					oFile.formData.function_type = originalFormData.function_type;
					oFile.formData.contract_date = "";
					oFile.formData.expiry_date = "";
					oFile.formData.received_date = "";
					oFile.formData.counter_party_name = "";

					// if (!oFile.formData.doc_attributes) {
					let customKeys = {
						Amount: { formKey: "amount", default: "" },
						Currency: { formKey: "currency", default: "JPY" },
						ContractDate: { formKey: "contract_date", default: "" },
						ExpiryDate: { formKey: "expiry_date", default: "" },
						ReceivedDate: { formKey: "received_date", default: "" },
						CounterPartyName: { formKey: "counter_party_name", default: "" },
						ProductName: { formKey: "product", default: "" },
						RefDocument: { formKey: "reference", default: "" },
					}
					oFile.formData.doc_attributes = [];
					for (let key in oFile.info && oFile.info.Custom) {
						if (Object.keys(customKeys).includes(key)) {
							let custom = customKeys[key];
							oFile.formData[custom.formKey] = oFile.formData[custom.formKey] || oFile.info.Custom[key] || custom.default;
						} else {
							oFile.formData.doc_attributes.push({ key: key, value: oFile.info.Custom[key] });
						}
					}
					// }
				}
				// console.log(oFile.formData);
			}

			formData = PDFFile.formData;

		}

		// this.objMain.setFormData(formData);
		this.objMain.updateState({ pageState: pageState, PDFFiles, PDFFile, selectedFile, formData });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	FileListChangeCallback = (fileKey = null) => {
		let { PDFFiles, PDFFile, selectedFile, formData, originalFormData } = this.objMain.state;
		selectedFile = null;
		PDFFile = {};
		formData = null;
		if (fileKey) {
			selectedFile = fileKey;
			PDFFile = PDFFiles[fileKey];
			if (PDFFile.formData) {
				for (let i in PDFFile.formData) {
					formData[i] = PDFFile.formData[i];
				}
			} else {
				formData = originalFormData;
			}
		}
		// console.log(PDFFile.formData);
		if (!formData) {
			formData = originalFormData;
			// console.log(originalFormData);
		}

		this.objMain.updateState({ PDFFile, selectedFile, formData });
	}

	FileListRemoveCallback = (fileKey) => {
		// let { PDFFiles, PDFFile, selectedFile, formData, originalFormData } = this.objMain.state;
		// selectedFile = null;
		// PDFFile = {};
		// formData = null;
		// if (fileKey) {
		// 	selectedFile = fileKey;
		// 	PDFFile = PDFFiles[fileKey];
		// 	formData = PDFFile.formData || originalFormData;
		// }
		// console.log(fileKey);
		// console.log(PDFFiles);
		// if (!formData) {
		// 	formData = originalFormData;
		// }

		// this.objMain.updateState({ PDFFile, selectedFile, formData });
	}

	FileUploadPanel = (props) => {
		// let { allowUpload } = this.state;
		// let { InputFileUpload } = this;
		let {
			// newActive,
			DocFile,
			// DocFileURL
		} = this.objMain.state;
		let { SearchDocKeys } = this;

		// if (!allowUpload) {
		// 	return null;
		// }

		// let className = `drop-zone-panel ${props.className || ""}`;

		return (
			<div className={`file-upload-panel${DocFile ? "" : " no-file"}`}>

				<div className={`drop-zone-panel`}>

					<label htmlFor="pdf-upload"
						className="dropbox-area file-dropbox"
						onDragOver={(ev) => {
							ev.preventDefault();
						}}
						onDrop={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
							this.RegisterFiles(ev.dataTransfer.files);
						}}
						onDragLeave={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
						}}
					>
						<div className="drop-area-inner">
							{/* <IconPDFFile /> */}
							<FontAwesomeIcon icon={faFileWord} />
						</div>
						{/* <InputFileUpload /> */}
						<input type="file" name="pdf-upload" id="pdf-upload" accept=".doc,.docx"
							style={{ display: "none" }}
							onChange={(ev) => {
								let DocFile = ev.target.files[0];
								let DocFileURL = URL.createObjectURL(DocFile);
								this.objMain.updateState({ DocFile, DocFileURL });
								SearchDocKeys();
								// this.RegisterFiles(ev.target.files, () => {
								// 	ev.target.value = null;
								// 	// console.log(ev.target.files);
								// });
							}}
						/>
					</label>
					<label className="info">PDFをアップロード</label>

				</div>

			</div>
		);
	}

	DocFilePreview = (props) => {
		let { DocFileURL } = this.objMain.state;

		if (!DocFileURL) {
			return null;
		}

		return (
			<FileViewer
				fileType={"docx"}
				// errorComponent={CustomErrorComponent}
				// onError={this.onError}
				// onSuccess={(ev) => {
				// 	console.log(ev);
				// }}
				filePath={DocFileURL}
			/>
		);
	}

	SearchDocKeys = () => {
		// var count = 0;
		var findInterval = setInterval(() => {
			// count++;
			let docText = document.querySelector("#docx");
			// console.log(count, docText);
			if (docText && docText.innerText) {
				// console.log(docText.innerText);
				let DocKeys = docText.innerText.match(/{\$:.*?}/g);
				//console.log(DocKeys);
				this.objMain.updateState({ DocKeys });
				clearInterval(findInterval);
			}
		}, 0);
	}

	DocUploaded = () => {
		let {
			DocFile,
			// DocFileURL,
			DocKeys
		} = this.objMain.state;
		let {
			DocFilePreview,
			// SearchDocKeys
		} = this;

		if (!DocFile) {
			return null;
		}

		//console.log(DocKeys);
		let ElementKeys = [];
		if (DocKeys) {
			for (let i in DocKeys) {
				ElementKeys.push(
					<li key={i} className="doc-key-item">{DocKeys[i]}</li>
				);
			}
		}
		// if (!DocKeys) {
		// 	SearchDocKeys();
		// }

		return (
			<Form.Row className="flex-full" style={{ position: "relative" }}>

				<Form.Group as={Col} xs={12} md={8}>
					<div className={`template-preview active`}>
						<DocFilePreview />
					</div>
				</Form.Group>
				<Form.Group as={Col} xs={12} md={4}>
					<ul className="doc-key-container">
						{ElementKeys}
					</ul>
				</Form.Group>

			</Form.Row>
		);
	}

	render() {
		let { t } = this.props;
		// let { FieldControl } = this.objMain;
		let { newActive } = this.objMain.state;
		let { formData, FileUploadPanel, DocUploaded } = this;

		// <Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >
		return (
			<div className="container-fluid">

				<Form
					// validated={formValidate[this.name]}
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="full-form"
				>

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					{/* <div className="form-body flex-column"> */}
					<div className="form-body flex-column">

						<Form.Row>
							<Form.Group as={Col} xs={8} md={6} xl={6}>
								<Form.Control
									name='template-search'
									placeholder={t("common:documents.template-selection.search")}
									value={formData.search_value || ""}
									onChange={(ev) => {
										//console.log("TEST");
									}}
									required
								/>
							</Form.Group>
							<Form.Group as={Col} xs={4} md={6} xl={6} className="flex-right">
								<Button variant="primary" type="button" onClick={(ev) => {
									//console.log(ev.target);
									this.objMain.updateState({ newActive: true });
								}}>
									<span className="btn-label">{t('common:documents.general.new')}</span>
								</Button>
							</Form.Group>
						</Form.Row>

					</div>

					<div className="form-foot">

					</div>

					<div className="page-action-bottom-relative">
						<div className="wrapper-button-cancel">
							<Button variant="secondary" onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
								<FontAwesomeIcon icon={faChevronLeft} />
								<span className="btn-label">{t('common:documents.general.back')}</span>
							</Button>
						</div>
						<div className="wrapper-button-submit">
							<Button variant="primary" type="submit">
								<span className="btn-label">{t('common:documents.general.next')}</span>
								<FontAwesomeIcon icon={faChevronRight} />
							</Button>
						</div>
					</div>

				</Form>
				<div className={`template-new${newActive ? " active" : ""}`}>
					<div className="header-wrapper sticky">
						<div className="header-left">
							<Button variant="secondary" onClick={(ev) => {
								this.objMain.updateState({ newActive: false, DocFile: null, DocFileURL: null });
							}}>
								<span>{t("common:documents.new-template-cancel")}</span>
							</Button>
						</div>
						<div className="header-center document-title">
							{/* {DocTitle} */}
						</div>
						<div className="header-right">
							{/* <ActionBar /> */}
							<Button variant="primary" onClick={(ev) => {
								this.objMain.updateState({ newActive: false, DocFile: null, DocFileURL: null });
							}}>
								<span>{t("common:documents.new-template-confirm")}</span>
							</Button>
						</div>
					</div>
					<div className="body-wrapper">
						<FileUploadPanel />
						<DocUploaded />
					</div>
				</div>

			</div>

		);
		// {/* </Container > */ }

	}

}
