import Main from "./Main";
import { withTranslation } from "react-i18next";
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";

class Signer extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Signer";
		this.state.code = "signer";
		this.state.function_type = 1;
		this.state.searchOption.function_type = 1;
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { code } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Dropdown as={ButtonGroup} key="document-create-button" className="btn-custom-group btn-custom-mui">
				<Link key="0" className="btn btn-custom btn-primary" to={`/${code}/documents/create`}>
					{t('common:main.general.create')}
				</Link>
				<Dropdown.Toggle split id="document-create-button-spliter" />
				<Dropdown.Menu>
					<Link key="1" className="btn btn-custom btn-primary dropdown-item" to={`/${code}/documents/create/by-template`}>
						{t('common:main.general.create-from-word')}
					</Link>
				</Dropdown.Menu>
			</Dropdown>
		)

		return ButtonList;
	}

}

export default withTranslation()(Signer);