
import mappingTranslateDefault from "../Main/MappingTranslateCommon";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
	Col,
	Form,
	InputGroup,
	Nav,
	Container,
	NavDropdown,
} from 'react-bootstrap';
import {
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { blue } from "@mui/material/colors";
import logo from "../Assets/img/logo_figure.svg";

export default class GuestSetting {

	static get key() {
		return "guest-setting";
	}
	static get name() {
		return "GuestSetting";
	}
	static get code() {
		return GuestSetting.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = GuestSetting.key;
		this.name = GuestSetting.name;
		this.code = GuestSetting.code;
		this.formData = {
			company_seal: "common:documents.host-setting.company-seal",
			use_digital_certificate: "common:documents.host-setting.digital-certificate",
			sign_level: "common:documents.document-info.sign-level",
			counter_party_name: "common:documents.document-info.counter-party-name",
			guests: "common:documents.guest-setting.guests",
		};
		this.initialValues = {
			guests: {},
		}

		this.objMain.setPageObject(this);
		// console.log(this.objMain.state.formData.use_digital_certificate);
	}

	PleaseSelectHost = (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalTitle = "common:documents.determine.saving";
		modalProps = {
			"show": true,
			"centered": true,
			"backdrop": true,
		}
		ModalBody = () => {
			return (
				<div>{t("common:documents.please-select-host-or-guest")}</div>
			);
		};

		this.objMain.updateState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	formSubmitCallback = (ev) => {
		let { pageState, formData } = this.objMain.state;
		var counthost = Object.keys(formData.signers).length;
		var countguest = Object.keys(formData.guests).length;
		if (countguest === 0 && counthost === 0) {
			this.PleaseSelectHost();
		} else {
			pageState[this.name].completed = true;
			this.objMain.setFormData(this.formData);
			this.objMain.updateState({ pageState: pageState });
			this.objMain.nextPageStep(ev, this.constructor);
		}
	};

	setDigitalCertificateFlag = (ev, UserID) => {
		let guest = this.formData.guests[UserID];
		let digiCert = ![true, "true"].includes(guest.use_digital_certificate);
		this.formData.guests[UserID].use_digital_certificate = digiCert;
		this.objMain.setFormData(this.formData);
	}

	OptionalCertificateType = (props) => {
		let { t } = this.props;
		let { user } = props;
		let { CertificateType } = this.objMain.state;
		let { sign_level } = this.formData;
		let CertItems = [];

		// console.log(sign_level);
		if (sign_level.toString() === "1") {
			CertItems.push(<NavDropdown.Item key={0} eventKey={0}>{t(`common:documents.host-setting.options-not-use`)}</NavDropdown.Item>);
		}

		for (let i in CertificateType) {
			let cert = CertificateType[i];
			if (cert.id === 2) {
				continue;
			}

			let certLabel = `${cert.id} : ${t(`common:documents.host-setting.options-${cert.label}`)}`;

			let CertWarning = () => { return null; };
			if (!user.available_certs.includes(cert.id)) {
				CertWarning = () => {
					return <FontAwesomeIcon icon={faExclamationTriangle} className="guest-no-certificate-warning" />;
				};
			}

			CertItems.push(
				<NavDropdown.Item key={cert.id} eventKey={cert.id}>
					<CertWarning />
					{certLabel}
				</NavDropdown.Item>
			);
		}

		return CertItems;
	}

	onCertChangeHandler = (key, user) => {
		let cert_type = parseInt(key);
		// console.log(cert_type)
		this.formData.guests[user.id].self_certificate = (cert_type !== 0);
		this.formData.guests[user.id].certificate_type = cert_type;
		this.objMain.setFormData(this.formData);
	}

	render() {

		let { t } = this.props;
		let { AvailableUser, SelectedUsers, SearchInputChange } = this.objMain;
		let { creationType, searchGuestsText } = this.objMain.state;
		let { guests, sign_level } = this.formData;
		let { OptionalCertificateType } = this;

		let SelectedUserProps = {
			users: guests,
			user_type: "guest",
		}

		// console.log(creationType, sign_level);
		if (["signer", "circle"].includes(creationType) && sign_level.toString() !== "0") {
			SelectedUserProps.optional = (props) => {
				let { user } = props;
				let { formData } = this.objMain.state;

				// let cert_type = parseInt(formData.certificate_type);
				// console.log(cert_type, user.certificate_type);
				// return null;

				if (sign_level.toString() === "2" && !user.certificate_type) {
					user.certificate_type = formData.certificate_type;
				}

				let CertTitle = user.certificate_type || t(`common:documents.host-setting.options-not-use`);

				if (!user.available_certs.includes(user.certificate_type) && user.certificate_type) {
					CertTitle = <span><FontAwesomeIcon icon={faExclamationTriangle} className="guest-no-certificate-warning-title" />{CertTitle}</span>;
				}

				return (
					<Nav activeKey={user.certificate_type} onSelect={(key) => {
						this.onCertChangeHandler(key, user);
					}} className="guest-certificate-selection">
						<NavDropdown title={CertTitle} id="basic-nav-dropdown">
							<OptionalCertificateType user={user} />
						</NavDropdown>
					</Nav>
				);
			};
		}

		let AvailableUserProps = {
			users: guests,
			user_type: "guest",
		}
		if (["signer", "circle"].includes(creationType)) {
			AvailableUserProps.optional = (props) => {
				let { user } = props;
				console.log(user);
				if (user.linkage_tenant_id && user.linkage_user_id) {
					return (
						<div className="user-linkage-container">
							<img src={logo} className="user-linkage-icon" alt="paperlogic" />
						</div>
					);
				} else {
					return null;
				}
			};
		}

		// let showDigiCertField = this.formData.company_seal ? "none" : "block";

		return (

			<Container fluid="md" className={this.key + "-container form-wrapper container-fluid"} >

				<Form
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="container-fluid full-form"
				>
					<div className="layout-right-title form-header guest_settings_layout" >
						<h2>{this.headerInfo}</h2>
						<div className="checkbox_guest">
							<FormControlLabel
								label={
									mappingTranslateDefault(
										t,
										"common:general.mfa-auth.checkbox-guest-sign",
										"ゲストの二要素認証を設定"
									)
								}
								control={
									<Checkbox
										sx={{
											color: blue[400],
											"&.Mui-checked": {
												color: blue[400],
											},
										}}
										// checked={applyMFALoginEmail}
										onChange={(e) => {
											// this.formData['is_applying_guest_mfa'] = ;
											let data = { ...this.formData, is_applying_guest_mfa: e.target.checked };

											this.objMain.setFormData(data);
											// this.setState({
											// 	formData: {
											// 		...this.state.formData,
											// 		isApplyMFAGuest: e.target.checked
											// 	}
											// });
										}}
									/>
								}
							/>
						</div>
					</div>

					<div className="form-body flex-column">

						<Form.Row className="flex-full">

							<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
								<Form.Label>{t("common:documents.guest-setting.guests-list")}</Form.Label>
								<SelectedUsers {...SelectedUserProps} />
							</Form.Group>

							<Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
								<Form.Label>{t("common:documents.guest-setting.available-user-list")}</Form.Label>
								<InputGroup className="form-control">
									<div className="search-input-container">
										<TextField
											className="search-input-field"
											placeholder={t('common:documents.general.guest-search-input-placeholder')}
											onChange={(e) => SearchInputChange(AvailableUserProps, e)}
											defaultValue={searchGuestsText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
									</div>
									<AvailableUser {...AvailableUserProps} />
								</InputGroup >
							</Form.Group>

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<ChevronLeftIcon />
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'>
									<span className="btn-label">{t('common:documents.general.next')}</span>
									<ChevronRightIcon />
								</Button>
							</div>
						</div>

					</div>

				</Form>

			</Container>

		);

	}

}
