
import OtpDialog from '../Auth/OtpDialog';
import AuthService from '../../Service/Auth';
import axios from "axios";

import API from "../../Config/API";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	Col,
	Form,
	Container,
	Tabs,
	Tab,
	InputGroup,
	ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	// faUsers,
	faExclamationCircle,
	faInfoCircle,
	faCheckCircle,
	faMinusCircle,
	faTimesCircle,
	faStopCircle,
	faQuestionCircle,
	faTimes,
	faAngleDoubleLeft,
	faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';

import FullPreview from "../Fragments/PDFViewer/FullPreview";

import Layout from '../../Layout';
import Service from '../../Service';
import Loading from '../Loading';
import Error from '../Error';
import PDFViewer from '../Fragments/PDFViewer';
import ProfileAvatar from '../../Layout/ProfileAvatar';
import FormDetail from '../Settings/AppCert/FormDetail';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import HistoryIcon from '@mui/icons-material/History';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from "@mui/icons-material/Description";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import ReplyIcon from '@mui/icons-material/Reply';
// import LinkIcon from '@mui/icons-material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Moment from 'moment';


class DocumentDetail extends Layout {

	constructor(props) {
		super(props);

		let SelectedFile = null;
		let PDFFiles = null;
		if (props.docInfo) {
			PDFFiles = props.docInfo.files;

			if (Object.keys(PDFFiles).length > 0) {
				SelectedFile = Object.keys(PDFFiles)[0];
			}
		}

		this.state.openOtpDialog = false;
		// this.state.isVerifiedGuest = true;
		this.state.isVerifySoftware = false;
		this.state.isVerifyEmail = false;
		this.state.isVerified = true;
		this.state.signingTypeFunc = 'SignConfirmHandler';

		this.state.loading = true;
		this.state.token = props.token || null;
		this.state.docInfo = props.docInfo;
		this.state.PDFFiles = PDFFiles;
		this.state.SelectedFile = SelectedFile;
		this.state.action = props.action || null;
		this.state.resendingEmail = {};
		this.state.appCert = null;
		this.state.usersGroup = ["hosts", "guests", "groups"];
		this.state.users = props.users || null;
		this.state.certApply = false;
		this.state.certUpload = {
			file: null,
			password: null,
		};
		this.state.isInvalid = {};
		this.state.operationLogFlg = false;
		this.state.docViewers = {};
		this.state.viewerIndex = [];
		// console.log(props.token);
		this.state.previewPdf = null;
		this.state.PageData = null;
		this.state.isGuest = !!(props.token || null);

		this.state.formData = {};
		this.state.fields = {};
		this.state.keyFields = {
			document_id: "document_id",
			document_type_id: "document_type",
			preservation_require: "preservation_require_label",

			title: "title",
			amount: "amount_text",
			currency: "currency",
			product_name: "product_name",
			reference: "reference",
			filebox_path: "filebox_path",

			counter_party_name: "counter_party_name",
			contract_date: "contract_date",
			expiry_date: "expiry_date",
			received_date: "received_date",
			effective_date: "effective_date",

			sign_finish_date: "sign_finish_date",
			content: "content",

			attributes: "attributes",

			email: "email",
			family_name: "family_name",
			first_name: "first_name",
			company_name: "company_name",
			mobile: "mobile",

			dateofnoti: "dateofnoti",

			select_datenoti: "select_datenoti",
		}
		// console.log("PageData", this.state.PageData);
	}

	/** please use function componentDidMountExtends intread */
	// componentDidMount() {
	// 	let { users, token, usersGroup } = this.state;
	// 	// console.log("async componentDidMount");
	// 	this.GetLayoutResources();
	// }

	componentDidMountExtends = async () => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		let { users, token, usersGroup, docInfo, PageData, isGuest, UserInfo, modal } = this.state;

		if (!users && !token) {
			await Service.GetUserList("user_type_name", usersGroup).then(resp => {
				users = resp;
			}).catch(err => {
				console.log(err.response);
			});
		} else {
			if (!users) {
				users = {};
				for (let group of usersGroup) {
					users[group] = {};
				}
			}
		}

		if (users) {
			users["sign-permission"] = {}
			if (users["hosts"]) {
				for (let i in users["hosts"]) {
					if (users["hosts"][i]["permission"].signer && users["hosts"][i]["permission"].certificate) {
						users["sign-permission"][i] = users["hosts"][i];
					}
				}
			}

			users["stamps"] = {}
			if (users["groups"]) {
				for (let i in users["groups"]) {
					if (users["groups"][i]["available_certs"].includes(2)) {
						users["stamps"][i] = users["groups"][i];
					}
				}
			}
		}

		//console.log(PageData);
		if (docInfo && !PageData && !isGuest) {
			await Service.GetPageData(docInfo.function_code).then(resp => {
				PageData = resp;

				PageData.doc_types_index = {};
				for (let item of PageData.doc_types) {
					PageData.doc_types_index[item.id] = item;
				}

				PageData.currencies_index = {};
				for (let item of PageData.currencies) {
					PageData.currencies_index[item.code] = item;
				}

				PageData.preservation_require_index = {};
				for (let item of PageData.preservation_require) {
					PageData.preservation_require_index[item.id] = item;
				}
			});
		}

		if (docInfo && !docInfo.amount_text) {
			docInfo.amount_text = this.numFormat(docInfo.amount);
		}

		for (let key in keyFields) {
			fields[key] = {
				name: key,
				value: formData[key] ? formData[key] : "",
				label: t(`common:settings.guest.${keyFields[key]}`),
			};
		}

		// if (isGuest) {

		// 	console.log(UserInfo);

		// 	// modal.props.show = true;
		// 	// modal.props.size = "xl";
		// 	// modal.props.className = "layout modal-responsive";
		// 	// modal.title = "common:documents.viewer-setting.header";
		// 	// modal.body = Loading;
		// 	// modal.action = [];

		// }

		// if (docInfo['is_applying_guest_mfa']){
		// 	this.setState({isVerifiedGuest: false});
		// 	this.getCurrentUserAttributesGuest();
		// }

		this.setState({ users, docInfo, PageData, fields, UserInfo, modal }, (ev, fnCB) => {
			this.resetDocViewerList(ev, fnCB);
			this.resetDocForwardUserList(ev, fnCB);
		});
	}

	numFormat(value) {
		if (isNaN(parseFloat(value))) {
			return value;
		} else {
			value = value.toString().split(".");
			value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return value.join(".");
		}
	}

	resetDocForwardUserList = (ev, fnCB) => {
		let { docInfo, users } = this.state;
		// let { docInfo, users, user_quick_filter, forwardUser } = this.state;
		let searchAvailableForwardUsers = [];
		let searchAvailableForwardUsersText = '';
		let availableForwardUsers = [];

		availableForwardUsers = Object.values(users["sign-permission"]);
		if (parseInt(docInfo.certificate_type) === 2) {
			availableForwardUsers = Object.values(users["stamps"]);
		}
		searchAvailableForwardUsers = [...availableForwardUsers];
		this.setState({
			searchAvailableForwardUsers, availableForwardUsers, searchAvailableForwardUsersText
		}, fnCB);
	}

	resetDocViewerList = (ev, fnCB) => {
		let { docInfo, users } = this.state;
		let docViewers = {};
		let viewerIndex = [];
		for (let user of docInfo.viewers) {
			if (user.user_type === 0) {
				docViewers[user.user_id] = users.hosts[user.user_id];
				viewerIndex.push(user.user_id);
			} else if (user.user_type === 2) {
				docViewers[user.user_id] = users.groups[user.user_id];
				viewerIndex.push(user.user_id);
			}
		}

		let availableViewers = {};
		let searchAvailableViewers = {};
		let searchAvailableViewersText = '';
		let availableViewersGroup = {};
		let searchAvailableViewersGroup = {};
		let searchAvailableViewersGroupText = '';
		for (let user of Object.values(users.hosts)) {
			if (!viewerIndex.includes(user.user_id)) {
				availableViewers[user.user_id] = users.hosts[user.user_id];
			}
		}
		for (let user of Object.values(users.groups)) {
			if (!viewerIndex.includes(user.user_id)) {
				availableViewersGroup[user.user_id] = users.groups[user.user_id];
			}
		}

		searchAvailableViewers = { ...availableViewers };
		searchAvailableViewersGroup = { ...availableViewersGroup };
		this.setState({
			docViewers, viewerIndex, availableViewers, availableViewersGroup,
			searchAvailableViewers, searchAvailableViewersText, searchAvailableViewersGroup, searchAvailableViewersGroupText
		}, fnCB);
	}

	viewerUpdateSubmit = (ev) => {
		let { t } = this.props;
		let { docInfo, viewerIndex, modal } = this.state;
		let document_id = docInfo.document_id;
		let viewers = viewerIndex;

		modal.body = Loading;
		modal.action = [];
		delete modal.props.size;
		delete modal.props.className;

		this.setState({ modal }, () => {
			Service.UpdateDocumentViewers({ document_id, viewers }).then(resp => {
				let { modal } = this.state;
				modal.props.show = false;
				docInfo.viewers = resp.viewers;
				this.setState({ docInfo, modal });
			}).catch(err => {
				console.log(err.response);
				this.ShowErrorMessage(err.response, [(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={(ev) => {
							let { modal } = this.state;
							modal.props.show = false;
							this.setState({ modal });
						}}>
						<span>{t("common:general.ok")} </span>
					</Button>
				)]);
			});
		});
	}

	// componentDidUpdate() {	}
	SignerResendEmail = (ev) => {
		let { docInfo, resendingEmail } = this.state;
		let user_id = parseInt(ev.target.closest(".action-resend-email").dataset.user_id);
		resendingEmail[user_id] = null;
		this.setState({ resendingEmail }, () => {
			Service.resendEmail(docInfo.id, user_id).then(resp => {
				let { resendingEmail } = this.state;
				// console.log(resp);
				delete resendingEmail[user_id];
				this.setState({ resendingEmail }, () => {
					this.UpdateResendStatus(user_id);
				});
			}).catch(err => {
				//console.log(err.response);
			})
		});
		// resendEmail
	}

	SignerOperation = (props) => {
		// let { t } = this.props;
		let { user, status, isReceiver } = props;
		let { docInfo, resendingEmail } = this.state;
		let SendEmailIcon = MailOutlineIcon;
		// <FontAwesomeIcon icon={faCaretDown} className="action-icon" />

		if (docInfo.master_doc_info) {
			return null;
		}

		if (docInfo.user_type === 0 && ![0, 5].includes(docInfo.doc_status)) {
			// console.log(resendingEmail);
			// console.log(status);
			// console.log(docInfo.function_code);
			// console.log(docInfo.doc_status);
			if (!status && docInfo.function_code === "sender" && ![2, 8].includes(docInfo.doc_status) && isReceiver) {
				return null;
			}

			// console.log(user.signing_status);
			if (user.signing_status === 'WAIT') {
				return null;
			}

			// console.log(user.signing_status);

			let sendClickHandler = this.SignerResendEmail;
			if (Object.keys(resendingEmail).includes(user.id.toString())) {
				SendEmailIcon = CircularProgress;
				sendClickHandler = null;
			}
			return (
				<div className={"sign-status"} >
					<Tooltip title="メールを再送する" arrow >
						<div className="icon-container clickable" >
							<SendEmailIcon data-user_id={user.id} className="action-resend-email" onClick={sendClickHandler} />
						</div>
					</Tooltip>
				</div>
			);
		} else {
			return null;
		}

	}

	UpdateResendStatus = (user_id) => {
		let { docInfo } = this.state;

		let allResent = true;
		let targetUser = null;
		for (let i in docInfo.signers) {
			let user = docInfo.signers[i];

			if (user.id === user_id) {
				user.token_expired = 0;
				targetUser = user;
			}

			if (allResent && user.token_expired === 1) {
				allResent = false;
			}

			if (!allResent && targetUser) {
				break;
			}
		}

		if (allResent) {
			if (docInfo.function_code === "sender") {
				docInfo.doc_status = 7;
			} else if (docInfo.function_code === "circle") {
				docInfo.doc_status = 11;
			} else if (docInfo.function_code === "verification") {
				docInfo.doc_status = 9;
			} else {
				docInfo.doc_status = 1;
			}
		}

		this.setState({ docInfo });
	}

	SignStatus = (props) => {
		let { t } = this.props;
		let { docInfo } = this.state;
		let { user, showStatus } = props;

		if (!showStatus || docInfo.doc_status === 0) {
			return null;
		}

		if (user.signing_status === 'WAIT') {
			return null;
		}

		let status = <CircleIcon fontSize="small" color='disabled' />;//"wait-signed";
		if (user.signing_status === 'FORWARD') {
			status = <ReplyIcon fontSize="small" color='primary' style={{ transform: "rotateZ(225deg) rotateX(180deg)" }} /> // "forward";
		} else if (user.signed === 1) {
			status = <CheckCircleIcon fontSize="small" color='success' />  //"signed";
		} else if (user.user_type === 1 && user.token_expired === 1) {
			status = <CancelIcon fontSize="small" color='warning' />  //"token-expired";
		}
		if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4 && Object.keys(docInfo.operation_log).length > 0) {
			docInfo.status_code = 'signed';
			status = <CheckCircleIcon fontSize="small" color='success' />  //"signed";
		}
		return (
			<div className={`sign-status ${docInfo.status_code}`} title={t(`common:document.${docInfo.status_code}`)} >
				<div className="icon-container">
					{status}
				</div>
			</div>
		);
	}

	SignerList = (props) => {
		let { t } = this.props;
		let { users, label, showStatus, filter, isReceiver } = props;
		let ObjElements = [];
		let { SignerOperation, SignStatus } = this;

		if (!users) {
			return null;
		}

		if (users instanceof Array && users.length === 0) {
			return null
		}

		if (!(users instanceof Array)) {
			users = Object.values(users);
		}

		ObjElements.push(
			<Form.Group key="group-label" xl={12} md={12} as={Col} className="info-label">
				<span className="document-info-key">{t(`common:${label}`)}</span>
			</Form.Group>
		);

		let inCaseUsers = [];
		for (let user of users) {
			if (filter) {
				let goNext = false;
				for (let i in filter) {
					let filterValue = filter[i];
					if (filterValue instanceof Array) {
						if (!filterValue.includes(user[i])) {
							goNext = true;
							break;
						}
					} else if (user[i] !== filter[i]) {
						goNext = true;
						break;
					}
				}
				if (goNext) {
					continue;
				}
			}

			inCaseUsers.push(user);
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = `${user.name} ${user.company_name || ""}`;
			let bottomInfo = `${user.email || ""}`;
			if (user.user_type === 2 && user.signedBy_name) {
				bottomInfo = `${t("common:document.company-seal.sign-by")}: ${user.signedBy_name}`;
			} else if (user.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user.members_count}`;
			}

			ObjElements.push(
				<Form.Group key={user.id} xs={12} md={12} as={Col}>
					<div className={`user-info  user${user.user_type}`}>
						<div className="display-avatar">
							<ProfileAvatar {...iconProps} />
						</div>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{topInfo} </span>
							</div>
							<div className="bottom-info">
								<span>{bottomInfo} </span>
							</div>
						</div>
						<SignerOperation user={user} showStatus={showStatus} isReceiver={isReceiver} />
						<SignStatus user={user} showStatus={showStatus} />
					</div>
				</Form.Group >
			);
		}

		if (inCaseUsers.length === 0) {
			ObjElements.push(
				<Form.Group key="empty-signer-list" xs={12} md={12} as={Col}>
					<div className="user-info empty"></div>
				</Form.Group >
			);
		}

		return ObjElements;

	}

	ViewerList = (props) => {
		let { t } = this.props;
		let { token } = this.state;
		let { users, label } = props;
		let ObjElements = [];

		if (!users) {
			users = [];
		}

		if (token) {
			return null;
		}

		//if (users instanceof Array && users.length === 0) {
		//	return null;
		//}

		if (!(users instanceof Array)) {
			users = Object.values(users);
		}

		let BTNEditViewer = () => {
			return (
				<IconButton
					sx={{ mr: 1, display: { xs: 'none', sm: 'inline-flex' } }}
					color="primary"
					className="btn-action"
					title={t("common:document.edit-viewer")}
					onClick={(ev) => {
						let { modal } = this.state;

						modal.props.show = true;
						modal.props.size = "xl";
						modal.props.className = "layout modal-responsive";
						modal.title = "common:documents.viewer-setting.header";
						modal.body = this.ViewerFormBody;
						modal.action = [
							<Button
								key="reset"
								sx={{ ml: 1 }}
								variant="outlined"
								onClick={this.resetDocViewerList}
							>
								<span>{t("common:general.reset")}</span>
							</Button>,
							<Button key="submit"
								sx={{ ml: 1 }}
								variant="contained"
								onClick={this.viewerUpdateSubmit}
							>
								<span>{t("common:general.submit")}</span>
							</Button>
						];

						this.setState({ modal });
					}}>
					<VisibilityIcon fontSize="small" />
				</IconButton>
			);
		};


		ObjElements.push(
			<Form.Group key="group-label" xl={12} md={12} as={Col} className="info-label">
				<BTNEditViewer />
				<span className="document-info-key">{t(`common:${label}`)}</span>
			</Form.Group>
		);

		let ViewersList = [];
		for (let user of users) {
			let viewerGroup = "";// user.user_type === 2 ? " group" : "";
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			}
			ViewersList.push(
				<div key={user.user_id} className={`viewer-info${viewerGroup}`}>
					<div className="viewer-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<span>{user.full_name}</span>
				</div>
			);
		}

		ObjElements.push(
			<Form.Group key="viewers-list" xs={12} md={12} as={Col}>
				{ViewersList}
			</Form.Group>
		);

		return ObjElements;

	}

	SearchInputChange(type, e) {
		if (type === 'viewers') {
			let availableUsers = this.state['availableViewers'];
			if (e.target.value === '') {
				this.setState({ searchAvailableViewers: { ...availableUsers }, searchAvailableViewersText: e.target.value });
			}
			else {
				let searchAvailableUsers = {};
				for (const key in availableUsers) {
					if (availableUsers[key].email.indexOf(e.target.value) > -1 || availableUsers[key].full_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
						searchAvailableUsers[key] = availableUsers[key];
					} else {
						if (availableUsers[key].groups.length > 0) {
							for (let i = 0; i < availableUsers[key].groups.length; i++) {
								if (availableUsers[key].groups[i].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
									if (searchAvailableUsers.hasOwnProperty(key)) {
										searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
									} else {
										searchAvailableUsers[key] = { ...availableUsers[key] };
										searchAvailableUsers[key].groups = [];
										searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
									}
								}
							}
						}
					}
				}
				this.setState({ searchAvailableViewers: { ...searchAvailableUsers }, searchAvailableViewersText: e.target.value });
			}
		} else if (type === 'viewersGroup') {
			let availableGroups = this.state['availableViewersGroup'];
			if (e.target.value === '') {
				this.setState({ searchAvailableViewersGroup: { ...availableGroups }, searchAvailableViewersGroupText: e.target.value });
			}
			else {
				let searchAvailableGroups = {};
				for (const key in availableGroups) {
					if (`${availableGroups[key].id}-${availableGroups[key].full_name}`.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
						searchAvailableGroups[key] = availableGroups[key];
					}
				}
				this.setState({
					searchAvailableViewersGroup: { ...searchAvailableGroups },
					searchAvailableViewersGroupText: e.target.value
				});
			}
		} else if (type === 'forwardUsers') {
			let availableForwardUsers = this.state['availableForwardUsers'];

			if (e.target.value === '') {
				this.setState({
					searchAvailableForwardUsers: [...availableForwardUsers],
					searchAvailableForwardUsersText: e.target.value
				});
			}
			else {
				let searchAvailableForwardUsers = [];
				for (let i = 0; i < availableForwardUsers.length; i++) {
					if (availableForwardUsers[i].email.indexOf(e.target.value) > -1
						|| `${availableForwardUsers[i].id}-${availableForwardUsers[i].full_name}`.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
						searchAvailableForwardUsers.push(availableForwardUsers[i]);
					}
				}
				this.setState({
					searchAvailableForwardUsers: [...searchAvailableForwardUsers],
					searchAvailableForwardUsersText: e.target.value
				});
			}
		}
	}

	ViewerFormBody = () => {
		let { t } = this.props;
		let { SelectedUsers, AvailableUser, AvailableGroup } = this;

		return (
			<div className="form-body flex-column full-height-modal-body">

				<Form.Row className="flex-full">

					<Form.Group xs={6} md={6} as={Col} controlId="user_list" className="signer-list-wrapper" style={{ height: "100%" }}>
						<Form.Label>{t("common:documents.viewer-setting.viewers-list")}</Form.Label>
						<div className="user-info-container" style={{ width: "100%", overflow: "auto" }}>
							<SelectedUsers />
						</div>
					</Form.Group>

					<Form.Group xs={6} md={6} as={Col} controlId="all_user_list" className="user-selection-container" style={{ height: "100%" }}>
						<Form.Label>{t("common:documents.host-setting.available-viewers-list")}</Form.Label>


						<Tabs defaultActiveKey="veiwer-by-user" transition={false} id="viewers-selection">

							<Tab eventKey="veiwer-by-user" title={t("common:documents.host-setting.viewer-by-user")}>
								<InputGroup className="form-control tab-input-group">
									<div className="search-input-container">
										<TextField
											className="search-input-field"
											placeholder={t('common:documents.general.text-input-placeholder')}
											onChange={(e) => this.SearchInputChange('viewers', e)}
											defaultValue={this.state.searchAvailableViewersText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
									</div>
									<AvailableUser />
								</InputGroup >
							</Tab>

							<Tab eventKey="viewer-by-group" title={t("common:documents.host-setting.viewer-by-group")}>
								<InputGroup className="form-control tab-input-group">
									<div className="search-input-container">
										<TextField
											className="search-input-field"
											placeholder={t('common:documents.general.text-input-placeholder')}
											onChange={(e) => this.SearchInputChange('viewersGroup', e)}
											defaultValue={this.state.searchAvailableViewersGroupText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
									</div>
									<AvailableGroup />
								</InputGroup >
							</Tab>
						</Tabs>
					</Form.Group>

				</Form.Row>

			</div>
		);
	}

	AvailableUser = (props) => {
		let { searchAvailableViewers } = this.state;

		if (!searchAvailableViewers) {
			return <Loading />;
		}

		let { t } = this.props;
		let { users, groupCollapsed } = this.state;
		let { UserSelectionBox } = this;

		let groupInfo = {};
		let noGroup = {}

		for (let id in searchAvailableViewers) {
			let user = searchAvailableViewers[id];
			if (user.groups.length > 0) {
				for (let group of user.groups) {
					if (!groupInfo.hasOwnProperty(group.id)) {
						groupInfo[group.id] = { "members": [] };
						for (let key in users.groups[group.id]) {
							groupInfo[group.id][key] = users.groups[group.id][key];
						}
					}
					groupInfo[group.id].members.push(user);
				}
			} else {
				noGroup[id] = user;
			}
		}

		let groupBox = [];
		for (let groupID in groupInfo) {
			let group = groupInfo[groupID];
			let memberBox = [];
			for (let user of group.members) {
				memberBox.push(
					<UserSelectionBox key={`${groupID} -${user.user_id}`} info={{
						icon: faAngleLeft,
						class: "info-container",
						top: user.full_name,
						bottom: user.email,
						user: user,
					}} />
				);
			}

			let groupBoxClass = `custom-collapse${groupCollapsed === groupID ? " show" : ""}`;
			let groupBoxStyle = { height: (group.members.length * 54) + "px" };
			groupBox.push(
				<UserSelectionBox key={groupID} info={{
					icon: faAngleDoubleLeft,
					class: "info-container group",
					top: group.full_name,
					bottom: `${t("common:general.member-remaining")} ${group.members.length}`,
					dataset: { "data-group": groupID },
					user: group,
					click: (ev) => {
						this.setState({ groupCollapsed: groupID });
					},
				}}>
					<ListGroup as="ul" className={groupBoxClass} style={groupBoxStyle}>
						{memberBox}
					</ListGroup>
				</UserSelectionBox>
			);
		}

		for (let userID in noGroup) {
			let user = noGroup[userID];
			groupBox.push(
				<UserSelectionBox key={user.user_id} info={{
					icon: faAngleLeft,
					class: "info-container",
					top: user.full_name,
					bottom: user.email,
					user: user,
				}} />
			);
		}

		return (
			<ListGroup as="ul" className="user-selection-box">
				{groupBox}
			</ListGroup>
		);
	}

	AvailableGroup = (props) => {
		let { searchAvailableViewersGroup } = this.state;

		if (!searchAvailableViewersGroup) {
			return <Loading />;
		}

		let { t } = this.props;
		let { UserSelectionBox } = this;

		let groupBox = [];
		for (let userID in searchAvailableViewersGroup) {
			let user = searchAvailableViewersGroup[userID];
			groupBox.push(
				<UserSelectionBox key={user.user_id} info={{
					icon: faAngleDoubleLeft,
					class: "info-container group",
					top: user.full_name,
					bottom: `${t("common:general.member-remaining")} ${user.host_member_count}`,
					user: user,
				}} />
			);
		}

		return (
			<ListGroup as="ul" className="user-selection-box">
				{groupBox}
			</ListGroup>
		);
	}

	UserSelectionBox = (props) => {
		let { children, info } = props;

		return (
			<ListGroup.Item as="li" className="user-info" {...info.dataset} >
				<div className="action">
					<button type="button" className="btn-add-user" onClick={ev => {
						let { docViewers, viewerIndex, availableViewers, availableViewersGroup,
							searchAvailableViewers, searchAvailableViewersGroup } = this.state;

						if (info.user.hasOwnProperty("members")) {
							for (let member of info.user.members) {
								docViewers[member.user_id] = member;
								viewerIndex.push(member.user_id);

								if (availableViewers.hasOwnProperty(member.user_id)) {
									delete availableViewers[member.user_id];
									delete searchAvailableViewers[member.user_id];
								}
							}
						} else {
							docViewers[info.user.user_id] = info.user;
							viewerIndex.push(info.user.user_id);

							if (info.user.user_type === 0 && availableViewers.hasOwnProperty(info.user.user_id)) {
								delete availableViewers[info.user.user_id];
								delete searchAvailableViewers[info.user.user_id];
							} else if (info.user.user_type === 2 && availableViewersGroup.hasOwnProperty(info.user.user_id)) {
								delete availableViewersGroup[info.user.user_id];
								delete searchAvailableViewersGroup[info.user.user_id];
							}
						}

						this.setState({
							docViewers, viewerIndex, availableViewers, availableViewersGroup,
							searchAvailableViewers, searchAvailableViewersGroup
						}, () => {
							let viewerBoxSelected = document.querySelector(".layout .signer-list-wrapper .user-info-container");
							viewerBoxSelected.scrollTop = 10000;
						});
					}}>
						<FontAwesomeIcon icon={info.icon} />
					</button>
				</div>
				<div className="info">
					<div className={info.class} onClick={info.click}>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{info.user.user_id + "-" + info.top}</span>
							</div>
							<div className="bottom-info">
								<span>{info.bottom}</span>
							</div>
						</div>
						<div className="user-info-optional">
							{/* <OptionalAction user={user} /> */}
						</div>
					</div>
					{children}
				</div>
			</ListGroup.Item>
		);
	}

	SelectedUsers = (props) => {
		let { t } = this.props;
		let { docViewers, viewerIndex } = this.state;
		// let {user_type} = props;
		let objElements = [];

		for (let userID of viewerIndex) {
			let user = docViewers[userID];
			// console.log(user);
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = user.user_id + "-" + user.full_name;
			let bottomInfo = user.email;
			if (user.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user.host_member_count}`;
			}

			let OptionalAction = () => { return null; };
			if (props.hasOwnProperty("optional") && props.optional) {
				OptionalAction = () => {
					return (
						<div className="user-info-optional">
							{props.optional}
						</div>
					);
				};

			}

			objElements.push(
				<div key={userID} className="user-info">
					<div className="display-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div className="user-info-detail">
						<div className="top-info">
							<span>{topInfo} </span>
						</div>
						<div className="bottom-info">
							<span>{bottomInfo} </span>
						</div>
					</div>
					<OptionalAction user={user} />
					<div className="remove-user">
						<button type="button" className="btn-icon" onClick={(ev) => {
							let { docViewers, viewerIndex, availableViewers, availableViewersGroup,
								searchAvailableViewers, searchAvailableViewersGroup } = this.state;

							if (user.user_type === 0) {
								availableViewers[user.user_id] = user;
								searchAvailableViewers[user.user_id] = user;
							} else if (user.user_type === 2) {
								availableViewersGroup[user.user_id] = user;
								searchAvailableViewersGroup[user.user_id] = user;
							}

							delete docViewers[user.user_id];
							viewerIndex.splice(viewerIndex.indexOf(user.user_id), 1)

							this.setState({
								docViewers, viewerIndex, availableViewers, availableViewersGroup,
								searchAvailableViewers, searchAvailableViewersGroup
							});
						}}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</div>
			);
		}

		return objElements;
	}

	ObjectAttributes = (props) => {
		let { t } = this.props;
		let { docInfo, UpdateInfo, formData } = this.state;

		let ObjAttr = [];
		if (UpdateInfo) {
			if (formData.attributes.length === 0) {
				formData.attributes.push({
					label: "",
					value: "",
				});
			}
			for (let attr of formData.attributes) {
				let key_index = formData.attributes.indexOf(attr);
				// RowItems.push(<InfoValue key={`${key} -${value} `} key_value={value} key_label={key} key_editable={true} />);
				ObjAttr.push(
					<Form.Row key={key_index} data-key={key_index} style={{ position: "relative" }}>
						<AddCircleIcon className="btn-attribute add-attribute" color="primary" onClick={(ev) => {
							console.log(key_index);
							formData.attributes.splice(key_index + 1, 0, {
								label: "",
								value: "",
							});
							this.setState({ formData });
						}} />
						<RemoveCircleIcon className="btn-attribute remove-attribute" color="error" onClick={(ev) => {
							console.log(key_index);
							formData.attributes.splice(key_index, 1);
							this.setState({ formData });
						}} />
						<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
							<Form.Control
								name={`label[${key_index}`}
								value={attr.label || ""}
								placeholder={t("common:documents.document-info.key")}
								onChange={(ev) => {
									formData.attributes[key_index].label = ev.target.value || "";
									this.setState({ formData });
								}}
							// required
							/>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.key") })}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group xl={8} md={12} xs={9} as={Col} controlId={attr.value} className={`custom-form-outline{ validated }`}>
							<Form.Control
								name={`value[${key_index}`}
								value={attr.value || ""}
								placeholder={t("common:documents.document-info.value")}
								onChange={(ev) => {
									formData.attributes[key_index].value = ev.target.value || "";
									this.setState({ formData });
								}}
							// required
							/>
							<Form.Control.Feedback type="invalid">
								{t('common:message.input.required', { field_name: t("common:documents.document-info.value") })}
							</Form.Control.Feedback>
						</Form.Group>
					</Form.Row>
				);
			}
		} else {
			for (let key in docInfo.attributes) {
				ObjAttr.push(
					<Form.Row key={key}>
						<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
							<span className="document-info-key">{key}</span>
						</Form.Group>
						<Form.Group xl={8} md={12} xs={9} as={Col}>
							<span className="form-control document-info-value">{docInfo.attributes[key]}</span>
						</Form.Group>
					</Form.Row >
				);
			}
		}

		return ObjAttr;
	}

	FileList = (props) => {
		let { PDFFiles, SelectedFile } = this.state;
		let fileKeys = Object.keys(PDFFiles);

		let ObjFileList = fileKeys.map((key) => {
			let PDFFile = PDFFiles[key];
			let classlist = ["file-item"];
			if (key === SelectedFile) {
				classlist.push("active");
			}

			return (
				<li key={key} onClick={(ev) => {
					// console.log(key, SelectedFile);
					if (key !== SelectedFile) {
						this.setState({ SelectedFile: key });
					}
				}} className={classlist.join(" ")}>
					<span className="file-name" title={PDFFile.name}>{PDFFile.name}</span>
				</li>
			);
		});

		return ObjFileList;
	}

	DocumentInfoSave = (ev) => {
		let { t } = this.props;
		let { docInfo, formData, modal, PageData } = this.state;

		let data = JSON.parse(JSON.stringify(formData));
		data.attributes = {};
		for (let attr of formData.attributes) {
			if (attr.label) {
				data.attributes[attr.label] = attr.value;
			}
		}
		if (data.amount && data.amount !== "") {
			data.amount = data.amount.toString().replaceAll(",", "");
			if (data.amount.endsWith(".")) {
				data.amount = data.amount.substring(0, data.amount.length - 1);
			}
		}

		modal.close = false;
		modal.body = Loading;
		modal.action = [];
		console.log(data);
		this.setState({ modal }, () => {
			Service.DocumentInfoSave(data).then(resp => {
				// console.log(resp);
				modal.close = true;
				modal.body = `common:${resp.message}`;
				modal.action = [
					<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						modal.props.show = false;
						this.setState({ modal });
					}}>
						<span>{t("common:general.ok")}</span>
					</Button>
				];

				for (let i in data) {
					docInfo[i] = data[i];
				}
				docInfo.amount_text = this.numFormat(docInfo.amount);
				docInfo.amount = this.localeStringCustom(docInfo.amount);
				docInfo.document_type = PageData.doc_types_index[docInfo.document_type_id].label;
				if (docInfo.preservation_require) {
					docInfo.preservation_require_label = PageData.preservation_require_index[docInfo.preservation_require].label;
				}
				docInfo.operation_log = resp.operation_log;

				this.setState({ docInfo, modal, UpdateInfo: false });
			}).catch(err => {
				console.log(err.response);
				let errMessage = err.response.data.message;
				if (err.response.data.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}

				modal.close = true;
				modal.body = t(`common:${errMessage}`);
				modal.action = [(
					<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.DocumentInfoSave}>
						<span>{t("common:general.try-again")}</span>
					</Button>
				)];

				this.setState({ modal });
			});
		});
	}

	ActionBar = (props) => {
		let { t } = this.props;
		let { docInfo, operationLogFlg, isGuest, UserInfo } = this.state;

		if (!docInfo || docInfo === false) {
			return null;
		}

		let ActionList = [];

		let permission = docInfo.permission;

		if (!isGuest) {
			if ([0, 5].includes(docInfo.doc_status) && permission.update && parseInt(docInfo.creator_flg) === 0) {
				ActionList.push(
					<div key="edit">
						<Tooltip title="下書きを編集する" arrow>
							<span>
								<Button
									sx={{ mr: 2 }}
									title={t("common:document.edit")}
									variant="contained"
									startIcon={<EditIcon />}
									className="as-link"
									component={Link}
									to={`/${docInfo.function_code}/documents/edit/${docInfo.id}`}
								>
									{t("common:document.edit")}
								</Button>
							</span>
						</Tooltip>
					</div>
				);
			} else if (![0, 5].includes(docInfo.doc_status) && (permission.update || (UserInfo && [1, "1"].includes(UserInfo.administrator_role)))) {
				const { UpdateInfo } = this.state;

				if (UpdateInfo) {
					ActionList.push(
						<div key="info-save">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.save")}
								variant="contained"
								startIcon={<SaveIcon />}
								className="as-link"
								onClick={(ev) => {
									let btnSubmit = document.getElementById("form_detail_submit");
									btnSubmit.click();
								}}
							>
								{t("common:document.save")}
							</Button>
						</div>
					);
					ActionList.push(
						<div key="info-cancel">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.cancel")}
								variant="outlined"
								startIcon={<CancelIcon />}
								className="as-link"
								onClick={(ev) => {
									console.log(ev.target);
									this.setState({ UpdateInfo: false });
								}}
							>
								{t("common:document.cancel")}
							</Button>
						</div>
					);
				} else {
					ActionList.push(
						<div key="info-edit">
							<Button
								sx={{ mr: 2 }}
								title={t("common:document.edit")}
								variant="contained"
								startIcon={<EditIcon />}
								className="as-link"
								onClick={(ev) => {
									let { docInfo, formData, keyFields } = this.state;
									if (docInfo) {
										for (let key in keyFields) {
											formData[key] = [null, undefined].includes(docInfo[key]) ? "" : docInfo[key];
										}

										formData.attributes = [];
										for (let key in docInfo.attributes) {
											formData.attributes.push({
												label: key,
												value: docInfo.attributes[key],
											});
										}
									}
									this.setState({ UpdateInfo: true, formValidate: false, formData });
								}}
							>
								{t("common:document.edit")}
							</Button>
						</div>
					);
				}
			}
		}

		// if (permission.download && docInfo.doc_status === 2) {
		ActionList.push(
			<Box key="download" sx={{ mr: 2 }}>
				<Button
					variant="outlined"
					className="btn-action"
					startIcon={<CloudDownloadOutlinedIcon />}
					//title={t("common:document.download")}
					onClick={this.onDownloadRequestHandler}>
					{"PDFをダウンロード"}
				</Button>
			</Box>
		);
		// }

		if (!isGuest) {
			ActionList.push(
				<div key="history">
					<Tooltip title={t("common:document.history")} arrow>
						<span>
							<IconButton
								sx={{ mr: 1 }}
								color="primary"
								className="btn-action"
								onClick={(e) => {
									if (operationLogFlg) {
										this.setState({ operationLogFlg: false });
									} else {
										this.setState({ operationLogFlg: true });
									}
								}}>
								<HistoryIcon fontSize="inherit" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			);
			// ActionList.push(
			// 	<div key="access-logs">
			// 		<Tooltip title={t("common:document.history")} arrow>
			// 			<span>
			// 				<IconButton
			// 					sx={{ mr: 1 }}
			// 					color="primary"
			// 					className="btn-action"
			// 					onClick={(e) => {
			// 						if (accessLogFlg) {
			// 							this.setState({ accessLogFlg: false });
			// 						} else {
			// 							this.setState({ accessLogFlg: true });
			// 						}
			// 					}}>
			// 					<AccessTimeIcon fontSize="inherit" />
			// 				</IconButton>
			// 			</span>
			// 		</Tooltip>
			// 	</div>
			// );
		} else {
			ActionList.push(
				<Box key="FAQ" sx={{ mr: 2 }}>
					<Tooltip title="FAQページへ" arrow>
						<IconButton
							aria-label="FAQ"
							href="https://paperlogic.co.jp/faq-v2/"
							target="_blank"
						>
							<HelpOutlineOutlinedIcon />
						</IconButton>
					</Tooltip>
				</Box>
			);
		}

		return ActionList;
	}

	UpdateModalDialog = (options) => {
		// let { t } = this.props;
		// console.log(options);
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		if (options.title) {
			modalTitle = options.title;
		}
		if (options.body) {
			ModalBody = options.body;
		}
		if (options.action) {
			ModalAction = options.action;
		}
		if (options.props) {
			modalProps = options.props;
		}
		// console.log(modalProps)
		this.setState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	UpdateDocInfo = () => {
		let { docInfo } = this.state;
		docInfo.signed = 1;
		docInfo.signer_info.signed = 1;
		let allSigned = [];
		for (let i in docInfo.signers) {
			let user = docInfo.signers[i];
			if (user.id === docInfo.permission.user_id) {
				user.signed = 1;
			}
			else if (user.id === docInfo.signer_info.user_id) {
				user.signed = 1;
				user.signing_status = "SIGN";
			}
			if (user.signed === 1) {
				allSigned.push(true);
			} else {
				allSigned.push(false);
			}
		}

		if (!allSigned.includes(false)) {
			if (docInfo.function_code === "sender") {
				docInfo.doc_status = 8;
			} else if (docInfo.function_code === "circle") {
				docInfo.doc_status = 12;
			} else if (docInfo.function_code === "verification") {
				if (docInfo.doc_status !== 14) {
					docInfo.doc_status = 10;
				}
			} else {
				docInfo.doc_status = 2;
			}
		}
		this.setState({ docInfo });
	}

	GuestSignConfirmHandler = (ev) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.signing.title",
			body: Loading,
			action: [],
			props: {
				"show": true,
				"centered": true,
			},
		});

		let { token } = this.state;

		// console.log("docInfo", docInfo);
		Service.signGuestDocument(token).then(resp => {
			// console.log(resp);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:" + resp.message)}</div>
					);
				},
				action: [],
			});

			this.UpdateDocInfo();
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t(`common:${errMessage}`)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.GuestSignConfirmHandler}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
	}

	SignConfirmHandler = (ev) => {
		let { t } = this.props;
		console.log(';runnnn')
		this.UpdateModalDialog({
			title: "common:documents.signing.title",
			body: Loading,
			action: [],
			props: {
				"show": true,
				"centered": true,
			},
		});

		let { docInfo } = this.state;
		// console.log("docInfo", docInfo);
		Service.signDocument(docInfo.id).then(resp => {
			// console.log(resp);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:documents.signing.received")}</div>
					);
				},
				action: [],
			});
			this.UpdateDocInfo();
			switch (docInfo.function_type_id) {
				case 1:
					if (this.context.userInfoContent.signed_document_signer > 0) {
						this.context.setUserInfoContent({
							...this.context.userInfoContent,
							signed_document_signer: this.context.userInfoContent.signed_document_signer - 1
						});
					}
					break;
				case 2:
					if (this.context.userInfoContent.signed_document_sender > 0) {
						this.context.setUserInfoContent({
							...this.context.userInfoContent,
							signed_document_sender: this.context.userInfoContent.signed_document_sender - 1
						});
					}
					break;
				case 3:
					if (this.context.userInfoContent.signed_document_circle > 0) {
						this.context.setUserInfoContent({
							...this.context.userInfoContent,
							signed_document_circle: this.context.userInfoContent.signed_document_circle - 1
						});
					}
					break;
				case 4:
					if (this.context.userInfoContent.signed_document_verification > 0) {
						this.context.setUserInfoContent({
							...this.context.userInfoContent,
							signed_document_verification: this.context.userInfoContent.signed_document_verification - 1
						});
					}
					break;
				default:
					break;
			}
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t(`common:${errMessage}`)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
	}
	onSignHandlers = (ev) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.signing.titleconfirm",
			body: Loading,
			action: [],
			props: {
				"show": true,
				"centered": true,
			},
		});

		let { docInfo } = this.state;
		// console.log("docInfo", docInfo);
		Service.saveDocumentlog(docInfo.id).then(resp => {
			// console.log(resp);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:documents.signing.confirmed")}</div>
					);
				},
				action: [],
			});
			this.UpdateDocInfo();
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t(`common:${errMessage}`)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.onSignHandlers}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
	}
	GuestAgreeButton = () => {
		let { t } = this.props;
		let { guestAgreement } = this.state;
		let btnConfirmProps = {
			disabled: true,
		}
		if (guestAgreement) {
			btnConfirmProps.disabled = false;
			btnConfirmProps.onClick = this.GuestSignConfirmHandler;
		}
		// console.log(guestAgreement);
		return (
			<Button sx={{ ml: 1 }} variant="contained" {...btnConfirmProps}  >
				<span>{t("common:general.confirm")}</span>
			</Button>
		);
	}

	onSignHandler = (ev) => {
		let { t } = this.props;
		let { token } = this.state;
		let { GuestAgreeButton } = this;
		// console.log(token);
		if (token) {
			this.UpdateModalDialog({
				title: "common:documents.sign.guest-confirm",
				body: () => {

					return (
						<Container fluid="md" className={"container-fluid"} >
							<Form.Row>
								<Form.Group xl={12} md={12} as={Col} >
									<div>{t("common:documents.sign.guest-agreement-info")}</div>
								</Form.Group>
							</Form.Row >
							<Form.Row>
								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox" label={t("common:documents.sign.yes-agree")} onChange={(ev) => {
										// console.log(ev.target.checked);
										this.setState({ guestAgreement: ev.target.checked });
										this.UpdateModalDialog({
											action: [
												<GuestAgreeButton key="guest-ok" />,
											],
										});
									}} />
								</Form.Group>
							</Form.Row >
						</Container>
					);
				},
				action: [
					<GuestAgreeButton key="guest-ok" />,
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		} else {
			this.UpdateModalDialog({
				title: "common:documents.signing.confirm",
				body: () => {
					return (
						<div>{t("common:documents.signing.confirm-question")}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.getCurrentUserAttributes.bind(this, 'SignConfirmHandler')}>
						<span>{t("common:general.confirm")}</span>
					</Button>),
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		}
	}

	VerifyConfirmProcess = () => {
		let { t } = this.props;
		let { docInfo, modal } = this.state;
		// console.log("docInfo", docInfo);
		Service.verifyDocument(docInfo.id).then(resp => {
			// console.log(resp);
			modal.body = t("common:" + resp.message);
			modal.action = [];
			this.setState({ modal }, this.UpdateDocInfo);
		}).catch(err => {
			//console.log(err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			modal.body = t(`common:${errMessage}`);
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.VerifyConfirmHandler}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	VerifyConfirmHandler = () => {
		let { modal } = this.state;
		modal.title = "common:documents.verify.title";
		modal.body = Loading;
		modal.action = [];

		this.setState({ modal }, this.VerifyConfirmProcess);
	}

	onVerifyHandler = (ev) => {
		let { t } = this.props;
		let { modal } = this.state;
		modal.title = "common:documents.verify.confirm";
		modal.body = t("common:message.documents.verify.confirm-question");
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.VerifyConfirmHandler}>
				<span>{t("common:general.confirm")}</span>
			</Button>
		)];
		modal.props = {
			show: true,
			centered: true,
		}

		this.setState({ modal });
	}

	UploadCertificateError = (err, data) => {
		let { t } = this.props;
		let { ModalAction, ModalBody } = this.state;

		let errMessage = err.response.data.message;
		if (err.response.data.hasOwnProperty("error")) {
			errMessage = err.response.data.error;
		}

		ModalBody = () => {
			return (
				<div>{t(`common:${errMessage}`)}</div>
			);
		};

		/* */
		ModalAction = [];
		ModalAction.push(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				this.setState({ ModalBody: Loading, ModalAction: [] }, () => {
					// this.CertificateUploadSubmit(data);
					this.onCertificateUploadHandler();
				});
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		);

		/* */
		this.setState({ ModalAction, ModalBody });
	}

	CertificateUploadSubmit = (data) => {
		let { token } = this.state;
		if (token) {
			Service.guestUpdateCertificate(token, data).then(resp => {
				window.location.reload();
			}).catch(err => {
				this.UploadCertificateError(err, data);
			});
		} else {
			Service.updateCertificate(data).then(resp => {
				window.location.reload();
			}).catch(err => {
				this.UploadCertificateError(err, data);
			});
		}
	}

	PrepareCertificateUploadParams = (ev) => {

		let { docInfo, certUpload } = this.state;
		// console.log(certUpload);

		/* File required */
		if (certUpload.file == null) {
			return false;
		}

		let reader = new FileReader();
		reader.readAsDataURL(certUpload.file);
		reader.onload = (ev) => {
			// console.log(ev);
			// console.log(reader);
			let data = {
				certificate_type: docInfo.signer_info.certificate_type_id,
				certificate_file: ev.target.result.replace(/^data:.+;base64,/, ''),
				certificate_extension: certUpload.file.name.split('.').pop().toLowerCase(),
				certificate_password: certUpload.password,
			};
			// console.log(data);// /* */
			this.setState({ ModalBody: Loading, ModalAction: [] }, () => {
				this.CertificateUploadSubmit(data);
			});
		};
		reader.onerror = (error) => {
			//console.log(error);
		};

	}

	getCertificateApplicationInfo = () => {
		let { docInfo, token } = this.state;

		Service.getGuestAppCertDetail(token, {
			cert_type: docInfo.signer_info.certificate_type_id
		}).then(resp => {
			let { appCert } = this.state;
			appCert = resp;
			// console.log(resp)
			this.setState({ appCert }, this.setStateFields);
		}).catch(err => {
			//console.log(err.response);
		});

	}

	onCertificateApplyHandler = (ev) => {
		this.setState({ certApply: true, appCert: null }, this.getCertificateApplicationInfo);
	}

	onCertificateUploadHandler = (ev) => {
		let { t } = this.props;
		// let {token} = this.state;
		// console.log(token);
		this.setState({
			certUpload: {
				file: null,
				password: null,
			}
		}, () => {
			this.UpdateModalDialog({
				title: "common:settings.profile.certificate.upload-title",
				body: () => {
					let { certUpload } = this.state;
					let fileNameLabel = t("common:settings.profile.certificate.file-select");
					if (certUpload && certUpload.file && certUpload.file.name) {
						fileNameLabel = certUpload.file.name;
					}

					return (
						<Container fluid="md" className={"container-fluid"} >
							<Form id="form_certificate_upload" className="full-form" >
								<Form.Row>
									<Form.Group as={Col} md={12} controlId="cert_file_input" >
										<Form.Label>{t("common:settings.profile.certificate.file-select")}</Form.Label>
										<Form.File
											id="cert_file_input"
											name="cert_file_input"
											label={fileNameLabel}
											custom
											onChange={(ev) => {
												// console.log(ev);
												certUpload.file = ev.target.files[0];
												this.setState({ certUpload });
											}}
											accept=".pfx,.p12"
										/>
										{/* <Form.Control type="file" name="cert_file_input" /> */}
									</Form.Group>
									<Form.Group as={Col} md={12} controlId="cert_password" >
										<Form.Label>{t("common:settings.profile.certificate.password")}</Form.Label>
										<Form.Control
											type="password"
											name="password"
											placeholder={t("common:settings.profile.certificate.password")}
											value={this.state.certUpload.password || ""}
											onChange={(ev) => {
												// console.log(ev);
												// let { certUpload } = this.state;
												certUpload.password = ev.target.value;
												this.setState({ certUpload });
											}}
										/>
									</Form.Group>
								</Form.Row >
							</Form>
						</Container>
					);
				},
				action: [
					(
						<Button key="ok" size="large" variant="contained" onClick={this.PrepareCertificateUploadParams}>
							<span>{t("common:general.confirm")}</span>
						</Button>
					),
				],
				props: {
					"show": true,
					"centered": true,
				},
			});
		});
	}

	onDownloadRequestHandler = () => {
		let { docInfo, token } = this.state;
		// console.log(token);
		if (token) {
			Service.guestDownloadAllAsZip(token).then(resp => {
				// console.log(resp);
				// let filename = new Date();
				let aLink = document.createElement("a");
				// aLink.download = "2021-07-25.zip";
				aLink.href = resp.url;
				aLink.click();

			}).catch(err => {
				//console.log(err.response);
			});
		} else {
			Service.downloadAllAsZip(docInfo.id).then(resp => {
				// console.log(resp);
				// let filename = new Date();
				let aLink = document.createElement("a");
				// aLink.download = filename.toISOString();
				aLink.href = resp.url;
				aLink.click();

			}).catch(err => {
				//console.log(err.response);
			});
		}
	}

	onPreviewPdfHandler = async (pdfHash) => {
		this.setState({ previewPdf: pdfHash });
	}

	onCertificateApplyFormClose = (cb) => {
		// modal.onClose
		this.setState({ certApply: false }, cb);
		// console.log(this.setState.certApply);
	}

	ForwardUserList = (props) => {
		let { t } = this.props;
		let { docInfo, user_quick_filter, forwardUser, searchAvailableForwardUsers } = this.state;
		if (!searchAvailableForwardUsers) {
			return <Loading />;
		}

		let isCompanySeal = false;
		let AvailableUserList = [];
		if (parseInt(docInfo.certificate_type) === 2) {
			isCompanySeal = true;
		}

		for (let user of searchAvailableForwardUsers) {
			if ([1, 3].includes(parseInt(docInfo.signer_info.certificate_type_id))) {
				if (!docInfo.signer_info) {
					continue;
				}

				if (!user.available_certs.includes(parseInt(docInfo.signer_info.certificate_type_id))) {
					console.log(user.available_certs);
					continue;
				}
			}

			if (user_quick_filter) {
				let regEscape = user_quick_filter.replace(/[-[\]{ }()*+?.,\\^$|]/g, "\\$&");
				let regex = new RegExp(regEscape, "i");
				let searchCondition = [];
				searchCondition.push(regex.test(user.user_id));
				searchCondition.push(regex.test(user.full_name));
				if (!isCompanySeal) {
					searchCondition.push(regex.test(user.email));
					searchCondition.push(regex.test(user.mobile_number));
				}

				if (!searchCondition.includes(true)) {
					continue;
				}
			}

			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let selected = "";
			if (forwardUser && forwardUser.user_id === user.user_id) {
				selected = " selected";
			}

			AvailableUserList.push(
				<ListGroup.Item key={user.user_id} as="li" className={`user-info user-${user.user_type}${selected}`} onClick={ev => {
					console.log(ev.target);
					this.setState({ forwardUser: user }, this.checkAvailableForwardUser);
				}}>
					<div className="user-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div className="user-info-detail" style={{
						cursor: "pointer",
						padding: "0 4px",
					}}>
						<div className="top-info">
							<span>{`${user.user_id}-${user.full_name}`}</span>
						</div>
						<div className="bottom-info">
							<span>{isCompanySeal ? `${t("common:general.total-members")} ${user.stamp_member_count}` : user.email || ""}</span>
						</div>
					</div>
				</ListGroup.Item>
			);
		}

		return (
			<ListGroup as="ul" className="user-selection-box">
				{AvailableUserList}
			</ListGroup>
		);
	}

	ForwardUserSelected = () => {
		let { t } = this.props;
		let { docInfo, forwardUser } = this.state;
		let LineInfo = [];
		let iconProps = {};

		if (!forwardUser) {
			return null;
		}

		if (forwardUser.user_type === 2) {
			iconProps.icon = PeopleIcon;
		} else if (forwardUser.profile_image) {
			iconProps.avatar = forwardUser.profile_image;
		}

		LineInfo.push(
			<div className='forwardUser-profile'>
				<div className='user-avatar'>
					<ProfileAvatar {...iconProps} />
				</div>
				<div className='user-profile'>
					<div className='top-info'>
						<span>{forwardUser.user_id}</span>-<span>{forwardUser.full_name}</span>
					</div>
					<div className='bottom-info'>
						<span>{forwardUser.email}</span>
					</div>
				</div>
			</div>
		);



		// let loopKeys = {
		// 	"id": forwardUser.user_id,
		// 	"name": forwardUser.full_name,
		// 	"email": forwardUser.email,
		// 	"mobile": forwardUser.mobile_number,
		// };
		// for (let i in loopKeys) {
		// 	LineInfo.push(
		// 		<div key={i} className="forwardUser-profile">
		// 			<span>{loopKeys[i]}</span>
		// 		</div>
		// 	);
		// }

		if (parseInt(docInfo.certificate_type) === 2) {
			LineInfo.push(
				<Form.Row key="group-member-count">
					<Form.Group xs={12} md={4} as={Col} style={{ height: "100%" }}>
						<span>{t('common:general.total-members')}</span>
					</Form.Group>
					<Form.Group xs={12} md={8} as={Col} style={{ height: "100%" }}>
						<span>{forwardUser.stamp_member_count}</span>
					</Form.Group>
				</Form.Row>
			);
		}

		return (
			<div className="forward-user-card" >
				{LineInfo}
			</div>
		);
	}

	ForwardFormFilter = () => {
		let { t } = this.props;
		let { showUserFilter } = this.state;

		return (
			<div className="body-action-container">
				<div className="body-action-bar" style={showUserFilter ? { height: "60px" } : null}>
					<Form.Control
						name="user_quick_filter"
						placeholder={t("common:documents.user-quick-filter")}
						value={this.state.user_quick_filter || ""}
						onChange={(ev) => {
							console.log(ev.target.value);
							this.setState({ user_quick_filter: ev.target.value });
						}}
					/>
				</div>
			</div>
		);
	}

	ForwardFormBody = () => {
		let { t } = this.props;
		let { isGuest, forwardUser, userSearching, formData, docInfo } = this.state;
		let { FieldControl, ForwardUserList, ForwardUserSelected, ForwardFormFilter } = this;

		if (isGuest || docInfo.master_doc_info) {
			let UserInfoBlock = [];

			if (forwardUser) {
				UserInfoBlock.push(<hr key="seperator" style={{ margin: 0 }} />);
				if (forwardUser.hasOwnProperty("user_id")) {
					UserInfoBlock.push(
						<Form.Row key="user-info-card">

							<Form.Group md={12} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
								<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.user-info")}</Form.Label>
								<div className="user-info-container forward-user-info">
									<ForwardUserSelected />
								</div>
							</Form.Group>

						</Form.Row>
					);
				} else {
					UserInfoBlock.push(
						<Form.Row key="user-info-card">

							<Form.Group as={Col} controlId="all_user_list" className="forward-user-wrapper forward-user-list" style={{
								height: "100%",
								position: "relative",
							}}>
								<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.user-info")}</Form.Label>
								<div className="user-info-container forward-user-info">

									<Form.Row key="user-info-row-1">
										<FieldControl name="family_name" xs={12} md={6} required validate="true" />
										<FieldControl name="first_name" xs={12} md={6} required validate="true" />
									</Form.Row>

									<Form.Row key="user-info-row-2">
										<FieldControl name="company_name" xs={12} md={12} />
										<FieldControl name="mobile" xs={12} md={6} />
									</Form.Row>

								</div>

							</Form.Group>

						</Form.Row>
					);
				}
			} else if (userSearching) {
				UserInfoBlock.push(<hr key="seperator" style={{ margin: 0 }} />);
				UserInfoBlock.push(
					<Form.Row key="user-searching" style={{ height: "300px" }}>
						<Loading />
					</Form.Row>
				);
			}

			return (
				<div className="form-body flex-column">
					<Form.Control type="hidden" name="action" defaultValue={formData.action} />

					<Form.Row>

						<FieldControl name="email" type="email" required validate="true" change={(ev) => {
							let { forwardUser, formData } = this.state;
							if (forwardUser && forwardUser.email !== formData.email) {
								forwardUser = null;
							}
							this.setState({ forwardUser }, this.checkAvailableForwardUser);
						}} />

						<Form.Group md={2} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
							<Form.Label>&nbsp;</Form.Label>
							<Button
								size="large"
								variant="contained"
								type="submit"
								name="search"
								onClick={this.onButtonSubmit}
								style={{
									marginTop: "6px",
									height: "46px",
								}}
							>
								<span className="btn-label">{t('common:general.search')}</span>
							</Button>
						</Form.Group>

					</Form.Row>

					{UserInfoBlock}

				</div >
			);
		} else {
			return (
				<div className="form-body flex-column full-height-modal-body">
					<Form.Control type="hidden" name="action" defaultValue={formData.action} />

					<Form.Row className="flex-full">

						<Form.Group xs={6} md={6} as={Col} controlId="user_list" className="forward-user-wrapper" style={{ height: "100%" }}>
							<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.forward.user-info")}</Form.Label>
							<div className="user-info-container forward-user-info">
								<ForwardUserSelected />
							</div>
						</Form.Group>

						<Form.Group xs={6} md={6} as={Col} controlId="all_user_list" className="user-selection-container forward-user-list" style={{
							height: "100%",
							position: "relative",
						}}>
							<Form.Label style={{ minHeight: "30px" }}>{t("common:documents.host-setting.available-user-list")}</Form.Label>

							<ForwardFormFilter />

							<InputGroup className="form-control">
								<div className="search-input-container">
									<TextField
										className="search-input-field"
										key="searchAvailableForwardUsers"
										placeholder={t('common:documents.general.text-input-placeholder')}
										onChange={(e) => this.SearchInputChange('forwardUsers', e)}
										defaultValue={this.state.searchAvailableForwardUsersText}
										sx={{
											"& fieldset": { border: 'none' },
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment>
													<IconButton>
														<SearchIcon />
													</IconButton>
												</InputAdornment>
											),
											style: {
												height: 40,
												paddingLeft: 5,
												fontSize: 15,
											},
										}}
									/>
								</div>
								<ForwardUserList />
							</InputGroup >
						</Form.Group>

					</Form.Row>

				</div>
			);
		}
	}

	getUserInfoByEmail = (ev, fnCallback) => {
		let { token, formData, forwardUser } = this.state;

		// formData.email = null;
		formData.family_name = null;
		formData.first_name = null;
		formData.company_name = null;
		formData.mobile = null;
		this.setState({ userSearching: true, forwardUser: null, formData }, async () => {
			let { docInfo } = this.state;
			if (docInfo.master_doc_info) {
				await Service.GetUserGuestInfo({
					"email": formData.email,
					"tenant_id": docInfo.master_doc_info.tenant_id,
				}).then(resp => {
					console.log(resp);
					forwardUser = resp;
					forwardUser.email = formData.email;
				}).catch(err => {
					console.log(err);
					forwardUser = { email: formData.email };
				});
			} else {
				await Service.GetGuestUserInfo(token, { email: formData.email }).then(resp => {
					console.log(resp);
					forwardUser = resp;
					forwardUser.email = formData.email;
				}).catch(err => {
					console.log(err);
					forwardUser = { email: formData.email };
				});
			}

			this.setState({ forwardUser, userSearching: false }, fnCallback);
		});
	}

	checkAvailableForwardUser = (ev) => {
		let { t } = this.props;
		let { modal, forwardUser } = this.state;

		modal.action = [];
		if (forwardUser) {
			modal.action = [(
				<Button key="btn-submit"
					sx={{ ml: 1 }}
					variant="contained"
					type="submit"
					name="submit"
					onClick={this.onButtonSubmit}
				>
					<span>{t("common:general.submit")}</span>
				</Button>
			)];
		}

		this.setState({ modal });
	}

	onButtonSubmit = (ev) => {
		document.getElementById("forward_user_form").submited = ev.target.closest("[type=submit]");
	}

	forwardSignSubmit = (ev) => {
		console.log(ev.target.submited.name);
		if (ev.target.submited.name === "search") {
			this.getUserInfoByEmail(ev, this.checkAvailableForwardUser);
		} else {
			let { t } = this.props;
			let { docInfo, modal, forwardUser, isGuest, formData, token } = this.state;

			if (!forwardUser) {
				return null;
			}

			modal.props.size = "sm";
			modal.props.centered = true;
			modal.body = "common:message.documents.forward.confirm";
			modal.action = [(
				<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
					modal.close = false;
					modal.body = Loading;
					modal.action = [];

					this.setState({ modal }, () => {
						if (isGuest) {
							if (!forwardUser.hasOwnProperty("user_id")) {
								forwardUser.email = formData.email;
								forwardUser.family_name = formData.family_name;
								forwardUser.first_name = formData.first_name;
								forwardUser.company_name = formData.company_name;
								forwardUser.mobile = formData.mobile;
							}
							Service.GuestForwardSign(token, forwardUser).then(resp => {
								this.ForwardSignSuccess(resp);
							}).catch(err => {
								this.ForwardSignFailure(err);
							});
						} else {
							Service.DocumentForwardSign({
								"document_id": docInfo.document_id,
								"user_info": forwardUser,
							}).then(resp => {
								this.ForwardSignSuccess(resp);
							}).catch(err => {
								this.ForwardSignFailure(err);
							});
						}
					});
				}}>
					<span>{t("common:general.confirm")}</span>
				</Button>
			)];

			this.setState({ modal });

		}
	}

	ForwardSignSuccess = (resp) => {
		let { t } = this.props;
		let { docInfo, modal, UserInfo } = this.state;
		console.log(resp);
		modal.close = true;
		modal.props.size = "lg";
		modal.props.centered = false;
		modal.body = "common:message.documents.forward.success";
		modal.action = [
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
				modal.props.show = false;
				this.setState({ modal });
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		];

		docInfo.operation_log = resp.logs;
		docInfo.signers = resp.signers;
		docInfo.signer_info.signed = 1;
		docInfo.signer_info.signing_status = "FORWARD";

		if (UserInfo && parseInt(docInfo.certificate_type) === 2) {
			for (let signer of docInfo.signers) {
				if (signer.new_signer_flag && signer.cs_signning === UserInfo.user_id && signer.signed === 0) {
					docInfo.signer_info = signer;
					break;
				}
			}
		}
		// console.log(docInfo);

		this.setState({ docInfo, modal, forwardUser: null });
	}

	ForwardSignFailure = (err) => {
		let { t } = this.props;
		let { modal } = this.state;
		console.log(err.response);
		let errMessage = err.response.data.message;
		if (err.response.data.hasOwnProperty("error")) {
			errMessage = err.response.data.error;
		}

		modal.close = true;
		modal.props.size = "lg";
		modal.props.centered = false;
		modal.body = t(`common:${errMessage}`);
		modal.action = [
			<Button key="try-again"
				sx={{ ml: 1 }}
				variant="contained"
				type="submit"
				onClick={this.onButtonSubmit}
			>
				<span>{t("common:general.try-again")}</span>
			</Button>
		];

		this.setState({ modal });
	}

	ButtonForwardHandler = (ev) => {
		let { t } = this.props;
		let { modal, isGuest, forwardUser } = this.state;

		modal.props.show = true;
		modal.props.size = isGuest ? "lg" : "xl";
		modal.props.className = "layout modal-responsive";
		modal.title = "common:documents.sign-forward.header";
		modal.body = this.ForwardFormBody;
		// modal.bodyaction = this.ForwardFormFilter;
		modal.form = {
			id: "forward_user_form",
			noValidate: true,
			onSubmit: (ev) => {
				// console.log(ev.target);
				this.formSubmitHandler(ev, null, this.forwardSignSubmit);
			},
		};

		if (forwardUser) {
			modal.action = [(
				<Button key="btn-submit"
					sx={{ ml: 1 }}
					variant="contained"
					type="submit"
					name="submit"
					onClick={this.onButtonSubmit}
				>
					<span>{t("common:general.submit")}</span>
				</Button>
			)];
		}
		this.setState({ modal });
	}

	SignButton = (props) => {
		let { t } = this.props;
		let { docInfo, token, isGuest } = this.state;

		if (token && docInfo.linkage_doc_id) {
			return null;
		}

		if ([1, 6, 7, 9, 11, 14].includes(docInfo.doc_status)) {
			if (docInfo.sign_permission === 1) {
				// console.log(docInfo.signer_info.signing_status);
				let Buttons = [];
				if (docInfo.signer_info && docInfo.signer_info.signing_status !== 'WAIT' && docInfo.signer_info.signed === 0) {
					if (docInfo.function_code === "verification") {
						Buttons.push(
							<Button key="btn-verify" variant="contained" size="large" onClick={this.onVerifyHandler} {...props}>
								<span className="btn-label">{t('common:documents.general.verify')}</span>
							</Button>
						);
					} else if (docInfo.signer_info.cert_exists) {
						Buttons.push(
							<Button key="btn-sign" variant="contained" size="large" onClick={this.onSignHandler} {...props}>
								<span className="btn-label">{t('common:documents.general.sign')}</span>
							</Button>
						);
					} else {
						Buttons.push(
							<Button key="btn-upload-cert" variant="outlined" size="large" onClick={this.onCertificateUploadHandler} {...props}>
								<span className="btn-label">{t('common:documents.general.certificate-upload')}</span>
							</Button>
						);

						if (token) {
							Buttons.push(
								<Button key="btn-request-cert" variant="outlined" size="large" onClick={this.onCertificateApplyHandler} {...props}>
									<span className="btn-label">{t('common:documents.general.certificate-apply')}</span>
								</Button>
							);
						}
					}

					if ([1, 3].includes(docInfo.function_type_id) || ([2, 4].includes(docInfo.function_type_id) && !isGuest)) {
						if (window.innerWidth > 760) {
							Buttons.push(
								<Button key="btn-sign-forward" color="info" variant="contained" size="large" onClick={this.ButtonForwardHandler} {...props}>
									<span className="btn-label">{t('common:documents.general.forward')}</span>
								</Button>
							);
						}
					}
					if ([14].includes(docInfo.doc_status)) {
						Buttons = [];
					}


				}

				if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4 && Object.keys(docInfo.operation_log).length === 0 && !isGuest && docInfo.signer_info.signed === 0) {
					Buttons.push(
						<Button key="btn-save-error-log" variant="contained" size="large" onClick={this.onSignHandlers} {...props}>
							<span className="btn-label">{t('common:documents.general.signprotectpdf')}</span>
						</Button>
					);
				}
				return Buttons;
			}

		}

		return null;
	}

	SignerFieldInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;
		let expiry_required = false;
		if (formData.dateofnoti || formData.dateofnoti === 0) {
			expiry_required = true;
		}
		if (parseInt(formData.dateofnoti) === 1) {
			expiry_required = false;
		}
		if (docInfo.function_code !== "signer") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="counter_party_name" key_label="contract-counter-party-name" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);
		RowItems.push(<InfoValue key="2" key_value="contract_date" key_label="contract-date" attributes={{ type: "date" }} />);
		RowItems.push(<InfoValue key="3" key_value="expiry_date" key_label="expiry-date" attributes={{ type: "date" }} required={expiry_required} />);
		RowItems.push(<InfoValue key="4" key_value="received_date" key_label="sign-finish-date" attributes={{ type: "date" }} />);
		RowItems.push(<InfoValue key="5" key_value="effective_date" key_label="effective-date" attributes={{ type: "date" }} />);

		return RowItems;
	}

	SenderFieldInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "sender") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="counter_party_name" key_label="supplier-name" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);
		RowItems.push(<InfoValue key="2" key_value="received_date" key_label="transaction-date" attributes={{ type: "date" }} />);
		// RowItems.push(<InfoValue key="contract_date" key_value="contract_date" key_label="contract-date" attributes={{ type: "date" }} />);
		// RowItems.push(<InfoValue key="expiry_date" key_value="expiry_date" key_label="expiry-date" attributes={{ type: "date" }} />);

		return RowItems;
	}

	CircleFieldInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "circle") {
			return null;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="received_date" key_label="document-create-date" attributes={{ type: "date" }} required />);
		RowItems.push(<InfoValue key="2" key_value="sign_finish_date" key_label="circle-finish-date" attributes={{ type: "date" }} />);
		RowItems.push(<InfoValue key="3" key_value="content" key_label="content" attributes={{ style: { whiteSpace: "normal", wordBreak: "break-all" } }} />);

		return RowItems;
	}

	VerificationInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "verification") {
			return null;
		}

		let required = true;
		if ([1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		let RowItems = [];
		RowItems.push(<InfoValue key="1" key_value="received_date" key_label="deal-date" attributes={{ type: "date" }} required={required} />);
		RowItems.push(<InfoValue key="2" key_value="counter_party_name" key_label="counter-party-name" required={required} />);

		return RowItems;
	}

	SharedFolderInfo = () => {
		let { InfoValue } = this;
		return <InfoValue key="1" key_value="filebox_path" key_label="filebox-path" attributes={{ type: "text", disabled: true }} required={false} />;
	}

	AccesesLogInfo = () => {
		const { AccessLogTree } = this
		return (
			<Form.Row className="d-none d-sm-block">
				<div>
					<AccessLogTree />
				</div>
			</Form.Row >
		)
	}

	CommonItemInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue, InfoValueField } = this;

		let required = true;
		if (docInfo.function_code === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		let RowItems = [];
		let CurrencyItems = { field: "currencies", value: "code", label: "label" };
		RowItems.push(
			<InfoValue key="1" key_value="amount" key_label="amount" key_join=" " alignment=" align-right">
				<InfoValueField key_value="amount" key_label="amount" xl={6} md={8} xs={6} attributes={{ type: "number" }} required={required} />
				<InfoValueField key_value="currency" key_label="currency" xl={2} md={4} xs={3} attributes={{ as: "select" }} items={CurrencyItems} required={required} />
			</InfoValue>
		);
		RowItems.push(<InfoValue key="3" key_value="product_name" key_label="product" />);
		RowItems.push(<InfoValue key="4" key_value="reference" key_label="reference" />);

		return RowItems;
	}

	DocumentStatusInfo = () => {
		let { t } = this.props;
		let { docInfo } = this.state;

		let StatusIcon = faQuestionCircle;
		let statusInfo = docInfo.status_list[docInfo.doc_status];
		if (docInfo.doc_status === 0) {
			StatusIcon = faExclamationCircle;
		} else if ([1, 7, 9, 11].includes(docInfo.doc_status)) {
			StatusIcon = faInfoCircle;
		} else if ([2, 8, 10, 12].includes(docInfo.doc_status)) {
			StatusIcon = faCheckCircle;
		} else if (docInfo.doc_status === 3) {
			StatusIcon = faStopCircle;
		} else if (docInfo.doc_status === 4) {
			StatusIcon = faMinusCircle;
		} else if (docInfo.doc_status === 5) {
			StatusIcon = faTimesCircle;
		}
		if (docInfo.doc_status === 14 && docInfo.function_type_id === 4) {
			// statusInfo.label = "document.status.cannot-timestamp";
			statusInfo.label = "document.status.protected-PDF-supported";
			statusInfo.code = "confirmed";
		}

		if (docInfo.remarks === "protected" && [1, 2, 3].includes(docInfo.function_type_id)) {
			statusInfo.code = "error";
		}

		return (
			<Form.Row>
				<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
					<span className="document-info-key">{t("common:documents.document-info.document-status")}</span>
				</Form.Group>
				<Form.Group xl={8} md={12} xs={9} as={Col} className="document-status-label">
					<div className={"form-control document-info-value " + statusInfo.code}>
						<FontAwesomeIcon icon={StatusIcon} className="icon" />
						<span className="label">{t(`common:${statusInfo.label}`)}</span>
					</div>
				</Form.Group>
			</Form.Row >
		)
	}

	SignLevelInfo = () => {
		let { t } = this.props;
		let { docInfo, UpdateInfo } = this.state;

		if (["signer", "circle"].includes(docInfo.function_code)) {
			let style = null;
			if (UpdateInfo) {
				style = { backgroundColor: "#cfd4da" };
			}
			return (
				<Form.Row>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{t("common:documents.document-info.sign-level")}</span>
					</Form.Group>
					<Form.Group xl={8} md={12} xs={9} as={Col}>
						<span className="form-control document-info-value" style={style}>{t(`common:documents.document-info.sign-level-${docInfo.sign_level}`)}</span>
					</Form.Group>
				</Form.Row >
			);
		} else {
			return null;
		}
	}

	DocumentTypeInfo = () => {
		let { InfoValue } = this;

		let items = { field: "doc_types", value: "id", label: "label" };
		return (
			<InfoValue key_value="document_type_id" key_label="document-type" attributes={{ as: "select" }} items={items} required />
		)
	}

	DocdateOfNotice = () => {
		let { t, name } = this.props;
		let { InfoValue } = this;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData, docInfo, UpdateInfo } = this.state;
		let Notival = docInfo.dateofnoti;
		let readOnly = true;
		if (docInfo.function_type_id !== 1) {
			return null;
		}
		if (UpdateInfo) {
			readOnly = false;
		}

		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i === 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i === 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			let radioProps = {
				"name": name,
				"onChange": (ev) => {
					// let {FieldControl} = this.objMain;
					if (formData.dateofnoti === 0) {
						formData.select_datenoti = "";
					}
					formData.dateofnoti = parseInt(ev.target.value);
					this.setFormData(formData);

				},
			};
			ItemElements.push(
				<FormControlLabel key={i} value={i} label={label} disabled={readOnly} control={<Radio {...radioProps} />} />
			);
		}

		if ((parseInt(formData.dateofnoti) !== parseInt(docInfo.dateofnoti)) && formData.dateofnoti !== undefined) {
			Notival = formData.dateofnoti;
		}
		return (
			<InfoValue key_value="dateofnoti" key_label="select_datenoti">
				<RadioGroup row name="dateofnoti" xl={8} md={12} xs={8} as={Col} key_value="dateofnoti" key_label="select_datenoti" aria-labelledby="demo-row-radio-buttons-group-label" value={Notival}>
					{ItemElements}
				</RadioGroup>
			</InfoValue>

		);
	}



	DocumentDateofNoti = () => {
		let { t } = this.props;
		let { InfoValue, InfoValueField } = this;
		let { formData, docInfo } = this.state;
		let required = true;

		if (docInfo.function_type_id !== 1) {
			return null;
		}

		if (!formData.expiry_date) {
			required = false;
		}
		let items = ['', 60, 90, 120];
		return (
			<InfoValue key_value="date-noti-" key_label="date-noti-" >
				<InfoValueField key_value="dateofnoti" key_label="date-noti-" attributes={{ as: "select" }} required={required} >
					{items.map(item => <option key={`key-${item}`} value={item}>{t(`common:documents.document-info.date-noti-${item}`)}</option>)}
				</InfoValueField>
			</InfoValue>


		)
	}

	DocumentSelectDatenoti = () => {
		// let { t } = this.props;
		let { InfoValue, InfoValueField, DateNotiRequired } = this;
		let { formData, docInfo, UpdateInfo } = this.state;
		// let lbname = "select_datenoti";
		if (docInfo.function_type_id !== 1) {
			return null;
		}

		if (docInfo.dateofnoti !== 0 && formData.dateofnoti !== 0) {
			return null;
		}

		if (UpdateInfo && formData.dateofnoti !== 0) {
			return null;
		}

		// if (UpdateInfo) {
		// 	lbname = "select_datenoti1";
		// }
		let RowItems = [];
		RowItems.push(
			<InfoValue key_value="select_datenoti" key_label="select_datenoti1" >
				<InfoValueField key_value="select_datenoti" key_label="select_datenoti1" attributes={{ type: "date" }} required={DateNotiRequired()} />
			</InfoValue>
		);

		return RowItems;

	}

	ChkDateNoti = () => {
		let { formData, UpdateInfo, docInfo } = this.state;
		if (UpdateInfo) {
			let repExpiry_date = formData.expiry_date.replaceAll("-", "/");
			let currentDate = new Date();
			let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
			let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');

			if (docInfo.dateofnoti !== formData.dateofnoti) {
				if (calculateDateChk < formData.dateofnoti) {
					formData.expiry_date = "";

					return true;
					// this.setFormData(formData);
				}
			}
		}

		return false;
	}

	ChksSelectDateNoti = () => {
		let { formData, UpdateInfo, docInfo } = this.state;
		if (UpdateInfo) {

			let repExpiry_date = formData.select_datenoti.replaceAll("-", "/");
			let currentDate = new Date();
			let formatcurrentDate = Moment(currentDate).format("YYYY/MM/DD");
			let calculateDateChk = Moment(repExpiry_date).diff(formatcurrentDate, 'days');

			if (calculateDateChk < 0) {
				if (formData.dateofnoti !== docInfo.dateofnoti) {
					formData.select_datenoti = "";
					return true;
				}
				formData.select_datenoti = docInfo.select_datenoti;

				return true;

			}

		}

		return false;
	}

	DocumentTitleInfo = () => {
		let { docInfo, formData } = this.state;
		let { InfoValue } = this;

		let required = true;
		if (docInfo.function_code === "verification" && [1, "1"].includes(formData.preservation_require)) {
			required = false;
		}

		return (
			<InfoValue key_value="title" key_label="doc-title" required={required} />
		);
	}

	PreservationRequireInfo = () => {
		let { docInfo } = this.state;
		let { InfoValue } = this;

		if (docInfo.function_code !== "verification") {
			return null;
		}

		let items = { field: "preservation_require", value: "id", label: "label" };
		return (
			<InfoValue key_value="preservation_require" key_label="preservation-require" attributes={{ as: "select" }} items={items} required />
		);
	}

	PageDataItems = (props) => {
		let { t } = this.props;
		let { field, value, label } = props;
		let { PageData } = this.state;

		if (!PageData) {
			return null;
		}

		let resultElements = null;
		if (PageData[field] instanceof Array) {
			resultElements = PageData[field].map(item => <option key={item[value]} value={item[value]}>{t(`common:${item[label]}`)}</option>);
		} else {
			resultElements = Object.values(PageData[field]).map(item => <option key={item[value]} value={item[value]}>{t(`common:${item[label]}`)}</option>);
		}
		return resultElements;
	}

	localeStringCustom = (val) => {
		let isNegative = false
		if (val.startsWith('-')) {
			isNegative = true;
			val = val.substring(1);
		}
		let str = val.split('.');
		if (str.length > 0) {
			if (str[0] !== '0') {
				str[0] = str[0].replace(/^0+/, '').split(/(?=(?:...)*$)/).join(",");
			}
		}
		if (isNegative) {
			return '-' + str.join('.')
		}
		return str.join('.');
	}

	InfoValueField = (props) => {
		let { t } = this.props;
		let { key_value, key_label, xl, md, xs, items, required, attributes } = props;
		let { formData, formValidate, docInfo } = this.state;
		let { PageDataItems } = this;

		let ChildElements = props.children;
		if (!ChildElements && items) {
			ChildElements = <PageDataItems {...items} />;
		}
		let key_valueshow = formData[key_value];
		if (key_label === "date-noti-") {
			key_valueshow = docInfo.dateofnoti;
		}
		let classList = ["custom-form-outline"];
		if (formValidate && required) { classList.push("was-validated") };
		if (formValidate) { classList.push("form-validated") };

		let translateLabel = t(`common:documents.document-info.${key_label}`);
		let newAttributes = { ...attributes };
		if (key_value === "amount") {

			let val = formData[key_value];
			if ((typeof val) !== "string") {
				val = this.localeStringCustom(val.toString());
				formData[key_value] = val;
			}
			newAttributes.onBlur = (ev) => {
				let { isInvalid } = this.state;
				let val = ev.target.value;

				val = val.normalize('NFKC');
				val = val.replaceAll("ー", "-");
				val = val.replaceAll(",", "");
				val = val.trim();
				if (!val || val === "") {
					msgValidate = t("common:message.input.required", { field_name: key_label });
					isInvalid["amount"] = true;
				}
				else if (val !== '-' && (isNaN(val) || val === '00' || val === '-00')) {
					msgValidate = t("common:message.input.invalid-amount");
					isInvalid["amount"] = true;
				} else {
					isInvalid["amount"] = false;
				}
				if (!isInvalid["amount"]) {
					val = this.localeStringCustom(val);
				}
				formData[ev.target.name] = val;

				this.setState({ formData, isInvalid, msgValidate });
			}
			newAttributes.onClick = (ev) => {
				if (ev.target.value === "0" || ev.target.value === 0) {
					formData[key_value] = '';
				}
				formData[ev.target.name] = formData[ev.target.name].replaceAll(",", "");
				this.setState({ formData });
			}
			newAttributes.onKeyPress = (ev) => {
				if (ev.charCode === 32) {
					ev.preventDefault();
				}
			};
			newAttributes.style = { textAlign: 'right' };
			newAttributes.maxLength = "19";
			delete newAttributes.type;
		}

		// Add max value to date input
		if (newAttributes.type === "date") {
			newAttributes.max = "9999-12-31"
		}
		let msgValidate = t('common:message.input.required', { field_name: translateLabel });

		if (key_label === "expiry-date") {
			msgValidate = t("common:message.input.required-expiry-date");
		}
		if (key_label === "select_datenoti1") {
			msgValidate = t("common:message.input.required-select_datenoti1");
		}

		return (
			<Form.Group xl={xl} md={md} xs={xs} as={Col} controlId={key_value} className={classList.join(" ")}>
				<Form.Control
					{...newAttributes}
					name={key_value}
					placeholder={translateLabel}
					value={[null, undefined].includes(key_valueshow) ? "" : key_valueshow}
					isValid={this.state.isInvalid.hasOwnProperty(key_value) && !this.state.isInvalid[key_value]}
					isInvalid={this.state.isInvalid.hasOwnProperty(key_value) && this.state.isInvalid[key_value]}
					onChange={(ev) => {
						if (key_value === "amount") {
							let val = ev.target.value;
							let { t } = this.props;
							if (val === "") {
								msgValidate = t("common:message.input.required", { field_name: key_label });
							}
							formData[key_value] = val;
						} else {
							formData[key_value] = [null, undefined].includes(ev.target.value) ? "" : ev.target.value; // ev.target.value || "";
						}
						this.setState({ formData });
					}}
					required={required ?? false}
				>{ChildElements}</Form.Control>
				<Form.Control.Feedback type="invalid">
					{msgValidate}
				</Form.Control.Feedback>
			</Form.Group>
		);
	}

	InfoValue = (props) => {
		let { t } = this.props;
		let { key_value, key_label, alignment, key_join } = props;
		let { docInfo, UpdateInfo, keyFields } = this.state;
		let { InfoValueField } = this;
		// console.log(docInfo);
		let key_labelshow = t(`common:documents.document-info.${key_label}`);
		let ChildElements = props.children;
		if (UpdateInfo) {
			if (!ChildElements) {
				ChildElements = <InfoValueField {...props} />
			}

			if (key_label === "select_datenoti1") {
				key_labelshow = "";
			}

			return (
				<Form.Row data-key={key_label}>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{key_labelshow}</span>
					</Form.Group>
					{ChildElements}
				</Form.Row>
			);
		} else {
			let field_value = "";

			if (ChildElements) {
				if (!(ChildElements instanceof Array)) {
					ChildElements = [ChildElements];
				}
				field_value = ChildElements.map(item => item.props.key_value);
				field_value = field_value.map(item => docInfo[keyFields[item]] || "").join(key_join || ",");

				if ((["select_datenoti"].includes(key_label))) {//select_datenoti1
					if (!ChildElements) {
						ChildElements = <InfoValueField {...props} />
					}
					return (
						<Form.Row data-key={key_label}>
							<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
								<span className="document-info-key">{key_labelshow}</span>
							</Form.Group>
							{ChildElements}
						</Form.Row>
					);
				}

			} else {
				field_value = docInfo[keyFields[key_value]] || "";
			}

			if (key_label === "select_datenoti1") {
				key_labelshow = "";
			}


			return (
				<Form.Row data-key={key_label}>
					<Form.Group xl={4} md={12} xs={3} as={Col} className="info-label">
						<span className="document-info-key">{key_labelshow}</span>
					</Form.Group>
					<Form.Group xl={8} md={12} xs={9} as={Col}>
						<span className={`form-control document-info-value${alignment || ""}`}>{t(`common:${field_value}`)}</span>
					</Form.Group>
				</Form.Row>
			);
		}
	}

	DateNotiRequired = () => {
		let { formData, UpdateInfo } = this.state;
		if (UpdateInfo) {
			if (formData.dateofnoti) {
				if (parseInt(formData.dateofnoti) === 1) {
					return false;
				}
				return true;
			} else if (parseInt(formData.dateofnoti) === 0) {
				return true;
			}

			return false;

		}
	}

	HostDocInfo = () => {
		const {
			DocumentStatusInfo,
			SignLevelInfo,
			DocumentTypeInfo,
			DocumentTitleInfo,
			CommonItemInfo,
			SignerFieldInfo,
			SenderFieldInfo,
			CircleFieldInfo,
			VerificationInfo,
			ObjectAttributes,
			// DocumentDateofNoti,
			DocumentSelectDatenoti,
			DocdateOfNotice,
			AccesesLogInfo,
			SharedFolderInfo,
			PreservationRequireInfo,
		} = this;
		let { formData } = this.state;;
		let ChkDateNoti = this.ChkDateNoti();
		let ChksSelectDateNoti = this.ChksSelectDateNoti();
		let chkDatenotiSelected = "";
		// let requireddate = this.DateNotiRequired();

		if (parseInt(formData.dateofnoti) === 0) {
			chkDatenotiSelected = "block"
		} else {
			chkDatenotiSelected = "none"
		}
		return (
			<>
				<DocumentStatusInfo />

				<SignLevelInfo />

				<DocumentTypeInfo />

				<PreservationRequireInfo />

				<DocumentTitleInfo />

				<CommonItemInfo />

				<SignerFieldInfo />
				<SenderFieldInfo />
				<CircleFieldInfo />
				<VerificationInfo />
				<SharedFolderInfo />

				{/* <DocumentDateofNoti /> */}

				<DocdateOfNotice validate={{ ChkDateNoti }} />
				<DocumentSelectDatenoti style={{ display: chkDatenotiSelected, validate: ChksSelectDateNoti }} />

				<ObjectAttributes />

				<AccesesLogInfo />

				<button id="form_detail_submit" type="submit" hidden></button>
			</>
		);
	}

	formSubmitCallback = (ev) => {
		// console.log(ev);
		let { t } = this.props;
		let { modal } = this.state;
		// console.log("docInfo", docInfo);
		modal.title = "common:documents.info.confirm-update";
		modal.body = "common:message.documents.info.confirm-update";
		modal.action = [(
			<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={this.DocumentInfoSave}>
				<span>{t("common:general.confirm")}</span>
			</Button>
		)];

		modal.props.show = true;
		modal.props.centered = true;

		this.setState({ modal });
	}

	GuestDocInfo = () => {
		const {
			DocumentStatusInfo,
			DocumentTitleInfo,
		} = this;

		return (
			<>
				<DocumentStatusInfo />
				<DocumentTitleInfo />
			</>
		);
	}

	Plsdeletefile = () => {
		let { t } = this.props;
		let { docInfo } = this.state;
		if (docInfo.remarks === "protected" && [1, 2, 3].includes(docInfo.function_type_id)) {
			return (
				<div>
					<br />
					<span>
						<span className="label-error" >{t('common:documents.general.protectpdfalert1')}</span>
						<br />
						<span className="label-error" >{t('common:documents.general.protectpdfalert2')}</span>
					</span>
				</div>
			);
		} else {
			return null;
		}
	}

	DocumentDetailBody = () => {
		let { docInfo, PDFFiles, previewPdf, isGuest } = this.state;
		let {
			SignerList,
			ViewerList,
			HostDocInfo,
			GuestDocInfo,
			SignButton,
			OperationLog,
			Plsdeletefile,
		} = this;

		if (!docInfo) {
			return <Loading />;
		}
		if (docInfo === false) {
			return <Error />;
		}

		// let PDFFile = PDFFiles[SelectedFile];

		let PDFProps = {
			showAll: false,
			scale: 0.6,
			fit: true,
			files: PDFFiles,
			docInfo: docInfo,
			// file: {
			// 	file: PDFFile.url,
			// 	password: PDFFile.password,
			// }
		};
		// console.log(docInfo);
		// console.log(PDFFile);
		// console.log(PDFFiles);

		if (["signer", "sender", "circle"].includes(docInfo.function_code)) {
			// PDFProps.files = PDFFiles;
			PDFProps.list = true;
		} else if (docInfo.function_code === "verification") {
			// PDFProps.file = PDFFile;
			PDFProps.info = true;
		}

		// let Amount = (docInfo.amount || "0").toString();
		// Amount = Amount.split(".");
		// Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		// Amount = Amount.join(".") + " " + (docInfo.currency || "JPY");

		// console.log(doc_id);
		let { signers, receivers, viewers } = docInfo;
		// console.log(docInfo);
		// return <Error />;

		const pdfs = Object.keys(PDFFiles).map((hash) => ({
			hash: hash,
			name: PDFFiles[hash].name,
			url: PDFFiles[hash].url,
		}));

		return (

			<div className="document-detail-container form-wrapper responsiveCSS">
				<Form
					id={"detail"}
					onSubmit={(ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) }}
					noValidate
					className="document-detail-form full-form"
				>

					<div className="form-body flex-column">

						<Form.Row>
							<Container fluid="md" className={"d-block d-sm-none order-first text-center mb-4"}>
								<Form.Row>
									<Form.Group as={Col} className={'mx-4'}>
										<Box>
											{pdfs.length === 1 && <Button
												variant="outlined"
												className="btn-action btn-block"
												size="large"
												startIcon={<DescriptionIcon />}
												onClick={() => this.onPreviewPdfHandler(pdfs[0].hash)}>
												{"PDFを見る"}
											</Button>}
											{pdfs.length > 1 &&
												<Select
													className="btn-action btn-block"
													value={previewPdf || '-1'}
													onChange={(ev) => this.onPreviewPdfHandler(ev.target.value)}
													style={{ fontSize: '12px', color: "#007BFF" }}
													variant={"standard"}
												>
													<MenuItem value={`- 1`} key={''} style={{ fontSize: '12px' }} className="d-none" >{'PDFを見る'}</MenuItem>
													{pdfs.map((pdf, index) => (
														<MenuItem value={`${pdf.hash}`} key={index} style={{ fontSize: '12px' }}>{pdf.name}</MenuItem>
													))}
												</Select>}
										</Box>
									</Form.Group>
								</Form.Row>
							</Container>

							<Container fluid="md" className={"container-fluid doc-info col-11 col-sm-5 order-3 order-sm-2"} >
								{/* <Form.Group xs={5} as={Col} className="signer-list-column"> */}

								<Form.Row className="signer-list-wrapper">

									<SignerList users={signers} filter={{ "user_type": [0, 2] }} showStatus={true} label="documents.host-setting.signers-list" />

									{!["sender", "verification"].includes(docInfo.function_code) && <SignerList users={signers} filter={{ "user_type": 1 }} showStatus={true} label="documents.guest-setting.guests-list" />}

									<SignerList users={receivers} isReceiver={true} label="documents.guest-setting.guests-list" />

									{!isGuest ? <ViewerList users={viewers} label="documents.viewer-setting.viewers-list" /> : null}

								</Form.Row>

								<Form.Row className='d-none d-sm-block'>
									<Form.Group xl={12} md={12} as={Col} >
									</Form.Group>
								</Form.Row >
								{/* </Form.Group> */}
							</Container>

							<Container fluid="md" className={"container-fluid doc-info col-11 col-sm-7 order-2 order-sm-3 "} >
								{
									!isGuest ?
										<HostDocInfo /> :
										<GuestDocInfo />
								}


								<Form.Row className="d-none d-sm-block">
									<Form.Group xl={12} md={12} as={Col} className="">
										<Plsdeletefile />
										<div className="page-action-bottom-relative">
											<SignButton className="btn-form-control" />
										</div>
									</Form.Group>
								</Form.Row >

							</Container>

							<Container fluid="md" className="d-block d-sm-none order-last text-center">
								<Form.Row>
									<Form.Group xl={12} md={12} as={Col} className="mx-4">
										<div className="page-action-bottom-relative">
											<SignButton className="btn-form-control btn-block" />
										</div>
									</Form.Group>
									<Form.Group xl={12} md={12} as={Col} className="mx-4">
										<Box key="download">
											<Button
												variant="outlined"
												className="btn-action btn-block"
												size="large"
												startIcon={<CloudDownloadOutlinedIcon />}
												//title={t("common:document.download")}
												onClick={this.onDownloadRequestHandler}>
												{"PDFをダウンロード"}
											</Button>
										</Box>
									</Form.Group>
								</Form.Row >
							</Container>
						</Form.Row >


					</div>

					<div className="form-foot">
					</div>

				</Form>

				<div className="file-panel preview d-none d-sm-flex">

					<div className={"file-preview-zone"}>

						<PDFViewer {...PDFProps} />

					</div>
					<OperationLog />

					{/* <div className="file-upload-list">
						<ul>
							<FileList />
						</ul>
					</div> */}

				</div>

			</div >
		);
	}

	OperationLog = () => {
		let { operationLogFlg } = this.state;
		let { DisplayOperationLog } = this;

		let active = "";

		if (operationLogFlg) {
			active = " active";
		} else {
			active = " inactive";
		}

		// console.log("PageRender", Pages);
		return (
			<div className={`accesslog ${active}`} >
				<DisplayOperationLog />
			</div>
		);
	}

	DisplayOperationLog = () => {

		let { t } = this.props;
		let { docInfo } = this.state;
		// console.log("docInfo");
		// console.log(docInfo);
		/* */
		if (docInfo.operation_log == null || docInfo.operation_log.length === 0) {
			return (
				t("common:documents.document-info.no-operation-log")
			);
		}

		/* Organization Tree-Structure */
		let logList = docInfo.operation_log;
		let list = [];

		/* Convert Array to Elements */
		for (let i = 0; i < logList.length; i++) {

			let logData = logList[i];
			let unique = logData.document_id + "-" + i;

			let update_date_format = "YYYY/MM/DD HH:Mi:SS";
			// Update date
			let date = new Date(logData.create_datetime);
			update_date_format = update_date_format.replace(/YYYY/, date.getFullYear());
			update_date_format = update_date_format.replace(/MM/, ("00" + (date.getMonth() + 1)).slice(-2));
			update_date_format = update_date_format.replace(/DD/, ("00" + date.getDate()).slice(-2));
			update_date_format = update_date_format.replace(/HH/, ("00" + date.getHours()).slice(-2));
			update_date_format = update_date_format.replace(/Mi/, ("00" + date.getMinutes()).slice(-2));
			update_date_format = update_date_format.replace(/SS/, ("00" + date.getSeconds()).slice(-2));

			let update_date_block = (
				<div className="operation-log-update-date">
					<span>{t(`common:documents.document-info.opelog.update-date`, { update_date: update_date_format })}</span>
				</div>
			);

			// Update user
			let update_user_block = (
				<div className="operation-log-update-user">
					<span>{t(`common:documents.document-info.opelog.update-user`, { update_user: logData.email })}</span>
				</div>
			);

			// Document type
			let document_type_id_block = this.OperationLogRequiredContents(
				logData.before_document_type_name,
				logData.after_document_type_name,
				t(`common:documents.document-info.document-type`),
			);

			// title
			let title_block = this.OperationLogNormalContents(
				logData.before_title,
				logData.after_title,
				t(`common:documents.document-info.doc-title`),
			);

			// amount
			let amount_block = this.OperationLogNormalContents(
				logData.before_amount,
				logData.after_amount,
				t(`common:documents.document-info.amount`),
			);

			// currency
			let currency_block = this.OperationLogRequiredContents(
				logData.before_currency,
				logData.after_currency,
				t(`common:documents.document-info.currency`),
			);

			// contract_date
			let contract_date_block = this.OperationLogNormalContents(
				logData.before_contract_date,
				logData.after_contract_date,
				t(`common:documents.document-info.contract-date`)
			);

			// expiry_date
			let expiry_date_block = this.OperationLogNormalContents(
				logData.before_expiry_date,
				logData.after_expiry_date,
				t(`common:documents.document-info.expiry-date`)
			);

			// effective_date
			let effective_date_block = this.OperationLogNormalContents(
				logData.before_effective_date,
				logData.after_effective_date,
				t(`common:documents.document-info.effective-date`)
			);

			// sign_finish_date
			let sign_finish_date_block = this.OperationLogNormalContents(
				logData.before_sign_finish_date,
				logData.after_sign_finish_date,
				t(`common:documents.document-info.sign-finish-date`)
			);

			// document_create_date
			let document_create_date_block = this.OperationLogNormalContents(
				logData.before_document_create_date,
				logData.after_document_create_date,
				t(`common:documents.document-info.document-create-date`)
			);

			// content
			let content_block = this.OperationLogRequiredContents(
				logData.before_content,
				logData.after_content,
				t(`common:documents.document-info.content`)
			);

			// product_name
			let product_name_block = this.OperationLogNormalContents(
				logData.before_product_name,
				logData.after_product_name,
				t(`common:documents.document-info.product`)
			);

			// reference_document
			let reference_document_block = this.OperationLogNormalContents(
				logData.before_reference_document,
				logData.after_reference_document,
				t(`common:documents.document-info.reference`)
			);

			// meta_attributes
			let meta_attributes_block = this.OperationLogMapContents(
				logData.before_meta_attributes,
				logData.after_meta_attributes,
				t(`common:documents.document-info.attributes`)
			);

			// files
			let files_block = this.OperationLogFileContents(
				logData.before_files,
				logData.after_files,
				t(`common:documents.document-info.file-name`)
			);

			// attachments
			let attachments_block = this.OperationLogNormalContents(
				logData.before_attachments,
				logData.after_attachments,
				t(`common:documents.document-info.attachments`)
			);

			// filebox_path
			let filebox_path_block = this.OperationLogNormalContents(
				logData.before_filebox_path,
				logData.after_filebox_path,
				t(`common:documents.document-info.filebox-path-select-button`)
			);

			// status_id
			let status_id_block = this.OperationLogTranslateContents(
				logData.before_status_name,
				logData.after_status_name,
				t(`common:documents.document-info.status`)
			);

			// remarks
			let remarks_block = this.OperationLogNormalContents(
				logData.before_remarks,
				logData.after_remarks,
				t(`common:documents.document-info.remarks`)
			);

			// auto_sign_location
			let auto_sign_location_block = this.OperationLogNormalContents(
				logData.before_auto_sign_location,
				logData.after_auto_sign_location,
				t(`common:documents.document-info.auto-sign-location`)
			);

			// use_digital_cert
			let use_digital_cert_block = this.OperationLogDigitalCertContents(
				logData.before_use_digital_cert,
				logData.after_use_digital_cert,
				t(`common:documents.document-info.digital-certificate`)
			);

			// host_cert_type
			let host_cert_type_block = this.OperationLogNormalContents(
				logData.before_host_cert_name,
				logData.after_host_cert_name,
				t(`common:documents.document-info.certificate-type`)
			);

			// received_date
			let received_date_block = this.OperationLogNormalContents(
				logData.before_received_date,
				logData.after_received_date,
				// t(`common:documents.document-info.received-date`)
				t(`common:documents.document-info.deal-date`)
			);

			// counter_party_name
			let counter_party_name_block = this.OperationLogNormalContents(
				logData.before_counter_party_name,
				logData.after_counter_party_name,
				t(`common:documents.document-info.counter-party-name`)
			);

			// receivers
			let receivers_block = this.OperationLogNormalContents(
				logData.before_receivers,
				logData.after_receivers,
				t(`common:documents.document-info.receivers`)
			);

			// sign_level
			let sign_level_block = this.OperationLogTranslateContents(
				logData.before_sign_level,
				logData.after_sign_level,
				t(`common:documents.document-info.sign-level`)
			);

			// Preservation require
			let preservation_require_block = this.OperationLogTranslateContents(
				logData.before_preservation_require,
				logData.after_preservation_require,
				t(`common:documents.document-info.document-type`),
			);

			// signers
			let signers_block = this.OperationLogArrayContents(
				logData.before_signers,
				logData.after_signers,
				t(`common:documents.host-setting.signers`)
			);

			// signer_info
			// let signer_info_block = this.OperationLogNormalContents(
			// 	logData.before_signer_info,
			// 	logData.after_signer_info,
			// 	t(`common:documents.document-info.signer-info`)
			// );

			// viewers
			let viewers_block = this.OperationLogArrayContents(
				logData.before_viewers,
				logData.after_viewers,
				t(`common:documents.viewer-setting.viewers-list`)
			);

			// forward
			/* Just work around, need to translate later */
			let forward_user_block = null;
			if (logData.before_forward && logData.before_forward) {
				let forwardFrom = logData.before_forward.email || logData.before_forward.full_name;
				let forwardTo = logData.after_forward.email || logData.after_forward.full_name;
				forward_user_block = (
					<div className="operation-log-content">
						<span>{`転送: 「${forwardFrom}」が「${forwardTo}」へ転送しました`}</span>
					</div>
				);
			}


			// sign_user
			let operationfirst = 'document.sign';
			if ([14].includes(docInfo.doc_status) && docInfo.function_type_id === 4) {
				operationfirst = 'document.signprotectpdffirst';
			}
			let sign_user_block = this.OperationLogFreeContents(
				logData.sign_user_email,
				t(`common:${operationfirst}`),
				'documents.document-info.opelog.operation-exec',
				t(`common:${operationfirst}`)
			);

			// timestamp_user
			let timestamp_user_block = this.OperationLogFreeContents(
				logData.timestamp_user_email,
				t(`common:documents.evaluate-timestamp`),
				'documents.document-info.opelog.operation-exec',
				t(`common:documents.evaluate-timestamp`)
			);

			// return_user
			let return_user_block = this.OperationLogFreeContents(
				logData.return_user_email,
				t(`common: document.remand`),
				'documents.document-info.opelog.operation-exec',
				t(`common: document.remand`)
			);

			// pdf_download_user
			let pdf_download_user_block = this.OperationLogFreeContents(
				logData.pdf_download_user_email,
				t(`common: document.download`),
				'documents.document-info.opelog.operation-exec',
				t(`common: document.download`)
			);

			/* */
			list.push(
				<div id={unique} className="operation-log-top" key={unique}>
					<div className="">
						<div className="lz-flex-1">
							{update_date_block}
							{update_user_block}
							{document_type_id_block}
							{title_block}
							{amount_block}
							{currency_block}
							{contract_date_block}
							{expiry_date_block}
							{effective_date_block}
							{sign_finish_date_block}
							{document_create_date_block}
							{content_block}
							{product_name_block}
							{reference_document_block}
							{meta_attributes_block}
							{files_block}
							{attachments_block}
							{filebox_path_block}
							{status_id_block}
							{remarks_block}
							{auto_sign_location_block}
							{use_digital_cert_block}
							{host_cert_type_block}
							{received_date_block}
							{counter_party_name_block}
							{receivers_block}
							{sign_level_block}
							{preservation_require_block}
							{signers_block}
							{/* {signer_info_block} */}
							{viewers_block}
							{forward_user_block}
							{sign_user_block}
							{timestamp_user_block}
							{return_user_block}
							{pdf_download_user_block}
						</div>
					</div>
				</div>
			);

		}

		/* */
		return (
			<div id="top" className="">
				{/* <div className="access-log-head">
					<div className="lz-flex-1">
						<div className="lz-m-0-10">
							<span>{t("common:documents.document-info.access-log")}</span>
						</div>
					</div>
				</div> */}
				<div className="">
					{list}
				</div>
			</div>
		);
	}

	OperationLogFreeContents = (logData, contentsName, message, operationName) => {
		let { t } = this.props;

		if (logData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:${message}`, { content: logData, operation: operationName })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogRequiredContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		if (beforeLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content: beforeLogData, after_content: afterLogData })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogNormalContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		if (beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content: beforeLogData, after_content: afterLogData })}
					</span>
				</div>
			);
		} else if (beforeLogData && !afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before`, { before_content: beforeLogData })}
					</span>
				</div>
			);
		} else if (!beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-after`, { after_content: afterLogData })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogTranslateContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		if (beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before-after`, { before_content: t(`common:${beforeLogData}`), after_content: t(`common:${afterLogData}`) })}
					</span>
				</div>
			);
		} else if (beforeLogData && !afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-before`, { before_content: t(`common:${beforeLogData}`) })}
					</span>
				</div>
			);
		} else if (!beforeLogData && afterLogData) {
			return (
				<div className="operation-log-content">
					<span>
						{contentsName}
						{t(`common:documents.document-info.opelog.update-after`, { after_content: t(`common:${afterLogData}`) })}
					</span>
				</div>
			);
		}

		return null;
	}

	OperationLogMapContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			let beforeEntries = Object.entries(beforeLogData);
			beforeEntries.forEach(function (v) {
				temp.push(v.join(':'));
			});
			beforeStrData = temp.join(',');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			let afterEntries = Object.entries(afterLogData);
			afterEntries.forEach(function (v) {
				temp.push(v.join(':'));
			});
			afterStrData = temp.join(',');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	OperationLogArrayContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			beforeLogData.forEach(function (v, i) {
				temp.push(v.email);
			});
			beforeStrData = temp.join(', ');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			afterLogData.forEach(function (v, i) {
				temp.push(v.email);
			});
			afterStrData = temp.join(', ');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	OperationLogDigitalCertContents = (beforeLogData, afterLogData, contentsName) => {
		let { t } = this.props;

		let use_digital_cert_active = t(`common:documents.document-info.use-digital-cert-active`);
		let use_digital_cert_inactive = t(`common:documents.document-info.use-digital-cert-inactive`);
		let beforeUseDigitalCert = null;
		let afterUseDigitalCert = null;

		if (beforeLogData === 0) {
			beforeUseDigitalCert = use_digital_cert_inactive;
		} else if (beforeLogData === 1) {
			beforeUseDigitalCert = use_digital_cert_active;
		}

		if (afterLogData === 0) {
			afterUseDigitalCert = use_digital_cert_inactive;
		} else if (afterLogData === 1) {
			afterUseDigitalCert = use_digital_cert_active;
		}

		return this.OperationLogNormalContents(beforeUseDigitalCert, afterUseDigitalCert, contentsName);
	}

	OperationLogFileContents = (beforeLogData, afterLogData, contentsName) => {
		//		let { t } = this.props;
		let beforeStrData = null;
		let afterStrData = null;
		let temp = [];

		if (beforeLogData && Object.keys(beforeLogData).length > 0) {
			for (let key in beforeLogData) {
				temp.push(beforeLogData[key].name);
			}
			beforeStrData = temp.join(',');
			temp = [];
		}

		if (afterLogData && Object.keys(afterLogData).length > 0) {
			for (let key in afterLogData) {
				temp.push(afterLogData[key].name);
			}
			afterStrData = temp.join(',');
		}

		return this.OperationLogNormalContents(beforeStrData, afterStrData, contentsName);
	}

	/** [Element] */
	AccessLogTree = () => {

		let { t } = this.props;
		let { docInfo, token } = this.state;

		/* */
		if (docInfo.access_log == null) {
			return null;
		}

		if (token) {
			return null;
		}

		/* Organization Tree-Structure */
		let tree = docInfo.access_log;
		let list = [];

		/* Convert Array to Elements */
		for (let i = 0; i < tree.length; i++) {

			/* */
			let item = this.AccessLogTreeItem(tree[i], i);

			/* */
			list.push(item);

		}

		/* */
		let toggle = (event, id) => {

			/* */
			let target = document.getElementById(id);
			let body = target.querySelector(".access-log-body");
			/* */
			if (body.classList.contains("access-log-collapse")) {
				/* */

				/* */
				body.classList.remove("access-log-collapse");

			} else {
				/* */
				/* */
				body.classList.add("access-log-collapse");

			}
		};

		/* */
		return (
			<div id="top" className="access-log">
				<div className="access-log-head">

					<Button
						onClick={(e) => { toggle(e, "top") }}
						variant="outlined"
						size="small"
					>
						{t("common:documents.document-info.access-log")}
					</Button>

				</div>
				<div className="access-log-body access-log-collapse">
					{list}
				</div>
			</div>
		);
	}

	/** [Element] */
	AccessLogTreeItem = (accessLog, index) => {

		/* */
		// let children = [];
		let unique = "access-log-{0}";

		/* */
		let accessDateObject = new Date(accessLog.access_date);
		// let accessDateObject
		let year = accessDateObject.getFullYear();
		let month = ("00" + (accessDateObject.getMonth() + 1)).slice(-2);
		let day = ("00" + accessDateObject.getDate()).slice(-2);;
		let hour = ("00" + accessDateObject.getHours()).slice(-2);;
		let minute = ("00" + accessDateObject.getMinutes()).slice(-2);;
		let second = ("00" + accessDateObject.getSeconds()).slice(-2);;
		let accessDate = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
		unique = unique.replace("{0}", accessLog.access_date.replace(" ", ""));

		/* */
		return (
			<div id={unique} className="org" key={`${index} -${unique}`}>
				<div className="access-log-head">
					<div className="lz-flex-1">
						<div className="lz-m-0-10">
							<span>{`${accessDate}: ${accessLog.email} (${accessLog.user_type_name})`}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	ReturnButton = () => {
		let { action, docInfo } = this.state;
		// console.log(action);
		if (action) {
			let ActionButton = action;
			return <ActionButton docInfo={docInfo} />;
		} else {
			return null;
		}
	}

	CertificateApplyForm = () => {
		let { t } = this.props;
		let { docInfo, appCert, certApply, token } = this.state;

		if (!certApply) {
			return null;
		}

		let fromProps = {
			as: "modal",
			data: null,
			doc_type: null,
			show: certApply,
			requiredFile: true,
			token: token,
			onClose: this.onCertificateApplyFormClose,
		};

		if (docInfo && appCert && certApply) {
			let signerInfo = docInfo.signer_info;
			let curDate = new Date();
			curDate = curDate.toISOString();

			let certData = {
				"user_id": signerInfo.user_id,
				"company_name": signerInfo.company_name,
				"name": signerInfo.name,
				"email": signerInfo.email,
				"company_id": docInfo.company_id,
				"tenant_id": docInfo.tenant_id,
				"application_date": curDate.substr(0, 10),
				"status": 0,
				"identity_verification_documents": 0,
				"credentials": "",
				"status_name": "",
				"file_name": "",
				"application_certificate_type": signerInfo.certificate_type_id,
				"operation": "insert_data",
				"document_id": docInfo.document_id,
			};

			for (let cert of appCert.cert_type) {
				if (cert.id === signerInfo.certificate_type_id) {
					certData.application_certificate_label = t(`common: certificate.type.${cert.label}`);
					break;
				}
			}

			if (appCert.records.length > 0) {
				let certRequestInfo = appCert.records[0];
				for (let i in certRequestInfo) {
					certData[i] = certRequestInfo[i];
				}
				certData.operation = "update_data";
				certData.status_name = t(`common:${certData.status_name}`);
			}

			// console.log(certData);
			fromProps.doc_type = appCert.identity_doc_type;
			fromProps.data = certData;
			// fromProps.show = true;

		}
		// console.log(fromProps);
		return (
			<FormDetail {...fromProps} />
		);
	}

	async handleConfirmOtpDialog(isDocumentVerifyEmail) {
		// const user = await AuthService.GetCurrentLogin();
		if (!isDocumentVerifyEmail) {
			return this.setState({
				openOtpDialog: false,
				isVerifySoftware: false,
				isVerified: true
			});
		}

		this.setState({
			openOtpDialog: false,
			isVerifyEmail: false,
			isVerified: true
		});

		if (this.state.signingTypeFunc === 'GuestSignConfirmHandler') {
			this.GuestSignConfirmHandler();
		} else {
			this.SignConfirmHandler();
		}

	}


	async getCurrentUserAttributes(type) {
		// console.log('day la get curent', this, type);
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		// console.log(this.state.signingTypeFunc,(attributes['custom:mfa_type'] !== 'disable' && (attributes['custom:mfa_email_auth'].includes('document') && attributes['custom:mfa_type'] === 'email' 
		// || (attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software'))));
		if (attributes['custom:mfa_type'] !== 'disable' &&
			((attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') ||
				(attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software'))) {
			this.UpdateModalDialog({
				props: {
					"show": false,
				},
			});
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {
				this.generateOtpDocument();
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
			switch (type) {
				case 'GuestSignConfirmHandler':
					this.setState({ signingTypeFunc: 'GuestSignConfirmHandler' });
					// this.GuestSignConfirmHandler();
					break;
				default:
					this.setState({ signingTypeFunc: 'SignConfirmHandler' });
					break;
			}
		} else {
			switch (type) {
				case 'GuestSignConfirmHandler':
					this.setState({ signingTypeFunc: 'GuestSignConfirmHandler' });
					this.GuestSignConfirmHandler();
					break;
				default:
					this.setState({ signingTypeFunc: 'SignConfirmHandler' });
					this.SignConfirmHandler();
					break;
			}
		}
	}

	async generateOtpDocument() {
		const uri = API.url + "/mfa-otp/generate.json";
		const tokenID = await AuthService.GetTokenID();
		await axios.post(uri, {}, {
			headers: {
				Authorization: tokenID,
			},
		}).catch((e) => {
			console.log(e);
			// reject(error);
		});
	};

	async getCurrentUserAttributesGuest() {
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		if (attributes['custom:mfa_type'] !== 'disable') {
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_email_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {

				this.generateOtpDocument();
				this.setState({
					isVerified: false
				});
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
		}
	}

	async handleConfirmOtpGuest(isDocumentVerifyEmail) {
		// const user = await AuthService.GetCurrentLogin();
		if (!isDocumentVerifyEmail) {
			return this.setState({
				openOtpDialog: false,
				isVerifySoftware: false,
				isVerified: true
			});
		}

		this.setState({
			openOtpDialog: false,
			isVerifyEmail: false,
			isVerified: true
		});
	}

	render() {
		let { docInfo, PDFFiles, previewPdf, openOtpDialog, isVerifySoftware, isVerifyEmail } = this.state;
		let { LogoImage, DocumentDetailBody, GeneralModal, FormModal, ActionBar, ReturnButton, CertificateApplyForm } = this;
		// console.log(docInfo);

		let DocTitle = null;
		if (docInfo) {
			DocTitle = `${docInfo.id}: ${docInfo.title}`;
		}

		const PDFFile = PDFFiles[previewPdf]
		if (PDFFile && PDFFile.url) {
			PDFFile.file = PDFFile.url;
		}
		// return <></>;
		return (
			<div className="document-detail-wrapper mb-0">
				<div className="d-none d-sm-flex header-wrapper sticky">
					<div className="header-left">
						<ReturnButton docInfo={docInfo} />
					</div>
					<div className="header-center document-title">
						{DocTitle}
					</div>
					<div className="header-right">
						<ActionBar />
					</div>
				</div>
				<div className='d-block d-sm-none m-3'>
					<div className="logo-horizontal mb-2">
						<LogoImage />
					</div>
					<div className="header-center document-title">
						{DocTitle}
					</div>
				</div>
				<div className="body-wrapper">
					<DocumentDetailBody />
				</div>

				{previewPdf !== null &&
					<FullPreview
						key="full-preview"
						hash={previewPdf}
						file={PDFFile}
						files={PDFFiles}
						active={previewPdf !== null}
						onClose={(ev) => {
							this.setState({ previewPdf: null });
						}}
					/>
				}
				<GeneralModal />
				<FormModal />
				<CertificateApplyForm />
				<OtpDialog resendEmailCode={this.generateOtpDocument.bind(this)} isFromSigned={true} openOtpDialog={openOtpDialog} isVerifyEmailInDocument={isVerifyEmail} isVerifySoftware={isVerifySoftware} mfaChallengeName={''}
					mfaUser={''} callback={this.handleConfirmOtpDialog.bind(this)} />
			</div>
		);

	}

}

export default withTranslation()(DocumentDetail);