import React from 'react';
import { withTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Link } from 'react-router-dom';
import {
    faMinus,
    faPlus,
    faChevronRight,
    faChevronLeft,
    faInfo,
} from '@fortawesome/free-solid-svg-icons';
import Service from '../../../Service';
import Loading from '../../Loading';
import {
    file_pdf as IconPDFFile,
} from '../../Assets/SVGIcons';
import {
    Form,
    Col,
    Button,
    Modal,
} from 'react-bootstrap';

import '../../Assets/css/pdf-preview.css';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import FullPreview from './FullPreview';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoIcon from '@mui/icons-material/Info';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
// import ModalDialog from '../../../Layout/ModalDialog';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // active: true,
            showAll: props.showAll || false,
            doc_id: props.doc_id || null,
            docInfo: props.docInfo || null,
            hash: props.hash || null,
            scale: props.scale || 1,
            PDFFile: props.file || {},
            // {
            //     file: props.file || null,
            //     password: props.password || null,
            // },
            PDFFiles: props.files || {},
            PDFLoadSuccess: false,
            noFile: false,
            multiple: props.multiple || false,
            allowUpload: props.upload || false,
            autoFit: props.fit || false,
            controller: props.controller || false,
            infoPanel: props.info || false,
            listPanel: props.list || false,
            fileChangeCallback: props.fileChangeCB,
            fileRemoveCallback: props.fileRemoveCB,
            fullPreview: props.full || false,
            showFullPreview: false,
            modalPassword: false,
            tempPassword: null,
            incorrectPassword: "",
            multipleFilesQueue: [],
        }

        if (!props.files && props.file) {
            let file = this.state.PDFFile;
            this.state.PDFFiles[file.hash] = file;
        }

        this.pdfDocOptions = {
            cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
        };

        // console.log(this.state);
    }

    static getDerivedStateFromProps(props, state) {
        // console.log(props);
        // console.log(state);
        if (state.hash !== props.hash) {
            state.hash = props.hash;
            state.doc_id = props.doc_id || null;
            state.docInfo = props.docInfo || null;
            state.scale = props.scale || 1;
            state.PDFFile = props.file || {};
            // state.PDFFile.password = props.password || null;
            // console.log("getDerivedStateFromProps", state.PDFFile.initialDone);
            state.PDFFile.initialDone = false;
            state.PDFLoadSuccess = false;
        }

        return { state };
    }

    componentDidMount() {
        let { doc_id, hash, PDFFile, PDFFiles } = this.state;
        // console.log(multipleFilesQueue);
        if (!PDFFile.file) {
            if (Object.values(PDFFiles).length > 0) {
                PDFFile = Object.values(PDFFiles)[0];
                // PDFFile = PDFFiles[firstFileKey];
                if (PDFFile.url) {
                    PDFFile.file = PDFFile.url;
                }
                PDFFile.initialDone = false;
                this.setState({ PDFFile });
            } else if (doc_id && hash) {
                Service.getDocumentFile(doc_id, hash).then(resp => {
                    // console.log(resp);
                    // SelectedFile = "9a192d8b1a7dc652a19835f6f08098bd";
                    PDFFile.file = resp;
                    PDFFile.initialDone = false;
                    this.setState({ PDFFile });
                }).catch(err => {
                    //console.log(err.resonse);
                });
            } else {
                this.setState({ noFile: true });
            }
        } else if (PDFFile.url) {
            // console.log(PDFFile);
            PDFFile.file = PDFFile.url;
            PDFFile.initialDone = false;
            this.setState({ PDFFile });
        }
    }

    // componentDidUpdate() {
    //     let { PDFFile, PDFFiles, multipleFilesQueue } = this.state;
    //     console.log(multipleFilesQueue);
    //     multipleFilesQueue.shift();
    //     this.setState({ multipleFilesQueue });
    // }

    DateFromMetadata = (value) => {
        if (!value) {
            return null;
        }
        try {
            // console.log(value);
            let strDate = value.replace(/([D]:)|(')/g, "");
            if (strDate[14] === "Z") {
                strDate = strDate.substr(0, 15);
            }
            strDate = strDate.split("");
            if (strDate.length === 19) {
                strDate.splice(17, 0, ":");
            }
            // console.log(strDate[14]);
            strDate.splice(12, 0, ":");
            strDate.splice(10, 0, ":");
            strDate.splice(8, 0, "T");
            strDate.splice(6, 0, "-");
            strDate.splice(4, 0, "-");
            strDate = strDate.join("");
            // console.log(strDate);

            let date = new Date(strDate);

            return date.toISOString();
        } catch (ex) {
            return value;
        }
    }

    onDocumentLoadSuccess = async (pdfObject) => {
        let { numPages } = pdfObject;
        let {
            // PDFFiles,
            PDFFile,
            showAll,
            autoFit,
            scale,
            fullPreview,
            // multipleFilesQueue
        } = this.state;
        // console.log(pdfObject);
        // console.log(PDFFiles);
        // console.log(PDFFile);
        // console.log(PDFFile.initialDone);
        // console.log(tempPDFPassword, keepPDFPassword);
        if (!PDFFile.initialDone) {

            // let pdfOutline = await pdfObject.getOutline();
            // console.log(pdfOutline);
            let pdfMeta = await pdfObject.getMetadata();
            PDFFile.info = pdfMeta.info;
            if (pdfMeta.metadata) {
                PDFFile.metadata = pdfMeta.metadata.getAll();
            } else {
                PDFFile.metadata = {};
            }
            console.log(PDFFile.info);
            console.log(pdfMeta);
            PDFFile.info.CreateDate = this.DateFromMetadata(PDFFile.info.CreationDate);
            PDFFile.info.ModifyDate = this.DateFromMetadata(PDFFile.info.ModDate || PDFFile.info.CreationDate);

            PDFFile.numPages = numPages;
            PDFFile.currentPage = PDFFile.currentPage || 1;
            let pageSpace = 20;
            let docHeight = 0;
            let pages = {};
            for (let i = 1; i <= numPages; i++) {
                let pageInfo = await pdfObject.getPage(i);
                let opList = await pageInfo.getOperatorList();
                let scan = null;
                let pos = null;
                let pageView = {
                    top: pageInfo.view[0],
                    left: pageInfo.view[1],
                    width: pageInfo.view[2],
                    height: pageInfo.view[3],
                };

                if ([90, 270].includes(pageInfo.rotate)) {
                    pageView.width = pageInfo.view[3];
                    pageView.height = pageInfo.view[2];
                }

                console.log(pageInfo);
                for (var ii = 0; ii < opList.fnArray.length; ii++) {
                    // paintInlineImageXObject paintImageXObject 
                    if (opList.fnArray[ii] === pdfjs.OPS.transform) {
                        pos = opList.argsArray[ii];
                    }
                    if (opList.fnArray[ii] === pdfjs.OPS.paintImageXObject) {
                        // console.log(pdfjs.OPS);
                        // console.log(opList);
                        let img = opList.argsArray[ii];
                        // let imgObject = pageInfo.objs.get(img[0]);
                        // console.log(img);
                        // console.log(imgObject);
                        // for (let mm = ii - 1; mm < 6; mm--) {
                        //     let pos = opList.argsArray[ii - 3];
                        // }
                        if (pos) {
                            if (pos[4] === 0 && pos[5] === 0) {
                                scan = {
                                    key: img[0],
                                    width: img[1],
                                    height: img[2],
                                    index: ii,
                                    position: pos,
                                    // dpi: Math.round(img[1] / (pageView.width / 72)),
                                    dpi: Math.round(img[1] / 8.27),
                                    // key: imgKey,
                                };
                                break;
                            }
                        }
                    }
                }

                if (scan === null) {
                    scan = {
                        key: 'dummyImage',
                        width: 2893,
                        height: 4092,
                        index: 0,
                        position: [2893, 0, 0, 4092, 0, 0],
                        // dpi: Math.round(2893 / (842.04 / 72)),
                        dpi: Math.round(2893 / 8.27),
                        // key: imgKey,
                    };
                }

                pages[i] = {
                    view: pageView,
                    scan: scan,
                };

                docHeight += pageView.height + pageSpace;
            }
            docHeight += pageSpace;
            let currentHeight = pageSpace;
            for (let i in pages) {
                let view = pages[i].view;
                pages[i].view.top = currentHeight;
                pages[i].view.percentage = {
                    top: currentHeight / docHeight * 100,
                    height: view.height / docHeight * 100,
                };
                currentHeight += view.height + pageSpace
            }
            pages[1].view.top = 0;
            pages[1].view.percentage.top = 0;

            PDFFile.pages = pages;
            PDFFile.initialDone = true;

            if (autoFit) {
                // Exclude the width of the PDF information section on the right side (340px) for 742px or more.
                let parentWidth =
                    window.innerWidth > 742
                        ? window.innerWidth - 340
                        : window.innerWidth;
                let parentHeight = window.innerHeight - 120;

                if (!fullPreview) {
                    // Non-fullscreen previews are set to fixed values for height and width.
                    let parentBlock = document.querySelector("#pdf_main_preview.pdf-fragment .pdf-preview-section");
                    parentWidth = parentBlock.offsetWidth - 40;
                    parentHeight = parentBlock.offsetHeight - 60;
                }

                if (showAll) {
                    parentWidth -= 40;
                }

                // console.log(parentWidth, parentHeight);
                console.log(pages[1]);

                let wScale = parentWidth / pages[1].view.width;
                let hScale = parentHeight / pages[1].view.height;
                console.log(wScale, hScale);
                console.log(pages[1].view.width, pages[1].view.height);
                console.log(parentWidth, parentHeight);

                scale = wScale;
                if (!showAll && hScale < wScale) {
                    scale = hScale;
                }
                if (scale < 0.1) {
                    scale = 0.1;
                }
                if (showAll) {
                    // console.log(scale);
                    scale = scale * 10;
                    scale = Math.floor(scale);
                    scale = scale / 10;
                    // console.log(scale);
                }
                // scale = parseFloat(scale.toFixed(1));
            }

            if (PDFFile.tryPassword) {
                PDFFile.password = PDFFile.tryPassword;
                delete PDFFile.tryPassword;
            }

            this.setState({ PDFFile, scale }, () => {
                let { PDFFiles, PDFFile, multipleFilesQueue } = this.state;
                if (multipleFilesQueue.length > 0) {
                    let nextFileHash = multipleFilesQueue.shift();
                    PDFFile = PDFFiles[nextFileHash];
                    this.setState({ PDFFile, multipleFilesQueue });
                }
            });
        }
        this.setState({ PDFLoadSuccess: true });
    }

    onDocumentLoadError = (pdfObject) => {
        // console.log(pdfObject);
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.load-pdf-fail")}</span>
            </div>
        );
    }

    onDocumentRenderSuccess = (pdfObject) => {
        // console.log(pdfObject);
    }

    onPasswordHandler = (fnCallback) => {

        // let { t } = this.props;
        let { PDFFile, modalPassword, modalPasswordClose, tempPassword } = this.state;

        // console.log(PDFFile);
        if (PDFFile.password) {
            fnCallback(PDFFile.password);
        } else {
            // var PDFFile.tryPassword = prompt("Please enter your name", "Harry Potter");
            modalPassword = true;
            tempPassword = "";
            modalPasswordClose = () => {
                let { PDFFile } = this.state;
                fnCallback(PDFFile.tryPassword);
            }
            this.setState({ modalPassword, tempPassword, modalPasswordClose });
        }
    }

    PDFPreviewScrollHandler = (ev) => {
        // let { PDFFile } = this.state;

        // // console.log(PDFFile);
        // let pageContainer = ev.target.querySelector(".react-pdf__Document");
        // // let percentScroll = ev.target.scrollTop / (pageContainer.offsetHeight - ev.target.offsetHeight);
        // // console.log(percentScroll);
        // let currentPage = null;
        // PDFFile.currentPage = PDFFile.currentPage || 1;
        // for (let i in PDFFile.pages) {
        //     let page = PDFFile.pages[i];
        //     let realTop = page.view.percentage.top / 100 * pageContainer.offsetHeight;
        //     if ((realTop - (ev.target.offsetHeight / 2)) > ev.target.scrollTop) {
        //         break;
        //     }
        //     currentPage = page;
        //     currentPage.pageNumber = i;
        //     PDFFiles[selectedFile].currentPage = parseInt(i);
        //     this.objMain.updateState({ PDFFiles });
        // }
        // var wanted = Object.values(PDFFile.pages).filter((item) => {
        // 	let result = [
        // 		item.view.top >= ev.target.scrollTop
        // 	];
        // 	return !result.includes(false);
        // });
        // console.log(ev.target.scrollTop);
    }

    PDFPages = () => {
        let { PDFFile, showAll, scale } = this.state;
        // console.log("PDFFile.initialDone", PDFFile.initialDone);
        if (!PDFFile.initialDone) {
            return null;
        }
        let Pages = [];

        // console.log(PDFFile);
        for (let i = 0; i < PDFFile.numPages; i++) {
            let active = "";
            // console.log(i + 1, PDFFile.currentPage);
            if (i + 1 === parseInt(PDFFile.currentPage)) {
                active = " active";
            }
            if (showAll) {
                active = " active";
            }

            Pages.push(
                <div key={i} className={`pdf-page-panel${active}`}>
                    {/* <Page pageNumber={i + 1} renderTextLayer={false} scale={previewScale / 100} onDragOver={this.allowDrop} className={"pdf-custom-page scale-" + previewScale}> */}
                    <Page debug={true} key={i} renderMode="canvas" pageNumber={i + 1} renderTextLayer={false} scale={scale} className={`pdf-custom-page scale-${scale}${active}`} />
                </div>
            );
        }

        // console.log("PageRender", Pages);
        return Pages;
    }

    nextPagePreview = (ev) => {
        let { PDFFile } = this.state;
        if (PDFFile.currentPage < PDFFile.numPages) {
            PDFFile.currentPage++;
            this.setState({ PDFFile });
        }
    }

    prevPagePreview = (ev) => {
        let { PDFFile } = this.state;
        if (PDFFile.currentPage > 1) {
            PDFFile.currentPage--;
            this.setState({ PDFFile });
        }
    }

    increasePreviewSize = (ev) => {
        let { scale } = this.state;
        scale += 0.1;
        if (scale > 10) {
            scale = 10;
        }
        scale = parseFloat(scale.toFixed(2));
        this.setState({ scale, autoFit: false });
    }

    decreasePreviewSize = (ev) => {
        let { scale } = this.state;
        scale -= 0.1;
        if (scale < 0.1) {
            scale = 0.1;
        }
        scale = parseFloat(scale.toFixed(2));
        this.setState({ scale, autoFit: false });
    }

    PDFPageNoData = () => {
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.no-data")}</span>
            </div>
        );
    }

    PageNavigate = () => {
        let { showAll, PDFFile, noFile } = this.state;

        if (showAll || noFile || !PDFFile.initialDone) {
            return null;
        }

        let PageInfoLable = `${PDFFile.currentPage || 0}/${PDFFile.numPages || 0}`;
        if (!PDFFile.currentPage || !PDFFile.numPages) {
            PageInfoLable = "Loading...";
        }

        return (
            <div className="page-navigator">
                <button type="button" className="btn-icon" onClick={this.prevPagePreview}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div className="info-label">{PageInfoLable}</div>
                <button type="button" className="btn-icon" onClick={this.nextPagePreview}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        );
    }

    MiniViewAction = () => {
        let { showAll, PDFFiles, PDFFile, noFile, listPanel } = this.state;
        let ButtonList = [];

        if (showAll || noFile || !PDFFile.initialDone || !PDFFiles) {
            return null;
        }

        if (listPanel) {
            ButtonList.push(
                <Tooltip key={0} title="PDF情報を見る" placement="top-end">
                    <button key="show-pdf-info" className="btn-icon show-pdf-info" onClick={(ev) => {
                        this.setState({ infoPanel: true });
                    }}>
                        <InfoIcon />
                    </button>
                </Tooltip>
            );
        }

        ButtonList.push(
            <Tooltip key={1} title="PDFを拡大して表示する" placement="top-end">
                <button key="full-preview" className="btn-icon full-preview" onClick={(ev) => {
                    this.setState({ showFullPreview: true });
                }}>
                    <FullscreenOutlinedIcon />
                </button>
            </Tooltip>
        );

        if (PDFFile.url) {
            ButtonList.push(
                <Tooltip key={2} title="Open in new window" placement="bottom-end">
                    <a key="open-in-new-window" className="btn-icon new-window" target='_blank' rel="noreferrer" href={PDFFile.url}>
                        <OpenInNewIcon />
                    </a>
                </Tooltip>
            );
        }

        return (
            <div key={0} className="mini-view-action-panel">
                {ButtonList}
            </div>
        );
    }

    RegisterFiles = (files, fnCallback) => {
        let { multiple } = this.state;
        for (let file of files) {
            let reader = new FileReader();
            reader.onload = (ev) => {
                // console.log(ev);
                let content = CryptoJS.enc.Latin1.parse(reader.result);
                var fileKey = CryptoJS.MD5(content).toString();
                let { PDFFiles, PDFFile, multipleFilesQueue } = this.state;
                // console.log(PDFFiles);
                if (!multiple) {
                    PDFFiles = {};
                }
                if (!PDFFiles.hasOwnProperty(fileKey)) {
                    PDFFiles[fileKey] = {
                        hash: fileKey,
                        file: file,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                    };
                }
                multipleFilesQueue.push(fileKey);
                PDFFile = PDFFiles[fileKey];
                this.setState({ PDFFiles, PDFFile, noFile: false }, fnCallback);
                // console.log(this.objMain.state.PDFFiles);
            };
            reader.onerror = () => {
                console.log(reader.error);
            };
            reader.readAsBinaryString(file);
            if (!multiple) {
                break;
            }
        }
    }

    InputFileUpload = () => {
        let { multiple } = this.state;

        return (
            <input type="file" name="pdf-upload" id="pdf-upload" accept=".pdf"
                multiple={multiple}
                style={{ display: "none" }}
                onChange={(ev) => {
                    this.RegisterFiles(ev.target.files, () => {
                        let { PDFFile, fileChangeCallback } = this.state;
                        ev.target.value = null;
                        console.log(ev.target.files);
                        if (fileChangeCallback && typeof fileChangeCallback === "function") {
                            fileChangeCallback(PDFFile.hash);
                        }
                    });
                }}
            />
        );
    }

    FileUploadDropZone = (props) => {
        let { allowUpload } = this.state;
        let { InputFileUpload } = this;

        if (!allowUpload) {
            return null;
        }

        let className = `drop-zone-panel ${props.className || ""}`;

        return (
            <div className={className}>

                <label htmlFor="pdf-upload"
                    className="dropbox-area file-dropbox"
                    onDragOver={(ev) => {
                        ev.preventDefault();
                    }}
                    onDrop={(ev) => {
                        ev.preventDefault();
                        let body = ev.target.closest(".pdf-fragment");
                        body.classList.remove("drag-over");
                        let dropPanel = body.querySelector(".file-upload-panel");
                        dropPanel.classList.remove("allow-drop");
                        this.RegisterFiles(ev.dataTransfer.files);
                    }}
                    onDragLeave={(ev) => {
                        ev.preventDefault();
                        let body = ev.target.closest(".pdf-fragment");
                        body.classList.remove("drag-over");
                        let dropPanel = body.querySelector(".file-upload-panel");
                        dropPanel.classList.remove("allow-drop");
                    }}
                >
                    <div className="drop-area-inner">
                        <IconPDFFile />
                    </div>
                    <InputFileUpload />
                </label>
                <label className="info">PDFをアップロード</label>

            </div>
        );
    }

    FileUploadPanel = () => {
        let { noFile, allowUpload } = this.state;
        let { FileUploadDropZone } = this;

        let panelClass = "";
        if (noFile && allowUpload) {
            panelClass = " allow-drop";
        } else if (noFile) {
            panelClass = " nofile";
        }

        if (!allowUpload) {
            return null;
        }

        return (
            <div className={`file-upload-panel${panelClass}`}>

                <FileUploadDropZone />

            </div>
        );
    }

    ActionFormSubmit = (ev) => {
        let { submitCB } = this.props;
        let { PDFFiles } = this.state;
        if (typeof submitCB === "function") {
            submitCB(ev, PDFFiles);
        }
    }

    ButtonFileUpload = () => {
        let { t } = this.props;
        let { allowUpload } = this.state;
        let { InputFileUpload } = this;

        if (!allowUpload) {
            return null;
        }

        return (
            <div className="button-file-upload">
                <label htmlFor="pdf-upload">
                    <span>{t("common:pdf-viewer.upload-new-file")}</span>
                    <InputFileUpload />
                </label>
            </div>
        );

    }

    ButtonPDFFileInfo = () => {
        let { multiple } = this.state;

        if (!multiple) {
            return false;
        }

        return (
            <div className="pdf-file-info">
                <div className="btn-info" onClick={(ev) => {
                    let { infoPanel } = this.state;
                    this.setState({ infoPanel: !infoPanel });
                }}>
                    <FontAwesomeIcon icon={faInfo} />
                </div>
            </div>
        );
    }

    ActionController = () => {
        let { formName, children } = this.props;
        let { controller, PDFFile } = this.state;
        let { ButtonFileUpload, ButtonPDFFileInfo } = this;

        if (!controller) {
            return null;
        }

        let PageInfo = "Loading..."
        if (PDFFile.initialDone) {
            PageInfo = `${PDFFile.currentPage}/${PDFFile.numPages}`;
        }

        return (
            <div className="control-container">

                <ButtonPDFFileInfo />

                <div className="view-size-controller">
                    <div className="btn size-decrease" onClick={this.decreasePreviewSize}>
                        <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div className="btn size-increase" onClick={this.increasePreviewSize}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>

                <ButtonFileUpload />

                <div className="page-index-info">
                    <span className="info-label">{PageInfo}</span>
                </div>

                <div className="child-element">
                    {/* {ImprintStampController} */}
                    <Form id={formName} onSubmit={this.ActionFormSubmit}>
                        {children}
                    </Form>
                </div>

            </div>
        );
    }

    checkFileSize = (value) => {
        if (!value) {
            return value;
        }
        // console.log(value);
        let unit = "bytes";
        if (value > 1024) {
            value = value / 1024;
            unit = "KB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "MB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "GB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "TB";
        }
        // console.log(value);

        value = parseFloat(value.toFixed(3));
        return `${value} ${unit}`;
    }

    OptionalPanel = () => {
        let { infoPanel, listPanel, noFile } = this.state;
        let { PDFInfo, FileListSelector, FileUploadDropZone } = this;

        if (noFile || (!infoPanel && !listPanel)) {
            return false;
        }

        return (
            <div className={`pdf-info-section file-panel${listPanel ? " slide" : ""}`}>
                <FileUploadDropZone className="file-drop-zone" />
                <FileListSelector />
                <PDFInfo />
            </div>
        );
    }

    FileListRemove = (props) => {
        // let { key } = props;
        let { allowUpload } = this.state;

        if (!allowUpload) {
            return null;
        }

        return (
            <button type="button" className="btn-icon file-remove" onClick={(ev) => {
                let { PDFFiles, PDFFile, noFile, fileChangeCallback, fileRemoveCallback } = this.state;
                let key = ev.target.closest(".file-item").dataset.file;
                delete PDFFiles[key];
                if (key === PDFFile.hash) {
                    PDFFile = {};
                    if (Object.keys(PDFFiles).length > 0) {
                        PDFFile = Object.values(PDFFiles)[0];
                        PDFFile.initialDone = false;
                    } else {
                        noFile = true;
                    }
                    this.setState({ PDFFile, noFile }, () => {
                        if (fileChangeCallback && typeof fileChangeCallback === "function") {
                            fileChangeCallback(PDFFile.hash);
                        }
                    });
                }
                this.setState({ PDFFiles }, () => {
                    if (fileRemoveCallback && typeof fileRemoveCallback === "function") {
                        fileRemoveCallback(key);
                    }
                });
            }}>
                <CancelOutlinedIcon name="delete" />
            </button>
        );
    }

    FileListSelector = () => {
        let { PDFFiles, PDFFile, listPanel } = this.state;
        let { FileListRemove } = this;
        let fileKeys = Object.keys(PDFFiles);
        // console.log(PDFFiles, PDFFile);
        if (!listPanel || !PDFFiles || fileKeys.length === 0) {
            return null;
        }

        let FileItems = fileKeys.map((key) => {
            let file = PDFFiles[key];
            let classlist = ["file-item"];
            if (key === PDFFile.hash) {
                classlist.push("active");
            }
            // console.log(key, PDFFile.hash);

            return (
                <li key={key} data-file={key} className={classlist.join(" ")}>
                    <span className="file-name" title={file.name} onClick={(ev) => {
                        let { PDFFiles, PDFFile, fileChangeCallback } = this.state;
                        // console.log(key, PDFFile.hash);
                        if (key !== PDFFile.hash) {
                            PDFFile = PDFFiles[key];
                            PDFFile.initialDone = false;
                            if (PDFFile.url) {
                                PDFFile.file = PDFFile.url;
                            }
                            this.setState({ PDFFile }, () => {
                                if (fileChangeCallback && typeof fileChangeCallback === "function") {
                                    fileChangeCallback(PDFFile.hash);
                                }
                            });
                        }
                        // this.onChangeFile(ev, key);
                    }}>{file.name}</span>
                    <FileListRemove hash={key} />
                </li>
            );
        });

        return (
            <div className="file-upload-list">
                <ul>
                    {FileItems}
                </ul>
            </div>
        );
    }

    PDFInfo = () => {
        let { t } = this.props;
        let { PDFFile, infoPanel, listPanel } = this.state;

        // if (!infoPanel || !PDFFile.initialDone) {
        if (!PDFFile.initialDone) {
            return null;
        }

        // console.log(PDFFile);

        let paperW = PDFFile.pages[1].view.width / 72;
        let paperH = PDFFile.pages[1].view.height / 72;
        paperW = parseFloat(paperW.toFixed(2));
        paperH = parseFloat(paperH.toFixed(2));
        let layout = "portrait";
        if (paperW > paperH) {
            layout = "landscape";
        }

        let PaperSize = `${paperW} × ${paperH} in (${layout})`

        let infoConfig = [
            {
                label: "file-name",
                value: PDFFile.name,
            },
            {
                label: "file-size",
                value: this.checkFileSize(PDFFile.size),
            },
            "separator",
            {
                label: "title",
                value: PDFFile.info.Title,
            },
            {
                label: "author",
                value: PDFFile.info.Author,
            },
            {
                label: "subject",
                value: PDFFile.info.Subject,
            },
            {
                label: "keywords",
                value: "KEYWORD",
            },
            {
                label: "creation-date",
                value: PDFFile.info.CreateDate,
            },
            {
                label: "modification-date",
                value: PDFFile.info.ModifyDate,
            },
            {
                label: "creator",
                value: PDFFile.info.Creator,
            },
            "separator",
            {
                label: "pdf-producer",
                value: PDFFile.info.Producer,
            },
            {
                label: "pdf-version",
                value: PDFFile.info.PDFFormatVersion,
            },
            {
                label: "page-count",
                value: PDFFile.numPages,
            },
            {
                label: "page-size",
                value: PaperSize,
            },
            "separator",
            {
                label: "fast-web-view",
                value: PDFFile.info.IsLinearized ? "Yes" : "No",
            },
            "separator",

        ];

        let ScanedInfo = t("common:general.not-available");
        if (PDFFile.pages[PDFFile.currentPage].scan) {
            let scanInfo = PDFFile.pages[PDFFile.currentPage].scan;
            ScanedInfo = `DPI: ${scanInfo.dpi} (${scanInfo.width}px x ${scanInfo.height}px)`;
        }

        infoConfig.push({
            label: "scaned-info",
            value: ScanedInfo,
        });

        // console.log(PDFFile.info);



        let InfoItems = [];
        for (let i in infoConfig) {
            let info = infoConfig[i];
            if (typeof info === "string") {
                InfoItems.push(<div key={i} className={info}></div>);
            } else {
                InfoItems.push(
                    <li key={i}>
                        <span className="label">{t(`common:pdf-viewer.pdf-info.${info.label}`)}</span>
                        <ul>
                            <li className="value" name="fileNameField">{info.value}</li>
                        </ul>
                    </li>
                );
            }
        }

        let { docInfo } = this.state;
        let CustomMetadata = PDFFile.info.Custom;

        if (docInfo) {
            CustomMetadata = {};
            if ([1, 2, 3, 4].includes(docInfo.function_type_id)) {
                CustomMetadata.Amount = docInfo.amount;
                CustomMetadata.Currency = docInfo.currency || "-";
                if (docInfo.function_type_id === 4) {
                    CustomMetadata.ReceivedDate = docInfo.received_date || "-";
                    CustomMetadata.CounterPartyName = docInfo.counter_party_name || "-";
                } else {
                    CustomMetadata.ContractDate = docInfo.contract_date || "-";
                    CustomMetadata.ExpiryDate = docInfo.expiry_date || "-";
                }
                CustomMetadata.ProductName = docInfo.product_name || "-";
                CustomMetadata.RefDocument = docInfo.reference || "-";
            }

            for (let cusKey in docInfo.attributes) {
                CustomMetadata[cusKey] = docInfo.attributes[cusKey] || "-";
            }
        }

        if (CustomMetadata) {
            InfoItems.push(<div key="custom-separator" className="separator"></div>);
            for (let i in CustomMetadata) {
                let customValue = CustomMetadata[i];
                if (typeof customValue !== "string") {
                    if (customValue.hasOwnProperty("name")) {
                        customValue = customValue.name;
                    } else {
                        customValue = null;
                    }
                }
                if (customValue) {
                    InfoItems.push(
                        <li key={`custom-${i}`}>
                            <span className="label">{i}</span>
                            <ul>
                                <li className="value" name="fileNameField">{customValue}</li>
                            </ul>
                        </li>
                    );
                }
            }
        }
        // console.log(InfoItems);

        let InfoHeaderPanel = (ev) => {
            if (!listPanel) {
                return null;
            }

            return (
                <div className="pdf-info-header separator">
                    <span>{t("common:pdf-viewer.pdf-info-header")}</span>
                    <button className="btn-icon pdf-info-close" onClick={(ev) => {
                        this.setState({ infoPanel: false });
                    }}>
                        <CloseOutlinedIcon />
                    </button>
                </div>
            );
        }

        return (
            <div className={`pdf-properties${infoPanel ? " active" : ""}`}>
                <InfoHeaderPanel />
                {InfoItems}
            </div >
        );
    }

    allowDropHandler = (ev) => {
        let { allowUpload } = this.state;

        if (allowUpload) {
            let body = ev.target.closest(".pdf-fragment");
            body.classList.add("drag-over");
            let dropPanel = body.querySelector(".file-upload-panel");
            dropPanel.classList.add("allow-drop");
        }
    };

    render() {
        let { t } = this.props;
        let {
            PDFFiles,
            PDFFile,
            showAll,
            noFile,
            showFullPreview,
            fullPreview,
            modalPassword,
        } = this.state;
        let {
            PDFPages,
            PDFPageNoData,
            MiniViewAction,
            PageNavigate,
            FileUploadPanel,
            ActionController,
            OptionalPanel,
        } = this;
        // console.log(PDFFiles);
        // console.log(PDFFile);
        // console.log(noFile);
        let pdfFullPreview = "";
        if (showAll) {
            pdfFullPreview = " pdf-full-preview";
        }

        let noFileClass = "";
        if (noFile) {
            noFileClass = " no-file";
        }

        let pdfMainProps = {};
        if (!fullPreview) {
            pdfMainProps.id = "pdf_main_preview";
        }

        return [
            <div key="main-preview" {...pdfMainProps} className={`pdf-fragment${pdfFullPreview}`}
                onDragEnter={this.allowDropHandler}
            >
                <div className={`pdf-preview-section${noFileClass}`}>
                    <div className="pdf-preview-panel" onScroll={this.PDFPreviewScrollHandler}>
                        <Document file={PDFFile.file}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={this.onDocumentLoadError}
                            onRenderSuccess={this.onDocumentRenderSuccess}
                            onPassword={this.onPasswordHandler}
                            options={this.pdfDocOptions}
                            loading={Loading}
                            noData={PDFPageNoData}
                        >
                            <PDFPages />
                        </Document>
                    </div>

                    <div className="pdf-controller-panel">
                        <ActionController />
                        <MiniViewAction />
                        <PageNavigate />
                        <FileUploadPanel />
                    </div>

                </div>

                <OptionalPanel />


            </div>,
            <FullPreview key="full-preview" file={PDFFile} files={PDFFiles} active={showFullPreview} onClose={(ev) => {
                this.setState({ showFullPreview: false });
            }} />,
            <Modal key="modal-password" show={modalPassword} backdrop="static" animation={false} onHide={this.state.modalPasswordClose} >
                <Form name="pdf-password-form">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>{t("common:documents.general.password-required")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password" className={this.state.incorrectPassword}>
                                <Form.Label>{t('common:documents.general.pdf-password')}</Form.Label>
                                <Form.Control
                                    name='pdf-password'
                                    type='password'
                                    placeholder={t('common:documents.general.pdf-password')}
                                    onChange={(ev) => {
                                        this.setState({ tempPassword: ev.target.value });
                                    }}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('common:message.input.incorrect-password')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={(ev) => {
                            let {
                                PDFFile,
                                // tempPassword,
                                modalPassword,
                                incorrectPassword,
                                noFile
                            } = this.state;
                            // tempPassword = "";
                            modalPassword = false;
                            incorrectPassword = "";
                            if (!PDFFile.password) {
                                delete PDFFiles[PDFFile.hash];
                                PDFFile = { initialDone: false };
                                if (Object.keys(PDFFiles).length > 0) {
                                    PDFFile = Object.keys(PDFFiles)[0];
                                } else {
                                    noFile = true;
                                }
                            }
                            this.setState({ PDFFiles, PDFFile, modalPassword, incorrectPassword, noFile });
                        }}>
                            <span>{t("common:general.close")}</span>
                        </Button>

                        <Button key="ok" variant="primary" onClick={(ev) => {
                            // this.state.incorrectPassword = "was-validated";
                            // this.state.modalPassword = false;
                            let { PDFFile, tempPassword, modalPassword, incorrectPassword } = this.state;
                            PDFFile.tryPassword = tempPassword;
                            tempPassword = "";
                            modalPassword = false;
                            incorrectPassword = "was-validated";
                            this.setState({ PDFFile, tempPassword, modalPassword, incorrectPassword }, this.state.modalPasswordClose);
                            // fnCallback(tempPDFPassword);
                            // this.objMain.modalToggle(false);
                        }}>
                            <span>{t("common:general.ok")}</span>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        ];

    }

}

export default withTranslation()(Main);
