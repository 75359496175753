// import PDFViewer from "../Fragments/PDFViewer";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
	Form,
	Button,
	Col,
	Table,
	InputGroup,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
// import CryptoJS from 'crypto-js';
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import FileViewer from 'react-file-viewer';
import { readString } from 'react-papaparse';
import MuiButton from '@mui/material/Button';
// import CsvViewer from "react-csv-viewer";
// import "react-table/react-table.css";
// import ReactTable from "react-table";
import Loading from '../Loading';
import FileExplorer from '../Fragments/FileExplorer';
import Service from "../../Service";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorCircleIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

// import { CSVReader } from 'react-papaparse'


export default class TemplateData {

	static get key() {
		return "template-data";
	}
	static get name() {
		return "TemplateData";
	}
	static get code() {
		return TemplateData.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = TemplateData.key;
		this.name = TemplateData.name;
		this.code = TemplateData.code;

		this.formData = {
			csv_data: "csv-data",
			csv_file: "csv-file",
			csv_name: "csv-name",
			temp_row_index: "common:documents.document-info.row-index",
			// temp_document_type: "common:documents.document-info.document-type",
			// temp_doc_title: "common:documents.document-info.doc-title",
			// temp_amount: "common:documents.document-info.amount",
			// temp_currency: "common:documents.document-info.currency",
			// temp_sign_level: "common:documents.document-info.sign-level",
			// temp_display_imprint: "common:documents.document-info.display-imprint",
			// temp_contract_date: "common:documents.document-info.contract-date",
			// temp_expiry_date: "common:documents.document-info.expiry-date",
			// temp_document_create_date: "common:documents.document-info.document-create-date",
			// temp_received_date: "common:documents.document-info.received-date",
			// temp_counter_party_name: "common:documents.document-info.counter-party-name",
			// temp_product: "common:documents.document-info.product",
			// temp_reference: "common:documents.document-info.reference",
			// temp_filebox_shortcut: "common:documents.document-info.shortcut-to-filebox",
			// temp_doc_attributes: "common:documents.document-info.attributes",
		};

		// {
		// 	code: "display_imprint",
		// 	label: "common:documents.document-info.display-imprint",
		// 	attributes: { as: "select", md: 12, required: true, validate: "true" },
		// },

		this.objMain.state.formStructure = {
			document_type: {
				code: "document_type",
				label: "common:documents.document-info.document-type",
				type: "int",
				attributes: { as: "select", md: 12, required: true, children: this.DocumentTypeItems },
			},
			preservation_require: {
				code: "preservation_require",
				label: "common:documents.document-info.preservation-require",
				type: "int",
				attributes: { as: "select", md: 12, required: true, children: this.PreservationRequireItems },
			},
			doc_title: {
				code: "doc_title",
				label: "common:documents.document-info.doc-title",
				attributes: { md: 12, required: true, validate: "true" },
			},
			amount: {
				code: "amount",
				label: "common:documents.document-info.amount",
				type: "float",
				attributes: { type: "number", step: "0.01", xs: 8, md: 8, required: true, validate: "true" },
				defaultValue: 0,
			},
			currency: {
				code: "currency",
				label: "common:documents.document-info.currency",
				attributes: { as: "select", xs: 4, md: 4, required: true, children: this.CurrencyItems },
				defaultValue: "JPY",
			},
			//sign_level: {
			//	code: "sign_level",
			//	label: "common:documents.document-info.sign-level",
			//	attributes: { as: "select", md: 12, required: true, children: this.SignLevelItems, change: this.SignLevelCallback },
			//	defaultValue: 0,
			//},
			host_cert_type: {
				code: "host_cert_type",
				label: "common:documents.host-setting.certificate-type",
				type: "int",
				attributes: {
					as: "select", md: 12, required: true, children: () => {
						return this.CertificateTypeItems('host');
					}
				},
				defaultValue: 0,
			},
			guest_cert_type: {
				code: "guest_cert_type",
				label: "common:documents.guest-setting.certificate-type",
				type: "int",
				attributes: {
					as: "select", md: 12, required: true, children: () => {
						return this.CertificateTypeItems('guest');
					}
				},
				defaultValue: 0,
			},
			// certificate_type: {
			// 	code: "certificate_type",
			// 	label: "common:documents.host-setting.certificate-type",
			// 	attributes: {
			// 		as: "select", md: 12, required: true, children: this.CertificateTypeItems, change: () => {
			// 			let { formData } = this.objMain.state;
			// 			if (formData.temp_certificate_type.toString() === "0") {
			// 				formData.sign_level = 0;
			// 			} else {
			// 				formData.sign_level = 2;
			// 			}
			// 			console.log(this.objMain.state.formData);
			// 		}
			// 	},
			// 	defaultValue: 0,
			// },
			contract_date: {
				code: "contract_date",
				label: "common:documents.document-info.contract-date",
				attributes: { type: "date", md: 12 },
				type: "date",
			},
			expiry_date: {
				code: "expiry_date",
				label: "common:documents.document-info.expiry-date",
				attributes: { type: "date", md: 12 },
				type: "date",
			},
			document_create_date: {
				code: "document_create_date",
				label: "common:documents.document-info.document-create-date",
				attributes: { type: "date", md: 12 },
				type: "date",
			},
			received_date: {
				code: "received_date",
				// label: "common:documents.document-info.received-date",
				label: "common:documents.document-info.deal-date",
				attributes: { type: "date", md: 12 },
				type: "date",
			},
			effective_date: {
				code: "effective_date",
				label: "common:documents.document-info.effective-date",
				attributes: { type: "date", md: 12 },
				type: "date",
			},
			// sign_finish_date: {
			// 	code: "sign_finish_date",
			// 	label: "common:documents.document-info.sign-finish-date",
			// 	attributes: { type: "date", md: 12 },
			// 	type: "date",
			// },
			product: {
				code: "product",
				label: "common:documents.document-info.product",
				attributes: { md: 12 },
			},
			reference: {
				code: "reference",
				label: "common:documents.document-info.reference",
				attributes: { md: 12 },
			},
			filebox_shortcut: {
				code: "filebox_shortcut",
				label: "common:documents.document-info.shortcut-to-filebox",
				attributes: {
					md: 12, readOnly: true,
					className: "filebox-shortcut-path",
					prepend: () => {
						let { t } = this.props;
						return (
							<InputGroup.Prepend className="">
								<Button variant="secondary" className="input-group-child" onClick={this.showFileboxPathSelection}>
									<span>{t("common:documents.document-info.filebox-path-select-button")}</span>
								</Button>
							</InputGroup.Prepend>
						)
					}
				},
			},
			signers: {
				code: "signers",
				label: "common:documents.host-setting.signers",
				attributes: { md: 12 },
				defaultValue: "",
			},
			guests: {
				code: "guests",
				label: "common:documents.guest-setting.guests",
				attributes: { md: 12 },
				defaultValue: "",
			},
			viewers: {
				code: "viewers",
				label: "common:documents.viewer-setting.viewers",
				attributes: { md: 12 },
				defaultValue: "",
			},
			// doc_attributes: {
			// 	code: "doc_attributes",
			// 	label: "common:documents.document-info.attributes",
			// 	attributes: { as: "select", md: 12, required: true, validate: "true" },
			// },

		};

		let { creationType } = this.objMain.state;
		if (creationType === "sender") {
			delete this.objMain.state.formStructure["sign_level"];
			delete this.objMain.state.formStructure["effective_date"];
			delete this.objMain.state.formStructure["guest_cert_type"];
		} else if (creationType === "circle") {
			delete this.objMain.state.formStructure["amount"];
			delete this.objMain.state.formStructure["currency"];
			delete this.objMain.state.formStructure["product"];
			delete this.objMain.state.formStructure["reference"];
		} else if (creationType === "verification") {
			delete this.objMain.state.formStructure["sign_level"];
			delete this.objMain.state.formStructure["guests"];
		}

		if (creationType !== "verification") {
			delete this.objMain.state.formStructure["preservation_require"];
			delete this.objMain.state.formStructure["received_date"];
		}

		if (creationType !== "signer") {
			delete this.objMain.state.formStructure["contract_date"];
			delete this.objMain.state.formStructure["expiry_date"];
		}

		if (creationType !== "circle") {
			delete this.objMain.state.formStructure["document_create_date"];
		}

		let { DocKeys } = this.objMain.state;
		// console.log(DocKeys);
		if (DocKeys) {
			for (let i in DocKeys) {
				this.objMain.state.formStructure[DocKeys[i].name] = {
					code: DocKeys[i].name,
					label: DocKeys[i].name,
					attributes: { md: 12, required: DocKeys[i].required, validate: DocKeys[i].required ? "true" : "false" },
					docKey: DocKeys[i],
				};
			}
		}

		let mainFormData = this.objMain.state.formData;
		//console.log(mainFormData);
		for (let key in this.objMain.state.formStructure) {
			let field = this.objMain.state.formStructure[key];
			this.formData[`temp_${key}`] = field.label
			if (!field.hasOwnProperty("defaultValue")) {
				field.defaultValue = mainFormData[field.code] || "";
			}
			// console.log(key, field.defaultValue);
			// if (typeof mainFormData[field.code] === "object") {
			// 	mainFormData[field.code]
			// }
		}

		if (!this.objMain.state.formRecords) {
			this.objMain.state.formRecords = [];
		}

		this.objMain.setPageObject(this);
	}

	ReadCSVData = (file) => {
		let { t } = this.props;
		let { formData } = this;
		let { modal } = this.objMain.state;
		modal.props.show = false;
		if (file) {
			let reader = new FileReader();
			reader.onload = (ev) => {
				//console.log(reader.result);
				readString(reader.result, {
					worker: true,
					header: true,
					dynamicTyping: true,
					skipEmptyLines: "greedy",
					complete: (results) => {
						//console.log(results.meta.delimiter);
						let CSVPapa = results;
						let CSVFile = file;
						let CSVFileURL = URL.createObjectURL(file);
						let CSVData = results.data;
						formData["csv_file"] = file;
						formData["csv_name"] = file.name;
						// console.log(CSVData);
						// console.log("results.data", results.data);

						let { formRecords, formStructure } = this.objMain.state;
						for (let data of CSVData) {
							let record = {};
							record.warning = [];
							for (let key in formStructure) {
								let field = formStructure[key];
								if (data.hasOwnProperty(field.code)) {
									record[field.code] = data[field.code];
								} else if (data.hasOwnProperty(t(field.label))) {
									record[field.code] = data[t(field.label)];
								} else {
									record[field.code] = field.defaultValue;
								}

								if (record[field.code] && field.type === "date") {
									if ((/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/).test(record[field.code])) {
										record[field.code] = new Date(record[field.code]).toISOString().substring(0, 10);
									} else {
										record.warning.push({
											"error": "invalid-input-date-format",
											"message": t("common:message.errors.invalid-import-date-format", { value: record[field.code], format: "YYYY-MM-DD" }),
											"field": field.code,
											"value": record[field.code],
											"format": "YYYY-MM-DD",
										})
										record[field.code] = null;
									}
								}

								if ([null, undefined].includes(record[field.code])) {
									record[field.code] = "";
								}

								if (typeof record[field.code] === "string") {
									if (field.type === "float") {
										record[field.code] = parseFloat(record[field.code].replace(/,/g, ''));
									} else if (field.type === "int") {
										record[field.code] = parseInt(record[field.code].replace(/,/g, ''));
									}
								}
							}
							formRecords.push(record);
						}
						console.log(formRecords);
						this.objMain.updateState({ CSVFile, CSVFileURL, CSVData, CSVPapa, formData, modal, formRecords, newRowActive: false });
					}
				});
			};
			reader.onerror = () => {
				//(reader.error);
			};
			reader.readAsText(file);
		} else {
			formData["csv_file"] = null;
			formData["csv_name"] = null;
			this.objMain.updateState({ CSVFile: null, CSVFileURL: null, CSVData: null, CSVPapa: null, formData, modal });
		}
	}

	formSubmitValidation = (ev, PDFFiles) => {
		ev.preventDefault();

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState, PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	// formSubmitCallback = (ev) => {
	// 	console.log(ev);
	// 	let { pageState } = this.objMain.state;
	// 	pageState[this.name].completed = true;
	// 	this.objMain.setFormData(this.formData);
	// 	this.objMain.updateState({ pageState: pageState });
	// 	this.objMain.nextPageStep(ev, this.constructor);
	// }

	FileUploadPanel = (props) => {
		// let { allowUpload } = this.state;
		// let { InputFileUpload } = this;
		let { DocFile } = this.objMain.state;
		let { SearchDocKeys } = this;

		// if (!allowUpload) {
		// 	return null;
		// }

		// let className = `drop-zone-panel ${props.className || ""}`;

		return (
			<div className={`file-upload-panel${DocFile ? "" : " no-file"}`}>

				<div className={`drop-zone-panel`}>

					<label htmlFor="pdf-upload"
						className="dropbox-area file-dropbox"
						onDragOver={(ev) => {
							ev.preventDefault();
						}}
						onDrop={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
							// this.RegisterFiles(ev.dataTransfer.files);
						}}
						onDragLeave={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
						}}
					>
						<div className="drop-area-inner">
							{/* <IconPDFFile /> */}
							<FontAwesomeIcon icon={faFileWord} />
						</div>
						{/* <InputFileUpload /> */}
						<input type="file" name="pdf-upload" id="pdf-upload" accept=".doc,.docx"
							style={{ display: "none" }}
							onChange={(ev) => {
								let DocFile = ev.target.files[0];
								let DocFileURL = URL.createObjectURL(DocFile);
								this.objMain.updateState({ DocFile, DocFileURL });
								SearchDocKeys();
								// this.RegisterFiles(ev.target.files, () => {
								// 	ev.target.value = null;
								// 	// console.log(ev.target.files);
								// });
							}}
						/>
					</label>
					<label className="info">PDFをアップロード</label>

				</div>

			</div>
		);
	}

	DocFilePreview = (props) => {
		let { DocFileURL } = this.objMain.state;

		if (!DocFileURL) {
			return null;
		}

		return (
			<FileViewer
				fileType={"docx"}
				// errorComponent={CustomErrorComponent}
				// onError={this.onError}
				// onSuccess={(ev) => {
				// 	console.log(ev);
				// }}
				filePath={DocFileURL}
			/>
		);
	}

	SearchDocKeys = () => {
		// var count = 0;
		var findInterval = setInterval(() => {
			// count++;
			let docText = document.querySelector("#docx");
			// console.log(count, docText);
			if (docText && docText.innerText) {
				// console.log(docText.innerText);
				let DocKeys = docText.innerText.match(/{\$:.*?}/g);
				//console.log(DocKeys);
				this.objMain.updateState({ DocKeys });
				clearInterval(findInterval);
			}
		}, 0);
	}

	CSVDataRender = () => {
		let {
			CSVFile,
			// CSVFileURL,
			CSVData,
			CSVPapa
		} = this.objMain.state;
		// let { DocFilePreview, SearchDocKeys } = this;

		if (!CSVFile) {
			return null;
		}

		// let CSVColumns = CSVPapa.meta.fields.map(column => {
		// 	return { Header: column, accessor: column };
		// });

		// console.log(CSVData);
		// console.log(CSVPapa);
		// let headers=[];
		// for(let col of CSVPapa.meta.fields){
		// 	headers.push(col);
		// }
		let headers = CSVPapa.meta.fields.map(column => {
			return <th>{column}</th>;
		});

		let details = CSVData.map(record => {
			let data = [];
			for (let i in CSVPapa.meta.fields) {
				let col = CSVPapa.meta.fields[i];
				if (record[col]) {
					data.push(<td key={i}>{record[col]}</td>);
				} else {
					data.push(<td key={i}>&nbsp;</td>);
				}
			}
			return (
				<tr>
					{data}
				</tr>
			);
		});

		return (
			<div className="ReactTable">
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							{headers}
						</tr>
					</thead>
					<tbody>
						{details}
					</tbody>
				</Table>
			</div>
		);
	}

	ImportCSVFile = (ev) => {
		let { t } = this.props;
		let { modal, formValidate } = this.objMain.state;
		let { formData } = this;

		modal.props = {
			show: true,
			centered: true,
			size: "lg",
		};

		modal.title = t('common:documents.template.csv-import-header');

		modal.form = {
			id: "csv-import-form",
			onSubmit: (ev) => {
				//console.log(ev);
				//console.log(ev.target.csv_file_import.files);

				// let { formData } = this.objMain.state;
				ev.preventDefault();
				ev.stopPropagation();
				this.ReadCSVData(ev.target.csv_file_import.files[0]);
				// return false;
			},
			noValidate: true,
		};

		modal.action = [(
			<Button key="ok" type="submit" variant="primary">
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		modal.body = () => {
			return (
				<Form.Row>
					<Form.Group as={Col} className="form-control-file">
						<Form.Label>{t("common:documents.template.csv-file")}</Form.Label>
						<Form.File
							id="csv_file_import"
							name="csv_file_import"
							label={formData['csv_data'] || t("common:documents.template.csv-file")}
							custom
						>
							<Form.File.Input
								isValid={formData["csv_data"] && formValidate[this.name]}
								isInvalid={!formData["csv_data"] && formValidate[this.name]}
								required={false}
								onChange={(ev) => {
									if (ev.target.files[0]) {
										let { formData } = this;
										formData["csv_name"] = ev.target.files[0].name;
										this.objMain.updateState({ formData });
									}
								}}
								accept="text/csv, .csv, application/vnd.ms-excel"
							/>
							<Form.File.Label>
								{formData['csv_name'] || t("common:documents.template.csv-file")}
							</Form.File.Label>
							<Form.Control.Feedback type="invalid">
								{/* <ValidateMessage field={fields["cert_file_name"]} /> */}
							</Form.Control.Feedback>
						</Form.File>
					</Form.Group>
				</Form.Row>
			);
		};

		this.objMain.updateState({ modal });
	}

	CertificateTypeItems = (certType) => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { cert_types } = PageData;
		// let { formData } = this;

		let CertTypeItems = [];
		CertTypeItems.push(<option key={"empty"} value="">---</option>);
		CertTypeItems.push(<option key={"not-use"} value={0}>{t("common:documents.host-setting.options-not-use")}</option>);

		for (let i in cert_types) {

			let cert_type = cert_types[i];

			if (certType === "host" || cert_type.id !== 2) {
				CertTypeItems.push(<option key={cert_type.id} value={cert_type.id}>{t(`common:documents.host-setting.options-${cert_type.label}`)}</option>);
			}

		}


		return CertTypeItems;
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { doc_types } = PageData;

		let ItemElements = [];
		ItemElements.push(<option key="empty" value="">---</option>);
		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	PreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	CurrencyItems = () => {
		let { PageData } = this.objMain.state;
		let { currencies } = PageData;
		let OptionItems = [];
		OptionItems.push(<option key="empty" value="">---</option>);
		for (let i in currencies) {
			let currency = currencies[i];
			OptionItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}
		return OptionItems;
	}

	SignLevelItems = () => {
		let { t } = this.props;
		let items = {
			0: "soft",
			// 1: "mixed",
			2: "strong",
		}

		let ItemElements = [];
		for (let i in items) {
			ItemElements.push(<option key={i} value={i}>{t(`common:documents.document-info.sign-level-${items[i]}`)}</option>);
		}

		return ItemElements;
	}

	CounterPartyList = () => {
		let { counterPartyNameList } = this.objMain.state;

		let row = [];

		for (let i = 0; i < counterPartyNameList.length; i++) {
			let counterPartyName = counterPartyNameList[i];

			row.push(
				<option key={counterPartyName} value={counterPartyName} />
			);
		}
		return (
			<datalist id="counter-party">
				{row}
			</datalist>
		);
	}

	SignLevelCallback = () => {
		let { formData } = this.objMain.state;

		console.log(formData.sign_level);
		if (formData.temp_sign_level.toString() === "0") {
			formData.temp_use_digital_certificate = false;
		} else if (formData.temp_sign_level.toString() === "2") {
			formData.temp_use_digital_certificate = true;
		}
		// console.log(formData.use_digital_certificate);
		this.objMain.setFormData(formData);
	}

	showFileboxPathSelection = async (ev) => {
		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.objMain.state;
		modalProps = {
			"show": true,
			// "aria-labelledby": "contained-modal-title-vcenter",
			"dialogClassName": "modal-full zero-padding",
			"centered": true,
		}
		modalTitle = "common:documents.document-info.filebox-select-path";
		ModalAction = [];
		ModalBody = Loading;
		ModalAction.push(
			<Button key="ok" variant="primary" onClick={(ev) => {
				// this.autoAddImprints(ev);
				this.formData.temp_filebox_shortcut = this.tempSelectedFilebox;
				this.objMain.setFormData(this.formData);
				this.objMain.modalToggle(false);
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);
		this.objMain.updateState({ modalTitle, ModalBody, ModalAction, modalProps });
		this.fileBoxSelectionBody({
			path: "/",
			type: "D",
		});

	}

	fileBoxSelectionCallback = (resp) => {
		// console.log(resp);
		this.tempSelectedFilebox = resp;
	}

	fileBoxSelectionBody = (options) => {
		let { ModalBody } = this.objMain.state;

		ModalBody = () => {
			return <FileExplorer type="D" readOnly={true} responseCallback={this.fileBoxSelectionCallback} />;
		};
		this.objMain.updateState({ ModalBody });
		// this.setState({ FileboxStorage });
	}

	DataWarning = (props) => {
		return <WarningIcon sx={{ color: "#ffc107" }} />
	}

	RowValidate = (props) => {
		// let { t } = this.props;
		let { formStructure } = this.objMain.state;
		let { record } = props;

		if (record.error) {
			return <CheckCircleIcon color="error" />
		}

		let validate = true;
		for (let key in formStructure) {
			if ([true, "true"].includes(formStructure[key].attributes.required)) {
				if ([null, undefined, ""].includes(record[key])) {
					validate = false;
					break;
				}
			}
		}

		if (record.error) {
			validate = false;
		}

		if (validate) {
			if (record.warning && (record.warning instanceof Array) && record.warning.length > 0) {
				return <WarningIcon sx={{ color: "#ffc107" }} />
			} else {
				return <CheckCircleIcon color="success" />
			}
		} else {
			return <ErrorCircleIcon color="error" />
		}
	}

	RenderRecordDetail = () => {
		let { t } = this.props;
		let { FieldControl } = this.objMain;
		let { formStructure, formData } = this.objMain.state;
		// console.log(formStructure);
		let Fields = [];

		if (formData.temp_error) {
			Fields.push(
				<span style={{ color: "red" }}>{t(`common:${formData.temp_error}`)}</span>
			);
		}

		let warning_fields = {};
		if (formData.temp_warning && (formData.temp_warning instanceof Array) && formData.temp_warning.length > 0) {
			for (let warning of formData.temp_warning) {
				warning_fields[warning.field] = {
					"className": "warning",
					"value": warning.message,
				}
				console.log(warning);
			}
			console.log(warning_fields);
		}

		for (let key in formStructure) {
			let field = formStructure[key];
			if (field.attributes.hasOwnProperty("children")) {
				console.log(typeof field.attributes.children);
				if (typeof field.attributes.children === "function") {
					field.attributes.children = field.attributes.children();
				}
			}

			let fieldElement = FieldControl({ key: field.code, name: `temp_${field.code}`, optionLabel: warning_fields[field.code], ...field.attributes });
			Fields.push(fieldElement);
		}

		return (
			<Form.Row className="record-form-detail-body form-body">
				{Fields}
			</Form.Row>
		);
	}

	RenderDataList = () => {
		// let { t } = this.props;
		let { formRecords, formData } = this.objMain.state;
		let { RowValidate } = this;
		let rows = [];
		console.log(formData);

		for (let i in formRecords) {
			let record = formRecords[i];
			// let li=
			console.log(record);

			let { t } = this.props;
			let { PageData } = this.objMain.state;
			let { doc_types } = PageData;

			let DocumentType = null;
			for (let i in doc_types) {
				let doc_type = doc_types[i];
				if (doc_type.id === record.document_type) {
					DocumentType = t("common:" + doc_type.label);
					break;
				}
			}

			let Amount = (record.amount || "0").toString();
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			Amount = Amount.join(".") + " " + (record.currency || "JPY");

			let selectedRow = "";
			if (formData.temp_row_index === i) {
				selectedRow = " selected";
			}

			let rowTitle = t("common:template.data-row-index") + " " + i;
			if (record.error) {
				rowTitle = t(`common:${record.error}`);
			}

			let rowClass = `document-row-list${selectedRow}`;

			rows.push(
				<tr key={i} data-row={i} className={rowClass} title={rowTitle} onClick={(ev) => {
					// Clear selected rows
					let rowAction = ev.target.closest(".doc-row-action");
					if (rowAction) {
						return null;
					}

					let selectedTable = ev.target.closest("table");
					let selectedRows = selectedTable.querySelectorAll("tr.selected");
					selectedRows.forEach((item) => {
						item.classList.remove("selected");
					});
					let target = ev.target.closest(".document-row-list");
					target.classList.add("selected");

					console.log(i, ev);
					let { formRecords, formStructure, formValidate, fieldValidate } = this.objMain.state;
					let { formData } = this;
					console.log(formData);
					let record = formRecords[i];

					if (formData.hasOwnProperty("temp_row_index") && !isNaN(parseInt(formData.temp_row_index))) {
						let row_index = formData["temp_row_index"];
						let record = formRecords[row_index];
						for (let key in formStructure) {
							let field = formStructure[key];
							record[field.code] = formData[`temp_${field.code}`];
						}
						console.log(formRecords);
					}

					formData["temp_row_index"] = i;
					formData["temp_error"] = record.error;
					formData["temp_warning"] = record.warning;
					console.log(formData.temp_row_index, formData.temp_doc_title);
					for (let key in formStructure) {
						let field = formStructure[key];
						formData[`temp_${field.code}`] = record[field.code];
					}
					console.log(formData.temp_row_index, formData.temp_doc_title);

					formValidate[this.name] = true;
					fieldValidate[this.name] = "was-validated";
					this.objMain.updateState({ formRecords, formValidate, fieldValidate, formData, newRowActive: true });
				}}>
					<td className={"sign-status"} style={{ fontSize: "24px" }}>
						<div className="inner-container">
							<RowValidate index={i} record={record} />
						</div>
					</td>
					<td className="document-type">
						<div className="inner-container">
							<span>{t("common:documents.document-info.document-type")}</span>
							<span>{DocumentType}</span>
						</div>
					</td>
					<td className="title-info">
						<div className="inner-container">
							<span>{t("common:documents.document-info.doc-title")}</span>
							<span>{record.doc_title}</span>
						</div>
					</td>
					<td className="amount-info">
						<div className="inner-container">
							<span>{t("common:documents.document-info.amount")}</span>
							<span>{Amount}</span>
						</div>
					</td>
					{/* <DownloadAction /> */}
					<td className="document-delete doc-row-action">
						{/* <DeleteAction /> */}
						<FontAwesomeIcon icon={faTrash} className="action-button" onClick={(ev) => {
							console.log(i, ev);
							let { formRecords } = this.objMain.state;
							formRecords.splice(i, 1);
							this.objMain.updateState({ formRecords });
						}} />
					</td>
				</tr>
			);
		}

		return (
			<Table>
				<tbody>
					{rows}
				</tbody>
			</Table>
		);
	}

	onSubmit = (ev, draft = false) => {
		let { t } = this.props;
		let { modal, formRecords, formStructure } = this.objMain.state;

		modal.props = {
			show: true,
			centered: true,
			backdrop: true,
		}

		modal.form = {};

		if (formRecords.length > 0) {

			let validate = true;
			for (let record of formRecords) {
				for (let key in formStructure) {
					if ([true, "true"].includes(formStructure[key].attributes.required)) {
						if ([null, undefined, ""].includes(record[key])) {
							validate = false;
							break;
						}
					}
				}

				if (record.error) {
					validate = false;
				}

				if (!validate) {
					validate = false;
					break;
				}
			}

			if (validate) {
				modal.title = "common:documents.template.saving";

				modal.body = "documents.save.send-mail-confirm-question";
				if (draft) {
					modal.body = "documents.save.draft-confirm-question";
				}

				modal.action = [(
					<Button key="ok" variant="primary" onClick={(ev) => {
						this.onSubmitConfirm(ev, draft);
					}}>
						<span>{t("common:general.confirm")}</span>
					</Button>
				)];
			} else {
				modal.title = "common:documents.warning";
				modal.body = "documents.template.please-fix-error-row";
				modal.action = [(
					<Button key="ok" variant="primary" onClick={(ev) => {
						modal.props.show = false;
						this.objMain.updateState({ modal });
					}}>
						<span>{t("common:general.ok")}</span>
					</Button>
				)];
			}
		} else {
			modal.title = "common:documents.warning";
			modal.body = "documents.template.please-add-data";
			modal.action = [(
				<Button key="ok" variant="primary" onClick={(ev) => {
					modal.props.show = false;
					this.objMain.updateState({ modal });
				}}>
					<span>{t("common:general.ok")}</span>
				</Button>
			)];
		}

		this.objMain.updateState({ modal });
	}

	onSubmitConfirm = (ev, draft = false) => {
		// let { t } = this.props;
		let { modal } = this.objMain.state;
		modal.body = Loading;
		// modal.props.backdrop = "static";
		modal.close = false;
		modal.action = [];
		this.objMain.updateState({ modal }, () => {
			// this.GetCurrentUserInfo(draft);
			this.Word2PDFBulkSave(draft);
		});
	}

	GetCurrentUserInfo = (draft) => {
		// let { creationType, creationMode, PDFFiles, formData } = this.objMain.state;
		Service.GetUserInfo().then(async resp => {
			this.GeneratePDF(draft, resp);
		}).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.GetCurrentUserInfo(draft);
			});
		});
	}

	Word2PDFBulkSave = async (draft) => {
		let { DocFile } = this.objMain.state;

		let templateInfo = await Service.FileUpload(DocFile).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.Word2PDFBulkSave(draft);
			});
		});

		console.log(templateInfo);
		if (!templateInfo) {
			return;
		}

		Service.Word2PDFBulkSave({
			"template": {
				"template_name": DocFile.name.replace(/\.docx|\.doc/g, ""),
				"file_name": DocFile.name,
				"file_md5": DocFile.md5,
				"temp-upload": templateInfo.temp,
			},
			"doc_info_list": this.BuildSaveForm(draft),
		}).then(async resp => {
			let { t } = this.props;
			console.log(resp);
			// console.log(resp);
			let { modal, creationType } = this.objMain.state;
			let message = "documents.determine.save-complete";
			if (resp.hasOwnProperty("message")) {
				message = resp.message;
			}
			modal.props.backdrop = "static";
			modal.body = message;
			modal.action = [(
				<MuiButton key="ok"
					variant="contained"
					component={Link}
					to={`/${creationType}`}
					onClick={(ev) => {
						window.onbeforeunload = null;
						this.objMain.unlisten();
						// window.location.href = `/${creationType}`;
					}}
				>
					<span>{t("common:general.ok")}</span>
				</MuiButton>
			)];
			modal.close = false;
			this.objMain.updateState({ modal });
		}).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.Word2PDFBulkSave(draft);
			});
		});
	}

	GeneratePDF = async (draft, UserInfo) => {
		let { formRecords, DocFile } = this.objMain.state;

		let templateInfo = await Service.FileUpload(DocFile).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.GeneratePDF(draft, UserInfo);
			});
		});
		if (!templateInfo) {
			return;
		}
		console.log(templateInfo);

		Service.Word2PDF({
			"template": {
				// "id": 1,
				// "template": 1,
				"template_name": DocFile.name.replace(/\.docx|\.doc/g, ""),
				"file_name": DocFile.name,
				// "file": DocFile,
				"tenant_id": UserInfo.tenant_id,
				"create_user": UserInfo.user_id,
				"temp-upload": templateInfo.temp,
			},
			"csv-data": formRecords,
		}).then(async resp => {
			console.log(resp);
			let multiFormData = this.BuildSaveForm(draft, UserInfo.tenant_id, resp);
			this.DocumentBulkSave(multiFormData);
		}).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.GeneratePDF(draft, UserInfo);
			});
		});
	}

	BuildSaveForm = (draft, tenant_id = null, W2PFiles = []) => {
		let multiFormData = [];

		let { formRecords, originalFormData, PageData, DocFile, DocKeys } = this.objMain.state;

		let hostMaster = {};
		let hostGroupMaster = {};
		let guestMaster = {};
		let viewMaster = {};
		let viewGroupMaster = {};

		for (let user of PageData.hosts) {
			hostMaster[user.user_id] = user;
			hostMaster[user.email] = user;
		}
		console.log(hostMaster);

		for (let user of PageData.hosts_groups) {
			hostGroupMaster[user.user_id] = user;
			hostGroupMaster[user.full_name] = user;
		}
		console.log(hostGroupMaster);

		for (let user of PageData.guests) {
			guestMaster[user.user_id] = user;
			guestMaster[user.email] = user;
		}
		for (let user of PageData.view_groups) {
			viewGroupMaster[user.user_id] = user;
			viewGroupMaster[user.full_name] = user;
		}
		for (let user of PageData.view_users) {
			viewMaster[user.user_id] = user;
			viewMaster[user.email] = user;
		}

		for (let i in formRecords) {
			let record = formRecords[i];

			let tempFormData = {};
			// certificate_type: 1
			// currency: "JPY"
			// document_type: 1
			// use_digital_certificate: false
			if (tenant_id) {
				tempFormData.tenant_id = tenant_id;
			}

			tempFormData.document_type = record.document_type || originalFormData.function_type;
			tempFormData.doc_title = record.doc_title;
			tempFormData.amount = record.amount;
			tempFormData.currency = record.currency || originalFormData.currency;
			tempFormData.certificate_type = parseInt(record.host_cert_type);;
			tempFormData.product = record.product;
			tempFormData.reference = record.reference;
			tempFormData.filebox_shortcut = record.filebox_shortcut;
			tempFormData.function_type = originalFormData.function_type; // 1:SiGNER, 2:SeNDER, 4:VeRIFICATION

			tempFormData.certificate_type = parseInt(tempFormData.certificate_type);
			// if (record.host_cert_type === 0 && record.guest_cert_type === 0) {
			// 	tempFormData.sign_level = 0;
			// } else if (record.host_cert_type !== 0 && record.guest_cert_type !== 0) {
			// 	tempFormData.sign_level = 2;
			// } else {
			// 	tempFormData.sign_level = 1;
			// }

			// console.log(tempFormData.certificate_type, tempFormData.sign_level);
			//tempFormData.sign_level = record.sign_level || originalFormData.sign_level;
			tempFormData.contract_date = record.contract_date;
			tempFormData.expiry_date = record.expiry_date;
			tempFormData.document_create_date = record.document_create_date;
			tempFormData.received_date = record.received_date;
			// tempFormData.counter_party_name = record.counter_party_name;
			tempFormData.effective_date = record.effective_date;

			tempFormData.doc_attributes = {};
			// for (let attr of PDFFile.formData.doc_attributes) {
			// 	tempFormData.doc_attributes[attr.key] = attr.value;
			// }
			console.log(PageData);
			let signers = record.signers.split(/,|;/);
			let guests = record.guests.split(/,|;/);
			let viewers = record.viewers.split(/,|;/);
			console.log(signers || []);
			console.log(guests);
			console.log(viewers);

			let notFound = [];
			tempFormData.signers = {};
			//case company seal
			if (tempFormData.certificate_type === 2) {
				for (let input of signers) {
					let user = hostGroupMaster[input];
					if (user) {
						tempFormData.signers[user.id] = {
							id: user.id,
							user_type: 2,
							name: user.name,
							self_certificate: true,
							certificate_type: 2,
							use_sign_imprint: false,
							sign_detail: null,
						};
					} else {
						notFound.push(input);
					}
				}
			}
			else {
				for (let email of signers) {
					let user = hostMaster[email];
					if (user) {
						tempFormData.signers[user.id] = {
							id: user.id,
							user_type: 0,
							name: user.name,
							email: user.email,
							self_certificate: ![0, "0"].includes(record.host_cert_type),
							certificate_type: record.host_cert_type,
							use_sign_imprint: false,
							sign_detail: null,
						};
					} else {
						notFound.push(email);
					}
				}

			}

			let counter_parties = {};

			if (tempFormData.function_type !== 2) {
				for (let email of guests) {
					let user = guestMaster[email];
					if (user) {
						if (user.company_name) {
							counter_parties[user.company_name] = user.company_name;
						} else {
							counter_parties[user.name] = user.name;
						}

						tempFormData.signers[user.id] = {
							id: user.id,
							user_type: 1,
							name: user.name,
							email: user.email,
							self_certificate: ![0, "0"].includes(record.guest_cert_type),
							certificate_type: record.guest_cert_type,
							use_sign_imprint: false,
							sign_detail: null,
						};
					}
				}
			}
			else {
				tempFormData.receivers = {};

				for (let email of guests) {
					let user = guestMaster[email];
					if (user) {
						if (user.company_name) {
							counter_parties[user.company_name] = user.company_name;
						} else {
							counter_parties[user.name] = user.name;
						}
						tempFormData.receivers[user.id] = {
							id: user.id,
							name: user.name,
							email: user.email,
							company_name: user.company_name,
						};
					}
				}

			}

			counter_parties = Object.values(counter_parties);
			tempFormData.counter_party_name = counter_parties.join(", ");

			tempFormData.viewers = [];

			for (let email of viewers) {
				let user = hostMaster[email];
				if (user) {
					tempFormData.viewers.push(user.id);
				}
			}

			let file = W2PFiles[i];
			if (file) {
				console.log(file);
				// pdf: "temp-upload/bef16e09-e761-4ad3-b727-c5fb9824848c_1.pdf"
				// pdf_md5: "e14e3c4ec6831df1b58778e748031503"
				// "template_name": DocFile.name.replace(/\.docx|\.doc/g, ""),
				// "file_name": DocFile.name,
				tempFormData.files = {};
				tempFormData.files[file.pdf_md5] = {
					hash: file.pdf_md5,
					name: DocFile.name.replace(/\.docx|\.doc/g, ".pdf"),
					// size: PDFFile.size,
					type: "application/pdf",
					// lastModified: this.dateToString(PDFFile.lastModified),
					// lastModifiedDate: this.dateToString(PDFFile.lastModifiedDate),
					temp: file.pdf,
				};
			}

			tempFormData.save_as_draft = draft;
			tempFormData.auto_send_email = !draft;

			// console.log(DocKeys);
			if (DocKeys) {
				tempFormData.replace_keys = {};
				for (let i in DocKeys) {
					tempFormData.replace_keys[DocKeys[i].name] = record[DocKeys[i].name];
				}
			}

			multiFormData.push(tempFormData);
		}

		console.log(multiFormData);

		return multiFormData;
	}

	DocumentBulkSave = (multiFormData) => {
		let { t } = this.props;
		Service.BulkSaveDocument(multiFormData).then(resp => {
			// console.log(resp);
			let { modal, creationType } = this.objMain.state;
			let message = "documents.determine.save-complete";
			if (resp.hasOwnProperty("message")) {
				message = resp.message;
			}
			modal.props.backdrop = "static";
			modal.body = message;
			modal.action = [(
				<MuiButton key="ok"
					variant="contained"
					component={Link}
					to={`/${creationType}`}
					onClick={(ev) => {
						window.onbeforeunload = null;
						this.objMain.unlisten();
						// window.location.href = `/${creationType}`;
					}}
				>
					<span>{t("common:general.ok")}</span>
				</MuiButton>
			)];
			modal.close = false;
			this.objMain.updateState({ modal });
		}).catch(err => {
			this.ShowErrorMessage(err.response, () => {
				this.DocumentBulkSave(multiFormData);
			});
		});
	}

	ShowErrorMessage = (errReponse, fnCallback) => {
		console.log(errReponse);
		let { t } = this.props;
		let { modal, formRecords } = this.objMain.state;

		let errMessage = errReponse.data.message;
		if (errReponse.data.hasOwnProperty("error")) {
			errMessage = errReponse.data.error;
		}
		modal.props.show = true;
		modal.body = errMessage;
		modal.action = [
			<Button key="cancel" variant="secondary" onClick={(ev) => {
				modal.props.show = false;
				this.objMain.updateState({ modal });
			}}>
				<span>{t("common:general.close")}</span>
			</Button>,
			<Button key="ok" variant="primary" onClick={(ev) => {
				modal.body = Loading;
				modal.action = [];
				this.objMain.updateState({ modal }, fnCallback);
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		];

		if (errReponse.data.hasOwnProperty("errors")) {
			let errors = errReponse.data.errors;
			if (!(errors instanceof Array)) {
				errors = Object.values(errors);
			}
			for (let err of errors) {
				formRecords[err.row].error = err.error;
			}
		}

		this.objMain.updateState({ modal, formRecords });
	}

	renderrrr() {
		return (
			<div className="container-fluid"></div>
		);
	}

	render() {
		let { t } = this.props;
		let { newRowActive } = this.objMain.state;
		let { RenderDataList, RenderRecordDetail } = this;

		// console.log(DocFile);
		// console.log(DocFileURL);

		// <Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >
		return (
			<div className="container-fluid">

				{/* <Form
					// validated={formValidate[this.name]}
					id={"form_" + this.name}
					// onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="full-form"
				> */}
				<div className="full-form">

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					<Form.Row>
						<Form.Group as={Col} md={8} className="hader-form-action">
							{/* <Button variant="primary" type="button" onClick={(ev) => {
								console.log(ev.target);
								let { formRecords, formStructure, formValidate, fieldValidate } = this.objMain.state;
								let { formData } = this;
								formData["temp_row_index"] = null;
								for (let key in formStructure) {
									let field = formStructure[key];
									formData[`temp_${field.code}`] = field.defaultValue;
								}

								formValidate[this.name] = false;
								fieldValidate[this.name] = "";
								this.objMain.updateState({ formValidate, fieldValidate, formData, newRowActive: true });
							}}>
								<span className="btn-label">{t('common:documents.template.new-row')}</span>
							</Button> */}
							<Button variant="primary" type="button" onClick={this.ImportCSVFile}>
								<span className="btn-label">{t('common:documents.template.csv-import')}</span>
							</Button>
						</Form.Group>
						<Form.Group as={Col} md={4} className="flex-right">
							<Button variant="primary" type="button" onClick={(ev) => {
								let { formStructure } = this.objMain.state;

								let headerLabel = [];
								for (let key in formStructure) {
									let field = formStructure[key];
									headerLabel.push(field.code);
								}

								let file_name = 'import_template.csv';
								let BOM = new Uint8Array([0xEF, 0xBB, 0xBF]); // UTF-8 BOM
								let csv = headerLabel.join(",");

								let blob = new Blob([BOM, csv], { type: 'text/csv;charset=utf-8;' });
								// if (navigator.msSaveBlob) { // IE 10+
								// 	navigator.msSaveBlob(blob, exportedFilenmae);
								// } else {
								let link = document.createElement("a");
								// if (link.download !== undefined) { // feature detection
								// Browsers that support HTML5 download attribute
								let url = URL.createObjectURL(blob);
								link.setAttribute("href", url);
								link.setAttribute("download", file_name);
								link.style.visibility = 'hidden';
								document.body.appendChild(link);
								link.click();
								document.body.removeChild(link);
							}}>
								<span className="btn-label">{t('common:documents.template.csv-template-download')}</span>
							</Button>
						</Form.Group>
					</Form.Row>

					{/* <div className="form-body flex-column"> */}
					<div className="form-body flex-column" style={{ minHeight: "calc(100vh - 347px)" }}>

						<Form.Row className="flex-full" style={{ position: "relative" }}>

							<Form.Group as={Col} md={12} className="no-margin">
								{/* <CSVDataRender /> */}
								<RenderDataList />
							</Form.Group>

							{/* <Form.Group as={Col} xs={12} md={4} className="no-margin"> */}
							{/* </Form.Group> */}

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button variant="secondary" onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<FontAwesomeIcon icon={faChevronLeft} />
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button variant="primary" onClick={this.onSubmit}>
									<span className="btn-label">{t('common:documents.general.confirm')}</span>
								</Button>
							</div>
						</div>

					</div>

				</div >

				<div className={`record-form-detail container-fluid${newRowActive ? " active" : ""}`}>
					<Form
						// validated={formValidate[this.name]}
						id={"form_detail_" + this.code}
						onSubmit={(ev) => {
							this.objMain.formSubmitHandler(ev, this, (evCB) => {
								//console.log(evCB);
								evCB.stopPropagation();
								let { formRecords, formStructure } = this.objMain.state;
								let { formData } = this;
								let record = {};
								for (let key in formStructure) {
									let field = formStructure[key];
									record[field.code] = formData[`temp_${field.code}`];
								}
								if (formData["temp_row_index"] == null) {
									formRecords.push(record);
								} else {
									formRecords[formData["temp_row_index"]] = record;
								}
								//console.log(formRecords);
								this.objMain.updateState({ formRecords, newRowActive: false });
							})
						}}
						noValidate
						className="full-form"
						style={{ height: "calc(100% - 60px)", minHeight: "unset" }}
					>
						{/* <div className="record-form-detail-head"> */}
						<Form.Row className="record-form-detail-head">
							<Form.Group as={Col} md={12} className="record-form-detail-head">
								<Button variant="primary" type="submit">
									<span className="btn-label">{t('common:documents.template.new-row-save')}</span>
								</Button>
								<Button variant="secondary" type="button" onClick={(ev) => {
									//console.log(ev.target);
									this.objMain.updateState({ newRowActive: false });
								}}>
									<span className="btn-label">{t('common:documents.template.new-row-cancel')}</span>
								</Button>
							</Form.Group>
						</Form.Row>
						{/* </div> */}

						{RenderRecordDetail()}

						<div className="footer"></div>
					</Form>

				</div>
			</div >

		);
		// {/* </Container > */ }

	}

}
