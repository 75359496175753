import React from 'react';
import Layout from '../../Layout';
import MainMenu from './Menu';
// import Service from '../../Service';
import { withTranslation } from "react-i18next";
import FileExplorer from '../Fragments/FileExplorer';

class FileBox extends Layout {

	constructor(props) {
		super(props);
		this.state.page = "FileBox";
		this.state.code = "filebox";
	}

	Menu = () => {
		return (
			<MainMenu {...this.props} page={this.state.page} />
		);
	}

	ItemsList = () => {
		// let { code } = this.state;
		// let items = Service.getDocumentList(code);
		let ItemsList = [];
		// for (let i = 0; i < items.length; i++) {
		// 	let item = items[i];
		// 	itemsList.push(
		// 		<ListGroup.Item key={i} action variant="light">
		// 			<span>{item}</span>
		// 		</ListGroup.Item>
		// 	);
		// }

		return ItemsList;
		// return (
		// 	<ListGroup as="ul" className="item-row">
		// 		{itemsList}
		// 	</ListGroup>
		// );
	}

	fileBoxSelectionCallback = (resp) => {
		// console.log(resp);
	}

	render() {

		// let { t } = this.props;
		const { Main } = this;

		return (
			<Main>
				<FileExplorer linkage={true} showDoc={false} responseCallback={this.fileBoxSelectionCallback} />
			</Main>

		);

	}

}

export default withTranslation()(FileBox);