import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import '../../Assets/css/auth.css';

import { withTranslation } from "react-i18next";
// import Service from '../../../Service';
import AuthService from '../../../Service/Auth';

// import Amplify, { Auth } from "aws-amplify";
// import awsCognito from "../../../Config/Cognito";

// Amplify.configure(awsCognito);

class Verification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: props.match.params,
            token: props.match.params.token,
            status: props.t('common:auth.registration.verification.initial'),
            success: true,
        };
    }

    componentDidMount() {
        let { t } = this.props;
        let { token, status, success } = this.state;

        status = t('common:auth.registration.verification.loading');
        document.title = this.props.t("common:auth.registration.verification.title");

        this.setState({ status });
        AuthService.RegisterVerification(token).then(resp => {
            status = t('common:auth.registration.verification.success');
            this.setState({ status });
        }).catch(err => {
            // console.log(err.response);
            status = t('common:auth.registration.verification.fail');
            success = false
            this.setState({ status, success });
        });
    }

    componentDidUpdate(prevProps) {
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    ResendEmail = () => {
        let { t } = this.props;
        let { success } = this.state;
        // console.log(success);
        if (success) {
            return null;
        } else {
            // return <Link to='/registration/resend-email' style={{ width: '100%' }}>{t('common:auth.registration.resend-email')}</Link>;
            return <Link to='/registration' style={{ width: '100%' }}>{t('common:auth.login.registration')}</Link>;
        }
    }

    render() {

        let { t } = this.props;
        let { status } = this.state;
        // console.log(success)
        let { ResendEmail } = this;

        return (
            <div className="auth contrainer-wrapper">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <div className="form-wrapper registration" style={{ "width": "600px" }}>

                            <div className="logo-horizontal">
                                <img src={logo} className="App-logo" alt="paperlogic" />
                            </div>

                            <div className="form-header">
                                <div className="header-info">
                                    <h3>{t('common:auth.registration.verification.header')}</h3>
                                </div>
                            </div>


                            <div className="form-container">

                                <div className="fields-container">

                                    <p>{status}</p>

                                </div>

                                <div className="form-bottom">
                                    <div className="wrapper-button-cancel">
                                        <ResendEmail />
                                    </div>
                                    <div className="wrapper-button-submit">
                                        <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="footer">
                        <div>© 2021 paperlogic.co.jp</div>
                    </div>
                </div>

            </div >
        );
    }

}
export default withTranslation()(Verification);