
import EnvParams from "./EnvParams";

// const host_origin = "https://coni1zkirk.execute-api.ap-northeast-1.amazonaws.com/sandbox";
const host_origin = EnvParams.api.domain;
const api_path = EnvParams.api.path;
const media_path = EnvParams.api.media;
const api_url = `${host_origin}${api_path}`;
const media_url = `${host_origin}${media_path}`;
const idp_url = EnvParams.api.idp;

const API = {
    host: host_origin,
    api_path: api_path,
    media_path: media_path,
    url: api_url,
    media: media_url,
    idp_url,
}

export default API;
