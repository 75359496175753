import React from 'react';
import Service from '../../../Service';
import '../../Assets/css/file-explorer.css';
import EmptyBox from '../../Assets/img/not_file.png';
// import EmptyBox_URL from '../../Assets/img/empty-box.svg';
// import { Link } from "react-router-dom";
import CryptoJS from 'crypto-js';
import Loading from '../../Loading';
// import { loading as ItemLoading } from '../../Assets/SVGIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Col,
    Form,
    // Card,
    // Accordion,
} from 'react-bootstrap';
import {
    faCaretRight,
    faCaretDown,
    faSpinner,
//    faFolder as faSolidFolder,
    faFileAlt,
    faFilePdf,
    faFileArchive,
    faFileExcel,
    faFileImage,
    faLink,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircle,
    faCheckCircle,
    faTimesCircle,
    faFolder,
    faFolderOpen,
} from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Layout from '../../../Layout';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';
import CircularProgress from '@mui/material/CircularProgress';


class FileExplorer extends Layout {
    constructor(props) {
        super(props);
        // this.state = {

        this.state.directories = {};
        this.state.selectedPath = "/";

        this.state.loadingTarget = "/";
        this.state.selectedRoot = "filebox";
        this.state.selectedType = props.type || "B";
        this.state.selectedItems = [];
        this.state.hasActiveChild = null;
        this.state.view = "view-icon";
        this.state.ContentLoading = null;
        this.state.failure = false;
        this.state.getPathError = false;
        this.state.waitingFiles = null;
        this.state.uploadStatus = {};
        this.state.newFolderName = "";
        this.state.submitSuccess = false;
        this.state.downloading = false;
        this.state.linkage = props.linkage || false;
        this.state.readOnly = props.readOnly || false;

        this.state.root = {};
        if (props.showDoc) {
            this.state.root.documents = {
                active: true,
                label: "main.filebox.folder-documents-label",
                directories: null,
                selectedPath: "/",
            };
        }
        this.state.root.filebox = {
            active: true,
            label: "main.filebox.folder-filebox-label",
            directories: null,
            selectedPath: "/",
        };
        // }
        // this.render();
        // console.log(props);
    }

    /** please use function componentDidMountExtends intread */
    componentDidMount() {
        // console.log(this.componentDidMountExtends);
        this.getDirectories();
    }

    itemSelectHandler = (ev) => {
        // console.log(ev);
        // console.log(ev.target.dataset);
        let target = ev.target.closest(".item");
        let { selectedItems } = this.state;
        if (ev.ctrlKey) {
            if (selectedItems.includes(target.dataset.name)) {
                var index = selectedItems.indexOf(target.dataset.name);
                if (index !== -1) {
                    selectedItems.splice(index, 1);
                }
            } else {
                selectedItems.push(target.dataset.name);
            }
        } else {
            selectedItems = [target.dataset.name];
        }
        this.setState({ selectedItems }, this.itemSelectedCallback);
    }

    getPathObject = (selectedPath) => {
        let { root, selectedRoot } = this.state;
        let { directories } = root[selectedRoot];

        if (!selectedPath) {
            selectedPath = this.state.selectedPath;
        }

        let pathSplit = selectedPath.split("/");
        pathSplit = pathSplit.filter(function (el) {
            return el;
        });
        let parentCursor = null;

        let pathCursor = directories;
        for (let i of pathSplit) {
            parentCursor = pathCursor[i];
            pathCursor = parentCursor["children"];
            if (parentCursor.hasOwnProperty("children")) {
                pathCursor = parentCursor["children"];
            }
        }

        return { pathCursor, parentCursor, pathSplit };
    }

    itemSelectedCallback = () => {
        let { selectedPath, selectedItems } = this.state;
        let currentPath = selectedPath;

        if (selectedPath !== "/") {
            currentPath = `${currentPath}/`;
        }
        if (selectedItems.length > 0) {
            currentPath = `${currentPath}${selectedItems[0]}`;
        }
        // console.log(currentPath);
        this.props.responseCallback(currentPath);
    }

    treeViewExpand = (ev) => {
        let { root, ContentLoading, loadingTarget, selectedPath, hasActiveChild } = this.state;
        // let { directories } = root[selectedRoot];
        let target = ev.target.closest(".path-label");
        let activeChild = target.parentNode.querySelector("li.active");
        // console.log(target);
        // console.log(activeChild);
        selectedPath = target.dataset.path;
        let { parentCursor, pathSplit } = this.getPathObject(selectedPath);

        let getNewPath = false;
        loadingTarget = `/${pathSplit.join("/")}`;
        hasActiveChild = null;
        if (activeChild) {
            hasActiveChild = loadingTarget;
        }
        parentCursor.expand = !parentCursor.expand;
        if (parentCursor.expand) {
            parentCursor.open = true;
        }
        ContentLoading = null;
        if (!parentCursor.hasOwnProperty("children")) {
            ContentLoading = faSpinner;
            getNewPath = true;
        }
        // this.itemSelectedCallback();
        this.setState({ root, ContentLoading, loadingTarget, hasActiveChild }, () => {
            if (getNewPath) {
                this.getDirectories();
            }
        });

    }

    pathClickHandler = (ev, expand) => {
        // console.log(ev.target.dataset);
        let target = ev.target.closest(".path-label");
        let { ContentLoading, selectedPath, loadingTarget, selectedItems, hasActiveChild } = this.state;
        // let { directories } = root[selectedRoot];

        if (selectedPath !== target.dataset.path) {
            selectedItems = [];
        }

        selectedPath = target.dataset.path;
        if (!selectedPath) {
            selectedPath = "/";
        }
        let { parentCursor, pathSplit } = this.getPathObject(selectedPath);

        let getNewPath = false;
        loadingTarget = `/${pathSplit.join("/")}`;
        hasActiveChild = null;
        ContentLoading = null;
        if (parentCursor) {
            parentCursor.open = true;

            if (expand === "toggle") {
                parentCursor.expand = !parentCursor.expand;
            } else if (expand === "open") {
                parentCursor.expand = true;
            }
            if (!parentCursor.hasOwnProperty("children")) {
                ContentLoading = faSpinner;
                getNewPath = true;
            }
        } else {
            // // root.open = !root.open;
            // for (let i in root) {
            //     root[i].open = false;
            // }
            // root[selectedRoot].open = true;
        }

        this.setState({ selectedPath, selectedItems, ContentLoading, loadingTarget, hasActiveChild }, () => {
            this.itemSelectedCallback();
            if (getNewPath) {
                this.getDirectories();
            }
        });

    }

    GetFileSuccess = (resp) => {
        let { root, ContentLoading, loadingTarget, selectedRoot } = this.state;

        if (!root[selectedRoot].directories) {
            root[selectedRoot].directories = {};
        }

        // let { directories } = root[selectedRoot];
        let { pathCursor, parentCursor } = this.getPathObject(loadingTarget);

        if (parentCursor) {
            parentCursor["children"] = {};
            pathCursor = parentCursor["children"];
        }

        for (let i in resp) {
            pathCursor[i] = resp[i];
        }

        ContentLoading = null
        this.setState({ root, ContentLoading });
    }

    GetFileboxStoragePath = () => {
        let { selectedType, loadingTarget, linkage } = this.state;
        Service.GetFileboxStoragePath(loadingTarget, selectedType, linkage).then(this.GetFileSuccess).catch(err => {
            //console.log(err.response);
            this.setState({ failure: true });
        });
    }

    GetDocumentStoragePath = () => {
        let { selectedType, loadingTarget } = this.state;
        Service.GetDocumentStoragePath(loadingTarget, selectedType).then(this.GetFileSuccess).catch(err => {
            //console.log(err.response);
            this.setState({ failure: true });
        });
    }

    getDirectories = () => {
        let { selectedRoot } = this.state;
        if (selectedRoot === "filebox") {
            this.GetFileboxStoragePath();
        } else if (selectedRoot === "documents") {
            this.GetDocumentStoragePath();
        }
    }

    CustomFAIcon = (props) => {
        return <FontAwesomeIcon {...props} />;
    }

    ContainerBody = (props) => {
        let { BuildDirectoryContrainer } = this;
        return (
            <div className={"content-panel"}>
                <BuildDirectoryContrainer />
            </div>
        );
    }

    BuildTreeView = (props) => {
        if (!props.directories) {
            return null;
        }
        let children = props.directories;
        if (children.hasOwnProperty("children")) {
            children = children.children;
        }

        let pathList = [];
        for (let i in children) {
            if (["dir", "linkage"].includes(children[i].type)) {
                let treeItem = this.BuildTreeItem(children[i]);
                pathList.push(treeItem);
            }
        }
        return (
            <ul>
                {pathList}
            </ul>
        )
    }

    BuildTreeItem = (folder) => {
        let { selectedPath, loadingTarget, hasActiveChild, ContentLoading } = this.state;
        let { BuildTreeView, CustomFAIcon } = this;
        let dirIconProps = { icon: faFolder, className: "icon" };
        let treeExpand = faCaretRight;
        let noChildren = "";
        let liClass = [];
        // if (folder.open === true || folder.expand === true) {
        //     dirIconProps.icon = faFolderOpen;
        //     liClass.push("open");
        // }
        if (folder.expand === true) {
            dirIconProps.icon = faFolderOpen;
            treeExpand = faCaretDown;
            liClass.push("expanded");
        } else {
            liClass.push("collapse");
        }
        let currentPath = "/" + folder.path + folder.name;
        if (currentPath === selectedPath) {
            dirIconProps.icon = faFolderOpen;
            liClass.push("active");
        }

        if (folder.type === "linkage") {
            dirIconProps.icon = faLink;
        }

        if (ContentLoading && loadingTarget === currentPath) {
            dirIconProps.icon = ContentLoading;
            dirIconProps.pulse = true;
        }

        // console.log(hasActiveChild, currentPath);
        if (hasActiveChild === currentPath) {
            liClass.push("has-active-child");
        }

        if (folder.hasOwnProperty("children")) {
            if (Object.keys(folder.children).length === 0) {
                noChildren = " loaded-no-children";
            }
        }

        return (
            <li key={currentPath} className={liClass.join(" ")}>
                <div className={"path-label"} data-path={currentPath}>
                    <div onClick={this.treeViewExpand} className="action caret-group toggle">
                        <div className="caret-expand">
                            <CustomFAIcon icon={treeExpand} className={"icon" + noChildren} />
                        </div>
                    </div>
                    <div onClick={this.pathClickHandler} onDoubleClick={(ev) => {
                        this.pathClickHandler(ev, "toggle");
                    }} className="action label-group">
                        <div className="directory-icon">
                            <CustomFAIcon {...dirIconProps} />
                        </div>
                        <div className="directory-label">
                            <span>{folder.name}</span>
                        </div>
                    </div>
                </div>
                <div className="path-children">
                    <BuildTreeView directories={folder.children} />
                </div>
            </li>
        );
    }

    onReturnBackHandler = (ev) => {
        let { selectedPath, loadingTarget } = this.state;
        let pathSplit = selectedPath.split("/");
        pathSplit = pathSplit.filter(function (el) {
            return el;
        });
        pathSplit.pop();
        selectedPath = `/${pathSplit.join("/")}`;
        loadingTarget = selectedPath;
        this.setState({ selectedPath, loadingTarget, selectedItems: [] });
    }

    registerFiles = (files) => {
        for (let file of files) {
            let reader = new FileReader();
            reader.onload = (ev) => {
                // console.log(ev);
                // let blob = new Blob([reader.result], { type: file.type });
                let content = CryptoJS.enc.Latin1.parse(reader.result);
                // console.log(reader.result);
                // console.log(content);
                var fileKey = CryptoJS.MD5(content).toString();
                // var sha256Hash = CryptoJS.SHA256(reader.result);
                // console.log(fileKey);
                let { PDFFiles } = this.objMain.state;
                PDFFiles[fileKey] = {
                    file: file,
                    name: file.name,
                    type: file.type,
                    // pages: [],
                };
                this.objMain.updateState({ PDFFiles });
                this.objMain.updateState({ selectedFile: fileKey });
                // console.log(this.objMain.state.PDFFiles);
            };
            reader.onerror = () => {
                //console.log(reader.error);
            };
            reader.readAsBinaryString(file);
        }
    }

    FileUploadStatus = (props) => {
        // let { waitingFiles } = this.state;
        let { file } = props;
        if (file.hasOwnProperty("uploaded")) {
            if (file.uploaded === true) {
                return <FontAwesomeIcon icon={faCheckCircle} className="success" />;
            } else if (file.uploaded === "uploading") {
                return <FontAwesomeIcon icon={faSpinner} pulse className="working" />;
            } else {
                return <FontAwesomeIcon icon={faTimesCircle} className="failure" />;
            }
        } else {
            return <FontAwesomeIcon icon={faCircle} className="waiting inqueue" />;
        }

    }

    startUploadFiles = async () => {
        let { waitingFiles, selectedPath } = this.state;
        let selectedItems = [];
        for (let file of waitingFiles) {
            file.uploaded = "uploading";
            this.setState({ waitingFiles });

            let uploadPath = `${selectedPath}/${file.name}`;
            let tempInfo = await Service.FileUpload(file, uploadPath, file).catch(err => {
                //console.log(err.response);
            });

            // console.log(selectedPath);
            // console.log(tempInfo);
            if (tempInfo) {
                file.uploaded = true;
                selectedItems.push(file.name);
            } else {
                file.uploaded = false;
            }
            this.setState({ waitingFiles });
        }
        this.setState({ selectedItems });
    }

    onUploadDone = () => {
        let { selectedItems } = this.state;
        if (selectedItems.length > 0) {
            this.setState({ ContentLoading: faSpinner, waitingFiles: null }, this.getDirectories);
        }
    }

    onUploadFilesHandler = (ev) => {
        // console.log(ev.target.files);
        this.UploadComfirmation(ev.target.files);
    }

    UploadComfirmation = (files) => {
        let { t } = this.props;
        let { waitingFiles, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal } = this.state;
        waitingFiles = files;

        modalTitle = "common:filebox.upload.comfirm-title";
        modalProps = {
            "show": true,
            "centered": true,
        };
        ModalBody = () => {
            let { waitingFiles } = this.state;
            let { FileUploadStatus } = this;
            let FileElems = [];
            for (let file of waitingFiles) {
                FileElems.push(
                    <div key={file.name} className="modal-working-logs">
                        <div className="status-icon">
                            <FileUploadStatus file={file} />
                        </div>
                        <div className="message-label">
                            <span>{file.name}</span>
                        </div>

                    </div>
                );
            }
            return FileElems;
        };

        /* */
        ModalAction = [];
        ModalAction.push(
            <Button key="ok" sx={{ml:1}} variant="contained" onClick={(ev) => {
                this.setState({ ModalAction: [] }, this.startUploadFiles);
            }}>
                <span>{t("common:filebox.upload.submit")}</span>
            </Button>
        );
        onExitedModal = this.onUploadDone;

        /* */
        this.setState({ waitingFiles, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal });
    }

    onReloadHandler = (ev) => {
        this.setState({ ContentLoading: faSpinner, selectedItems: [] }, this.getDirectories);
    }

    SecondaryActionBar = () => {
        let { selectedPath, readOnly, selectedRoot } = this.state;
        // let { directories } = root[selectedRoot];
        let { parentCursor, pathSplit } = this.getPathObject(selectedPath);

        // let pathSplit = selectedPath.split("/");
        // pathSplit = pathSplit.filter(function (el) {
        //     return el;
        // });
        // console.log(pathSplit);

        let addrItems = [];
        let path = "";
        for (let i of pathSplit) {
            path = `${path}/${i}`;
            addrItems.push(
                <div key={path} data-path={path} onClick={this.pathClickHandler} className="address-nav path-label">
                    <FontAwesomeIcon icon={faCaretRight} />
                    <span>{i}</span>
                </div>
            )
        }

        let disabled = false;
        let disabledClass = "";
        let reloadAction = this.onReloadHandler;
        let uploadAction = this.onUploadFilesHandler;
        if (parentCursor && parentCursor.type === "linkage") {
            disabled = true;
            disabledClass = "disabled";
            reloadAction = null;
            uploadAction = null;
        }

        let UplaodActionBlock = () => { return null; };
        if (!readOnly && selectedRoot === "filebox") {
            UplaodActionBlock = () => {
                return (
                    <div className="action-upload">
                        <Tooltip title="ファイルをアップロード" arrow>
                        <span>
                        <IconButton
                            color="primary"
                            disabled={disabled}
                            onChange={uploadAction}
                            htmlFor="file_upload"
                        >
                            <BackupOutlinedIcon className={disabledClass} />
                            <input type="file" name="file_upload" id="file_upload" className="hide" />
                        </IconButton>
                        </span>
                        </Tooltip>
                    </div>
                );
            }
        }


        return (
            <div className="content-action-bar">
                <div className="secondary-action-bar-container">
                    <Tooltip title="上のディレクトリにもどる" arrow>
                    <span>
                    <IconButton className="action-back" onClick={this.onReturnBackHandler} >
                        <ArrowBackOutlinedIcon/>
                    </IconButton>
                    </span>
                    </Tooltip>
                    <div className="address-bar">
                        {addrItems}
                    </div>
                    <UplaodActionBlock />
                    <Tooltip title="画面をリロード" arrow>
                    <span>
                    <IconButton color='primary' disabled={disabled} className="action-reload" onClick={reloadAction} >
                        <CachedIcon className={disabledClass}/>
                    </IconButton>
                    </span>
                    </Tooltip>
                </div>
            </div>
        );
    }

    ContainerBlock = (props) => {
        let { items } = props;
        if (items.length === 0) {
            return <img width={"256px"} src={EmptyBox} alt="empty"/>
        }
        return (
            <div id="container_block" className="content-body-inner" onClick={(ev) => {
                if (ev.target.id === "container_block" && !ev.ctrlKey) {
                    this.setState({ selectedItems: [] });
                }
            }}>
                {items}
            </div>
        )
    }

    BodyContainer = () => {
        let { ContentLoading, selectedPath, loadingTarget, failure } = this.state;
        let { Reload, ContainerBlock } = this;
        if (ContentLoading && selectedPath === loadingTarget) {
            return <Loading />;
        } else if (failure) {
            return <Reload />;
        }

        let { selectedRoot, selectedItems, view, readOnly } = this.state;
        // let { directories } = root[selectedRoot];
        let { pathCursor } = this.getPathObject(selectedPath);

        if (selectedPath === "/") {
            selectedPath = "";
        }

        let itemsList = [];
        for (let i in pathCursor) {
            let item = pathCursor[i];
            let active = "";
            if (selectedItems.includes(i)) {
                active = " active";
            }

            let currentPath = selectedPath + "/" + i;
            if (item.type === "dir") {
                itemsList.push(
                    <div key={currentPath} className="item-wrapper clickable">
                        <Tooltip title={i} arrow>
                        <div className={"item path-label" + active} data-name={i} data-path={currentPath} onClick={this.itemSelectHandler} onDoubleClick={(ev) => {
                            this.pathClickHandler(ev, "open");
                        }} >
                            <div className="icon-wrapper">
                                <FolderOutlinedIcon className="icon" />
                            </div>
                            <div className="item-label">
                                <span>{i}</span>
                            </div>
                        </div>
                        </Tooltip>
                    </div>
                );
            } else if (item.type === "linkage") {
                itemsList.push(
                    <div key={currentPath} className="item-wrapper clickable">
                        <Tooltip title={i} arrow>
                        <div className={"item path-label" + active} data-name={i} data-path={currentPath} onClick={this.itemSelectHandler} onDoubleClick={(ev) => {
                            this.pathClickHandler(ev, "open");
                        }} >
                            <div className="icon-wrapper">
                                <FolderOutlinedIcon className="icon" />
                                <LinkIcon className="icon-linkage" />
                            </div>
                            <div className="item-label">
                                <span>{i}</span>
                            </div>
                        </div>
                        </Tooltip>
                    </div>
                );
            } else {
                let FileIcon = faFileAlt;
                if (item.type === "pdf") {
                    FileIcon = faFilePdf;
                } else if (["xls", "xlsx"].includes(item.type)) {
                    FileIcon = faFileExcel;
                } else if (["zip", "rar", "7z", "tar", "gz"].includes(item.type)) {
                    FileIcon = faFileArchive;
                } else if (["png", "jpg", "jpeg", "svg", "gif"].includes(item.type)) {
                    FileIcon = faFileImage;
                }
                itemsList.push(
                    <div key={currentPath} className="item-wrapper clickable" >
                        <Tooltip title={i} arrow>
                        <div className={"item path-label" + active} data-name={i} data-path={currentPath} onClick={this.itemSelectHandler} >
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={FileIcon} className={`icon ${item.type}`} />
                            </div>
                            <div className="item-label">
                                <span>{i}</span>
                            </div>
                        </div>
                        </Tooltip>
                    </div>
                );
            }
        }

        let ActionIcon = null;
        let dragClass = "drag-over";
        let onDropHandler = (ev) => {
            ev.preventDefault();
            let body = ev.target.closest(".content-body");
            body.classList.remove(dragClass);
            this.UploadComfirmation(ev.dataTransfer.files);
        }
        // if (parentCursor && parentCursor.type === "linkage") {
        //     dragClass = "drag-not-allow";
        //     onDropHandler = (ev) => {
        //         ev.preventDefault();
        //         let body = ev.target.closest(".content-body");
        //         body.classList.remove(dragClass);
        //     }
        //     ActionIcon = <FontAwesomeIcon icon={faMinusCircle} />
        // }

        let empty = "";
        if (itemsList.length === 0) {
            empty = " empty-folder";
        }

        if (readOnly || selectedRoot === "documents") {
            return (
                <div className={`content-body ${view}${empty}`}>
                    <ContainerBlock items={itemsList} />
                </div>
            );
        }

        return (
            <div className={`content-body ${view}${empty}`}
                onDragEnter={(ev) => {
                    let body = ev.target.closest(".content-body");
                    body.classList.add(dragClass);
                }}
            >

                <ContainerBlock items={itemsList} />

                <label htmlFor="drop_file_upload" className="filebox-drop-area"
                    onDragOver={(ev) => {
                        ev.preventDefault();
                    }}
                    onDrop={onDropHandler}
                    onDragLeave={(ev) => {
                        ev.preventDefault();
                        let body = ev.target.closest(".content-body");
                        body.classList.remove(dragClass);
                    }}
                >
                    <div className="drop-area-inner">
                        {ActionIcon}
                    </div>
                    <input type="file" name="drop_file_upload" id="drop_file_upload" className="hide" multiple onChange={this.onUploadFilesHandler} />
                </label>
            </div>
        );
    }

    BuildDirectoryContrainer = () => {
        let { selectedPath } = this.state;
        let { SecondaryActionBar, BodyContainer } = this;
        if (!selectedPath) {
            return null;
        }
        // console.log(pathSplit);

        // console.log(pathCursor);

        return (
            <div className={"content-panel-wrapper"}>
                <SecondaryActionBar />

                <BodyContainer />
            </div>
        )
    }

    onCreateFolderDone = () => {
        let { submitSuccess } = this.state;
        if (submitSuccess) {
            this.setState({
                ContentLoading: faSpinner,
                newFolderName: "",
                submitSuccess: false,
            }, this.getDirectories);
        } else {
            this.setState({ newFolderName: "", submitSuccess: false });
        }
    }

    createNewFolderSubmit = (ev) => {
        let { t } = this.props;
        let { selectedPath, newFolderName } = this.state;

        this.setState({ selectedItems: [] }, (ev) => {
            let newPath = `${selectedPath}/${newFolderName}`;
            Service.CreateFileBoxNewFolder(newPath).then(resp => {
                // console.log(resp);
                let { ModalBody, ModalAction } = this.state;

                ModalBody = () => {
                    return (
                        <div>{t(`common:${resp.message}`)}</div>
                    );
                };
                ModalAction = [];
                this.setState({ selectedItems: [newFolderName], submitSuccess: true });
                this.setState({ ModalBody, ModalAction });
            }).catch(err => {
                console.log(err.response);

                let errMessage = err.response.data.message;
                if (err.response.data.hasOwnProperty("error")) {
                    errMessage = err.response.data.error;
                }
                let { ModalBody, ModalAction } = this.state;

                ModalBody = () => {
                    return (
                        <div>{t(`common:${errMessage}`)}</div>
                    );
                };
                ModalAction = [
                    (
                        <Button key="ok" sx={{ml:1}} variant="contained" onClick={this.addNewFolderHandler}>
                            <span>{t("common:general.try-again")}</span>
                        </Button>
                    ),
                ];
                this.setState({ ModalBody, ModalAction });
            });
        })
    }

    addNewFolderHandler = () => {
        let { t } = this.props;
        let { newFolderName, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal } = this.state;
        modalTitle = "common:filebox.create.folder-name-input-title";
        modalProps = {
            "show": true,
            "centered": true,
        };
        ModalBody = () => {
            let { newFolderName } = this.state;
            return (
                <form name="form_folder_name_input">
                    <Form.Row>
                        <Form.Group as={Col} controlId="folder_name" className={""}>
                            <Form.Label>{t('common:filebox.create.folder-name-input')}</Form.Label>
                            <Form.Control
                                name='folder_name'
                                placeholder={t('common:filebox.create.folder-name-input')}
                                onChange={(ev) => {
                                    // let { newFolderName } = this.state;
                                    // console.log(newFolderName);
                                    // console.log(ev.target.value);
                                    newFolderName = ev.target.value;
                                    this.setState({ newFolderName });
                                }}
                                value={newFolderName}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('common:message.message.filebox.create.folder-name-required')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                </form>
            );
        };

        /* */
        ModalAction = [];
        ModalAction.push(
            <Button key="ok" sx={{ml:1}} variant="contained" onClick={(ev) => {
                this.setState({ ModalBody: Loading, ModalAction: [] }, this.createNewFolderSubmit);
            }}>
                <span>{t("common:filebox.create.new-folder-submit")}</span>
            </Button>
        );
        onExitedModal = this.onCreateFolderDone;

        // newFolderName = "";
        // console.log(newFolderName)
        /* */
        this.setState({ newFolderName, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal });
    }

    startDownloadFile = (url, name) => {
        let aLink = document.createElement("a");
        if (name) {
            var request = new XMLHttpRequest();
            request.open('GET', url, true);
            request.responseType = 'blob';
            request.onload = (ev) => {
                aLink.href = URL.createObjectURL(ev.target.response);
                aLink.download = name;
                aLink.click();
                this.setState({ downloading: false });
            };
            request.send();
        } else {
            aLink.href = url;
            aLink.click();
            this.setState({ downloading: false });
        }
    }

    downloadSelectedFiles = () => {
        this.setState({ downloading: true }, (ev) => {
            let { selectedPath, selectedItems, selectedRoot } = this.state;
            // let { directories } = root[selectedRoot];
            let { pathCursor } = this.getPathObject(selectedPath);
            let downloadTarget = pathCursor[selectedItems[0]];
            // console.log(downloadTarget);

            let downloadPath = `${selectedPath}/${selectedItems[0]}`;
            if (downloadTarget.type === "linkage") {
                Service.downloadAllAsZip(downloadTarget.document_id).then(resp => {
                    // console.log(resp);
                    this.startDownloadFile(resp.url);
                }).catch(err => {
                    console.log(err.response);
                });
            } else if (downloadTarget.hasOwnProperty("doc_path")) {
                Service.documentDownload(downloadTarget.doc_path).then(resp => {
                    // console.log(resp);
                    this.startDownloadFile(resp.url, downloadTarget.name);
                }).catch(err => {
                    console.log(err.response);
                });
            } else if (selectedRoot === "filebox") {
                Service.fileboxDownload(downloadPath).then(resp => {
                    // console.log(resp);
                    this.startDownloadFile(resp.url);
                }).catch(err => {
                    console.log(err.response);
                    this.setState({ downloading: false });
                });
            } else if (selectedRoot === "documents") {
                Service.documentDownload(downloadPath).then(resp => {
                    // console.log(resp);
                    this.startDownloadFile(resp.url);
                }).catch(err => {
                    console.log(err.response);
                    this.setState({ downloading: false });
                });
            }
        });
    }

    FileDeleteStatus = (props) => {
        // let { waitingFiles } = this.state;
        let { file } = props;
        if (file.hasOwnProperty("deleted")) {
            if (file.deleted === true) {
                return <FontAwesomeIcon icon={faCheckCircle} className="success" />;
            } else if (file.deleted === "deleting") {
                return <FontAwesomeIcon icon={faSpinner} pulse className="working" />;
            } else {
                return <FontAwesomeIcon icon={faTimesCircle} className="failure" />;
            }
        } else {
            return <FontAwesomeIcon icon={faCircle} className="waiting inqueue" />;
        }

    }

    onDeleteDone = () => {
        let { deleteDone, deleteObjects, root, selectedPath } = this.state;
        // let { directories } = root[selectedRoot];
        let { pathCursor } = this.getPathObject(selectedPath);

        if (deleteDone) {
            for (let file of deleteObjects) {
                delete pathCursor[file.name];
            }
            // this.setState({ root, ContentLoading: faSpinner, deleteObjects: null, deleteDone: false }, this.getDirectories);
        }
        this.setState({ root, deleteObjects: null, deleteDone: false });
    }

    DeleteConfirmSubmit = async () => {
        let { deleteObjects } = this.state;
        for (let file of deleteObjects) {
            file.deleted = "deleting";
            this.setState({ deleteObjects });

            let filePath = `${file.path}${file.name}`;
            let delResult = null;
            if (file.type === "dir") {
                delResult = await Service.fileboxDeleteFolder(filePath).catch(err => {
                    console.log(err.response);
                });
            } else {
                delResult = await Service.fileboxDeleteFile(filePath).catch(err => {
                    console.log(err.response);
                });
            }

            if (delResult) {
                file.deleted = true;
            } else {
                file.deleted = false;
            }
            this.setState({ deleteObjects });
        }
        this.setState({ deleteDone: true });
    }

    deleteItemHandler = () => {
        let { t } = this.props;
        let { selectedItems, selectedPath, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal } = this.state;

        // let { directories } = root[selectedRoot];
        let { pathCursor } = this.getPathObject(selectedPath);

        let deleteObjects = [];
        for (let file of selectedItems) {
            deleteObjects.push(pathCursor[file]);
        }

        modalTitle = "common:filebox.delete.comfirm-title";
        modalProps = {
            "show": true,
            "centered": true,
        };
        ModalBody = () => {
            let { deleteObjects } = this.state;
            let { FileDeleteStatus } = this;
            let FileElems = [];
            for (let file of deleteObjects) {
                // console.log(file);
                // let filePath = `${selectedPath}/${file}`;
                FileElems.push(
                    <div key={file.name} className="modal-working-logs">
                        <div className="status-icon">
                            <FileDeleteStatus file={file} />
                        </div>
                        <div className="message-label">
                            <span>{file.name}</span>
                        </div>

                    </div>
                );
            }
            return FileElems;
        };

        /* */
        ModalAction = [];
        ModalAction.push(
            <Button key="ok" sx={{ml:1}} variant="contained" onClick={(ev) => {
                this.setState({ ModalAction: [] }, this.DeleteConfirmSubmit);
            }}>
                <span>{t("common:filebox.delete.submit")}</span>
            </Button>
        );
        onExitedModal = this.onDeleteDone;

        /* */
        this.setState({ deleteObjects, modalTitle, modalProps, ModalAction, ModalBody, onExitedModal, deleteDone: false });
    }

    ToolbarContainer = () => {
        let { readOnly } = this.state;

        if (readOnly) {
            return null;
        }

        let { selectedItems, selectedRoot, selectedPath, downloading } = this.state;
        let newFolderdisabled = false;
        let deletedisabled = false;
        let downloaddisabled = false;
        let actNewFolder = this.addNewFolderHandler;
        let actDownload = this.downloadSelectedFiles;
        let actDelete = this.deleteItemHandler;
        // let { directories } = root[selectedRoot];
        let { pathCursor, parentCursor } = this.getPathObject(selectedPath);
        if (selectedItems.length === 0) {
            deletedisabled = true;
            actDelete = null;
        } else {
            for (let item of selectedItems) {
                let selectedObject = pathCursor[item];
                if (selectedObject) {
                    if (selectedObject.type === "linkage") {
                        deletedisabled =true;
                        actDelete = null;
                        break;
                    } else if (selectedObject.hasOwnProperty("doc_path")) {
                        deletedisabled = true;
                        actDelete = null;
                        break;
                    }
                }
            }
        }

        if (selectedItems.length === 0) {
            deletedisabled = true;
            actDelete = null;
        }
        if (selectedItems.length !== 1) {
            downloaddisabled = true;
            actDownload = null;
        } else {
            let selectedObject = pathCursor[selectedItems[0]];
            if (selectedObject) {
                if (selectedObject.type === "dir") {
                    downloaddisabled = true;
                    actDownload = null;
                }
            }
        }

        let DownloadIcon =(
                <CloudDownloadOutlinedIcon/>
            );
        if (downloading) {
            actDownload = null;
            DownloadIcon = <CircularProgress size={20}/>;
            downloaddisabled = true;
        }

        if (parentCursor && parentCursor.type === "linkage") {
            actNewFolder = null;
            newFolderdisabled = true;
        }

        let ActionIcons = [];
        // if (readOnly) {
        //     ActionIcons.push(<FontAwesomeIcon key="new-folder" icon={faPlusSquare} className={newFolderClass} onClick={actNewFolder} />);
        // } else 
        if (selectedRoot === "documents") {
            ActionIcons.push(
                <Tooltip key={0} title = "選択したファイルをダウンロード" arrow>
                <IconButton
                    disabled = {downloaddisabled}
                    key="download"
                    onClick={actDownload} >
                        {DownloadIcon}
                </IconButton>
                </Tooltip>
            );
        } else {
            ActionIcons.push(
                <Tooltip key={1} title = "ディレクトリにフォルダを作成" arrow>
                    <span>
                    <Button
                        key="create"
                        sx={{mr:2}}
                        variant="outlined"
                        size="small"
                        startIcon = {<CreateNewFolderOutlinedIcon/>}
                        disabled = {newFolderdisabled}
                        color='primary'
                        onClick={actNewFolder} >
                        {"フォルダを新規作成"}
                    </Button>
                    </span>
                </Tooltip>
            );
            ActionIcons.push(
                <Tooltip key={2} title = "選択しているファイルをダウンロード" arrow>
                    <span>
                    <IconButton
                        key="download"
                        disabled = {downloaddisabled}
                        color='primary'
                        onClick={actDownload} >
                        {DownloadIcon}
                    </IconButton>
                    </span>
                </Tooltip>
            );
            ActionIcons.push(
                <Tooltip key={3} title = "選択しているファイルを削除" arrow>
                    <span>
                    <IconButton
                        key="delete"
                        disabled = {deletedisabled} color='primary' onClick={actDelete} >
                        <DeleteIcon />
                    </IconButton>
                    </span>
                </Tooltip>
            );
        }
        // className = "action-icon disabled";

        return (
            <div className="toolbar">
                <div className="toolbar-items-container">
                    <div className="toolbar-left">
                    </div>
                    <div className="toolbar-center"></div>
                    <div className="toolbar-right">
                        {ActionIcons}
                    </div>
                </div>
            </div>
        );
    }

    FooterContainer = () => {
        let { selectedPath, selectedItems } = this.state;
        // let { directories } = root[selectedRoot];
        let currentPath = selectedPath;

        if (selectedPath !== "/") {
            currentPath = `${currentPath}/`;
        }
        if (selectedItems.length === 1) {
            currentPath = `${currentPath}${selectedItems[0]}`;
        } else if (selectedItems.length > 1) {
            currentPath = `${selectedItems.length} items selected.`;
        }
        let { pathCursor } = this.getPathObject(selectedPath);

        let items = [];
        if (pathCursor) {
            items = Object.keys(pathCursor);
        }

        return (
            <div className="footer-inner">
                <div className="footer-left">
                    <span>{currentPath}</span>
                </div>
                <div className="footer-center">

                </div>
                <div className="footer-right">
                    {`${items.length} item(s)`}
                </div>
            </div>
        );
    }

    RenderRootTree = () => {
        let { t } = this.props;
        let { selectedRoot, root } = this.state;
        // let { directories } = root[selectedRoot];
        let { BuildTreeView } = this;
        let RootItems = [];

        for (let i in root) {
            let item = root[i];
            let active = "";
            let style = {};

            if (selectedRoot === i) {
                active = " active";
            }

            RootItems.push(
                <div key={i} className={`accordion-group ${active}`} style={style}>
                    <div className="header" onClick={(ev) => {
                        let { root, selectedPath, selectedRoot, selectedItems, loadingTarget } = this.state;
                        root[selectedRoot].selectedPath = selectedPath;
                        selectedPath = root[i].selectedPath;
                        loadingTarget = selectedPath;
                        selectedRoot = i;
                        selectedItems = [];
                        // console.log(root, selectedRoot, selectedItems, selectedPath);
                        this.setState({ root, selectedRoot, selectedItems, selectedPath, loadingTarget }, () => {
                            if (root[i].directories == null) {
                                this.getDirectories();
                                this.setState({ ContentLoading: faSpinner }, this.getDirectories);
                            }
                        });
                    }}>
                        <span>{t(`common:${item.label}`)}</span>
                    </div>
                    <div className="detail">
                        <div className="tree-wrapper">
                            <BuildTreeView directories={item.directories} />
                        </div>
                    </div>
                </div>
            );
        }

        return RootItems;

    }

    RenderBody = () => {

        let { root } = this.state;
        // let { directories } = root[selectedRoot];
        let { RenderRootTree, ContainerBody, FooterContainer, ToolbarContainer } = this;
        // let active = "";
        let dirIconProps = { icon: faFolder, className: "icon" };
        // let treeExpand = faCaretRight;
        // let open = "";

        if (root.open === true) {
            dirIconProps.icon = faFolderOpen;
            // treeExpand = faCaretDown;
            // open = "open";
        }

        // if (selectedPath === "/") {
        //     active = " active";
        // }

        return (
            <div className="file-explorer-wrapper">

                <ToolbarContainer />

                <div className="container">
                    <div className="drawer">
                        <div className="tree-panel">
                            <RenderRootTree />
                        </div>
                        <div className="panel-separator">
                            <div className="line"></div>
                        </div>
                        <ContainerBody />
                    </div>
                </div>
                <div className="footer">
                    <FooterContainer />
                </div>
            </div>
        );

    }

    Reload = () => {
        let { t } = this.props;
        return (
            <div className="reload-container">
                <Button onClick={(ev) => {
                    this.setState({ failure: false }, this.getDirectories);
                }}>{t("common:filebox.general.reload")}</Button>
            </div>
        );
    }

    render() {
        let { root, failure } = this.state;
        let { GeneralModal } = this;
        let FileExplorerBody = Loading;

        if (failure) {
            FileExplorerBody = this.Reload;
        }

        for (let i in root) {
            if (root[i].directories !== null) {
                FileExplorerBody = this.RenderBody;
                break;
            }
        }

        // if (root.filebox.directories !== null || root.documents.directories !== null) {
        //     FileExplorerBody = this.RenderBody;
        // } else 

        return (
            <div className="file-explorer">
                <FileExplorerBody />
                <GeneralModal />
            </div>
        );
    }
}

export default withTranslation()(FileExplorer);
