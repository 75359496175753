import PDFViewer from "../Fragments/PDFViewer";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
// import CryptoJS from 'crypto-js';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default class VerificationUpload {

	static get key() {
		return "verification-upload";
	}
	static get name() {
		return "VerificationUpload";
	}
	static get code() {
		return VerificationUpload.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = VerificationUpload.key;
		this.name = VerificationUpload.name;
		this.code = VerificationUpload.code;

		this.objMain.setPageObject(this);
	}

	// registerFiles = (ev) => {
	// 	let files = ev.target.files;
	// 	for (let file of files) {
	// 		let reader = new FileReader();
	// 		reader.onload = (ev) => {
	// 			// console.log(ev);
	// 			let content = CryptoJS.enc.Latin1.parse(reader.result);
	// 			var fileKey = CryptoJS.MD5(content).toString();
	// 			let { PDFFiles } = this.objMain.state;
	// 			PDFFiles[fileKey] = {
	// 				file: file,
	// 				name: file.name,
	// 				type: file.type,
	// 				size: file.size,
	// 				lastModified: file.lastModified,
	// 				lastModifiedDate: file.lastModifiedDate,
	// 			};
	// 			this.objMain.updateState({ PDFFiles });
	// 			this.objMain.updateState({ SelectedFile: fileKey });
	// 			// console.log(this.objMain.state.PDFFiles);
	// 		};
	// 		reader.onerror = () => {
	// 			//console.log(reader.error);
	// 		};
	// 		reader.readAsBinaryString(file);
	// 	}
	// }

	// formSubmitValidation = (ev, PDFFiles) => {
	// 	ev.preventDefault();

	// 	let { pageState } = this.objMain.state;
	// 	pageState[this.name].completed = true;
	// 	this.objMain.updateState({ pageState: pageState, PDFFiles });
	// 	this.objMain.nextPageStep(ev, this.constructor);
	// }

	PDFViewerSubmitCallBack = (ev, files) => {
		console.log("PDFViewerSubmitCallBack", files);
		let { pageState, PDFFiles, PDFFile, selectedFile, formData, originalFormData, creationMode, creationType } = this.objMain.state;
		pageState[this.name].completed = true;
		PDFFiles = files;

		if (Object.keys(PDFFiles).length > 0) {
			if (!PDFFile || Object.keys(PDFFile).length === 0 || !Object.keys(PDFFiles).includes(PDFFile.hash)) {
				PDFFile = Object.values(PDFFiles)[0];
				selectedFile = PDFFile.hash;
			}
		} else {
			selectedFile = null;
			PDFFile = {};
		}

		if (creationMode === "multiple") {

			for (let key in PDFFiles) {
				let oFile = PDFFiles[key];
				if (!oFile.formData) {
					oFile.formData = {}

					oFile.formData.doc_title = (oFile.info && oFile.info.Title) || "";
					oFile.formData.document_type = originalFormData.document_type;
					oFile.formData.amount = "";
					oFile.formData.currency = originalFormData.currency;
					oFile.formData.product = "";
					oFile.formData.reference = "";
					oFile.formData.filebox_shortcut = "";
					oFile.formData.function_type = originalFormData.function_type;
					oFile.formData.contract_date = "";
					oFile.formData.expiry_date = "";
					oFile.formData.received_date = "";
					oFile.formData.counter_party_name = "";
					oFile.formData.content = "";
					oFile.formData.preservation_require = 2;

					// if (!oFile.formData.doc_attributes) {
					let customKeys = {
						Amount: { formKey: "amount", default: "" },
						Currency: { formKey: "currency", default: "JPY" },
						ContractDate: { formKey: "contract_date", default: "" },
						ExpiryDate: { formKey: "expiry_date", default: "" },
						ReceivedDate: { formKey: "received_date", default: "" },
						CounterPartyName: { formKey: "counter_party_name", default: "" },
						ProductName: { formKey: "product", default: "" },
						RefDocument: { formKey: "reference", default: "" },
					}
					oFile.formData.doc_attributes = [];
					for (let key in oFile.info && oFile.info.Custom) {
						if (Object.keys(customKeys).includes(key)) {
							let custom = customKeys[key];
							oFile.formData[custom.formKey] = oFile.formData[custom.formKey] || oFile.info.Custom[key] || custom.default;
						} 
						// else {
						// 	oFile.formData.doc_attributes.push({ key: key, value: oFile.info.Custom[key] });
						// }
					}

					if (creationType !== "verification" || ![1, "1"].includes(oFile.formData.preservation_require)) {
						let validate = [];
						validate.push(oFile.formData.doc_title ? true : false);
						validate.push(oFile.formData.amount ? true : false);
						validate.push(oFile.formData.received_date ? true : false);
						validate.push(oFile.formData.counter_party_name ? true : false);
						if (validate.includes(false)) {
							oFile.validity = false;
						}
					}
				}
				// console.log(oFile.formData);
			}

			formData = PDFFile.formData;

		}

		// this.objMain.setFormData(formData);
		this.objMain.updateState({ pageState: pageState, PDFFiles, PDFFile, selectedFile, formData });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	FileListChangeCallback = (fileKey = null) => {
		console.log("FileListChangeCallback", fileKey);
		let { PDFFiles, PDFFile, selectedFile, formData, originalFormData, creationMode } = this.objMain.state;
		selectedFile = null;
		PDFFile = {};
		formData = null;
		if (fileKey && creationMode === "multiple") {
			selectedFile = fileKey;
			PDFFile = PDFFiles[fileKey];
			if (PDFFile.formData) {
				formData = {};
				for (let i in PDFFile.formData) {
					formData[i] = PDFFile.formData[i];
				}
			} else {
				formData = originalFormData;
			}
		}
		// console.log(PDFFile.formData);
		if (!formData) {
			formData = originalFormData;
			// console.log(originalFormData);
		}

		this.objMain.updateState({ PDFFile, selectedFile, formData });
	}

	FileListRemoveCallback = (fileKey) => {
		// let { PDFFiles, PDFFile, selectedFile, formData, originalFormData } = this.objMain.state;
		// selectedFile = null;
		// PDFFile = {};
		// formData = null;
		// if (fileKey) {
		// 	selectedFile = fileKey;
		// 	PDFFile = PDFFiles[fileKey];
		// 	formData = PDFFile.formData || originalFormData;
		// }
		// console.log(fileKey);
		// console.log(PDFFiles);
		// if (!formData) {
		// 	formData = originalFormData;
		// }

		// this.objMain.updateState({ PDFFile, selectedFile, formData });
	}

	render = () => {
		let { t } = this.props;
		let { PDFFiles, PDFFile, creationMode } = this.objMain.state;
		console.log(PDFFiles, PDFFile);
		let multiple = false;
		if (creationMode === "multiple") {
			multiple = true;
		}

		let viewerProps = {
			"files": PDFFiles,
			"file": PDFFile,
			"upload": true,
			"showAll": true,
			"fit": true,
			"controller": true,
			"multiple": multiple,
			"list": multiple,
			"info": !multiple,
			"fileChangeCB": this.FileListChangeCallback,
			"fileRemoveCB": this.FileListRemoveCallback,
			"submitCB": this.PDFViewerSubmitCallBack,
		}

		return (
			<div className={"right-body-container"} >
				{/* multiple={true} list={true}  */}
				<PDFViewer {...viewerProps}>

					<div className="form-container">
						<div className="page-action-bottom">

							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									disabled
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'
									endIcon={<ChevronRightIcon />}
								>
									<span className="btn-label">{t('common:documents.general.next')}</span>
								</Button>
							</div>

						</div>
					</div>

				</PDFViewer >
			</div >
		);
	}

}
