import React from 'react';
import logo from '../../Assets/img/Logo2.svg';

import { withTranslation } from "react-i18next";
import Agreement from './Agreement';
import '../../Assets/css/auth.css';
import {
	Form,
	Col,
	Button,
	InputGroup,
	// Dropdown,
	Modal,
} from 'react-bootstrap';

// import LoadingComponent from "../../../components/loading/LoadingComponent";

class Registration extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			formData: {},
			formValidate: {},
			fieldValidate: {},
			currentPage: 0,
			modalShow: false,
			modalMessage: "test",
			activePage: Agreement,
			partner_id: 0,
		};

		/* Temporary set fields value */
		// this.state.formData = this.initialTempFormData();
	}

	initialTempFormData() {
		return {
			lastname: "a@s",
			firstname: "a@s",
			lastname_kana: "a@s",
			firstname_kana: "a@s",
			email: "a@s",
			mobile: "a@s",
			password: "0987656343Wasan@s-s-c.co.th",
			password_confirm: "0987656343Wasan@s-s-c.co.th",
			company_name: "a@s",
			company_name_kana: "a@s",
			company_address: "a@s",
			company_telephone: "a@s",
			term_condition: "Accepted",
		};
	}

	setPageObject = (page) => {

		let { t } = this.props;
		let { formData, fields, formValidate, fieldValidate } = this.state;

		page.props = this.props;
		page.title = "common:auth.registration." + page.key + ".title";
		page.headerLabel = "common:auth.registration." + page.key + ".header";
		page.headerInfo = t(page.headerLabel);

		if (!formValidate.hasOwnProperty(page.name)) {
			formValidate[page.name] = false;
		}
		if (!fieldValidate.hasOwnProperty(page.name)) {
			fieldValidate[page.name] = {};
		}

		page.fields = {};

		for (let i in page.formData) {
			if (!fields.hasOwnProperty(i)) {
				fields[i] = {
					name: i,
					value: "",
					label: t(page.formData[i]),
				};
				if (page.hasOwnProperty("initialValues")) {
					if (page.initialValues.hasOwnProperty(i)) {
						fields[i].value = page.initialValues[i];
					}
				}
			}
			page.fields[i] = fields[i];

			if (formData.hasOwnProperty(i)) {
				page.fields[i].value = formData[i];
			}

			page.formData[i] = page.fields[i].value;
		}
		// let PageProps = new activePage(this);

		// console.log(page.title);
		// console.log(formData.lastname);
		document.title = t(page.title || "common:auth.registration.title");
	}

	formSubmitHandler = (ev, page, fnCallback) => {
		ev.preventDefault();

		let { location } = this.props;
		let { formValidate, fieldValidate, partner_id } = this.state;
		// Get partner_id
		let searchParam = new URLSearchParams(location["search"]);
		if (searchParam.has("partner_id")) {
			partner_id = searchParam.get("partner_id");
		}
		this.setState({ partner_id });
		formValidate[page.name] = true;
		fieldValidate[page.name] = {};
		this.setState({ formValidate });
		this.setState({ fieldValidate });
		const form = ev.target;
		// this.additionalValidity(form)
		if (form.checkValidity() === false) {
			ev.stopPropagation();
		} else {
			if (typeof fnCallback == "function") {
				fnCallback(ev);
			}
		}
	};

	ValidateMessage = (props) => {
		let { t } = this.props;
		let { field } = props;
		let { fieldValidate, activePage } = this.state;

		let pageName = activePage.name;

		let msgValidate = t('common:message.input.required', { field_name: field.label });
		if (fieldValidate[pageName].hasOwnProperty(field.name)) {
			msgValidate = t(`common:${fieldValidate[pageName][field.name]}`);
		}

		return <span>{msgValidate}</span>

	}

	CusFormControl = (props, OptionItems) => {
		let { field } = props;
		let { formData } = this.state;
		if (OptionItems) {
			return (
				<Form.Control as="select"
					placeholder={field.label}
					value={formData[field.name]}
					onChange={this.onChangeHandler}
					{...props}
				>
					<OptionItems />
				</Form.Control>
			)
		} else {
			return (
				<Form.Control
					placeholder={field.label}
					value={formData[field.name]}
					onChange={this.onChangeHandler}
					{...props}
				/>
			);
		}
	}

	FieldControl = (props) => {
		if (!props.hasOwnProperty("field")) {
			return null;
		}

		let { field } = props;
		let { ValidateMessage } = this;
		let { formData } = this.state;

		let newProps = {};
		for (let i in props) {
			if (props[i] instanceof Object) {
				continue;
			}
			newProps[i] = props[i];
		}

		let Prepend = () => { return null; }
		if (props.hasOwnProperty("prepend")) {
			Prepend = props.prepend;
		}

		if (!props.hasOwnProperty("type")) {
			newProps.type = "text";
		}

		// console.log(field);
		// console.log(formData[field.name]);

		return (
			<Form.Group as={Col} controlId={field.name} className="custom-form-outline">
				<Form.Label>{field.label}</Form.Label>
				<InputGroup className="mb-3">
					<Prepend />
					<Form.Control
						name={field.name}
						placeholder={field.label}
						value={formData[field.name] || ""}
						onChange={this.onChangeHandler}
						{...newProps}
					/>
					<Form.Control.Feedback type="invalid">
						<ValidateMessage field={field} />
					</Form.Control.Feedback>
				</InputGroup>
			</Form.Group>
		);
	}

	setActivePage = (PageClass) => {
		this.setState({ activePage: PageClass });
	}

	setFormData = (data) => {
		let { formData } = this.state;
		for (let i in data) {
			formData[i] = data[i];
		}

		this.setState({ formData });
	}

	onChangeHandler = (event) => {
		let key = event.target.name;
		let val = event.target.value;
		let { formData } = this.state;
		// console.log(formData);
		formData[key] = val;
		this.setState({ formData });
	}

	componentDidMount() {
		// document.title = this.props.t("common:auth.registration.agreement.title");
	}

	componentDidUpdate(prevProps) {
		// document.title = this.props.t("common:auth.register.title");
		// console.log("componentDidUpdate", prevProps);
	}

	modalToggle = (toggle) => {
		this.setState({ modalShow: toggle });
	}

	render() {

		let { t } = this.props;
		let { activePage, modalShow, modalMessage } = this.state;
		// console.log(activePage);
		let PageProps = new activePage(this);
		// console.log(PageProps);

		let CurrentForm = PageProps.render();
		// console.log(this.state);s

		return (
			<div className="auth contrainer-wrapper">
				<div className="auth contrainer-wrapper horizontal">
					<div className="contrainer-block">

						<div className="form-wrapper registration" style={{ "width": PageProps.containerWidth || "600px" }}>

							<div className="logo-horizontal">
								<img src={logo} className="App-logo" alt="paperlogic" />
							</div>

							<div className="form-header">
								<div className="header-info">
									{PageProps.headerInfo}
								</div>
							</div>

							{/* <Button variant="primary" onClick={(ev) => { this.modalToggle(true) }}>Launch static backdrop modal</Button> */}

							{/* <MDBValidation id="main_form" noValidate> */}
							{CurrentForm}

						</div>

					</div>

					<div className="footer">
						<div>© 2021 paperlogic.co.jp</div>
					</div>
				</div>

				<div>

					<Modal show={modalShow} animation={false} onHide={(ev) => { this.modalToggle(false) }} >
						<Modal.Header closeButton>
							<Modal.Title>Modal title</Modal.Title>
						</Modal.Header>
						<Modal.Body>{modalMessage}</Modal.Body>
						<Modal.Footer>
							<Button variant="primary" onClick={(ev) => { this.modalToggle(false) }}>{t('common:auth.general.ok')}</Button>
						</Modal.Footer>
					</Modal>

				</div>

			</div >
		);
	}

}
export default withTranslation()(Registration);
