import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/img/Logo2.svg';
import Cookies from 'js-cookie';
import '../../Assets/css/auth.css';

import { withTranslation } from "react-i18next";
// import Service from '../../../Service';
import Loading from '../../Loading';
// import Error from "../../Error";

import {
    Button,
} from 'react-bootstrap';
import AuthService from '../../../Service/Auth';

class Processing extends React.Component {
    constructor(props) {
        super(props);
        // console.log(props);
        if (!props.location.state) {
            // return <Error code={404} />;
            window.location.href = '/login';
        }
        this.state = props.location.state;
        this.state.formData.mobile = this.state.formData.full_mobile;
        this.state.registerStatus = "processing";
        this.state.message = "";
        this.state.formData.lang = Cookies.get('lang');
        if (!this.state.formData.lang) {
            this.state.formData.lang = Cookies.set('lang', 'ja');
        }
        // console.log(this.state);

    }

    componentDidMount() {
        document.title = this.props.t("common:auth.registration.processing.title");
        this.registerRequest();
    }

    registerRequest = () => {
        let { t } = this.props;
        let { formData } = this.state;
        AuthService.AccoutRegister(formData).then(response => {
            this.setState({ message: t('common:auth.registration.processing.success') });
            this.setState({ registerStatus: "success" });
            window.location.href = '/registration/complete';
        }).catch(error => {
            error = error.response.data;
            this.setState({ message: t(error.message) });
            this.setState({ registerStatus: "error" });
        });
    }

    updateRegisterStatus = (registerStatus) => {
        this.setState({ message: "" });
        this.setState({ registerStatus });
    }

    componentDidUpdate(prevProps) {
        let { registerStatus } = this.state;
        if (["processing", "retry"].includes(registerStatus)) {
            this.registerRequest();
        } else if (registerStatus === "success") {
            window.location.href = '/registration/complete';
        }
        // document.title = this.props.t("common:auth.register.title");
        // console.log("componentDidUpdate", prevProps);
    }

    RegistrationRetry = (ev) => {
        this.updateRegisterStatus("retry");
    }

    render() {

        let { t } = this.props;
        let { message, registerStatus } = this.state;
        let TryAgain = "";
        let Body = <Loading />;
        if (message) {
            Body = message;
        }
        if (registerStatus === "error") {
            TryAgain = <Button variant="link" onClick={this.RegistrationRetry}>{t('common:auth.registration.processing.try-again')}</Button>;
        }

        return (
            <div className="auth contrainer-wrapper">
                <div className="auth contrainer-wrapper horizontal">
                    <div className="contrainer-block">

                        <div className="form-wrapper registration" style={{ "width": "600px" }}>

                            <div className="logo-horizontal">
                                <img src={logo} className="App-logo" alt="paperlogic" />
                            </div>

                            <div className="form-header">
                                <div className="header-info">
                                    <h3>{t('common:auth.registration.processing.header')}</h3>
                                </div>
                            </div>


                            <div className="form-container">

                                <div className="fields-container">
                                    {Body}
                                </div>

                                <div className="form-bottom">
                                    <div className="wrapper-button-cancel">
                                        <Link to='/login' style={{ width: '100%' }}>{t('common:auth.registration.success.goto-login')}</Link>
                                    </div>
                                    <div className="wrapper-button-submit">
                                        {TryAgain}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="footer">
                        <div>© 2021 paperlogic.co.jp</div>
                    </div>
                </div>

            </div >
        );
    }

}
export default withTranslation()(Processing);