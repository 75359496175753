import React from 'react'
import { withTranslation } from 'react-i18next';
import Layout from '../../Layout';
import Menu from "./Menu";
import Loading from "../Loading";
import { Col, Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import Service from '../../Service';
import ipRangeCheck from 'ip-range-check';
class RestrictIP extends Layout {
	constructor(props) {
		super(props);
		this.state.page = "restrictIp-setting";

		this.state.switchLoading = false;
		this.state.use_restrictIp = false;
		this.state.fields = {};
		this.state.keyFields = {
			address: "IPアドレス",
		};
	}

	componentDidMountExtends = async () => {
		let { UserInfo } = this.state;
		const data = {
			tenant_id: UserInfo.current_tenant_id
		}
		const tenantData = await Service.getTenant(data)
		const use_restrictIp = !!tenantData.enableRestrictIp
		const restrictIp = tenantData.restrictIp
		const formData = {
			address: restrictIp !== null ? restrictIp.data.join('\n') : ''
		};
		this.setState({ use_restrictIp, formData });
		this.setStateFields();
	};

	setStateFields = (cb) => {
		// let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label,
				// label: t(`common:settings.sso.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	};

	Menu = () => {
		return <Menu {...this.props} page={this.state.page} />;
	};

	RequireFormLabel = () => {
		return (
			<Form.Group as={Col} xs={1} style={{ color: "red", marginTop: "30px" }}>
				必須
			</Form.Group>
		);
	};
	 IPtoNum(ip){
		return Number(
		  ip.split(".")
			.map(d => ("000"+d).substr(-3) )
			.join("")
		);
	  }
	isIP(ip) {
		// console.log(this.IPtoNum('134.168.1.1') < this.IPtoNum('192.168.1.112') &&  this.IPtoNum('192.168.1.112') > this.IPtoNum('134.168.1.1'))
		if (ip.includes('/') && !ip.includes('~')){
			const baseIp = ip.split('/');
			// console.log('cidr ip', ip, ipRangeCheck(baseIp, ip))
			if(baseIp.length > 0) {
				return ipRangeCheck(baseIp[0], ip);
			}
			return false;
		} else if (ip.includes('~') && !ip.includes('/')){
			const ipSplit = ip.replace(/\s/g,'').split('~');
			// console.log(this.IPtoNum(ipSplit[0]) < this.IPtoNum(ipSplit[1]))
			if(ipSplit.length > 1) {
				return this.IPtoNum(ipSplit[0]) < this.IPtoNum(ipSplit[1]) && this.IPtoNum(ipSplit[1]) > this.IPtoNum(ipSplit[0]);
			}
			return false;
		} else {
			// console.log('norip',ip, ipRangeCheck(ip, ip));
			return ipRangeCheck(ip, ip);
		}
	}
	  
	validateIp = (address) => {
		if (address == null || address === "") {
			return true
		}
		// eslint-disable-next-line no-useless-escape
		return address.split('\n').every(ad => {
			return this.isIP(ad);
			// return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ad)
		})
	};

	updateTenant = async () => {
		let { modalTitle, ModalBody, modalProps } = this.state;
		modalProps = { ...modalProps, show: true, centered: true };
		modalTitle = "";
		ModalBody = Loading;
		this.setState({ modalTitle, ModalBody, modalProps });

		let { UserInfo, formData, use_restrictIp } = this.state;
		const data = {
			tenant_id: UserInfo.current_tenant_id
		}
		const tenantData = await Service.getTenant(data)
		const restrictIp = !formData["address"] ? null : { data: formData["address"].split('\n') }
		let body = {
			"tenant_id": tenantData["tenant_id"],
			"company_id": tenantData["company_id"],
			"name": tenantData["name"],
			"api_token": tenantData["api_token"],
			"status": tenantData["status"],
			"enableRestrictIp": use_restrictIp ? 1 : 0,
			"restrictIp": restrictIp,
		};
		await Service.UpdateTenant(body).then(() => {
			this.setState({ modalTitle: "完了", ModalBody: () => <>登録しました。</> });
		}).catch(() => {
			this.setState({ modalTitle: "エラー", ModalBody: () => <>エラーが発生しました。再度処理してください。</> });
		})

	}

	handleSwitch = () => {
		let { modalTitle, ModalBody, modalProps, ModalAction, use_restrictIp } = this.state;
		const use_restrictIp_to = !use_restrictIp;
		const { t } = this.props;
		if (use_restrictIp_to === true) {
			this.setState({ use_restrictIp: use_restrictIp_to }, async () => {
				await this.updateTenant();
			});
			return
		}

		modalProps = { ...modalProps, show: true, centered: true };
		this.setState({ modalTitle: "", ModalBody: Loading, modalProps });

		modalTitle = "確認";
		ModalBody = () => (
			<>
				IPアドレス制限を無効にします。
				<br />
				よろしいですか？
			</>
		);
		ModalAction = [
			<Button
				key="ok"
				sx={{ ml: 1 }}
				type="submit"
				variant="contained"
				onClick={async () => {
					this.setState(
						{ modalTitle: "削除中", ModalBody: Loading, ModalAction: [] },
						async () => {
							this.setState({ use_restrictIp: use_restrictIp_to }, async () => {
								await this.updateTenant();
							});
							ModalBody = () => <>無効にしました</>;
							this.setState({
								modalTitle: "完了",
								ModalBody,
								ModalAction: [],
							});
						}
					);
				}}
			>
				<span>{t("common:general.ok")}</span>
			</Button>,
		];
		this.setState({ modalTitle, ModalBody, modalProps, ModalAction });
	}

	IpAddressInputBox = () => {
		const { RequireFormLabel, ValidateMessage, updateTenant } = this;
		const { formData, fields } = this.state;

		return (
			<div className="form-container">
				<Form.Row>
					<Form.Group as={Col} controlId="formGridCity">
						<Form.Label>{"IPアドレス"}</Form.Label>
						<Form.Control
							as="textarea"
							value={formData["address"] || ""}
							rows={6}
							placeholder={"1行に1つのIPアドレスを設定してください"}
							name="address"
							type="address"
							isInvalid={!this.validateIp(formData["address"])}
							validate={`${this.validateIp(formData["address"])}`}
							onChange={this.onChangeHandler}
						/>
						<Form.Control.Feedback type="invalid">
							{fields["address"] && <ValidateMessage field={fields["address"]} />}
						</Form.Control.Feedback>
					</Form.Group>
					<RequireFormLabel />
				</Form.Row>

				<Form.Row className="justify-content-center">
					<Button
						className="text-center"
						style={{ padding: "6px 48px", margin: "12px" }}
						variant="contained"
						disabled={!this.validateIp(formData["address"])}
						onClick={() => updateTenant()}
					>
						<span className="label">確定</span>
					</Button>
				</Form.Row>
			</div>
		);
	};

	render() {
		const { use_restrictIp, switchLoading } = this.state;
		const { Main, IpAddressInputBox } = this;
		return (
			<Main>
				<div className="setting-restrictIp p-4">
					<Form.Check
						id="use-restrictIp-switch"
						key="use-restrictIp-switch"
						type="switch"
						onChange={this.handleSwitch}
						checked={use_restrictIp}
						disabled={switchLoading}
						label={"IPアドレス制限を使用する"}
					// label={t("common:settings.sso.use_restrictIp")}
					/>
					<IpAddressInputBox />
				</div>
			</Main>
		);
	}
}

export default withTranslation()(RestrictIP);
