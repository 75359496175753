import axios from 'axios';
import API from '../Config/API';
import Amplify, { Auth } from "aws-amplify";
import {
	CognitoUserSession,
	CognitoIdToken,
	CognitoAccessToken,
	CognitoRefreshToken,
	CognitoUser,
	CognitoUserPool,
} from "amazon-cognito-identity-js";
import awsCognito from "../Config/Cognito";
import Service from '../Service';
import { Credentials } from '@aws-amplify/core';
Amplify.configure(awsCognito);

const AuthService = class {

	static Login = async (user, pass) => {
		// console.log(user, pass);
		return await new Promise((resolve, reject) => {
			Auth.signIn(user, pass).then(async user => {
				resolve(user);
				// console.log(user);
			}).catch(error => {
				reject(error);
			});
		});
	}

	/**
	 *  SSO to Idp
	 */
	static SsoLogin = async (ssoDomain) => {
		const callbackUrl = document.location.origin;
		const {domain, userPoolWebClientId } = awsCognito.Auth;
		const url = `${domain}/authorize?idp_identifier=${ssoDomain}&response_type=code&client_id=${userPoolWebClientId}&redirect_uri=${callbackUrl}/cognito-callback`;
		window.open(url, "_self");
	};

	/**
	 * get id_token from authrorize_code
	 */
	static getTokenFromCode = async (code) => {
		const callbackUrl = document.location.origin;
		const { domain, userPoolWebClientId } = awsCognito.Auth;
		const url = `${domain}/token?grant_type=authorization_code&client_id=${userPoolWebClientId}&redirect_uri=${callbackUrl}/cognito-callback&code=${code}`;
		const response = await axios.post(
			url,
			{},
			{
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			},
		);
		return response.data;
	};
	
	/**
	 * get and set user info from id_token
	 */
	static getUserInfoFromToken = async (token) => {
		const session = new CognitoUserSession({
			IdToken: new CognitoIdToken({ IdToken: token.id_token }),
			RefreshToken: new CognitoRefreshToken({
				RefreshToken: token.refresh_token,
			}),
			AccessToken: new CognitoAccessToken({
				AccessToken: token.access_token,
			}),
		});
		await Credentials.set(session, "session");
		const currentUser = this.createCognitoUser(
			session.getIdToken().decodePayload()["cognito:username"]
		);

		currentUser.setSignInUserSession(session);
	};

	static createCognitoUser(username) {
		const userData = {
			Username: username,
			Pool: new CognitoUserPool({
				UserPoolId: awsCognito.Auth.userPoolId,
				ClientId: awsCognito.Auth.userPoolWebClientId,
			}),
		};
		const user = new CognitoUser(userData);
		return user;
	};

	static GetCurrentLogin = async () => {
		//console.log("user, pass");
		return await new Promise((resolve, reject) => {
			Auth.currentAuthenticatedUser({
				bypassCache: false,
			}).then(async (user) => {
				resolve(user);
			}).catch(async (error) => {
				reject(error);
			});
		});
	}

	static GetUserAttributes = async (key) => {
		const { attributes } = await Auth.currentAuthenticatedUser({bypassCache: true});
		if (key && attributes.hasOwnProperty(key)) {
			return attributes[key];
		} else {
			return attributes;
		}

	}

	static GetCurrentSession = async () => {
		let result = await Auth.currentSession().catch(err => console.log(err));
		// console.log(result);
		return result;
		// .then(data => console.log(data))/



	}

	static GetTokenID = async () => {
		// const { attributes } = await Auth.currentAuthenticatedUser();
		// if (key && attributes.hasOwnProperty(key)) {
		// 	return attributes[key];
		// } else {
		// 	return attributes;
		// }
		let result = await AuthService.GetCurrentSession();

		// let result = await Auth.currentSession().catch(err => console.log(err));
		// console.log(result);
		return result.idToken.jwtToken;
		// .then(data => console.log(data))/



	}

	static AccoutRegister = async (data) => {
		const uri = API.url + "/register/account.json";
		// console.log(data);
		return await new Promise((resolve, reject) => {
			axios.post(uri, data).then(async resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static RegisterVerification = async (token) => {
		const uri = API.url + "/register/verify.json";
		// console.log(uri);

		return await new Promise((resolve, reject) => {
			axios.post(uri, { token }).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static RegisterResendVerifyEmail = async (email) => {
		const uri = API.url + "/register/resend-email.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, { email }).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static AccountRecoveryRequest = async (email) => {
		const uri = API.url + "/recovery/request.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, { email }).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static AccountRecoveryReset = async (token, password, password_confirm) => {
		const uri = API.url + "/recovery/reset.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				password,
				password_confirm
			}, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static UserVerification = async (token, password, password_confirm) => {
		const uri = API.url + "/user/host/verify.json";

		return await new Promise((resolve, reject) => {
			axios.post(uri, {
				password,
				password_confirm
			}, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	static Logout() {
		const callbackUrl = document.location.origin;
		const { domain, userPoolWebClientId } = awsCognito.Auth;
		const url = `${domain}/logout?client_id=${userPoolWebClientId}&logout_uri=${callbackUrl}/login`;

		Service.deleteUserControl().then(response => {
			Auth.signOut().then(async resp => {
				window.open(url, "_self");
				// window.location.href = '/login';
			}).catch(error => {
				//console.log(error.response);
			});
        }).catch((error) => {
            //console.log("CATCH", error);
        });
	}

	static async adminResetMfa(email){
		const uri = API.url + "/mfa-reset.json";
		const token = await AuthService.GetTokenID();
		return new Promise((resolve, reject) => {
			axios.post(uri, { userName: email }, {
				headers: {
					Authorization: token,
				},
			}).then(resp => {
				resolve(resp.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

}
	
export default AuthService;
