import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
export default function LoadingDashboard(props) {
	
	return (
		<Stack sx={{p:2,pt:2}} spacing={2}>
            <Skeleton variant="text" width="30%"/>
            <Stack direction="row" spacing={2}>
                
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="74%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
               
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="74%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="74%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="74%" height={50} />
            </Stack>
            <Stack spacing={2}>
                <Skeleton variant="text" width="20%"/>
                <Skeleton variant="text" width="15%"/>
            
        
                <Stack direction="row" spacing={2}>
                    <Skeleton variant="rectangular" width="13%" height={57} />
                    <Skeleton variant="rectangular" width="13%" height={57} />
                    <Skeleton variant="rectangular" width="13%" height={57} />
                    <Skeleton variant="rectangular" width="13%" height={57} />
                    <Skeleton variant="rectangular" width="13%" height={57} />
                    <Skeleton variant="rectangular" width="13%" height={57} />
                </Stack>
            </Stack>
        </Stack>
	);
}
