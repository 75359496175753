import React from 'react';
import Layout from "../../Layout";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
    // Document,
    //  Page, 
    pdfjs
} from 'react-pdf';
// import { version } from 'pdfjs-dist';
// import pdfjs from 'pdfjs-dist-enable-signature';
// import PDF from 'react-pdf-js';
// import pdfjs from 'react-pdf-js-enable-signature';
// import md5 from 'md5';
// import CryptoJS from 'crypto-js';
import MenuConfig from '../../Config/Menu';
// import Dashboard from '../Main/Dashboard';
// import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

// pdfjs.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import FileViewer from 'react-file-viewer';

const file = 'example03.docx'
const type = 'docx'

class Test extends Layout {
    constructor(props) {
        super(props);
        // console.log(props);
        this.state.translateKeyPrefix = "common:main.";
        this.state.canvas = null;
        // this.state.activePage = Dashboard;
    }

    // componentWillMount() {
    //     var promise = new Promise( (resolve, reject) => {

    //      let name = 'Paul'

    //      if (name === 'Paul') {
    //       resolve("Promise resolved successfully");
    //      }
    //      else {
    //       reject(Error("Promise rejected"));
    //      }
    //     });

    //     let obj = {newName: ''};

    //     promise.then( result => {
    //      this.setState({name: result});
    //     }, function(error) {
    //      this.setState({name: error});
    //     });
    //    }


    Menu = () => {
        let menuKeys = Object.keys(MenuConfig);
        let { activePage } = this.state;
        let { t } = this.props;

        let MenuComponent = menuKeys.map((key) => {
            let Menu = MenuConfig[key];
            let IconMenu = Menu.icon;
            let classname = "menu-item-custom";
            if (activePage === Menu.page) {
                classname += " active";
            }

            return (
                <li key={key} className={classname}>
                    <Link onClick={(ev) => { this.changePage(ev, Menu.page); }} className="avatar-custom" to={Menu.link}>
                        <div className="icon-avatar">
                            <IconMenu />
                        </div>
                        <div className="text-avatar" >
                            <span>{t(Menu.text)}</span>
                        </div>
                    </Link>
                    <div className="document-count" >
                        <span>8</span>
                    </div>
                </li>
            );
        });

        return (
            <ul className="menu-custom">
                {MenuComponent}
            </ul>
        );
    }

    componentDidMountExtends = () => {
        let LoadingTask = pdfjs.getDocument("http://localhost:3000/Multiple%20Layout%20-%20with%20signed.pdf");
        LoadingTask.promise.then(resp => {
            // console.log(resp);
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            resp.getPage(1).then(page => {
                let viewport = page.getViewport({ scale: 1 });
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                page.render({
                    canvasContext: ctx,
                    viewport: viewport,
                })

                let body = document.querySelector(".right-body");
                //console.log(body);
                body.append(canvas);
                //console.log(page);
            });
        }).catch(error => {
            //console.log(error);
        });
        //console.log(LoadingTask);
    }

    render() {
        const { Main } = this;
        // let { canvas } = this.state;
        // let PDFPreview = this.state.canvas;
        // console.log(this.state.translateKeyPrefix);

        return (
            <Main>
                {/* {PDFPreview} */}
                <FileViewer
                    fileType={type}
                    filePath={file}
                // errorComponent={CustomErrorComponent}
                // onError={this.onError}
                />
            </Main>
        );
    }

}
// const testInside = async () => {
//     let TET = withTranslation()(Test);
//     return <TET />;
// }


export default withTranslation()(Test);;
