import React from 'react';
import {
    // BrowserRouter,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import { Router } from "react-router";
import { createBrowserHistory } from "history";

// import MainRoute from "./Main";
// import DocumentsRoute from "./Documents";

import Pages from './Pages';
import Verification from '../Pages/Auth/Registration/Verification';
import ResendVerifyEmail from '../Pages/Auth/Registration/ResendVerifyEmail';
import Test from '../Pages/Test'
import StaticTest from '../Test'
// import Layout from '../Layout';
// import { withTranslation } from 'react-i18next';
import FileExplorer from '../Pages/Fragments/FileExplorer';
import Logout from '../Pages/Auth/Logout';

export default function Index(props) {
    let { userInfo } = props;
    const history = createBrowserHistory();

    /* */
    let settingsMenu = null;
    let mainMenu = null;

    if (userInfo) {

        settingsMenu = "";

        if (userInfo.administrator_role === "1") {
            settingsMenu = [
                <Route key="tenant" exact path="/settings/tenant" component={Pages.SettingsTenant} />,
                <Route key="organization" exact path="/settings/organization" component={Pages.SettingsOrganization} />,
                <Route key="user" exact path="/settings/user" component={Pages.SettingsUser} />,
                <Route key="guest" exact path="/settings/guest" component={Pages.SettingsGuest} />,
                <Route key="stamp" exact path="/settings/stamp" component={Pages.SettingsStamp} />,
                <Route key="notification" exact path="/settings/notification" component={Pages.SettingsNotification} />,
                <Route key="app-cert" exact path="/settings/application-certificate" component={Pages.SettingsAppCert} />,
                <Route key="counter-party" exact path="/settings/counter-party" component={Pages.SettingsCounterParty} />,
                <Route key="sso-setting" exact path="/settings/sso-setting" component={Pages.SettingsSso} />,
                <Route key="restrictIp-setting" exact path="/settings/restrictIp-setting" component={Pages.SettingRestrictIp} />,
            ];
        }

        mainMenu = [
            <Route key="dashboard" exact path="/dashboard" component={Pages.Dashboard} />,
            <Route key="search" exact path="/search" component={Pages.Search} />,
        ];

        if (userInfo.partner_id !== "1") {
            if (userInfo.signer_role === "1") {
                mainMenu.push(<Route key="signer" exact path="/signer" component={Pages.Signer} />);
            }
            if (userInfo.sender_role === "1") {
                mainMenu.push(<Route key="sender" exact path="/sender" component={Pages.Sender} />);
            }
            if (userInfo.circle_role === "1") {
                mainMenu.push(<Route key="circle" exact path="/circle" component={Pages.Circle} />);
            }
        }

        if (userInfo.verification_role === "1") {
            mainMenu.push(<Route key="verification" exact path="/verification" component={Pages.Verification} />);
        }
        if (userInfo.evaluation_role === "1") {
            mainMenu.push(<Route key="evaluation" exact path="/evaluation" component={Pages.Evaluation} />);
        }
        if (userInfo.filebox_role === "1") {
            mainMenu.push(<Route key="filebox" exact path="/filebox" component={Pages.FileBox} />);
        }
        if (userInfo.workflow_role === "1") {
            mainMenu.push(<Route key="workflow" exact path="/workflow" component={Pages.Workflow} />);
        }

    }

    return (
        <Router history={history}>

            <Switch>
                {/* <Route key="workflow" exact path="/workflow" component={Pages.Workflow} />; */}

                <Route path="/registration/verification/:token" component={Verification} />
                <Route path="/registration/resend-email" component={ResendVerifyEmail} />
                <Route exact path="/sitemap" component={Pages.SiteMap} />
                <Route exact path="/test" component={Test} />
                <Route exact path="/file-explorer" component={FileExplorer} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/layout" component={StaticTest} />

                {/* Main router group */}
                <Redirect exact from="/" to="/dashboard" />
                <Redirect exact from="/login" to="/dashboard" />

                {/* Main router group */}
                <Route exact path="/terms-of-service" component={Pages.TermsOfService} />

                {mainMenu}
                {/* <Route exact path="/dashboard" component={Pages.Dashboard} />
                <Route exact path="/signer" component={Pages.Signer} />
                <Route exact path="/sender" component={Pages.Sender} />
                <Route exact path="/verification" component={Pages.Verification} />
                <Route exact path="/filebox" component={Pages.FileBox} />
                <Route exact path="/search" component={Pages.Search} />
                <Route exact path="/evaluation" component={Pages.Evaluation} />
                <Route exact path="/circle" component={Pages.Circle} /> */}

                <Route exact path="/settings/profile" component={Pages.SettingsProfile} />
                {settingsMenu}
                {/* <Route exact path="/settings/tenant" component={Pages.SettingsTenant} />
                <Route exact path="/settings/organization" component={Pages.SettingsOrganization} />
                <Route exact path="/settings/user" component={Pages.SettingsUser} />
                <Route exact path="/settings/guest" component={Pages.SettingsGuest} />
                <Route exact path="/settings/stamp" component={Pages.SettingsStamp} />
                <Route exact path="/settings/notification" component={Pages.SettingsNotification} />
                <Route exact path="/settings/application-certificate" component={Pages.SettingsAppCert} /> */}

                {/* Document router group */}
                <Route exact path="/:type/documents/create" component={Pages.Documents} />
                <Route exact path="/:type/documents/create-with-template" component={Pages.Documents} />
                <Route exact path="/:type/documents/create/:mode" component={Pages.Documents} />
                <Route exact path="/:type/documents/edit/:document_id" component={Pages.Documents} />
                <Route exact path="/documents/sign/:document_id" component={Pages.DocumentSign} />
                <Route exact path="/documents/detail/:document_id" component={Pages.DocumentSign} />
                <Route exact path="/guest/document/:token" component={Pages.DocumentSign} />
                <Route exact path="/guest/linkage/:token" component={Pages.GuestLinkage} />
                {/* <Pages.Documents type="signer" /> */}
                {/* </Route> */}
                {/* <Route path="/sender/documents/creation">
                    <Pages.Documents type="sender" />
                </Route> */}
                {/* Search router group */}
                <Route exact path="/search/result" component={Pages.SearchResult} />
                {/* Application Certificate group */}
                <Route exact path="/appcert/upload/:application_date" component={Pages.AppCertUpload} />
                <Route exact path="/guest/appcert/:token" component={Pages.AppCertUpload} />
                {/* PDF Full Preview */}
                <Route exact path="/pdf-full-preview" component={Pages.PDFFullPreview} />
                <Route exact path="/account/recovery/reset-password/:token" >
                    <Pages.Error code={404}
                        msg={userInfo.language === 'ja' ? 'すでに別のアカウントでログインしています。\nログアウトをして再度お試しください。' : 'general.error-404.signed-in-with-another-account'}
                        isCustom={true} layout={true} />
                </Route>
                <Route exact path="/user/verification/:token" >
                    <Pages.Error code={404}
                        msg={userInfo.language === 'ja' ? 'すでに別のアカウントでログインしています。\nログアウトをして再度お試しください。' : 'general.error-404.signed-in-with-another-account'}
                        isCustom={true} layout={true} />
                </Route>

                <Route>
                    <Pages.Error code={404} layout={true} />
                </Route>

            </Switch>

        </Router >
    );
}

