import '../Assets/css/settings.css';
import ProfileMFA from './ProfileMFA';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import Service from '../../Service';
import { Form, Col } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faUpload, faDownload, faTrash, faSync } from '@fortawesome/free-solid-svg-icons';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { withTranslation } from "react-i18next";
import ProfileAvatar from '../../Layout/ProfileAvatar';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Moment from 'moment';

/** */
class Profile extends Layout {

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "profile";

		/* Prepare state data */
		this.state.certificates = null;
		this.state.apiToken = null;

		this.state.fields = {};
		this.state.keyFields = {
			family_name: "family_name",
			first_name: "first_name",
			family_name_kana: "family_name_kana",
			first_name_kana: "first_name_kana",
			tenant_name: "tenant_name",
			tenant_id: "current-tenant",
			// profile_image: "image-file-select",
			// profile_image_name: "image-file-name",
		}

		this.state.emailControlMode = false;
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.profile.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** HTTP-Request API */
	componentDidMountExtends = () => {
		let { UserInfo } = this.state;
		if (UserInfo.hasOwnProperty("email_control_original")) {
			UserInfo.email_control = JSON.parse(JSON.stringify(UserInfo.email_control_original));
		} else {
			UserInfo.email_control_original = JSON.parse(JSON.stringify(UserInfo.email_control));
		}

		console.log('resp', UserInfo);

		this.setState({
			UserInfo,
			formData: {
				family_name: UserInfo.family_name,
				first_name: UserInfo.first_name,
				family_name_kana: UserInfo.family_name_kana,
				first_name_kana: UserInfo.first_name_kana,
				tenant_id: UserInfo.tenant_id,
				// profile_image: null,
				// profile_image_name: null,
			},
			apiToken: UserInfo.api_token_key,
		}, () => {
			this.setStateFields();

			/*  Request Cerificates data from API */
			Service.getCertificatesList().then(response => {
				// console.log(UserInfo);
				console.log("respinese: ", response)
				this.setState({
					certificates: response,
					loading: false,
				})

			}).catch((error) => {
				console.log("CATCH", error);
			});
		});

	}




	/** */
	eventCertificateUpload(cert_id) {

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.profile.certificate.upload-title";
		ModalAction = [];

		/* Update body */
		ModalBody = () => {
			return (
				<form id="certificate-upload">

					{/* */}
					<Form.Control type="hidden" name="type" value={cert_id || ""} />

					{/* */}
					<div className="">

						<div className="">
							<Form.Group className="">
								<Form.Label>{t("common:settings.profile.certificate.file-select")}</Form.Label>
								<Form.Control type="file" name="upload" />
							</Form.Group>
						</div>

						<div className="">
							<Form.Group className="">
								<Form.Label>{t("common:settings.profile.certificate.password")}</Form.Label>
								<Form.Control type="password" name="password" placeholder={t("common:settings.profile.certificate.password")} />
							</Form.Group>
						</div>

					</div>
				</form>
			);
		}

		/* */
		let eventSubmit = (event) => {
			this.eventCertificateUploadSubmit(event);
		};

		/* Add SubmitButton */
		ModalAction.push(
			<Button sx={{ ml: 2 }} key="ok" variant="contained" onClick={eventSubmit}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modalTitle, ModalBody, ModalAction, modalProps });

	}

	/** */
	async eventCertificateUploadSubmit(event) {

		/* */
		let form = document.querySelector("#certificate-upload");

		/* File required */
		if (form.elements['upload']['files']['length'] === 0) {
			return false;
		}

		/* Translate Function */
		let { t } = this.props;

		/* Prepare Modal */
		let { ModalBody, modalProps } = this.state;

		/* Find "certificate_file" from form */
		let certificate_file = form.elements['upload']['files'][0];
		let certificate_extension = certificate_file.name.split('.').pop();

		/* Convert file to BASE64 */
		certificate_file = await this.convertFileToBase64URL(certificate_file).then((base64) => {
			return base64.replace(/^data:.+;base64,/, '');
		}).catch((error) => {
			console.log("Error", error);
		});

		/* */
		ModalBody = Loading;

		/* */
		let data = JSON.stringify({
			"certificate_type": form.elements['type']['value'],
			"certificate_file": certificate_file,
			"certificate_extension": certificate_extension,
			"certificate_password": form.elements['password']['value'],
		});

		/* */
		Service.updateCertificate(data).then((response) => {

			/* */
			let { certificates, modalProps } = this.state;

			/* */
			for (let i = 0; i < certificates.length; i++) {

				/* */
				let cert = certificates[i];

				/* */
				if (cert['id'] === parseInt(form.elements['type']['value'])) {
					cert['user_id'] = this.state.UserInfo['user_id'];
					cert['cert_name'] = response.data[0].cert_name;
					cert['cert_exp_date'] = response.data[0].cert_exp_date;
					cert['cert_start_date'] = response.data[0].cert_start_date;
				}

			}

			/* */
			modalProps = { "show": false };
			console.log(certificates);

			/* */
			this.setState({ certificates, modalProps });

		}).catch((error) => {

			/* */
			let { ModalAction, ModalBody } = this.state;

			/* */
			ModalAction = [];
			ModalBody = () => {
				return (
					<div>{t("common:" + error.response.data.error)}</div>
				);
			};

			/* */
			this.setState({ ModalAction, ModalBody });

		});

		/* */
		this.setState({ ModalBody, modalProps });

	}

	/** Convert input[file] to Base64URL (string) */
	convertFileToBase64URL(file) {

		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});

	}







	/** */
	eventCertificateDownload(cert_id) {

		/* */
		let data = JSON.stringify({
			"certificate_type": cert_id,
		});

		/* */
		Service.downloadCertificate(data).then((response) => {

			// console.log("response", response);

			/* */
			let byte = atob(response.file);
			let blob = new Array(byte.length);

			/* */
			for (let i = 0; i < byte.length; i++) {
				blob[i] = byte.charCodeAt(i);
			}

			/* */
			blob = new Uint8Array(blob);
			blob = new Blob([blob], { type: "octet/stream" });

			/* Convert blob to EncodeURL */
			let url = window.URL.createObjectURL(blob);

			/* */
			let a = document.createElement("a");
			a.download = response.filename;
			a.href = url;
			a.click();

			/* */
			window.URL.revokeObjectURL(url);

		}).catch((error) => {
			console.log("error", error);
		});


	}

	/** */
	eventCertificateDelete(cert_id) {

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.profile.delete-title";
		ModalAction = [];
		ModalBody = () => {
			return (
				<div className="">
					<span>{t("common:message.settings.profile.delete-confirm")}</span>
				</div>
			);
		};

		/* */
		let onDelete = () => {

			/* */
			let data = JSON.stringify({
				"certificate_type": cert_id,
			});

			/* */
			Service.deleteCertificate(data).then((response) => {

				/* */
				let { certificates, modalProps } = this.state;

				/* */
				for (let i = 0; i < certificates.length; i++) {

					/* */
					let cert = certificates[i];

					/* */
					if (cert['id'] === cert_id) {
						cert['user_id'] = null;
						cert['cert_name'] = null;
						cert['cert_exp_date'] = null;
						cert['cert_start_date'] = null;
					}

				}

				/* Hide ConfirmBox */
				modalProps = { "show": false };

				console.log(certificates);
				/* */
				this.setState({ certificates, modalProps });


			}).catch((error) => {

				return (
					<div>{t("common:" + error.response.data.error)}</div>
				);

			});

			/* */
			ModalBody = Loading;

			/* */
			this.setState({ ModalBody });

		};

		/* Add SubmitButton */
		ModalAction.push(
			<Button sx={{ ml: 2 }} key="ok" variant="contained" color="warning" onClick={onDelete}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modalTitle, ModalBody, ModalAction, modalProps });

	}

	/** [Element] */
	CertificatesTable = () => {

		let { t } = this.props;
		console.log(this.state.certificates);
		/* */
		let list = this.state.certificates;
		let rows = [];

		/* */
		for (let i = 0; i < list.length; i++) {

			/* Certificate Item */
			let cert = list[i];

			/* */
			let actionUpload = {
				"title": "Upload",
				"onClick": (e) => {
					this.eventCertificateUpload(cert.id);
				},
			};

			/* */
			let actionDownload = {
				"title": "Download",
				"onClick": (e) => {
					this.eventCertificateDownload(cert.id);
				},
			};

			/* */
			let actionDelete = {
				"title": "Delete",
				"onClick": (e) => {
					this.eventCertificateDelete(cert.id);
				},
			};

			/*  */
			if (cert['user_id'] == null) {

				/* */
				actionDownload['disabled'] = true;
				actionDelete['disabled'] = true;

			} else {

				/* */
				actionUpload['disabled'] = true;

			}

			/* */
			rows.push((
				<tr key={i}>
					<td>{t(`common:certificate.type.${cert.label}`)}</td>
					<td className='table-overflow-text'>{cert['cert_name'] ?? ''}</td>
					<td>{cert['cert_exp_date'] ? `${Moment(cert['cert_start_date']).format('YYYY.MM.DD')} - ${Moment(cert['cert_exp_date']).format('YYYY.MM.DD')}` : ''}</td>
					<td>
						<IconButton {...actionUpload}>
							<FileUploadIcon />
						</IconButton>
						<IconButton {...actionDownload}>
							<FileDownloadIcon />
						</IconButton>
						<IconButton {...actionDelete}>
							<DeleteIcon />
						</IconButton>
					</td>
				</tr>
			));

		}

		/* */
		return (
			<table className="profile-certificates">
        <thead>
          <tr>
            <th></th>
            <th>証明書名</th>
            <th>有効期間</th>
            <th></th>
          </tr>
        </thead>
				<tbody>{rows}</tbody>
			</table>
		)

	}

	CheckRole = (role) => {
		let adminRole = <CheckCircleIcon fontSize="small" color='success' />;
		if (role === "0") {
			adminRole = "";
		}
		return adminRole
	}


	/** [Element] */
	render() {
		let { t } = this.props;
		let { UserInfo, certificates } = this.state;
		const { Main, CertificatesTable, CheckRole, EmailControllerBlock } = this;

		if (UserInfo == null || certificates == null) {
			return (
				<Main>
					<Loading />
				</Main>
			);
		}

		let tenantName = "";

		for (let tenant of UserInfo.tenants) {
			if (UserInfo.tenant_id === tenant.id) {
				tenantName = tenant.name;
			}
		}
		let iconProps = {};
		if (UserInfo) {
			iconProps.avatar = localStorage.getItem('profile_image');
		}
		iconProps.size = { width: 150, height: 150 }

		/* */
		return (
			<Main>

				<div className="row lz-m-40">

					<div className="col-xl-3">

						<div className="profile-pic lz-mb-40">
							<div className="profile-pic-edit" onClick={this.openDialogProfileImageForm}>
								<ProfileAvatar {...iconProps} />
							</div>
						</div>

						<div className="profile-settings lz-mb-40">
							<div className="lz-txt-c">
								<Button variant="text" onClick={this.openDialogPasswordChangeForm}>
									<span>{t("common:settings.profile.change-password")}</span>
								</Button>
							</div>
							<div className="lz-txt-c">
								<Button variant="text" href="/logout">
									<span>{t("common:auth.general.logout")}</span>
								</Button>
							</div>
						</div>

					</div>

					<div className="col-xl-9">

						{/* title */}
						<div className="lz-mt-20 lz-mb-10">

							<div className="lz-m-0-20">
								<b>{UserInfo.company_name}</b>
							</div>

						</div>

						{/* UserInfo */}
						<div className="lz-mb-40">

							<div className="profile-box">
								<div className="box-head">
									<div className="box-title">
										<b>{t("common:settings.profile.title-personal-info")}</b>
									</div>
									<div className="box-menu">

										<Button
											color="primary"
											size="small"
											aria-label="EditIcon"
											key="edit"
											startIcon={<EditIcon />}
											variant="outlined" onClick={this.openDialogProfileInfoForm}
										>
											{t("common:document.edit")}
										</Button>
									</div>
								</div>
								<div className="box-body">

									<div className="lz-m-20">
										<table className='userInfoTree'>
											<tbody>
												<tr data-row="" >
													<td>
														<div>{t('common:settings.profile.family_name')}</div>
													</td>
													<td>
														<div>{UserInfo.family_name}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.first_name')}</div>
													</td>
													<td>
														<div>{UserInfo.first_name}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.family_name_kana')}</div>
													</td>
													<td>
														<div>{UserInfo.family_name_kana}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.first_name_kana')}</div>
													</td>
													<td>
														<div>{UserInfo.first_name_kana}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.email')}</div>
													</td>
													<td>
														<div>{UserInfo.email}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.current-tenant')}</div>
													</td>
													<td>
														<div>{`${UserInfo.tenant_id}: ${tenantName}`}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.signer-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.signer_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.sender-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.sender_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.circle-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.circle_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.verification-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.verification_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.evaluation-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.evaluation_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.filebox-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.filebox_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.administrator-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.administrator_role)}</div>
													</td>
												</tr>
												<tr data-row="">
													<td>
														<div>{t('common:settings.profile.certificate-role')}</div>
													</td>
													<td>
														<div>{CheckRole(UserInfo.certificate_role)}</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
							</div>

						</div>


						{/* MFA Authentication */}
						<div className="lz-mb-40">
							<ProfileMFA />

						</div>
						{/* Certificate */}
						<div className="lz-mb-40">

							<div className="profile-box">
								<div className="box-head">
									<div className="box-title">
										<b>{t("common:settings.profile.title-certificate")}</b>
									</div>
								</div>
								<div className="box-body">

									<div className="lz-m-20">
										<CertificatesTable />
									</div>

								</div>
							</div>

						</div>

						{/* API Token */}
						<div className="lz-mb-40">

							<div className="profile-box">
								<div className="box-head">
									<div className="box-title">
										<b>{t("common:settings.profile.api-token")}</b>
									</div>
								</div>
								<div className="box-body">

									<div className="lz-m-20">

										<div className="profile-api-token">

											<div className="lz-flex">

												<div className="lz-flex-1">
													<Form.Group className="">
														<Form.Control type="text" value={this.state.apiToken || ""} readOnly />
													</Form.Group>
												</div>

												<div className="lz-flex-0 lz-ml-10 lz-nowrap">

													<IconButton className=" lz-ml-4" color="primary" key="generate" onClick={() => { this.openDialogGenerateApiTokenForm() }}>
														<SyncProblemIcon />
													</IconButton>

												</div>

											</div>

										</div>


									</div>

								</div>
							</div>

						</div>

						<EmailControllerBlock />
						{/* <EmailControllerBlockAll /> */}

					</div>

				</div>

			</Main>
		);

	}

	EmailControllerBlock = (props) => {
		let { t } = this.props;
		let { UserInfo } = this.state;
		let isUpdated = false;

		for (let i in UserInfo.email_control) {
			for (let j in UserInfo.email_control[i]) {
				let current = UserInfo.email_control[i][j].allow;
				let original = UserInfo.email_control_original[i][j].allow;
				if (current !== original) {
					isUpdated = true;
					break;
				}
			}
		}

		return (
			< div className="lz-mb-40" >
				<div className="profile-box">
					<div className="box-head" style={{ height: "32px" }}>
						<div className="box-title">
							<b>{t("common:settings.profile.email-controller-header")}</b>
						</div>
						<div className="box-menu">
							{isUpdated ? [<Button
								color="primary"
								size="small"
								aria-label="CancelIcon"
								key="ec-cancel"
								startIcon={<CancelIcon />}
								variant="outlined"
								onClick={(ev) => {
									UserInfo.email_control = JSON.parse(JSON.stringify(UserInfo.email_control_original));
									this.setState({ UserInfo });
								}}
							>
								{t("common:general.cancel")}
							</Button>, <span key="ec-space">&nbsp;&nbsp;</span>, <Button
								color="primary"
								size="small"
								aria-label="SaveIcon"
								key="ec-save"
								startIcon={<SaveIcon />}
								variant="contained"
								onClick={(ev) => {
									console.log(UserInfo.email_control);
									this.EmailControllerSaveSubmit(UserInfo.email_control);
								}}
							>
								{t("common:general.save")}
							</Button>] : ""}
						</div>
					</div>
					<div className="box-body">
						<div className="lz-m-20">
							<div className="profile-api-token">
								<div className="lz-flex">
									<table className='email-controller-list'>
										<tbody>
											{UserInfo.email_category.map((item, index) => {
												let targetLevel = UserInfo.email_control[1] || {}; // use for company level only
												let targetCate = targetLevel[item.id];
												return (
													<tr key={index}>
														<td style={{ padding: "6px" }}>
															<span>{t(`common:settings.profile.email-controller-${item.name}`)}</span>
														</td>
														<td style={{ padding: "6px" }}>
															<span>{targetCate
																? targetCate.allow
																	? <CheckBoxIcon fontSize="medium" color="primary" onClick={(ev) => {
																		targetCate.allow = !targetCate.allow;
																		this.setState({ UserInfo });
																	}} />
																	: <CheckBoxOutlineBlankIcon fontSize="medium" color="action" onClick={(ev) => {
																		targetCate.allow = !targetCate.allow;
																		this.setState({ UserInfo });
																	}} />
																: <IndeterminateCheckBoxIcon fontSize="medium" color="disabled" />
															}</span>

														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}

	EmailControllerBlockAll = (props) => {
		let { t } = this.props;
		let { UserInfo } = this.state;
		let isUpdated = false;

		for (let i in UserInfo.email_control) {
			for (let j in UserInfo.email_control[i]) {
				let current = UserInfo.email_control[i][j].allow;
				let original = UserInfo.email_control_original[i][j].allow;
				if (current !== original) {
					isUpdated = true;
					break;
				}
			}
		}

		return (
			< div className="lz-mb-40" >
				<div className="profile-box">
					<div className="box-head" style={{ height: "32px" }}>
						<div className="box-title">
							<b>{t("common:settings.profile.email-controller-header")}</b>
						</div>
						<div className="box-menu">
							{isUpdated ? [<Button
								color="primary"
								size="small"
								aria-label="CancelIcon"
								key="ec-cancel"
								startIcon={<CancelIcon />}
								variant="outlined"
								onClick={(ev) => {
									UserInfo.email_control = JSON.parse(JSON.stringify(UserInfo.email_control_original));
									this.setState({ UserInfo });
								}}
							>
								{t("common:general.cancel")}
							</Button>, <span key="ec-space">&nbsp;&nbsp;</span>, <Button
								color="primary"
								size="small"
								aria-label="SaveIcon"
								key="ec-save"
								startIcon={<SaveIcon />}
								variant="contained"
								onClick={(ev) => {
									console.log(UserInfo.email_control);
									this.EmailControllerSaveSubmit(UserInfo.email_control);
								}}
							>
								{t("common:general.save")}
							</Button>] : ""}
						</div>
					</div>
					<div className="box-body">
						<div className="lz-m-20">
							<div className="profile-api-token">
								<div className="lz-flex">
									<table className='email-controller-list'>
										<thead>
											<tr>
												<th>
													<span>{t("common:settings.profile.email-controller-level")}</span>
												</th>
												{UserInfo.email_category.map((item, index) => (
													<th key={index}>
														<span>{t(`common:settings.profile.email-controller-${item.name}`)}</span>
													</th>
												))}
											</tr>
										</thead>
										<tbody>
											{["system", "company", "tenant"].map((lvName, level) => (
												<tr key={level}>
													<td key={`${level}-label`}>
														<span>{t(`common:settings.profile.email-level-${lvName}`)}</span>
													</td>
													{UserInfo.email_category.map((item, index) => {
														let targetLevel = UserInfo.email_control[level] || {};
														let targetCate = targetLevel[item.id];
														return (
															<td key={`${level}-${index}`} data-key={`${level}-${index}`} style={{ padding: "6px" }}>
																<span>{targetCate
																	? targetCate.allow
																		? <CheckBoxIcon fontSize="medium" color="primary" onClick={(ev) => {
																			targetCate.allow = !targetCate.allow;
																			this.setState({ UserInfo });
																		}} />
																		: <CheckBoxOutlineBlankIcon fontSize="medium" color="action" onClick={(ev) => {
																			targetCate.allow = !targetCate.allow;
																			this.setState({ UserInfo });
																		}} />
																	: <IndeterminateCheckBoxIcon fontSize="medium" color="disabled" />
																}</span>
															</td>
														);
													})}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);

	}


	ProfileImageChangeSubmit = (ev) => {
		let { modal } = this.state;
		console.log(ev);

		// let { formData } = this.objMain.state;
		ev.preventDefault();
		ev.stopPropagation();
		// this.ReadCSVData(ev.target.csv_file_import.files[0]);

		/* Find Form */
		// let form = document.querySelector("#profile-image-form");
		let files = ev.target.profile_image.files;

		/* */
		if (files.length < 1) {
			return false;
		}

		let file = files[0];

		/* DialogBody to Loading */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ modal });

		/* */
		var reader = new FileReader();

		/* */
		reader.onload = (event) => {

			/* */
			let data = {
				"image": event.target.result,
			};

			/* */
			Service.userChangeProfileImage(data).then((resp) => {
				let { UserInfo, modal } = this.state;
				modal.props.show = false;

				UserInfo["profile_image"] = data["image"];
				localStorage.setItem('profile_image', data["image"])

				/* */
				this.setState({ modal });

			}).catch((error) => {
				console.log("error", error.response);
			});
		};

		/* */
		reader.readAsDataURL(file);
	}


	/** [Dialog] */
	openDialogProfileImageForm = () => {

		let { t } = this.props;
		let { modal, formData } = this.state;

		/* Prepare Modal */
		/* Translate function */

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		};

		/* */
		modal.title = "common:settings.profile.image-change-title";

		modal.form = {
			id: "profile-image-form",
			onSubmit: this.ProfileImageChangeSubmit,
			noValidate: true,
		};

		/* Update body */
		modal.body = () => {
			let { formData, profileImageValidate } = this.state;
			return (
				<Form.Row>
					<Form.Group as={Col} className="form-control-file">
						<Form.Label>{t("common:settings.profile.image-file-select")}</Form.Label>
						<Form.File
							id="profile_image"
							name="profile_image"
							// label={formData['csv_data'] || t("common:settings.profile.image-file-select")}
							custom
						>
							<Form.File.Input
								isValid={formData["profile_image"] && profileImageValidate}
								isInvalid={!formData["profile_image"] && profileImageValidate}
								required={false}
								onChange={(ev) => {
									if (ev.target.files[0]) {
										let { formData, profileImageValidate } = this.state;
										let file = ev.target.files[0];
										profileImageValidate = true;
										if (file.size / 1024 > 800) {
											ev.target.value = '';
											// formData["profile_image"] = null;
											// formData["profile_image_name"] = null;
											// alert("Image size is over limit.");
										} else {
											console.log(ev.target.files);
											formData["profile_image"] = ev.target.files[0];
											formData["profile_image_name"] = ev.target.files[0].name;
										}
										this.setState({ formData, profileImageValidate });
									}
								}}
								accept="image/*"
							/>
							<Form.File.Label>
								{formData['profile_image_name'] || t("common:settings.profile.image-file-select")}
							</Form.File.Label>
							<Form.Control.Feedback type="invalid">
								<span>{t("common:settings.profile.image-size-limit-exeeded")}</span>
							</Form.Control.Feedback>
						</Form.File>
					</Form.Group>
				</Form.Row>
			);
		}

		/* Reset DialogFooter buttons */
		modal.action = [(
			<Button key="ok" type="submit" variant="contained">
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		formData["profile_image"] = null;
		formData["profile_image_name"] = null;

		/* */
		this.setState({ modal, formData, profileImageValidate: false });

	}

	/** [Dialog] */
	openDialogPasswordChangeForm = () => {


		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.profile.change-password-title');

		/* Update body */
		ModalBody = () => {
			return (
				<fieldset id="change-password-form" className="dialog-form">

					<div className="lz-m-20">

						<div className="row">

							<div className="col-xl-12">
								<Form.Group className="">
									<Form.Label>{t('common:settings.profile.new-password')}</Form.Label>
									<Form.Control type="password" name="new_password" />
								</Form.Group>
							</div>

						</div>

						<Form.Row>
							<div id="password_message" className="custom-form-outline col">
								<Box className="smallText" sx={{ mb: 1 }}>
									{t("common:message.general.password-input-rule")}
								</Box>
							</div>
						</Form.Row>

						<div className="row">

							<div className="col-xl-12">
								<Form.Group className="">
									<Form.Label>{t('common:settings.profile.confirm-new-password')}</Form.Label>
									<Form.Control type="password" name="new_password_confirm" />
								</Form.Group>
							</div>

						</div>

					</div>

				</fieldset>
			);
		}

		/* */
		let eventSubmit = (event) => {

			/* */
			let { ModalBody, ModalAction, modalProps } = this.state;

			/* DialogBody to Loading */
			ModalBody = () => {
				return (
					<div className="lz-m-20">
						<Loading />
					</div>
				);
			};

			/* */
			this.setState({ ModalBody });

			/* */
			let form = document.querySelector("#change-password-form");

			/* */
			let data = {
				"password": form.elements['new_password']['value'],
				"confirm_password": form.elements['new_password_confirm']['value'],
			}

			/* */
			Service.userChangePassword(data).then((response) => {

				/* */
				modalProps = { "show": false };

				/* */
				this.setState({ modalProps });


			}).catch((error) => {

				/* */
				ModalBody = () => (
					<div className="">
						<p>{t(`common:${error.response.data.message}`)}</p>
					</div>
				);

				/* Clear foot buttons */
				ModalAction = [];


				/* Add SubmitButton */
				ModalAction.push(
					<Button sx={{ ml: 2 }} key="retry" variant="contained" onClick={() => { this.openDialogPasswordChangeForm() }}>
						<span>{t("common:general.try-again")}</span>
					</Button>
				);

				/* */
				this.setState({ ModalBody, ModalAction });

			});

		};

		/* Reset DialogFooter buttons */
		ModalAction = [];

		/* Add SubmitButton */
		ModalAction.push(
			<Button sx={{ ml: 2 }} key="ok" variant="contained" onClick={eventSubmit}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modalTitle, ModalBody, ModalAction, modalProps });

	}

	EmailControllerSaveSubmit = (payload) => {
		let { t } = this.props;
		let { modal } = this.state;
		/* DialogBody to Loading */
		modal.props.show = true;
		modal.title = 'common:settings.profile.email-controller-saving';
		modal.body = Loading;
		modal.close = false;
		modal.action = null;
		this.setState({ modal }, () => {
			Service.EmailControllerSave(payload).then(resp => {
				let { UserInfo } = this.state;
				console.log(resp);
				modal.props.show = false;
				UserInfo.email_control_original = JSON.parse(JSON.stringify(UserInfo.email_control));
				this.setState({ modal, UserInfo });
			}).catch(err => {
				console.log("error", err.response);

				let errMessage = err.response.data.message;
				if (err.response.data.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}

				modal.body = t(`common:${errMessage}`);
				modal.close = true;
				modal.action = [
					<Button sx={{ ml: 2 }} key="ok" variant="contained" onClick={(ev) => {
						this.EmailControllerSaveSubmit(payload);
					}}>
						<span>{t("common:general.try-again")}</span>
					</Button>
				];

				this.setState({ modal });
			});
		});
	}

	formSubmitCallback = (ev) => {
		let { t } = this.props;
		let { formData, modal } = this.state;
		/* DialogBody to Loading */
		modal.body = Loading;
		modal.action = null;
		modal.close = false;

		// let tenantChanged = parseInt(UserInfo.tenant_id) === parseInt(formData.tenant_id);
		// /* Update DialogBody */
		this.setState({ modal }, () => {
			Service.SetUserInfo(formData).then(resp => {
				// if (tenantChanged) {
				// 	window.location.reload();
				// } else {
				// 	modal.props.show = false;
				// 	// for (let key in formData) {
				// 	// 	UserInfo[key] = formData[key];
				// 	// }
				// 	Service.GetUserInfo().then(resp => {
				// 		this.setState({ modal, UserInfo: resp });
				// 	}).catch((err) => {
				// 		console.log("error", err.response);
				// 		this.setState({ modal });
				// 	});
				// }
				Service.deleteUserControl().then(response => {
					Service.GetUserInfo().then(resp => {
						modal.props.show = false;
						this.setState({ modal, UserInfo: resp });
					}).catch((err) => {
						console.log("error", err.response);
						this.setState({ modal });
					});
				}).catch((error) => {
					console.log("CATCH", error);
				});
			}).catch(err => {
				console.log("error", err.response);

				let errMessage = err.response.data.message;
				if (err.response.data.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}

				modal.body = t(`common:${errMessage}`);
				modal.action = this.BasicInfoActionTryAgain;
				modal.close = true;

				this.setState({ modal });
			});
		});
	}

	TenantOption = () => {
		let { UserInfo } = this.state;

		let OptionItems = [];
		for (let tenant of UserInfo.tenants) {
			OptionItems.push(<option key={tenant.id} value={tenant.id}>{`${tenant.id}: ${tenant.name}`}</option>);
		}

		return OptionItems;
	}

	BasicInfoForm = () => {
		// let { t } = this.props;
		let { FieldControl } = this;

		return (

			<div className="form-container">

				<Form.Row>
					<FieldControl name="family_name" xs={12} md={6} required validate="true" />
					<FieldControl name="first_name" xs={12} md={6} required validate="true" />
				</Form.Row>

				<Form.Row>
					<FieldControl name="family_name_kana" xs={12} md={6} />
					<FieldControl name="first_name_kana" xs={12} md={6} />
				</Form.Row>

				{/* <Form.Row>
					<FieldControl as="select" name="tenant_id" xs={12} md={12}>
						<TenantOption />
					</FieldControl>
				</Form.Row> */}
			</div>
		);
	}

	BasicInfoActionButtons = () => {
		let { t } = this.props;
		let Buttons = [];
		Buttons.push(
			<Button sx={{ ml: 2 }} key="ok" type="submit" variant="contained" >
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		return Buttons;
	}

	BasicInfoActionTryAgain = () => {
		let { t } = this.props;
		let Buttons = [];
		Buttons.push(
			<Button sx={{ ml: 2 }} key="ok" variant="contained" onClick={(ev) => {
				let { modal } = this.state;
				modal.body = this.BasicInfoForm;
				modal.action = this.BasicInfoActionButtons;
				this.setState({ modal });
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		);

		return Buttons;
	}

	/** [Dialog] */
	openDialogProfileInfoForm = () => {
		// let { t } = this.props;
		let { modal } = this.state;
		// let { FieldControl, TenantOption } = this;

		modal.props = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modal.title = 'common:settings.profile.basic-info-edit';

		modal.form = {
			id: "user-info-form",
			onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
			noValidate: true,
		};
		/* Update body */
		modal.body = this.BasicInfoForm;
		modal.action = this.BasicInfoActionButtons;
		/* */
		this.setState({ modal, formValidate: false });

	}

	/** [Dialog] */
	openDialogGenerateApiTokenForm = () => {

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.profile.api-token-title');
		ModalAction = [];

		/* Update body */
		ModalBody = () => {
			return (
				<fieldset id="api-token-form" className="dialog-form">

					<div className="lz-m-20">

						<Form.Group className="">
							<Form.Label>{t('common:message.settings.profile.api-token-comfirm-password')}</Form.Label>
							<Form.Control type="password" name="password" />
						</Form.Group>

					</div>

				</fieldset>
			);
		}

		/* */
		let eventSubmit = (event) => {

			/* */
			let { apiToken, ModalBody, ModalAction, modalProps } = this.state;

			/* */
			ModalBody = () => {
				return (
					<div className="lz-m-20">
						<Loading />
					</div>
				);
			};

			/* */
			let form = document.querySelector("#api-token-form");

			/* */
			let data = {
				"password": form.elements['password']['value'],
			};

			/* */
			Service.userGenerateApiToken(data).then((response) => {

				/* Update display token */
				apiToken = response['token'];

				/* */
				modalProps = { "show": false };

				/* */
				this.setState({ apiToken, modalProps });


			}).catch((error) => {

				/* */
				ModalBody = () => (
					<div className="">
						<p>{t(error.response.data.error)}</p>
					</div>
				);

				/* Clear foot buttons */
				ModalAction = [];


				/* Add SubmitButton */
				ModalAction.push(
					<Button sx={{ ml: 2 }} key="retry" variant="contained" onClick={() => { this.openDialogGenerateApiTokenForm() }}>
						<span>{t("common:general.try-again")}</span>
					</Button>
				);

				/* */
				this.setState({ ModalBody, ModalAction });

			});

			/* */
			this.setState({ ModalBody });

		};

		/* Add SubmitButton */
		ModalAction.push(
			<Button sx={{ ml: 2 }} key="ok" variant="contained" onClick={eventSubmit}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modalTitle, ModalBody, ModalAction, modalProps });

	}

}

export default withTranslation()(Profile);
