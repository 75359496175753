import SVGIcons from '../Pages/Assets/SVGIcons';
import Dashboard from '../Pages/Main/Dashboard';
import FileBox from '../Pages/Main/FileBox';
import Sender from '../Pages/Main/Sender';
import Signer from '../Pages/Main/Signer';
import Verification from '../Pages/Main/Verification';
// import Workflow from '../Pages/Main/Workflow';
import Circle from '../Pages/Main/Circle';
import Evaluation from '../Pages/Main/Evaluation';
import Search from '../Pages/Main/Search';

const MenuConfig = {
    dashboard: {
        icon: SVGIcons["home"],
        text: 'common:menu.dashboard',
        link: '/dashboard',
        name: "Dashboard",
        page: Dashboard,
    },
    signer: {
        icon: SVGIcons["signer"],
        text: 'common:menu.signer',
        link: '/signer',
        name: "Signer",
        page: Signer,
    },
    sender: {
        icon: SVGIcons["sender"],
        text: 'common:menu.sender',
        link: '/sender',
        name: "Sender",
        page: Sender,
    },
    circle: {
        icon: SVGIcons["circle"],
        text: 'common:menu.circle',
        link: '/circle',
        name: "Circle",
        page: Circle,
    },
    verification: {
        icon: SVGIcons["verification"],
        text: 'common:menu.verification',
        link: '/verification',
        name: "Verification",
        page: Verification,
    },
    // workflow: {
    //     icon: SVGIcons["workflow"],
    //     text: 'common:menu.workflow',
    //     link: '/workflow',
    //     name: "Workflow",
    //     page: Workflow,
    // },
    evaluation: {
        icon: SVGIcons["evaluation"],
        text: 'common:menu.evaluation',
        link: '/evaluation',
        name: "Evaluation",
        page: Evaluation,
    },
    filebox: {
        icon: SVGIcons["filebox"],
        text: 'common:menu.filebox',
        link: '/filebox',
        name: "FileBox",
        page: FileBox,
    },
    search: {
        icon: SVGIcons["search"],
        text: 'common:menu.search',
        link: '/search',
        name: "Search",
        page: Search,
    },
};

export default MenuConfig;