import '../Assets/css/settings.css';
import React from 'react';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import { withTranslation } from "react-i18next";
import { Form } from 'react-bootstrap';
import OrganizationService from '../../Service/Organization';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
/** */
class Organization extends Layout {

	/** */
	constructor(props) {
		super(props);
		this.state.page = "organization";
		this.state.organizations = null;

	}

	/** [Layout] */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [API] HTTP Request when state change */
	componentDidUpdate = () => {

		/* */
		let { organizations } = this.state;

		/* */
		if (organizations === null) {
			OrganizationService.getList().then((response) => {

				/* */
				let tree = this.convOrganizationToTree(response);

				/* */
				this.setState({ organizations: tree });

			}).catch((error) => {
				console.log("OrganizationService.getList.error", error);
			});
		}

	}

	/** [RENDER] */
	render() {

		/* Translate function */
		let { t } = this.props;

		/* */
		const { Main, OrganizationCsvFileDownload, CsvFileUpload } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-organization" className="page-settings">

					{/* Menu */}
					<div id="organization-menu" className="">

						<div>
								<Button
									sx={{ borderRadius: 19 }}
									title="Action"
									onClick={() => { this.openDialogGroupForm() }}
									variant="contained"
									disabled = {!this.state.organizations}
									startIcon={<AddIcon />}
									
								>
									{t('common:settings.organization.add_organization')}
								</Button>
								<Button
									sx={{ml:3}}
									
									key="list-download"
									onClick={(ev) => {
										OrganizationCsvFileDownload();
									}}
									startIcon={<DownloadIcon />}
									variant="outlined"
									size="small"
									disabled = {!this.state.organizations}
								>
									{t('common:settings.general.list-download')}
								</Button>
								<Button
									sx={{ml:2}}
									
									key="list-upload"
									onClick={(ev) => {
										CsvFileUpload();
									}}
									startIcon={<UploadIcon />}
									variant="outlined"
									size="small"
									disabled = {!this.state.organizations}
								>
									{t('common:settings.general.list-upload')}
								</Button>
							
							
						</div>		

					</div>

					{/* Main */}
					<div className="lz-m-20">

						<div className="row">
							<div className="col-xl-6">
								<this.OrganizationTree />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}

	/** */
	CsvFileUpload = (validateMessage = null) => {

		/* Prepare Modal */
		let { ModalAction, ModalBody, modalTitle, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "counter-party-csv-dialog",
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.general.csv-upload";

		let ValidateMessage = () => {
			if (validateMessage) {
				let messageArr = [];
				let messageKeys = Object.keys(validateMessage);
				let messageKey = messageKeys[0];
				let headerErrKey = messageKeys[1];
				let itemMap = validateMessage[messageKey];

				if (validateMessage[headerErrKey] !== '') {
					messageArr.push(
						<div key={headerErrKey}>
							<span>
								{t(`common:${validateMessage[headerErrKey]}`)}
							</span>
						</div>
					);
				} else {
					for (let [itemKey, itemLines] of Object.entries(itemMap)) {
						if (itemLines.length !== 0) {
							messageArr.push(
								<div key={itemKey}>
									<span>
										{t(`common:${messageKey}`, { item_name: t(`common:${itemKey}`), line: itemLines.join(',') })}
									</span>
								</div>
							);
						}
					}
				}
				return messageArr;
			} else {
				return null;
			}
		}

		/* Update body */
		ModalBody = () => {
			return (
				<div>
					{/* */}
					<div className="">
						<Form.Group className="">
							<Form.Label>{t("common:settings.general.csv-file-select")}</Form.Label>
							<Form.Control type="file" id="csv-upload" name="upload" accept=".csv" />
						</Form.Group>
					</div>
					<div>

						<ValidateMessage />
					</div>
				</div>
			);
		}

		/* Add SubmitButton */
		ModalAction = [(
			<Button key="ok" variant="text" onClick={(ev) => {
				this.OrganizationCsvFileUpload();
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		/* */
		this.updateState({ modalClose: true, ModalAction, ModalBody, modalTitle, modalProps });
	}

	/** [Element] */
	OrganizationTree = () => {

		/* */
		if (this.state.organizations == null) {
			return <Loading />;
		}

		/* Organization Tree-Structure */
		let tree = this.state.organizations.tree;
		let list = [];

		/* Convert Array to Elements */
		for (let i = 0; i < tree.length; i++) {

			/* */
			let item = this.OrganizationTreeItem(tree[i]);

			/* */
			list.push(item);

		}

		/* */
		return (
			<div>{list}</div>
		);

	}

	/** [Element] */
	OrganizationTreeItem = (org) => {

		/* Translate function */
		let { t } = this.props;
		/* */
		let children = [];
		let unique = "org-{0}";

		/* */
		unique = unique.replace("{0}", org.user_id);

		/* Convert children (Array) to Elements */
		for (let i = 0; i < org.children.length; i++) {

			/* */
			let child = this.OrganizationTreeItem(org.children[i], i);

			/* */
			children.push(child);

		}

		/* */
		let toggle = (event, id) => {

			/* */
			let button = event.target.closest("button");
			let target = document.getElementById(id);
			let body = target.querySelector(".org-body");

			/* */
			if (body.classList.contains("org-collapse")) {

				/* */
				button.children[0]['innerHTML'] = "▼";

				/* */
				body.classList.remove("org-collapse");

			} else {

				/* */
				button.children[0]['innerHTML'] = "▶︎";

				/* */
				body.classList.add("org-collapse");

			}


		};

		if (org.user_type === 2) {
			/* */
			return (
				<div id={unique} className="org" key={unique}>
					<div className="org-head">
						<div className="lz-flex-0">
							<IconButton
								onClick={(e) => { toggle(e, unique) }}
							>
								<span className="org-toggle">▼</span>
							</IconButton>
						</div>
						<div className="lz-flex-1">
							<div>
								<span className="org-tag">{org.user_id } : </span><span className="org-name">{ org.name }</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<IconButton	
								onClick={() => { this.openDialogGroupForm(org) }}
							>
								<MoreVertIcon />
							</IconButton>
						</div>
					</div>
					<div className="org-body">
						{children}
					</div>
				</div>
			);
		} else {

			let headClassName = "user-head";
			let guestIconName = "";
			let guestIconStyle = "";

			if (org.user_type === 1) {
				headClassName = "guest-head";
				guestIconName = "settings.organization.guest-icon-name";
				guestIconStyle = "guest-icon";
			}

			/* */
			return (
				<div id={unique} className="org" key={unique}>
					<div className={headClassName}>
						<div className="lz-flex-1">
							<div className="lz-m-0-10">
								<span>{`${org.name}`}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<div>
								<span className={guestIconStyle}>{t(`common:${guestIconName}`)}</span>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	/** [Elements...] */
	GroupOptions = (skip = []) => {

		/* Translate function */
		let { t } = this.props;

		/* */
		let options = [];

		/* */
		options.push(
			<option value="" key={""}>{t('common:settings.organization.no_parent')}</option>
		);

		/* */
		for (let i in this.state.organizations.indexes) {

			/* */
			let org = this.state.organizations.indexes[i];

			/* */
			if (skip.includes(org.user_id)) {
				continue;
			}

			if (org.user_type !== 2) {
				continue;
			}

			/* */
			options.push(
				<option value={org.user_id} key={org.user_id}>
					{org.name}
				</option>
			);

		}

		/* */
		return options;

	}

	/** [Data] Convert OrganizationList to Tree */
	convOrganizationToTree = (list) => {

		/* */
		let output = {
			"tree": [],
			"indexes": {},
		};

		/* */
		if (list === null) {
			return output;
		}

		/* Create indexes */
		for (let i = 0; i < list.length; i++) {

			/* */
			let item = Object.assign({}, list[i]);
			let user_id = item["user_id"];
			let group_id = item["group_id"];
			let user_type = item["user_type"];

			if (user_type !== 2) {
				continue;
			}

			/* */
			item.children = [];

			/* */
			output.indexes[user_id] = item;

			/* */
			if (group_id === null) {
				output.tree.push(item);
			}

		}

		/* Append children */
		for (let i in output.indexes) {

			/* */
			let item = output.indexes[i];
			let group_id = item["group_id"];

			/* */
			if (group_id == null) {
				continue;
			}

			/* */
			output.indexes[group_id].children.push(item);

		}

		/* Append children (User) */
		for (let i in list) {

			/* */
			let item = list[i];
			let group_id = item["group_id"];
			let user_type = item["user_type"];

			/* */
			if (group_id == null || user_type === 2) {
				continue;
			}

			/* */
			item.children = [];

			if (group_id in output.indexes) {
				/* */
				output.indexes[group_id].children.push(item);
			}

		}

		/* */
		return output;

	}

	/** [Data] */
	skipList = (user_id = null) => {

		/* */
		let list = [];
		let task = [];

		/* */
		let org = this.state.organizations.indexes;

		/* */
		if (user_id !== null) {
			task.push(user_id);
		}

		/* */
		while (task.length > 0) {

			/* */
			let id = task.shift();

			/* */
			list.push(id);

			/* */
			if (!org.hasOwnProperty(id)) {
				continue;
			}

			/* */
			let children = org[id]['children'];

			/* */
			for (let i = 0; i < children.length; i++) {
				task.push(children[i]['user_id']);
			}

		}

		return list;

	}

	/** [State] */
	openDialogGroupForm = (org = null) => {

		if (this.state.organizations === null) {
			return false;
		}

		/* Translate function */
		let { t } = this.props;

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* */
		if (org === null) {

			/* Set dialog title */
			modalTitle = t('common:settings.organization.add_organization');

			/* Default Org values */
			org = {
				"user_id": "",
				"group_id": "",
				"name": "",
			}

		} else {

			/* Set dialog title */
			modalTitle = t('common:settings.organization.update_organization');

		}

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* Clear footer buttons */
		ModalAction = [];

		/* Update body */
		ModalBody = () => {
			return this.OrganizationForm(org)
		}

		/* Add SubmitButton */
		ModalAction.push(
			<Button
				sx={{ ml:2}}
				color="warning"
				variant="outlined"
				startIcon={<DeleteOutlineIcon />}
				key="delete"
				onClick={() => { this.OrganizationDelete() }}>
				<span>{t("common:general.yes-delete")}</span>
			</Button>
		);

		ModalAction.push(
			<Button
				sx={{ ml:2}}
				key="ok"
				variant="contained"
				onClick={() => { this.OrganizationSave() }}>
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modalTitle, ModalBody, ModalAction, modalProps });

	}

	OrganizationForm = (org) => {

		/* Translate function */
		let { t } = this.props;

		/* */
		let skip = this.skipList(org.user_id);

		/* */
		let groupOptions = this.GroupOptions(skip);

		return (
			<fieldset id="organization-form" className="dialog-form">

				<input type="hidden" name="user_id" defaultValue={org.user_id} />

				<div className="lz-m-20">

					<Form.Group className="">
						<Form.Label>{t('common:settings.organization.organization_name')}</Form.Label>
						<Form.Control type="text" name="name" defaultValue={org.name} />
					</Form.Group>

					<Form.Group className="">
						<Form.Label>{t('common:settings.organization.parent_organization')}</Form.Label>
						<Form.Control as="select" name="group_id" defaultValue={org.group_id}>
							{groupOptions}
						</Form.Control>
					</Form.Group>

				</div>

			</fieldset>
		);
	}

	/* */
	OrganizationSave = () => {

		/* */
		let { organizations, ModalBody, modalProps } = this.state;

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ ModalBody });

		/* */
		let form = document.querySelector("#organization-form");
		let data = {
			"tenant_id": null,
			"group_id": form.elements['group_id']['value'],
			"user_id": form.elements['user_id']['value'],
			"name": form.elements['name']['value'],
		};

		/* */
		OrganizationService.save(data).then((response) => {

			/* */
			organizations = null;

			/* */
			modalProps = { "show": false };

			/* */
			this.setState({ organizations, modalProps });

		}).catch((error) => {
			console.log("error", error.response);
		})

	};

	/* */
	OrganizationCsvFileDownload = async () => {

		/* */
		let { ModalBody, ModalAction, modalTitle, modalProps } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "organization-csv-dialog",
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.general.csv-download";

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		ModalAction = [];

		/* */
		this.updateState({ modalClose: false, ModalBody, ModalAction, modalTitle, modalProps });

		/* */
		OrganizationService.DownloadCsvOrganization().then((response) => {

			/* */
			modalProps = { "show": false };
			/* */
			this.setState({ modalProps });

			let aLink = document.createElement("a");
			aLink.href = response.url;
			aLink.click();

		}).catch((error) => {
			/* */
			console.log("error", error);
		});
	}

	/* */
	OrganizationCsvFileUpload = async () => {

		/* */
		let { ModalBody, ModalAction, modalProps, organizations } = this.state;
		/* */
		let form = document.querySelector("#csv-upload");

		/* File required */
		if (form['files']['length'] === 0) {
			return false;
		}

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		ModalAction = [];

		/* */
		this.updateState({ modalClose: false, ModalBody, ModalAction });

		/* */
		let reader = new FileReader();

		new Promise((resolve) => {
			/* */
			reader.onload = (event) => {
				resolve((event.target.result).split(',')[1]);
			};

			/* */
			reader.readAsDataURL(form.files[0]);

		}).then((result) => {

			/* */
			let upload_data = { "upload_file": result };
			/* */
			OrganizationService.UploadCsvOrganization(upload_data).then((response) => {

				if ('error' in response) {
					/* */
					this.CsvFileUpload(response.error);
				} else {
					/* */
					organizations = null;
					/* */
					modalProps = { "show": false };
					/* */
					this.setState({ modalProps, organizations });
				}

			}).catch((error) => {
				/* */
				console.log("error", error);
				/* */
				this.updateState({ modalClose: false });
			});
		});
	}

	/* */
	OrganizationDelete = () => {

		/* Translate function */
		let { t } = this.props;
		/* */
		let { organizations, ModalBody, ModalAction, modalProps } = this.state;

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		ModalAction = [];

		/* */
		this.updateState({ modalClose: false, ModalBody, ModalAction });

		/* */
		let form = document.querySelector("#organization-form");
		let data = {
			"parent_group_id": form.elements['group_id']['value'],
			"group_id": form.elements['user_id']['value'],
		};

		/* */
		OrganizationService.delete(data).then((response) => {

			/* */
			organizations = null;

			/* */
			modalProps = { "show": false };

			/* */
			this.setState({ organizations, modalProps });

		}).catch((error) => {
			ModalBody = () => {
				return (
					<div className="lz-m-20">
						<div className="lz-m-20">
							{t(`common:${error.response.data.message}`)}
						</div>
						<div className="lz-m-20">
							{error.response.data.detail}
						</div>
					</div>
				);
			};

			this.updateState({ modalClose: true, ModalBody, ModalAction });
		})

	};

}

export default withTranslation()(Organization);
