import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import mappingTranslateDefault from "../Main/MappingTranslateCommon";
import applestore from "../../Pages/Assets/img/applestore.svg";
import googpleplay from "../../Pages/Assets/img/google-play-badge.png";

/**
 * @param {type boolean - open the dialog} openOtpDialog
 * @param {type Function - callback function on submit click} callback
 *  @param {type string  - Link encryption for generate QR} qrValue
 */
export default withTranslation()(function QRMFADialog(props) {
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [isVerify, setIsVerify] = useState(false);

  const getCurrentAuthUser = async () => {
    return await Auth.currentAuthenticatedUser({
      bypassCache: false,
    });
  };
  const handleChange = (event) => {
    setIsVerify(false);
    setOtp(event.target.value);
  };
  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setIsVerify(false);
    props.callback(false, true);
  };
  const handleConfirm = async () => {
    setOpen(true);
    let user = await getCurrentAuthUser();
    await Auth.verifyTotpToken(user, otp)
      .then((r) => {
        console.log(r);
        setIsVerify(false);
        setOpen(false);
        props.callback(false, false, true);
      })
      .catch((e) => {
        setIsVerify(true);
        setOpen(false);
        // if (e.)
        props.callback(true, true);
        console.log(e);
        // Token is not verified
      });

    // don't forget to set TOTP as the preferred MFA method
  };
  //   setTimeout(()=>{
  //     setOpen(true);
  //   }, 5000)
  return (
    <div>
      <Dialog
        fullWidth={true}
        className="otp_dialog"
        open={props.openQrDialog}
        disableEscapeKeyDown={true}
        onClose={handleClose}
      >
        <DialogTitle className="otp_dialog_header">
          {mappingTranslateDefault(
            props.t,
            "common:general.mfa-auth.qr-header",
            "アプリ認証を設定する"
          )}
        </DialogTitle>
        <DialogContent className="otp_dialog_content">
          <DialogContentText color={"black"}>
            <strong>
              {mappingTranslateDefault(
                props.t,
                "common:general.mfa-auth.qr-step1",
                "1. スマートフォンで認証アプリをお持ちでない方は、\nはじめにアプリをダウンロードしてください。"
              )}
            </strong>
          </DialogContentText>
          <div className="otp_dialog_dl">
            <div className="otp_dialog_dl_batch">
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=vi&gl=US",
                    "_blank"
                  )
                }
                src={googpleplay}
                className="logo_googplay"
                alt="Googleplay"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/microsoft-authenticator/id983156458",
                    "_blank"
                  )
                }
                src={applestore}
                className="logo_applestore"
                alt="Appstore"
              />
            </div>
            <div className="otp_dialog_dl_ms-auth">
              <div className="otp_dialog_dl_ms-auth_qr">
                <QRCode
                  size={512}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={"https://www.microsoft.com/ja-jp/security/mobile-authenticator-app"}
                />
              </div>
              <div className="otp_dialog_dl_ms-auth_caption">Microsoft Authenticator</div>
            </div>
          </div>
          <div className="otp_dialog_button_store">
            <div className="otp_dialog_button_store_guide">

              <DialogContentText color={"black"}>
                <strong>
                  {mappingTranslateDefault(
                    props.t,
                    "common:general.mfa-auth.qr-step2",
                    "2. 認証アプリを開いて\n右のQRコードを読み込んでください。"
                  )}
                </strong>
              </DialogContentText>
            </div>
            <div className="otp_dialog_button_store_qr">
              {props.qrValue ? (
                <QRCode
                  size={512}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={props.qrValue}
                />
              ) : null}
              {/* <img src="" alt="QR code" /> */}
            </div>
          </div>
          <DialogContentText color={"black"}>
            <strong>
              {mappingTranslateDefault(
                props.t,
                "common:general.mfa-auth.qr-step3",
                "3. アプリに表示されたPINコードを入力してください。"
              )}
            </strong>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={isVerify}
            id="otp"
            label="PIN"
            type="text"
            inputProps={{ maxLength: 6 }}
            fullWidth={true}
            helperText={
              isVerify
                ? mappingTranslateDefault(
                    props.t,
                    "common:general.mfa-auth.invalid-otp",
                    "無効なPINコードです"
                  )
                : ""
            }
            variant="outlined"
            value={otp}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {mappingTranslateDefault(
              props.t,
              "common:general.mfa-auth.qr-cancel",
              "閉じる"
            )}
          </Button>
          <LoadingButton
            loading={open}
            color="primary"
            variant="contained"
            onClick={handleConfirm}
          >
            {mappingTranslateDefault(
              props.t,
              "common:general.mfa-auth.ok",
              "OK"
            )}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
});
