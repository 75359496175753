import React from 'react';

import {
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from 'react-router-dom';

import {
	Button,
} from 'react-bootstrap';
import CompanyForm from './CompanyForm';


class InfoConfirm {

	static get key() {
		return "info-confirm";
	}
	static get name() {
		return "InfoConfirm";
	}
	static get code() {
		return InfoConfirm.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = InfoConfirm.key;
		this.name = InfoConfirm.name;
		this.code = InfoConfirm.code;
		this.formData = objMain.state.formData;

		this.objMain.setPageObject(this);

		this.containerWidth = "800px";
		this.formData.verify_url = window.location.origin + "/registration/verification/{token}";
		this.formData.partner_id = objMain.state.partner_id;
	}

	formSubmitCallback = (ev) => {
		this.objMain.setFormData(this.formData);
		// console.log("DONE", this.objMain.state.formData);
	};

	confirmSubmit = (ev) => {
		// this.objMain.modalToggle(true);

	}

	render() {

		let { t } = this.props;
		// console.log(this.formData);

		// let info = Object.keys(this.formData).map((key) =>
		// 	<div key={key} className="form-group col-md-6 col-12 ">
		// 		<label className="form-label">{this.fields[key].label}</label>
		// 		<span className="form-control">{this.formData[key].toString()}</span>
		// 	</div>
		// );

		// console.log(info);

		return (

			<div className="form-container">

				<div className="fields-container data-list form-row">

					<div className="form-group col-md-12 col-12 ">
						<label className="form-label">{this.fields["term_condition"].label}</label>
						<span className="form-control">{this.formData["term_condition"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["lastname"].label}</label>
						<span className="form-control">{this.formData["lastname"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["firstname"].label}</label>
						<span className="form-control">{this.formData["firstname"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["lastname_kana"].label}</label>
						<span className="form-control">{this.formData["lastname_kana"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["firstname_kana"].label}</label>
						<span className="form-control">{this.formData["firstname_kana"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["email"].label}</label>
						<span className="form-control">{this.formData["email"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["full_mobile"].label}</label>
						<span className="form-control">{this.formData["full_mobile"].toString()}</span>
					</div>

					<div className="form-group col-md-12 col-12 ">
						<label className="form-label">{this.fields["company_name"].label}</label>
						<span className="form-control">{this.formData["company_name"].toString()}</span>
					</div>

					<div className="form-group col-md-12 col-12 ">
						<label className="form-label">{this.fields["company_name_kana"].label}</label>
						<span className="form-control">{this.formData["company_name_kana"].toString()}</span>
					</div>

					<div className="form-group col-md-12 col-12 ">
						<label className="form-label">{this.fields["company_address"].label}</label>
						<span className="form-control">{this.formData["company_address"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["company_country"].label}</label>
						<span className="form-control">{this.formData["company_country"].toString()}</span>
					</div>

					<div className="form-group col-md-6 col-12 ">
						<label className="form-label">{this.fields["company_telephone"].label}</label>
						<span className="form-control">{this.formData["company_telephone"].toString()}</span>
					</div>

				</div>

				<div className="form-bottom">
					<div className="wrapper-button-cancel">
						<Button variant="secondary" onClick={(ev) => this.objMain.setActivePage(CompanyForm)}>
							<FontAwesomeIcon icon={faChevronLeft} />
							<span>{t('common:auth.general.back')}</span>
						</Button>
					</div>
					<div className="wrapper-button-submit">
						<Link to={{ pathname: "/registration/processing", state: { formData: this.formData } }} className="btn btn-primary">
							<span>{t('common:auth.general.comfirm')}</span>
						</Link>
						{/* <Button variant="primary" onClick={this.confirmSubmit}>
							<span>{t('common:auth.general.comfirm')}</span>
						</Button> */}
					</div>
				</div>

			</div>
		);

	}

}

export default InfoConfirm;
