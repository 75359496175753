import React from 'react';
import ReactDOM from 'react-dom';
import Languages from './Languages';
import Router from './Router';
import AuthRouter from './Router/Auth';
// import Login from './Pages/Auth/Login';
// import reportWebVitals from './reportWebVitals';
import './index.css';
import './Pages/Assets/css/main.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import i18next from "i18next";

// import Amplify, { Auth } from "aws-amplify";
// import awsCognito from "./Config/Cognito";
// import Main from './Main';
import AuthService from './Service/Auth';
import SystemService from './Service/System';
import Cookies from 'js-cookie';
// import Service from './Service';
import Error from "./Pages/Error";
import Service from './Service';
import { ThemeProvider } from '@mui/material/styles';
import { MuiCustomTheme } from './Pages/Assets/CustomTheme/MuiCustomTheme.ts';
import CssBaseline from '@mui/material/CssBaseline';
import { UserInfoProvider } from "./Context/ContextProvider/UserInfoProvider";

var langProps = {
	lang: Cookies.get('lang'),
};

if (!langProps.lang) {
	langProps.lang = 'ja';
	Cookies.set('lang', langProps.lang);
}

if (process.env.NODE_ENV === 'production' && ["STG", "PROD"].includes(process.env.REACT_APP_ENV_FILE)) {
	console.log = function (val) { };
}

window.onload = (ev) => {
	console.log(ev);
	localStorage.removeItem("user-info");
}
// Amplify.configure(awsCognito);

async function Index() {
	let MainRouter = AuthRouter;
	let errProps = null;
	let userInfo = null;

	let authInfo = await AuthService.GetCurrentLogin().catch(async err => {
		//console.log(err);
	});

	if (authInfo) {
		userInfo = await Service.GetUserInfo().catch(err => {
			//(err.response);
			if (err.hasOwnProperty("data")) {
				errProps = {
					msg: err.response.data.message,
					code: err.response.status,
				}
			} else {
				errProps = {
					msg: "Connection error, please try to reload.",
					code: 400,
				}
			}
		});
		//console.log(userInfo);
		if (userInfo) {
			MainRouter = Router;
			langProps.lang = userInfo.language;
			console.log(userInfo.language);
			Cookies.set('lang', langProps.lang);
		} else {
			// Logout from Cognito if login errro
			await AuthService.Logout();
			// .catch(err => {
			// 	console.log(err.response);
			// });

			MainRouter = () => {
				return (
					<Error {...errProps} />
				);
			};
		}
	}
	
	if (userInfo) {
		// check Tenant
		const data = {
			tenant_id: userInfo.current_tenant_id
		}
		await Service.getTenant(data).catch(e => {
			if (e.response.status === 401) {
				errProps = {
					title: 'IP制限中です',
					msg: ["許可されたIPからアクセスしてください", "詳しくは管理者にお問合せください"],
					code: 401,
				}
			}
		})
	}

	let langResources = await SystemService.GetLanguageResources(langProps.lang).catch(err => {
		//console.log(err.response);
		if (err.hasOwnProperty("data")) {
			errProps = {
				msg: err.response.data.message,
				code: err.response.status,
			}
		} else {
			errProps = {
				msg: "Connection error, please try to reload.",
				code: 400,
			}
		}
	});

	if (langResources) {
		langProps.resources = langResources;
	}

	if (errProps) {
		MainRouter = () => {
			return (
				<Error {...errProps} />
			);
		};
	}

	// console.log(langProps);
	// console.log(langResources);

	ReactDOM.render(
		<React.StrictMode>
			<ThemeProvider theme={MuiCustomTheme}>
				<CssBaseline />
				<Languages {...langProps}>
					<UserInfoProvider userInfoContent={userInfo}>
						<MainRouter userInfo={userInfo} />
						{/* <MainRouter /> */}
					</UserInfoProvider>
				</Languages>
			</ThemeProvider>
		</React.StrictMode>
		, document.getElementById('root')

	);
};

Index();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
