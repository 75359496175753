// import PDFViewer from "../Fragments/PDFViewer";
// import PDFBGIcon from '../Assets/img/icons/file_pdf.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faChevronLeft,
	faChevronRight,
	// faShareSquare
} from "@fortawesome/free-solid-svg-icons";
import {
	Form,
	Button,
	// Container,
	Col,
	// InputGroup,
} from 'react-bootstrap';
import CryptoJS from 'crypto-js';
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
// import FileViewer from 'react-file-viewer';
import PDFViewer from "../Fragments/PDFViewer";
import Service from "../../Service";
import Loading from '../Loading';

export default class TemplateInfo {

	static get key() {
		return "template-info";
	}
	static get name() {
		return "TemplateInfo";
	}
	static get code() {
		return TemplateInfo.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = TemplateInfo.key;
		this.name = TemplateInfo.name;
		this.code = TemplateInfo.code;

		this.formData = {
			param_info: "param-info"
		};

		this.objMain.setPageObject(this);
	}

	registerFiles = (ev) => {
		let files = ev.target.files;
		for (let file of files) {
			let reader = new FileReader();
			reader.onload = (ev) => {
				// console.log(ev);
				let content = CryptoJS.enc.Latin1.parse(reader.result);
				var fileKey = CryptoJS.MD5(content).toString();
				let { PDFFiles } = this.objMain.state;
				PDFFiles[fileKey] = {
					file: file,
					name: file.name,
					type: file.type,
					size: file.size,
					lastModified: file.lastModified,
					lastModifiedDate: file.lastModifiedDate,
				};
				this.objMain.updateState({ PDFFiles });
				this.objMain.updateState({ SelectedFile: fileKey });
				// console.log(this.objMain.state.PDFFiles);
			};
			reader.onerror = () => {
				//console.log(reader.error);
			};
			reader.readAsBinaryString(file);
		}
	}

	formSubmitValidation = (ev, PDFFiles) => {
		ev.preventDefault();

		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.updateState({ pageState: pageState, PDFFiles });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	formSubmitCallback = (ev) => {
		console.log(ev);
		console.log(this.constructor);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	}

	ShowErrorMessage = (errReponse, fnCallback) => {
		console.log(errReponse);
		let { t } = this.props;
		let { modal } = this.objMain.state;

		let errMessage = errReponse.data.message;
		if (errReponse.data.hasOwnProperty("error")) {
			errMessage = errReponse.data.error;
		}

		modal.body = errMessage;
		modal.action = [(
			<Button key="ok" variant="primary" onClick={(ev) => {
				modal.body = Loading;
				modal.action = [];
				this.objMain.updateState({ modal }, fnCallback);
			}}>
				<span>{t("common:general.try-again")}</span>
			</Button>
		)];
		this.objMain.updateState({ modal });
	}

	ConvertDoc2PDF = (DocFile) => {
		Service.FileUpload(DocFile).then(resp => {
			console.log(resp);
			Service.ConvertDoc2PDF({
				// "id": 1,
				// "template": 1,
				"template_name": DocFile.name.replace(/\.docx|\.doc/g, ""),
				"file_name": DocFile.name,
				// "file": DocFile,
				// "tenant_id": UserInfo.tenant_id,
				// "create_user": UserInfo.user_id,
				"temp-upload": resp.temp,
			}).then(resp => {
				console.log(resp);
				let DocAsPDF = resp;
				let DocKeys = {};
				for (let i in DocAsPDF.keys) {
					let eKey = {
						code: DocAsPDF.keys[i],
						name: DocAsPDF.keys[i].replace(/{\$:|}/g, ""),
						required: true,
					};
					DocKeys[i] = eKey;
				}
				this.objMain.updateState({ DocAsPDF, DocKeys, DocKeysRequiredAll: true });
				// md5: "baa47551286c13238a2fb24b11e2f731"
				// pdf: "temp-upload/ebff7cde-4401-41cf-80ef-7d094b73885f.pdf"
				// url: "https://paperlogic-storage.s3.amazonaws.com/temp-upload/xxx"
			}).catch(err => {
				this.ShowErrorMessage(err.response);
			});
		}).catch(err => {
			this.ShowErrorMessage(err.response);
		});
	}

	FileUploadPanel = (props) => {
		// let { allowUpload } = this.state;
		// let { InputFileUpload } = this;
		let { DocFile } = this.objMain.state;

		// if (!allowUpload) {
		// 	return null;
		// }

		// let className = `drop-zone-panel ${props.className || ""}`;

		return (
			<div className={`file-upload-panel${DocFile ? "" : " no-file"}`}>

				<div className={`drop-zone-panel`}>

					<label htmlFor="pdf-upload"
						className="dropbox-area file-dropbox"
						onDragOver={(ev) => {
							ev.preventDefault();
						}}
						onDrop={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
							this.RegisterFiles(ev.dataTransfer.files);
						}}
						onDragLeave={(ev) => {
							ev.preventDefault();
							let body = ev.target.closest(".pdf-fragment");
							body.classList.remove("drag-over");
							let dropPanel = body.querySelector(".file-upload-panel");
							dropPanel.classList.remove("allow-drop");
						}}
					>
						<div className="drop-area-inner">
							{/* <IconPDFFile /> */}
							<FontAwesomeIcon icon={faFileWord} />
						</div>
						{/* <InputFileUpload /> */}
						<input type="file" name="pdf-upload" id="pdf-upload" accept=".doc,.docx"
							style={{ display: "none" }}
							onChange={(ev) => {
								let DocFile = ev.target.files[0];
								let reader = new FileReader();
								reader.onload = (ev) => {
									let content = CryptoJS.enc.Latin1.parse(reader.result);
									DocFile.md5 = CryptoJS.MD5(content).toString();
									let DocFileURL = URL.createObjectURL(DocFile);
									this.objMain.updateState({ DocFile, DocFileURL }, () => {
										this.ConvertDoc2PDF(DocFile);
									});
									console.log(DocFile);
								};
								reader.onerror = () => {
									console.log(reader.error);
								};
								reader.readAsBinaryString(DocFile);
							}}
						/>
					</label>
					<label className="info">Wordファイルをアップロード（.docx）</label>

				</div>

			</div>
		);
	}

	DocFilePreview = (props) => {
		let { DocAsPDF } = this.objMain.state;

		// if (!DocAsPDF) {
		// 	return null;
		// }
		let PDFProps = {
			showAll: true,
			file: DocAsPDF || null,
			upload: false,
			fit: true,
			controller: true,
			info: false,
			// full: true,
		};

		return (
			<PDFViewer {...PDFProps} />
		);
	}

	// FindDocKeys = () => {
	// 	var count = 0;
	// 	var findInterval = setInterval(() => {
	// 		count++;
	// 		let docText = document.querySelector("#docx");
	// 		// console.log(count, docText);
	// 		if (docText && docText.innerText) {
	// 			// console.log(docText.innerText);
	// 			let allKeys = docText.innerText.match(/{\$:.*?}/g);
	// 			console.log(allKeys);
	// 			let DocKeys = {};
	// 			for (let i in allKeys) {
	// 				let eKey = {
	// 					code: allKeys[i],
	// 					name: allKeys[i].replace(/{\$:|}/g, ""),
	// 					required: true,
	// 				};
	// 				DocKeys[i] = eKey;
	// 			}
	// 			this.objMain.updateState({ DocKeys, DocKeysRequiredAll: true });
	// 			clearInterval(findInterval);
	// 		}
	// 	}, 0);
	// }

	DocUploaded = () => {
		let { t } = this.props;
		let { DocFile, DocAsPDF, DocKeys, DocKeysRequiredAll } = this.objMain.state;
		let { DocFilePreview } = this;

		if (!DocFile) {
			return null;
		}

		if (!DocAsPDF) {
			return <Loading />;
		}

		//console.log(DocKeys);
		let ElementKeys = [];
		if (DocKeys) {
			for (let i in DocKeys) {
				ElementKeys.push(
					<li key={i} className="doc-key-item">
						<span>{DocKeys[i].code}</span>
						<Form.Check key="user-self-cert"
							type="switch"
							id={`key_required_${i}`}
							name={`key_required_${i}`}
							value='required'
							onChange={(ev) => {
								let { DocKeys } = this.objMain.state;
								//console.log(ev.target.checked);
								DocKeys[i].required = ev.target.checked;
								this.objMain.updateState({ DocKeys });
							}}
							label={<div>{t("common:documents.template.key-required")}</div>}
							checked={[true, "true"].includes(DocKeys[i].required)}
						// title={t("common:documents.host-setting.use-self-certificate")}
						/>
					</li>
				);
			}
		}

		return (
			<Form.Row className="flex-full" style={{ position: "relative" }}>

				<Form.Group as={Col} xs={12} md={8} className="no-margin">
					<div className={`template-preview active`}>
						<DocFilePreview />
					</div>
				</Form.Group>
				<Form.Group as={Col} xs={12} md={4} className="no-margin doc-key-item-list">
					<ul className="doc-key-container">
						<li className="doc-key-item">
							<h3>{t("common:documents.template.key-list")}</h3>
							<Form.Check key="user-self-cert"
								type="switch"
								id={`key_required_all}`}
								name={`key_required_all`}
								value='required'
								onChange={(ev) => {
									let { DocKeys, DocKeysRequiredAll } = this.objMain.state;
									DocKeysRequiredAll = ev.target.checked;
									for (let i in DocKeys) {
										DocKeys[i].required = DocKeysRequiredAll;
									}
									this.objMain.updateState({ DocKeys, DocKeysRequiredAll });
								}}
								label={<div>{t("common:documents.template.key-required-all")}</div>}
								checked={[true, "true"].includes(DocKeysRequiredAll)}
							// title={t("common:documents.host-setting.use-self-certificate")}
							/>
						</li>
						{ElementKeys}
					</ul>
				</Form.Group>

			</Form.Row>
		);
	}

	PageAction = () => {
		let { t } = this.props;
		let { DocFile, DocAsPDF } = this.objMain.state;

		if (!DocFile || !DocAsPDF) {
			return null;
		}

		return (
			<div className="page-action-bottom-absolute">
				{/* <div className="wrapper-button-cancel">
					<Button variant="secondary" onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
						<FontAwesomeIcon icon={faChevronLeft} />
						<span className="btn-label">{t('common:documents.general.back')}</span>
					</Button>
				</div> */}
				<div className="wrapper-button-submit">
					<Button variant="primary" type="submit">
						<span className="btn-label">{t('common:documents.general.next')}</span>
						<FontAwesomeIcon icon={faChevronRight} />
					</Button>
				</div>
			</div>
		);
	}

	render() {
		// let { t } = this.props;
		// let { FieldControl } = this.objMain;
		let { DocFile } = this.objMain.state;
		let { FileUploadPanel, PageAction, DocUploaded } = this;

		// console.log(DocFile);
		// console.log(DocFileURL);

		// <Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >
		return (
			<div className="full-preview">

				<Form
					// validated={formValidate[this.name]}
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="full-form"
					style={{ height: "100%" }}
				>

					{/* <div className="form-body flex-column"> */}
					<div className="form-body flex-column">

						<div className={`template-new${DocFile ? "" : " active"}`}>
							<FileUploadPanel />
						</div>

						<DocUploaded />

						<PageAction />

					</div>

					<div className="form-foot">

					</div>

				</Form>

			</div>

		);
		// {/* </Container > */ }

	}

}
