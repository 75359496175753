import React, { useCallback, useState } from "react";
import Service from "../../Service";
import { UserInfoContext } from "../UserInfoContext";

export const useProvideUserInfo = (userInfoContentProps = {}) => {
  const [userInfoContent, setUserInfoContent] = useState(userInfoContentProps);

  const getUserInfo = useCallback(async() => {
    Service.GetUserInfo()
      .then((response) => {
        const user_info = response;
        setUserInfoContent(user_info);
        //console.log("Service.GetUserInfo()");
      })
      .catch((error) => {
        //console.log("CATCH", error);
      });
  }, []);

  return {
    userInfoContent,
    setUserInfoContent,
    getUserInfo,
  };
};

export const UserInfoProvider = ({
  children,
  userInfoContent: userInfoContentProps,
}) => {
  const { userInfoContent, getUserInfo, setUserInfoContent } = useProvideUserInfo(
    userInfoContentProps
  );

  return (
    <UserInfoContext.Provider
      value={{
        userInfoContent,
        getUserInfo,
        setUserInfoContent
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
