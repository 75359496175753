// import Service from "./Service";
import React from "react";
// import {
//     Button,
//     Collapse,
// } from 'react-bootstrap';
// import Service from "./Service";

export default class Test extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            UserInfo: null,
        };
        //console.log(this.state.UserInfo);

    }

    render() {
        setTimeout(() => {
            // this.state.UserInfo = "resp";
            this.setState({ UserInfo: "resp" });
        }, 1000);
        return (
            <div>{this.state.UserInfo}</div>
        );
    }
}
