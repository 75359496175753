// import { I18nextProvider } from "react-i18next";
// // import i18next from "i18next";
// import i18n from 'i18next';
// import Cookies from 'js-cookie';
// // import Router from '../Router';
// import common_en from "./Translations/common-en.json";
// import common_ja from "./Translations/common-ja.json";
// import message_en from "./Translations/message-en.json";
// import message_ja from "./Translations/message-ja.json";
// import SystemService from "../Service/System";
// // import agreement_en from "./Translations/agreement-en.html";
// // import agreement_ja from "./Translations/agreement-ja.json";
// // const file = require("./Translations/agreement-en.html");
// // import txt from 'raw-loader!./Translations/agreement-en.txt';
// // const file = require("./Translations/agreement-en.txt");
// import common from "./Translations/common-en.json";
// import SyncBackend from 'i18next-fs-backend';

// var lang = Cookies.get('lang');
// if (!lang) {
//     lang = Cookies.set('lang', 'ja');
// }
// var resources = {
//     // en: {
//     //     common: common_en,
//     //     message: message_en,
//     // },
//     // ja: {
//     //     common: common_ja,
//     //     message: message_ja,
//     // },
// };
// // console.log(common);
// // var resources = {};
// // });

// // .then(resp => {
// //         console.log(resp);
// //         let data = resp.data;
// //         resources[lang] = {
// //             common: data.data,
// //         };
// //         resources.ja = {
// //             common: common_ja,
// //         }
// //         i18next.init({
// //             interpolation: { escapeValue: false },
// //             lng: lang,
// //         });

// //         console.log(resources);
// //     }).catch(err => {
// //         console.log(err);
// //     });
// //     console.log(lang);

// // }

// // GetDictionary();
// // class Languages {
// //     constructor(props) {
// //         console.log(props);
// //     }

// //     render() {
// //         return (
// //             <I18nextProvider i18n={i18next}>
// //                 {/* {props.children} */}
// //             </I18nextProvider>
// //         );
// //     }
// // }
// const i18next = new Promise((resolve, reject) => {
//     SystemService.GetDictionary(lang).then(resp => {
//         console.log(resp);
//         i18n.init({
//             // initImmediate: false,
//             interpolation: { escapeValue: false },
//             lng: lang,
//             resources: {
//                 en: {
//                     common: common_en,
//                     message: message_en,
//                 },
//             },
//             // resources: resources,
//         });
//         resolve(i18n);
//         // resources = resp.data.data;
//         // i18next.addResourceBundle(lang, 'common', resp.data.data);
//     }).catch(err => {
//         reject(err);
//     });
// });
// // })
// // var times = 0;
// // var resources = await SystemService.GetDictionary(lang);

// function Languages(props) {
//     // i18next.init({
//     //     // initImmediate: false,
//     //     interpolation: { escapeValue: false },
//     //     lng: lang,
//     //     resources: resources,
//     //     // console.log(resp);
//     // });
//     // var result = await GetDictionary();
//     // var result = await promise;
//     // console.log(result);
//     console.log(resources);
//     // times++;
//     //     // fs.readFile('D:/Projects/SSC/PaperLogic/NewENV/SourceSync/mdb5-react/src/Languages/Translations/agreement-en.json', null, (err, data) => {
//     //     // console.log(agreement_en);
//     //     // })D:\Projects\SSC\PaperLogic\NewENV\SourceSync\mdb5-react\src\Languages\Translations\agreement-en.html

//     //     // fetch('./Translations/agreement-en.txt').then((r) => r.text()).then(text => {
//     //     // console.log(txt);

//     return (
//         <I18nextProvider i18n={i18next}>
//             {props.children}
//         </I18nextProvider>
//     );

//     // return <I18nextProvider i18n={i18next} />;
// }

// export default Languages;

import React from "react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
// import common_en from "./Translations/common-en.json";
// import common_ja from "./Translations/common-ja.json";
// import SystemService from "../Service/System";

// import Cookies from 'js-cookie';
// var lang = Cookies.get('lang');
// if (!lang) {
//     lang = Cookies.set('lang', 'ja');
// }

// function Languages(props) {
// super(props);
// this.props = props;
// console.log(props);
// this.i18next = i18next;
// this.i18next = new Promise((resolve, reject) => {
//     SystemService.GetDictionary(lang).then(resp => {
//         console.log(resp);
//         i18next.init({
//             // initImmediate: false,
//             interpolation: { escapeValue: false },
//             lng: lang,
//             resources: {
//                 en: {
//                     common: common_en,
//                 },
//             },
//             // resources: resources,
//         });
//         resolve(i18next);
//         // resources = resp.data.data;
//         // i18next.addResourceBundle(lang, 'common', resp.data.data);
//     }).catch(err => {
//         reject(err);
//     });
// this.i18next.init({
//     interpolation: { escapeValue: false },
//     lng: lang,
//     resources: {
//         en: {
//             common: common_ja,
//         },
//     },
// });
// });
// }

function Languages(props) {

    // let lang = new Languages();
    // let i18next = lang.i18next;
    // return Result.render();
    // console.log(props);
    // console.log(options);
    i18next.init({
        interpolation: { escapeValue: false },
        lng: props.lang,
        resources: {
            [props.lang]: {
                common: props.resources,
            },
        }
    });

    return (
        <I18nextProvider i18n={i18next}>
            {props.children}
        </I18nextProvider>
    );
}

export default Languages;