import Main from "./Main";
import { withTranslation } from "react-i18next";

// import {
// 	Button,
// } from 'react-bootstrap';

// import {
// 	faTasks,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class Circle extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Circle";
		this.state.code = "circle";
		this.state.function_type = 3;
		this.state.searchOption.function_type = 3;
	}

}

export default withTranslation()(Circle);