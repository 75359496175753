import React from 'react';
//import { Link } from "react-router-dom";
import TransAgreement from '../../../Languages/Translations/Static/Agreement';
import UserForm from './UserForm';

import {
    Form,
} from 'react-bootstrap';
import Button from '@mui/material/Button';

class Agreement {

	static get key() {
		return "agreement";
	}
	static get name() {
		return "Agreement";
	}
	static get code() {
		return Agreement.name.toLowerCase();
	}

    constructor(objMain) {
        this.objMain = objMain;
		this.key = Agreement.key;
		this.name = Agreement.name;
		this.code = Agreement.code;
        this.formData = {
            term_condition: "common:auth.registration.agreement.term-condition",
        };

        this.objMain.setPageObject(this);
        this.containerWidth = "100%";
    }

    formSubmitCallback = (ev) => {
        let { t } = this.props;
        this.formData.term_condition = t('common:auth.registration.agreement.accepted');
        this.objMain.setFormData(this.formData);
        this.objMain.setActivePage(UserForm);
    };

    render() {

        let { t } = this.props;
        let { formValidate } = this.objMain.state;

        return (

            <Form
                validated={formValidate[this.name]}
                id={"form_" + this.name}
                onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
                noValidate
            >

                <div className="fields-container term-condition-detail">
                    <TransAgreement />
                </div>

                <div className="form-bottom">
                    <div className="wrapper-button-cancel">
                        <Button variant="outlined" href="/login" size="large">
                            {t('common:auth.general.decline')}
                        </Button>
                    </div>
                    <div className="wrapper-button-submit">
                        <Button variant="contained" type='submit' value="Accepted">
                            <span>{t('common:auth.general.accept')}</span>
                        </Button>
                    </div>
                </div>

            </Form >
        );

    }

}

export default Agreement;
