import '../Assets/css/settings.css';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import OrganizationService from '../../Service/Organization';
import { withTranslation } from "react-i18next";
import { Form, } from 'react-bootstrap';
import AuthService from '../../Service/Auth';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserInfoContext } from "../../Context/UserInfoContext";
import Moment from 'moment';
import mappingTranslateDefault from '../Main/MappingTranslateCommon';

class User extends Layout {
	static contextType = UserInfoContext;

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "user";
		this.state.activeTab = 0;
		this.state.tabs = [
			{ "status": 0, "label": "common:settings.user.active" },
			{ "status": 1, "label": "common:settings.user.inactive" },
		];

		this.state.user_info = null;

		this.state.pagination = {
			"page": 1,
			"take": 10,
			"total": 0,
		};

		this.state.search = "";
		this.state.datalist = null;
		this.state.organizations = null;

	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {
		//
		const user_info = this.context.userInfoContent;
		this.setState({ user_info });
	}

	componentDidUpdate() {

		/* */
		if (this.state.datalist === null) {

			/* */
			let tab = this.state.activeTab;
			let page = this.state.pagination.page;
			let take = this.state.pagination.take;
			// let { UserInfo } = this.state;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"tenant_id": null,
				"status": this.state.tabs[tab]['status'],
				"search": this.state.search,
				"skip": skip,
				"take": take,
				//"super_administrator_role": UserInfo.super_administrator_role,
			}

			/* */
			Service.getUserHostList(data).then((response) => {

				/* */
				let { datalist, pagination } = this.state;

				/* */
				datalist = response.records;

				/* */
				pagination.total = response.total;

				/* */
				this.setState({ datalist, pagination });

			}).catch((error) => {
				//console.log(error.response);
			});

		}

		/* */
		if (this.state.organizations === null) {
			OrganizationService.getList().then((response) => {

				/* */
				let { organizations } = this.state;

				/* */
				organizations = response;

				/* */
				this.setState({ organizations });

			}).catch((error) => {
				//console.log("OrganizationService.getList.error", error);
			});
		}

	}

	/** [Render] */
	render() {

		/* Search Function */
		let { t } = this.props;

		/* */
		const { Main, CsvFileUpload } = this;

		/* */
		const searchAttr = {
			"placeholder": t('common:settings.user.search'),
			"onKeyPress": (e) => {
				if (e.code === "Enter") {

					/* */
					let { search, datalist } = this.state;

					/* */
					search = e.target.value;
					datalist = null;

					/* */
					this.setState({ search, datalist });

				}
			},
		}

		/* */
		return (
			<Main>

				<div id="page-settings-guest" className="page-settings">

					{/* FilterTabs */}
					<div className="lz-flex">
						<div className='lz-flex-1'>
							<div className="lz-m-10 lz-mb-0">
								<Button
									key="list-download"
									sx={{ ml: 2 }}
									variant="outlined"
									size='small'
									startIcon={<UploadIcon />}
									disabled={!this.state.datalist}
									onClick={(ev) => {
										CsvFileUpload();
									}} className="btn-custom new-document" >
									{t('common:settings.general.list-upload')}
								</Button>
								<Button
									key="list-download"
									sx={{ ml: 2 }}
									onClick={(ev) => {
										this.HostListCsvFileDownload();
									}}
									startIcon={<DownloadIcon />}
									variant="outlined"
									size="small"
									disabled={!this.state.datalist}
								>
									{t('common:settings.general.list-download')}
								</Button>
							</div>
						</div>

						<div className="lz-flex-0">

							<div className="lz-m-10 lz-mb-0">
								<this.FilterTabs />
							</div>

						</div>

					</div>

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								<div className="lz-flex">

									{/* Add-Host Button */}

									<div >
										<Button
											sx={{ borderRadius: 19 }}
											title="Action"
											variant="contained"
											startIcon={<AddIcon />}
											disabled={!this.state.datalist}
											onClick={() => { this.openHostCreateDialog() }}>
											<span>{t('common:settings.user.add_user')}</span>
										</Button>
									</div>

									{/* Search-TextBox */}
									<div className="input-textbox">
										<input type="search" {...searchAttr} />
									</div>

								</div>

							</div>

						</div>

						<div className="ki-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="">
						<this.IndexTable />
					</div>

					{/* BottomPagination */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}


	/** */
	CsvFileUpload = (validateMessage = null) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.general.csv-upload";

		let ValidateMessage = () => {
			if (validateMessage) {
				let messageArr = [];
				let messageKeys = Object.keys(validateMessage);
				let messageKey = messageKeys[0];
				let headerErrKey = messageKeys[1];
				let itemMap = validateMessage[messageKey];

				if (validateMessage[headerErrKey] !== '') {
					messageArr.push(
						<div key={headerErrKey}>
							<span>
								{t(`common:${validateMessage[headerErrKey]}`)}
							</span>
						</div>
					);
				} else {
					for (let [itemKey, itemLines] of Object.entries(itemMap)) {
						if (itemLines.length !== 0) {
							messageArr.push(
								<div key={itemKey}>
									<span>
										{t(`common:${messageKey}`, { item_name: t(`common:${itemKey}`), line: itemLines.join(',') })}
									</span>
								</div>
							);
						}
					}
				}
				return messageArr;
			} else {
				return null;
			}
		}

		/* Update body */
		modal.body = () => {
			return (
				<div>
					{/* */}
					<div className="">
						<Form.Group className="">
							<Form.Label>{t("common:settings.general.csv-file-select")}</Form.Label>
							<Form.Control type="file" id="csv-upload" name="upload" accept=".csv" />
						</Form.Group>
					</div>
					<div>

						<ValidateMessage />
					</div>
				</div>
			);
		}

		modal.form = {};
		modal.close = true;

		/* Add SubmitButton */
		modal.action = [(
			<Button sx={{ ml: 1 }} key="ok" variant="contained" onClick={(ev) => {
				this.HostUserCsvFileUpload();
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });
	}

	/** [Element] */
	FilterTabs = () => {

		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { activeTab, pagination, datalist } = this.state;

				/* */
				activeTab = i
				pagination.page = 1;
				datalist = null;

				/* */
				this.setState({ activeTab, pagination, datalist });

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);
		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] = true;
		}

		/* */
		let start_list = 0;
		let last_list = 0;
		if (pagination.total > 0) {
			start_list = 1 + ((pagination.page - 1) * pagination.take);
			if (pagination.page === max) {
				last_list = pagination.total
			} else {
				last_list = start_list + (pagination.take - 1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	getCertificateInfo = (certificates, code) => {
		let cert = certificates.find(item => item.code === code);
		if (cert['user_id'] !== null) {
			return (<div>
				<div className='text-ellipsis certificates-name'>
					{cert['cert_name'] ?? ''}
				</div>
				<div className='text-ellipsis certificates-date'>
					{cert['cert_exp_date'] && cert['cert_start_date']
						? `${Moment(cert['cert_start_date']).format('YYYY.MM.DD')} - ${Moment(cert['cert_exp_date']).format('YYYY.MM.DD')}` : ''}
				</div>
			</div>)
		}
	}

	/** [Element] */
	IndexTable = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist, user } = this.state;
		// let currentUserInfo = {};
		// Service.GetUserInfo().then(data => {
		// 	currentUserInfo = data;
		// });

		/* */
		if (datalist === null) {
			return <LoadingList />;
		}

		/* */
		let statusMap = {
			"0": { "label": "common:settings.user.active", "class": "active" },
			"1": { "label": "common:settings.user.disabled", "class": "disabled" },
			"2": { "label": "common:settings.user.pending", "class": "pending" },
		};

		/* Prepare TableRows */
		let rows = [];

		/* Update TableRows with data from state */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let user = datalist[i];

			/* */
			let status = statusMap[user.status];

			// User administrator role
			//let adminRole = "common:settings.profile.administrator-role-active";
			let adminRole = <CheckCircleIcon fontSize="small" color='success' />;
			if (user['administrator_role'] === 0) {
				adminRole = "";
			}

			let userstatus = <AccountCircleIcon fontSize="small" color='success' />;
			if (user.status === 0) {
				userstatus = <AccountCircleIcon fontSize="small" color='success' />;
			} else if (user.status === 2) {
				userstatus = <Chip
					size="small"
					color="warning"
					icon={<KeyOffIcon color="warning" />}
					label={t(status.label)}
					variant="outlined"
					style={{
						fontWeight: 500
					}}
				/>;
			} else {
				userstatus = <AccountCircleIcon fontSize="small" color='disabled' />;
			}
			// User super administrator role
			//let superAdminRole = "common:settings.profile.super-administrator-role-active";
			//if (user['super_administrator_role'] === 0) {
			//	superAdminRole = "common:settings.profile.super-administrator-role-inactive";
			//}

			/* */
			rows.push(
				<tr className="item document-row-list clickable" key={i} onClick={() => { this.openHostUpdateDialog(user) }}>
					<td>
            <div>
              <div className="text-ellipsis">
						    <div>{user['user_id']}</div>
              </div>
            </div>
					</td>
					<td>
            <div>
              <div className="text-ellipsis">
                {user['family_name']} {user['first_name']}
              </div>
            </div>
          </td>
					<td>
            <div>
              <div className="text-ellipsis">
                {user['email']}
              </div>
            </div>
          </td>
					{/* <td>
						<div>{t(superAdminRole)}</div>
					</td> */}
					<td>
            {this.getCertificateInfo(user['certificates'], 'personal-jcan')}
          </td>
          <td>
            {this.getCertificateInfo(user['certificates'], 'custom')}
          </td>
          <td className='no-overflow'>
            <div>{adminRole}</div>
          </td>
          <td className='no-overflow'>
            <div>
              <span>
                {userstatus}
              </span>
            </div>
          </td>
          <td className="">
					{
						user.status === 2 && this.state.user_info.administrator_role === "1" &&
													<IconButton aria-label="delete"
								onClick={(ev) => {
									ev.stopPropagation()
									console.log("delete");
									this.DeleteInactiveUserConfirmation(user['user_id'], i);
								}}
							>
								<DeleteIcon />
							</IconButton>
					}
          </td>
					{/* <td></td> */}
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.user.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<table id="datalist" className="user-settings-list">
				<thead>
					<tr>
          <th className="user-user-id">
							<div>{t('common:settings.user.id')}</div>
						</th>
						<th className="user-user-name">
							<div>{t('common:settings.user.name')}</div>
						</th>
						<th className="user-user-email">
							<div>{t('common:settings.user.email')}</div>
						</th>
						<th className="user-personal-jcan">
							<div>{t('common:certificate.title.personal-jcan')}</div>
						</th>
						<th className="user-custom-certificate">
							<div>{t('common:certificate.title.custom-certificate')}</div>
						</th>
						{/* <th>
							<div>{t('common:settings.user.super-administrator-role')}</div>
						</th> */}
						<th className="user-administrator-role">
							<div>{t('common:settings.user.administrator-role')}</div>
						</th>
						<th className="user-user-status">
							<div>{t('common:settings.user.status')}</div>
						</th>
						<th className="user-user-trash"></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		);

	}


	/** [Element] */
	OrganizationSelector = (host = null) => {

		/* */
		if (host === null) {

			/* */
			host = {
				"groups": [],
			};

		} else {

			/* */
			if (typeof host.groups === "string") {
				host.groups = JSON.parse(host.groups);
			}

		}

		/* */
		let { t } = this.props;

		/* */
		let items = [];

		/* */
		let list = this.state.organizations;

		/* */
		let selected = [];

		/* */
		for (let i = 0; i < host.groups.length; i++) {

			/* */
			let id = parseInt(host.groups[i]['id']);

			/* */
			selected.push(id);

		}

		/* */
		for (let i = 0; i < list.length; i++) {

			/* */
			let item = list[i];
			let checked = {};

			if (item.user_type !== 2) {
				continue;
			}

			if (selected.includes(item['user_id'])) {
				checked.defaultChecked = "checked";
			}

			/* */
			items.push(
				<div className="org-selector-item" key={i}>
					<label className="checkboxBtn">
						<input type="checkbox" name="organizations[]" value={item.user_id} {...checked} />
						<div>{item['name']}</div>
					</label>
				</div>
			);

		}

		/* */
		return (
			<div className="org-selector">
				<div className="org-selector-label">
					<div>{t('common:settings.user.organization')}</div>
				</div>
				<div className="org-selector-itemlist">
					{items}
				</div>
			</div>
		);

	}

	/** [Element] */
	RoleSetting = (host = null) => {

		/* */
		if (host === null) {

			let { UserInfo } = this.state;
			/* */
			host = {
				"partner_id": parseInt(UserInfo.partner_id),
				"signer_role": 0,
				"sender_role": 0,
				"circle_role": 0,
				"verification_role": 0,
				"evaluation_role": 0,
				"filebox_role": 0,
				"administrator_role": 0,
				"certificate_role": 0,
				//"super_administrator_role": 0,
			};
		}

		/* */
		let { t } = this.props;

		/* */
		let items = [];

		if (host.partner_id !== 1) {
			/* */
			items.push(this.GetRoleItem(host.signer_role, "signer", "signer_role", "document.function-type.signer"));
			items.push(this.GetRoleItem(host.sender_role, "sender", "sender_role", "document.function-type.sender"));
			items.push(this.GetRoleItem(host.circle_role, "circle", "circle_role", "document.function-type.circle"));
		}

		/* */
		items.push(this.GetRoleItem(host.verification_role, "verification", "verification_role", "document.function-type.verification"));
		items.push(this.GetRoleItem(host.evaluation_role, "evaluation", "evaluation_role", "document.function-type.evaluation"));
		items.push(this.GetRoleItem(host.filebox_role, "filebox", "filebox_role", "document.function-type.filebox"));
		// items.push(this.GetRoleItem(host.super_administrator_role, "super_administrator", "super_admin_role", "settings.user.super-administrator-role"));
		items.push(this.GetRoleItem(host.administrator_role, "administrator", "admin_role", "settings.user.administrator-role"));
		items.push(this.GetRoleItem(host.certificate_role, "certificate", "certificate_role", "settings.user.certificate-role"));

		/* */
		return (
			<div className="org-selector">
				<div className="org-selector-label">
					<div>{t('common:settings.user.role-setting')}</div>
				</div>
				<div className="org-selector-itemlist">
					{items}
				</div>
			</div>
		);
	}

	GetRoleItem = (role, key_name, role_name, item_name) => {
		/* */
		let { t } = this.props;
		/* */
		let checked = {}
		if (role === 1) {
			checked.defaultChecked = "checked";
		}

		return (
			<div className="org-selector-item" key={key_name}>
				<label className="checkboxBtn">
					<input type="checkbox" name={role_name} {...checked} />
					<div>{t(`common:${item_name}`)}</div>
				</label>
			</div>
		);
	}

	/** [Action] */
	openHostCreateDialog = () => {

		/* waiting for organizations */
		if (this.state.organizations == null) {
			return false;
		}

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.user.add_user');

		/* Reset DialogFooter */
		ModalAction = [];

		/* Update DialogBody */
		ModalBody = () => {
			return this.createHostCreateForm();
		};

		/* Update DialogBody */
		ModalAction.push((
			<Button sx={{ ml: 1 }} key="ok" variant="contained" onClick={this.submitHostCreateForm}>
				<span>{t("common:general.ok")}</span>
			</Button>
		));

		/* */
		this.updateState({ modalClose: true, modalTitle, ModalBody, ModalAction, modalProps });

	}

	/** [Element] */
	createHostCreateForm = () => {

		/* Translate function */
		let { t } = this.props;

		/* */
		let { RoleSetting } = this;

		/* */
		return (
			<form id="settings-user-form" className="">

				{/* TextBox section */}
				<div className="lz-m-10">

					{/* FirstName & FamilyName */}
					<div className="row">
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.family_name')}</Form.Label>
								<Form.Control type="text" name="family_name" />
							</Form.Group>

						</div>
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.first_name')}</Form.Label>
								<Form.Control type="text" name="first_name" />
							</Form.Group>

						</div>
					</div>

					{/* FirstName & FamilyName (KANA) */}
					<div className="row">
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.family_name_kana')}</Form.Label>
								<Form.Control type="text" name="family_name_kana" />
							</Form.Group>

						</div>
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.first_name_kana')}</Form.Label>
								<Form.Control type="text" name="first_name_kana" />
							</Form.Group>

						</div>
					</div>

					{/* E-Mail */}
					<div className="row">
						<div className="col-xl-12">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.email')}</Form.Label>
								<Form.Control type="email" name="email" />
							</Form.Group>

						</div>
					</div>

					{/* Mobile Number */}
					<div className="row">
						<div className="col-xl-12">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.mobile')}</Form.Label>
								<Form.Control type="text" name="mobile" />
							</Form.Group>

						</div>
					</div>

					{/* Organization */}
					<div className="row">
						<div className="col-xl-12">
							{this.OrganizationSelector()}
						</div>
					</div>

					{/* Role setting */}
					<div className="row">
						<div className="col-xl-12">
							{RoleSetting()}
						</div>
					</div>

					<div className="row">
						<div className="col-xl-6">

							{/* Active (status) */}
							<Form.Group className="lz-mt-10" controlId="status">
								<Form.Check type="checkbox" label={t('common:settings.user.active')} defaultChecked="checked" />
							</Form.Group>

						</div>
					</div>

				</div>

			</form>
		);

	}

	/** [Action] */
	submitHostCreateForm = () => {

		/* Translate function */
		let { t } = this.props;

		/* */
		let { datalist, ModalBody, modalProps, ModalAction, UserInfo } = this.state;

		/* */
		let form = document.querySelector("form#settings-user-form");
		let fields = form.elements;

		/* Preprae RequestBody */
		let body = {
			"family_name": fields["family_name"]["value"],
			"first_name": fields["first_name"]["value"],
			"family_name_kana": fields["family_name_kana"]["value"],
			"first_name_kana": fields["first_name_kana"]["value"],
			"email": fields["email"]["value"],
			"mobile": fields["mobile"]["value"],
			"status": "1",
			"organizations": [],
			"admin_role": "0",
		};

		/* */
		if (fields["status"]["checked"]) {
			body.status = '0';
		}

		/* */
		if (UserInfo.partner_id !== "1") {
			/* */
			body.signer_role = this.setRole(fields["signer_role"]["checked"]);
			body.sender_role = this.setRole(fields["sender_role"]["checked"]);
			body.circle_role = this.setRole(fields["circle_role"]["checked"]);
		}

		/* */
		body.verification_role = this.setRole(fields["verification_role"]["checked"]);
		body.evaluation_role = this.setRole(fields["evaluation_role"]["checked"]);
		body.filebox_role = this.setRole(fields["filebox_role"]["checked"]);
		// body.super_admin_role = this.setRole(fields["super_admin_role"]["checked"]);
		body.admin_role = this.setRole(fields["admin_role"]["checked"]);
		body.certificate_role = this.setRole(fields["certificate_role"]["checked"]);

		/* */
		if (fields["organizations[]"] instanceof RadioNodeList) {
			for (let i = 0; i < fields["organizations[]"].length; i++) {

				/* */
				let cb = fields["organizations[]"][i];

				/* */
				if (cb.checked === true) {
					body.organizations.push(cb.value);
				}

			}
		} else {
			/* */
			if (fields["organizations[]"] !== undefined && fields["organizations[]"].checked === true) {
				body.organizations.push(fields["organizations[]"].value);
			}
		}

		/* */
		ModalBody = Loading;
		ModalAction = [];
		this.setState({ modalClose: false, ModalBody, ModalAction });

		/* */
		Service.setUserHost(body).then(async (response) => {

			/* */
			datalist = null;

			/* */
			modalProps = { "show": false };

			/* */
			this.setState({ datalist, modalProps });
			await this.context.getUserInfo();

		}).catch((error) => {

			let msgErr = error.response.data.message;
			if (error.response.data.hasOwnProperty("error")) {
				msgErr = error.response.data.error;
			}
			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t(`common:${msgErr}`)}</p>
					</div>
				)
			}

			/* Clear DialogFooter */
			ModalAction = [];

			/* */
			this.setState({ modalClose: true, ModalBody, ModalAction });

		});

		/* */
		this.setState({ modalClose: false, ModalBody });

	}




	/** [Action] */
	openHostUpdateDialog = (host) => {


		/* waiting for organizations */
		if (this.state.organizations == null) {
			return false;
		}

		/* Prepare Modal */
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.user.add_user');

		/* Reset DialogFooter */
		ModalAction = [];

		/* Update DialogBody */
		ModalBody = () => {
			return this.createHostUpdateForm(host);
		};

		/* Update DialogBody */
		ModalAction.push((
			<Button sx={{ ml: 1 }} key="ok" variant="contained" onClick={this.submitHostUpdateForm}>
				<span>{t("common:general.ok")}</span>
			</Button>
		));

		/* */
		this.updateState({ modalClose: true, modalTitle, ModalBody, ModalAction, modalProps });

	}
	//Reset mfa
	submitResetMfa(host) {
		/* Translate function */
		let { t } = this.props;
		// console.log(host);
		/* */
		let { modalTitle, ModalBody, modalProps, ModalAction } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = mappingTranslateDefault(t, "common:settings.user.reset-mfa", '二要素認証を無効化する');

		/* Reset DialogFooter */
		ModalAction = [];

		/* RequestBody */
		// let body = {
		// 	"host_id": host['user_id'],
		// };
		// console.log(host);

		/* Update DialogBody */
		ModalBody = () => {
			return (
				<div className="">
					<Loading />
				</div>
			)
		}

		/* */
		this.updateState({ modalClose: false, modalTitle, ModalBody, ModalAction, modalProps });

		AuthService.adminResetMfa(host['email']).then((response) => {

			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{mappingTranslateDefault(t, 'common:settings.user.reset-mfa-done', '二要素認証を正常に無効にする')}</p>
					</div>
				);
			};

			/* */
			this.updateState({ modalClose: true, ModalBody });

		}).catch((error) => {
			let msgErr = error.response.data || error.response.data.message;
			if (error.response.data.hasOwnProperty("error")) {
				msgErr = error.response.data.error;
			}
			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t(`common:${msgErr}`)}</p>
					</div>
				)
			}

			/* */
			this.updateState({ modalClose: true, ModalBody });

		});
	}

	/** [Element] */
	createHostUpdateForm = (host) => {

		/* Translate function */
		let { t } = this.props;
		let { RoleSetting } = this;

		/* */
		let tab = this.state.tabs[this.state.activeTab];
		let status = tab.status;
		let active = {};
		let resend = {};
		let reset = {};
		// let admin_role = {};

		/* */
		if (status === 0) {
			active.defaultChecked = "checked";
		}

		/* */
		// if (host['administrator_role'] === 1) {
		// 	admin_role.defaultChecked = "checked";
		// }

		/* */
		if (host['status'] === 0 && host['has_login'] === 1) {
			resend.hidden = "hidden";
		} else {
			reset.hidden = "hidden";
		}

		/* */
		return (
			<form id="settings-user-form" className="">

				<Form.Control type="hidden" name="user_id" defaultValue={host['user_id']} readOnly />

				{/* TextBox section */}
				<div className="lz-m-10">

					{/* FirstName & FamilyName */}
					<div className="row">
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.family_name')}</Form.Label>
								<Form.Control type="text" name="family_name" defaultValue={host['family_name']} />
							</Form.Group>

						</div>
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.first_name')}</Form.Label>
								<Form.Control type="text" name="first_name" defaultValue={host['first_name']} />
							</Form.Group>

						</div>
					</div>

					{/* FirstName & FamilyName (KANA) */}
					<div className="row">
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.family_name_kana')}</Form.Label>
								<Form.Control type="text" name="family_name_kana" defaultValue={host['family_name_kana']} />
							</Form.Group>

						</div>
						<div className="col-xl-6">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.first_name_kana')}</Form.Label>
								<Form.Control type="text" name="first_name_kana" defaultValue={host['first_name_kana']} />
							</Form.Group>

						</div>
					</div>

					{/* E-Mail */}
					<div className="row">
						<div className="col-xl-12">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.email')}</Form.Label>
								<Form.Control type="email" name="email" defaultValue={host['email']} readOnly />
							</Form.Group>

						</div>
					</div>

					{/* Mobile Number */}
					<div className="row">
						<div className="col-xl-12">

							<Form.Group className="">
								<Form.Label>{t('common:settings.user.mobile')}</Form.Label>
								<Form.Control type="text" name="mobile" defaultValue={host['mobile_number']} />
							</Form.Group>

						</div>
					</div>

					{/* Organization */}
					<div className="row">
						<div className="col-xl-12">
							{this.OrganizationSelector(host)}
						</div>
					</div>

					{/* Role setting */}
					<div className="row">
						<div className="col-xl-12">
							{RoleSetting(host)}
						</div>
					</div>

					{/* AdminRole&Active & ResendMail (status) */}
					<div className="row">

						<div className="col-xl-6 col-md-6 col-xs-6">

							<Form.Group className="lz-mt-10" controlId="status">
								<Form.Check type="checkbox" label={t('common:settings.user.active')} {...active} />
							</Form.Group>

						</div>

						<div className="col-xl-6 col-md-4 col-xs-6" {...reset}>

							<div className="lz-m-10 lz-txt-r">
								<a href="#reset-mail" onClick={() => { this.sumitHostResetPassword(host) }}>
									<span>{t("common:settings.user.reset-password")}</span>
								</a>
							</div>
							{(this.state.user_info.administrator_role === '1' || this.state.user_info.super_administrator_role === '1') ?
								<div className="lz-m-10 lz-txt-r">
									<a href="#reset-mfa" onClick={() => { this.submitResetMfa(host) }}>
										<span>{mappingTranslateDefault(t, "common:settings.user.reset-mfa", '二要素認証を無効化する')}</span>
									</a>
								</div> : null}

						</div>

						<div className="col-xl-6 col-md-4 col-xs-6" {...resend}>

							<div className="lz-m-10 lz-txt-r">
								<a href="#resend-mail" onClick={() => { this.sumitHostResendMail(host) }}>
									<span>{t("common:settings.user.resend_mail")}</span>
								</a>
							</div>

						</div>

					</div>

				</div>

			</form>
		);

	}

	/** */
	submitHostUpdateForm = () => {

		/* Translate function */
		let { t } = this.props;

		/* */
		let { datalist, ModalBody, modalProps, ModalAction, UserInfo } = this.state;

		/* */
		let form = document.querySelector("#settings-user-form");
		let fields = form.elements;
		let email = fields['email']['value'];

		/* Preprae RequestBody */
		let body = {
			"user_id": fields['user_id']['value'],
			"status": "1",
			"organizations": [],
			"signer_role": "0",
			"sender_role": "0",
			"circle_role": "0",
			"verification_role": "0",
			"evaluation_role": "0",
			"filebox_role": "0",
			"admin_role": "0",
			// "super_admin_role": "0",
			"certificate_role": "0",
			"family_name": fields['family_name']['value'],
			"first_name": fields['first_name']['value'],
			"family_name_kana": fields['family_name_kana']['value'],
			"first_name_kana": fields['first_name_kana']['value'],
			"mobile": fields['mobile']['value'],
		};

		/* */
		if (fields["status"]["checked"]) {
			body.status = '0';
		}

		/* */
		if (UserInfo.partner_id !== "1") {
			/* */
			body.signer_role = this.setRole(fields["signer_role"]["checked"]);
			body.sender_role = this.setRole(fields["sender_role"]["checked"]);
			body.circle_role = this.setRole(fields["circle_role"]["checked"]);
		}

		/* */
		body.verification_role = this.setRole(fields["verification_role"]["checked"]);
		body.evaluation_role = this.setRole(fields["evaluation_role"]["checked"]);
		body.filebox_role = this.setRole(fields["filebox_role"]["checked"]);
		// body.admin_role = this.setRole(fields["super_admin_role"]["checked"]);
		body.admin_role = this.setRole(fields["admin_role"]["checked"]);
		body.certificate_role = this.setRole(fields["certificate_role"]["checked"]);

		/* */
		if (fields["organizations[]"] instanceof RadioNodeList) {
			for (let i = 0; i < fields["organizations[]"].length; i++) {

				/* */
				let cb = fields["organizations[]"][i];

				/* */
				if (cb.checked === true) {
					body.organizations.push(cb.value);
				}

			}
		} else {
			/* */
			if (fields["organizations[]"] !== undefined && fields["organizations[]"].checked === true) {
				body.organizations.push(fields["organizations[]"].value);
			}
		}


		/* */
		ModalBody = Loading;
		ModalAction = [];
		this.setState({ modalClose: false, ModalBody, ModalAction });

		/* */
		Service.updateUserHost(body).then(async (response) => {

			// console.log("update", response);
			Service.deleteUserControl(email).then(response => {
				/* */
				datalist = null;

				/* */
				modalProps = { "show": false };

				/* */
				this.setState({ datalist, modalProps });
			}).catch((error) => {
				//console.log("CATCH", error);
			});

			await this.context.getUserInfo();
		}).catch((error) => {

			let msgErr = error.response.data.message;

			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t(`common:${msgErr}`)}</p>
					</div>
				)
			}

			/* Clear DialogFooter */
			ModalAction = [];

			/* */
			this.setState({ modalClose: true, ModalBody, ModalAction });

		});

		/* */
		this.updateState({ modalClose: false, ModalBody });

	}

	setRole(checked) {
		/* */
		if (checked) {
			return '1';
		}
		return '0';
	}

	/** */
	sumitHostResetPassword(host) {

		/* Translate function */
		let { t } = this.props;

		/* */
		let { modalTitle, ModalBody, modalProps, ModalAction } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.user.reset-password');

		/* Reset DialogFooter */
		ModalAction = [];

		/* RequestBody */
		// let body = {
		// 	"host_id": host['user_id'],
		// };
		// console.log(host);

		/* Update DialogBody */
		ModalBody = () => {
			return (
				<div className="">
					<Loading />
				</div>
			)
		}

		/* */
		this.updateState({ modalClose: false, modalTitle, ModalBody, ModalAction, modalProps });

		/* */
		AuthService.AccountRecoveryRequest(host['email']).then((response) => {

			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t('common:settings.user.reset-email-sent')}</p>
					</div>
				);
			};

			/* */
			this.updateState({ modalClose: true, ModalBody });

		}).catch((error) => {

			let msgErr = error.response.data.message;
			if (error.response.data.hasOwnProperty("error")) {
				msgErr = error.response.data.error;
			}
			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t(`common:${msgErr}`)}</p>
					</div>
				)
			}

			/* */
			this.updateState({ modalClose: true, ModalBody });

		});
	}

	/** */
	sumitHostResendMail(host) {

		/* Translate function */
		let { t } = this.props;

		/* */
		let { modalTitle, ModalBody, modalProps, ModalAction } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"dialogClassName": "",
			"centered": true,
		}

		/* */
		modalTitle = t('common:settings.user.resend_mail');

		/* Reset DialogFooter */
		ModalAction = [];

		/* RequestBody */
		let body = {
			"host_id": host['user_id'],
		};

		/* Update DialogBody */
		ModalBody = () => {
			return (
				<div className="">
					<Loading />
				</div>
			)
		}

		/* */
		this.updateState({ modalClose: false, modalTitle, ModalBody, ModalAction, modalProps });

		/* */
		Service.UserHostResendMail(body).then((response) => {

			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t('common:settings.user.mail_sent')}</p>
					</div>
				);
			};

			/* */
			this.updateState({ modalClose: true, ModalBody });

		}).catch((error) => {

			let msgErr = error.response.data.message;
			if (error.response.data.hasOwnProperty("error")) {
				msgErr = error.response.data.error;
			}
			/* Update DialogBody */
			ModalBody = () => {
				return (
					<div className="">
						<p>{t(`common:${msgErr}`)}</p>
					</div>
				)
			}

			/* */
			this.updateState({ modalClose: true, ModalBody });

		});
	}

	HostListCsvFileDownload = async () => {

		/* */
		let { ModalBody, ModalAction, modalTitle, modalProps } = this.state;

		/* Show Modal */
		modalProps = {
			"show": true,
			"centered": true,
		}

		/* */
		modalTitle = "common:settings.general.csv-download";

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		ModalAction = [];

		/* */
		this.updateState({ modalClose: false, ModalBody, ModalAction, modalTitle, modalProps });
		let tab = this.state.activeTab;
		// let { UserInfo } = this.state;

		/* */
		let data = {
			"tenant_id": null,
			"status": this.state.tabs[tab]['status'],
			"search": this.state.search,
			//"super_administrator_role": UserInfo.super_administrator_role,
		}
		/* */
		Service.DownloadCsvHostListUser(data).then((response) => {

			/* */
			modalProps = { "show": false };
			/* */
			this.setState({ modalProps });

			let aLink = document.createElement("a");
			aLink.href = response.url;
			aLink.click();

		}).catch((error) => {
			/* */
			console.log("error", error);
		});
	}

	DeleteInactiveUserConfirmation = (user_id, index) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"centered": true,
		}

		/* */
		modal.title = t("common:settings.user.delete.comfirm.header");

		/* */
		modal.body = t("common:settings.user.delete.comfirm.message");

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [(
			<Button key="ok" variant="contained" onClick={(ev) => {
				let { modal } = this.state;
				modal.body = Loading;
				modal.action = [];
				this.setState({ modal }, () => {
					this.InactiveUserDelete(user_id, index);
				});
			}}>
				<span>{t("common:general.yes-delete")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });

	}

	async InactiveUserDelete(user_id, index) {
		/* Translate Function */
		let { t } = this.props;
		let { modal } = this.state;

		/* */
		Service.DeleteInactiveUser({
			"user_id": user_id,
		}).then(resp => {
			// console.log(resp)
			modal.props.show = false;
			let { datalist } = this.state;
			datalist.splice(index, 1);
			this.setState({ modal, datalist });
		}).catch(err => {
			console.log("error", err.response);

			let errMessage = err.response.data.message;
			if (err.response.data.hasOwnProperty("error")) {
				errMessage = err.response.data.error;
			}

			modal.body = t(`common:${errMessage}`);
			modal.action = [(
				<Button key="ok" variant="primary" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = Loading;
					modal.action = [];
					this.setState({ modal }, () => {
						this.InactiveUserDelete(user_id);
					});
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)];

			this.setState({ modal });
		});
	}

	/* */
	HostUserCsvFileUpload = async () => {

		/* Translate function */
		let { t } = this.props;
		/* */
		let {
			modal,
			// datalist
		} = this.state;
		/* */
		let form = document.querySelector("#csv-upload");

		/* File required */
		if (form['files']['length'] === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		modal.action = [];
		modal.close = false;

		/* */
		this.setState({ modal });
		/* */
		let reader = new FileReader();

		new Promise((resolve, reject) => {
			/* */
			reader.onload = (event) => {
				resolve((event.target.result).split(',')[1]);
			};

			/* */
			reader.readAsDataURL(form.files[0]);

		}).then((result) => {

			/* */
			let upload_data = { "upload_file": result };
			/* */
			Service.UploadCsvHostUser(upload_data).then((response) => {

				if ('error' in response) {
					/* */
					this.CsvFileUpload(response.error);
				} else {
					let mailSendUsers = response.save_users;
					/* Update DialogBody */
					modal.body = () => {
						return (
							<div className="">
								<p>{t('common:general.sending-mail')}</p>
								<Loading />
							</div>
						);
					};

					/* */
					this.setState({ modal });

					let body = {
						"users_info": mailSendUsers,
					};

					/* */
					Service.UserHostSendMail(body).then((response) => {
						let notSendUsers = response.data;
						let SendMessage = () => {

							if (notSendUsers.length === 0) {
								return (
									<div className="">
										<p>{t('common:general.send-mail-complete')}</p>
									</div>
								);
							} else {
								let messageArr = [];

								messageArr.push(
									<div key='send-error-message'>
										<span>
											<p>{t('common:settings.user.csv-upload.can-not-send-mail')}</p>
										</span>
									</div>
								);

								for (let val of notSendUsers) {
									messageArr.push(
										<div key={val}>
											<span>
												{val}
											</span>
										</div>
									);
								}
								return messageArr;
							}
						}

						/* Update DialogBody */
						modal.body = () => {
							return (
								<SendMessage />
							);
						};

						modal.close = true;

						/* */
						this.setState({ modal });

					}).catch((error) => {
						/* */
						//console.log("error", error);
						modal.close = true;
						/* */
						this.setState({ modal });
					});
				}

			}).catch((error) => {
				/* */
				//console.log("error", error);
				modal.close = true;
				/* */
				this.setState({ modal });
			});
		});
	}

}

export default withTranslation()(User);
