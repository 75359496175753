import '../../Assets/css/settings.css';
import React from 'react';
import Layout from '../../../Layout';
import Menu from '../Menu';
import Loading from '../../Loading';
import LoadingList from '../../LoadingList';
import Service from '../../../Service';
import { withTranslation } from "react-i18next";
import { Form, Col, Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';

/** ApplicationCertificate */
class AppCert extends Layout {

	// static get name() {
	// 	return "UserForm";
	// }

	/** Constructor */
	constructor(props) {

		/* Props */
		super(props);
		this.state.page = "application-certificate";

		/* */
		this.state.create = {
			"users": [],
		};
		this.state.pagination = {
			"page": 1,
			"take": 10,
			"total": 0,
		};
		this.state.datalist = null;
		this.state.userlist = null;
		this.state.certlist = null;
		this.state.identitylist = null;
		this.state.applicationList = null;
		this.state.applicationDate = null;
		this.state.search = "";
		this.state.keyFields = {
			user_id: "user-id",
			status_name: "status-name",
			status: "status",
			application_date: "date",
			application_certificate_type: "certificate",
			tax_id: "tax-id",
			company_name: "company-name",
			company_name_romaji: "company-name-roma",
			name: "full-name",
			name_kana: "full-name-kana",
			name_romaji: "full-name-roma",
			branch_and_department: "branch-department",
			email: "email",
			credentials: "qualification",
			identity_verification_documents: "personal-document-type",
			file_name: "file_name",
			upload: "upload_path",
			company_id: "company_id",
			tenant_id: "tenant_id",
		};
	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {
		//
	}

	componentDidUpdate() {

		/* */
		if (this.state.datalist === null) {

			/* */
			let page = this.state.pagination.page;
			let take = this.state.pagination.take;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"search": this.state.search,
				"skip": skip,
				"take": take,
			}

			/* */
			Service.getAppCertList(data).then((response) => {

				/* */
				let { datalist, pagination } = this.state;

				/* */
				datalist = response.records;

				/* */
				pagination.total = response.total;

				/* */
				this.setState({ datalist, pagination });

			}).catch((error) => {
				//console.log(error.response);
			});

			/* */
			Service.getAppCertUserList(data).then((response) => {

				/* */
				let { userlist, certlist, identitylist } = this.state;

				/* */
				userlist = response.records;
				certlist = response.cert_type;
				identitylist = response.identity_doc_type;

				/* */
				this.setState({ userlist, certlist, identitylist });

			}).catch((error) => {
				//console.log(error.response);
			});
		}

	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData, applicationList } = this.state;
		// this.state.fields = {};
		for (let i = 0; i < applicationList.length; i++) {
			let user = applicationList[i];

			for (let key in keyFields) {
				let label = keyFields[key];
				fields[key + "-" + i] = {
					name: key + "-" + i,
					value: user[key],
					label: t(`common:settings.appcert.application-${label}`),
				};
				// if (formData[key + "-" + i]) {
				// 	fields[key + "-" + i].value = formData[key + "-" + i];
				// }
				if (key === "status_name") {
					formData[key + "-" + i] = t(`common:${user[key]}`);
				} else {
					formData[key + "-" + i] = user[key];
				}
			}
			fields["delete-confirm-" + i] = false;
		}

		this.setState({ fields }, cb);
	}

	CertTypeItems = () => {
		// let { t } = this.props;
		let { certlist } = this.state;
		let ItemElements = [];

		for (let i in certlist) {
			let cert_type = certlist[i];
			ItemElements.push(<option key={cert_type.id} value={cert_type.id}>{cert_type.name}</option>);
		}

		return ItemElements;
	}

	IdentityTypeItems = () => {
		let { t } = this.props;
		let { identitylist } = this.state;
		let ItemElements = [];

		for (let i in identitylist) {
			let identity_type = identitylist[i];
			ItemElements.push(<option key={identity_type.id} value={identity_type.id}>{t("common:" + identity_type.label)}</option>);
		}

		return ItemElements;
	}

	/** [Render] */
	render() {

		/* Search Function */
		let { t } = this.props;

		/* */
		const { Main } = this;

		/* */
		return (
			<Main>

				<div id="page-settings-app-cert" className="page-settings">

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								<div>

									{/* Add-Host Button */}
									
										<Button
											sx={{ borderRadius: 19 }}
											key="create"
											title="Action"
											disabled = { !this.state.datalist }
											variant="contained"
											startIcon={<AddIcon />}
											onClick={() => { this.openCreateFormDialog() }}
										>
											<span>{t(`common:settings.appcert.add`)}</span>
										</Button>
									

									{/* Resend Mail Button */}
									
										<Button
											sx={{ml:3}}
											
											key="list-download"
											variant="outlined"
											size='small'
											disabled = { !this.state.datalist }
											endIcon={<SendIcon />}
											onClick={() => { this.openResendEmailDialog() }}
										>
											{t(`common:settings.appcert.resend-email`)}
										</Button>
										
									

									{/* Search-TextBox */}
									{/* <div className="input-textbox">
										<input type="search" />
									</div> */}

								</div>

							</div>

						</div>

						<div className="ki-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="body-wrapper">
						<this.IndexTable />
					</div>

				</div>

			</Main>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);

		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] = true;
		}
		
		/* */
		let start_list = 0;
		let last_list = 0;
		if(pagination.total > 0){
			start_list = 1 + ( ( pagination.page - 1 ) * pagination.take );
			if(pagination.page === max){
				last_list = pagination.total
			}else{
				last_list = start_list + (pagination.take -1);
			}
		}
		
		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	/** [Element] */
	IndexTable = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;

		/* */
		if (datalist === null) {
			return <LoadingList />;
		}

		/* Prepare TableRows */
		let rows = [];

		/* Update TableRows with data from state */
		for (let data of datalist) {

			let btnDetailClass = ["sign-status"];
			// console.log(doc);
			if (data.difference_count === 0) {
				btnDetailClass.push("signed");
			} else {
				btnDetailClass.push("wait-sign");
			}

			/* */
			rows.push(
				<tr key={data.application_date} className="document-row-list">
					
					<td className="document-created-date clickable" onClick={(ev) => { this.openDetailFormDialog(ev, data);}}>
						<div className="inner-container">
							
							<span>{data.application_date}</span>
						</div>
					</td>
					<td className="creator-info clickable" onClick={(ev) => { this.openDetailFormDialog(ev, data);}}>
						<div className="inner-container">
							
							<span>{data.all_user_count}</span>
						</div>
					</td>
					<td className="creator-info clickable" onClick={(ev) => { this.openDetailFormDialog(ev, data);}}>
						<div className="inner-container">
							
							<span>{data.difference_count}</span>
						</div>
					</td>
					<td className="stamp-delete">
						<IconButton aria-label="delete"
							// onClick={(ev) => {
							// 	let { fields } = this.state;
							// 	fields["delete-confirm-" + i] = true;
							// 	this.setState({ fields });
							// }}
						>
							<DeleteIcon/>
						</IconButton>
					</td>
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.appcert.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<table id="datalist">
				<thead>
					<tr>
						<th>
							<div>{t("common:appcert.application-date")}</div>
						</th>
						<th>
							<div>{t("common:appcert.application-number-of-person")}</div>
						</th>
						<th>
							<div>{t("common:appcert.not-upload-number-of-person")}</div>
						</th>
						<th>
						</th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		);

	}

	setValidity = (form, item_name, fieldValidate, message) => {
		form[item_name].setCustomValidity(message);
		fieldValidate[item_name] = message;
	}

	additionalValidity = (form) => {
		// console.dir(form);
		let { fieldValidate } = this.state;

		for (let i = 0; i < form.all_user_count.value; i++) {
			// Required check
			if (form['application_date-' + i]['value'] === '') {
				this.setValidity(form, 'application_date-' + i, fieldValidate, 'message.input.required');
			}

			if (form['company_name_romaji-' + i]['value'] === '') {
				this.setValidity(form, 'company_name_romaji-' + i, fieldValidate, "message.input.required");
			}

			if (form['name-' + i]['value'] === '') {
				this.setValidity(form, 'name-' + i, fieldValidate, "message.input.required");
			}

			if (form['name_kana-' + i]['value'] === '') {
				this.setValidity(form, 'name_kana-' + i, fieldValidate, "message.input.required");
			}

			if (form['name_romaji-' + i]['value'] === '') {
				this.setValidity(form, 'name_romaji-' + i, fieldValidate, "message.input.required");
			}

			if (form['branch_and_department-' + i]['value'] === '') {
				this.setValidity(form, 'branch_and_department-' + i, fieldValidate, "message.input.required");
			}

			if (form['company_name_romaji-' + i]['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
				this.setValidity(form, 'company_name_romaji-' + i, fieldValidate, "message.errors.input.validate.wrong-policy");
			}

			if (form['name_romaji-' + i]['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
				this.setValidity(form, 'name_romaji-' + i, fieldValidate, "message.errors.input.validate.wrong-policy");
			}

			if (form['credentials-' + i]['value'] !== '' && form['credentials-' + i]['value'].match(/^[a-zA-Z0-9-+.,_()/:@\x20]*$/g) === null) {
				this.setValidity(form, 'credentials-' + i, fieldValidate, "message.errors.input.validate.wrong-policy");
			}
		}

		this.setState({ fieldValidate });

		if (Object.keys(fieldValidate).length > 0) {
			return false;
		} else {
			return true;
		}
	}

	formSubmitCallback = (ev) => {
		const form = ev.target;

		if (this.additionalValidity(form) === false) {
			ev.stopPropagation();
		} else {
			if (form.action_name.value === "create") {
				this.AppCertSave(ev);
			} else {
				this.AppCertUpdate(ev);
			}
		}
	}

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;
		for (let i = 0; i < form.all_user_count.value; i++) {
			form['application_date-' + i].setCustomValidity("");
			form['company_name_romaji-' + i].setCustomValidity("");
			form['name-' + i].setCustomValidity("");
			form['name_kana-' + i].setCustomValidity("");
			form['name_romaji-' + i].setCustomValidity("");
			form['branch_and_department-' + i].setCustomValidity("");
			form['credentials-' + i].setCustomValidity("");
		}

		this.formSubmitHandler(ev, null, this.formSubmitCallback)
	}

	/** [Action] */
	openDetailFormDialog = (ev, data) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "app-cert-dialog",
		}

		/* */
		modal.title = "common:settings.appcert.application-select-user-title";

		/* */
		modal.body = Loading;

		modal.form = {
			id: "appcert-form",
			onSubmit: this.formPrepareBeforeSubmit,
			noValidate: true,
		};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" type="submit"  sx={{ml:1}} variant="contained" >
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

		this.getDetailData(data);

	}

	getDetailData = (data) => {

		/* */
		Service.getAppCertDetail(data).then((response) => {

			/* */
			let { applicationList, applicationDate } = this.state;

			/* */
			applicationList = response.records;
			applicationDate = data.application_date;

			/* */
			this.setState({ applicationList, applicationDate });

			this.detailFormDialog_DataForm()
			this.setStateFields();
		}).catch((error) => {
			//console.log(error.response);
		});

	}

	/** [Action] */
	detailFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		// let { t } = this.props;

		/* */
		modal.body = this.DetailFormDialog_UploadTable;

		/* */
		this.setState({ modal });

	}

	/** [Element] */
	DetailFormDialog_UploadTable = () => {

		/* Translate function */
		let { t } = this.props;
		let { FieldControl } = this;

		let certTypeItems = this.CertTypeItems();
		let identityTypeItems = this.IdentityTypeItems();
		/* */
		let { applicationList, formData, fields } = this.state;
		/* */
		let users = applicationList;
		let rows = [];

		/* */
		for (let i = 0; i < users.length; i++) {

			/* */
			// let user = users[i];

			let DeleteAction = () => {
				if (formData[`status-` + i] === 0) {
					return [(
						 <IconButton
						 	key={"btn-delete" + i}
							onClick={(ev) => {
									let { fields } = this.state;
									fields["delete-confirm-" + i] = true;
									this.setState({ fields });
									this.DeleteAppCertConfirm(formData, i, ev);
							}} 
						 >
						 	<DeleteIcon />			
						</IconButton>
					), (
						<Modal key={"delete-confirm-modal" + i} show={fields["delete-confirm-" + i]}>
							<Modal.Header closeButton>
								<Modal.Title>{t("common:settings.appcert.delete-title")}</Modal.Title>
							</Modal.Header>
							<Modal.Body>{t("common:message.settings.appcert.delete.confirm")}</Modal.Body>
							<Modal.Footer>
								<Button variant="outlined" onClick={(ev) => {
									fields["delete-confirm-" + i] = false;
									this.setState({ fields });
								}}>
									{t("common:auth.general.cancel")}
								</Button>
								<Button sx={{ml:1}} variant="contained" onClick={(ev) => {
									this.DeleteAppCert(formData, i, users.length, ev);
								}}>
									{t("common:general.yes-delete")}
								</Button>
							</Modal.Footer>
						</Modal>
					)];
				} else {
					return null;
				}
			}

			/* */
			rows.push(
				<fieldset id={`appcert-item-${i}`} className="appcert-item-row" key={i}>
					<div className="appcert-item">
						<Form.Row>
							<FieldControl name={`user_id-${i}`} xs={12} md={3} readOnly />
							<FieldControl name={`status_name-${i}`} xs={12} md={3} readOnly />
							<FieldControl type="date" name={`application_date-${i}`} xs={12} md={3} validate="true" />
							<FieldControl as="select" name={`application_certificate_type-${i}`} xs={12} md={3}>
								{certTypeItems}
							</FieldControl>
						</Form.Row>
						<Form.Row>
							<FieldControl name={`tax_id-${i}`} xs={12} md={4} readOnly />
							<FieldControl name={`company_name-${i}`} xs={12} md={4} readOnly />
							<FieldControl name={`company_name_romaji-${i}`} xs={12} md={4} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`name-${i}`} xs={12} md={4} validate="true" />
							<FieldControl name={`name_kana-${i}`} xs={12} md={4} validate="true" />
							<FieldControl name={`name_romaji-${i}`} xs={12} md={4} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`branch_and_department-${i}`} xs={12} md={6} validate="true" />
							<FieldControl name={`email-${i}`} xs={12} md={6} readOnly />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`credentials-${i}`} xs={12} md={12} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl as="select" name={`identity_verification_documents-${i}`} xs={12} md={6}>
								{identityTypeItems}
							</FieldControl>
						</Form.Row>
						{/* <input type="hidden" name={`upload-${i}`} defaultValue={formData[`upload-` + i]} />
						<input type="hidden" name={`upload-file-name-${i}`} defaultValue={formData[`file_name-` + i]} /> */}
						<input type="hidden" name={`status-${i}`} defaultValue={formData[`status-` + i]} />
						<input type="hidden" name={`company_id-${i}`} defaultValue={formData[`company_id-` + i]} />
						<input type="hidden" name={`tenant_id-${i}`} defaultValue={formData[`tenant_id-` + i]} />
						<Form.Group as={Col} md={12} controlId={`file_name-${i}`} >
							<Form.Label>{t("common:settings.appcert.application-personal-document-upload")}</Form.Label>
							<Form.File
								id={`file_name-${i}`}
								name={`file_name-${i}`}
								label={formData['file_name-' + i] || t("common:settings.appcert.application-personal-document-upload")}
								custom
								onChange={(ev) => {
									this.uploadFile(formData, i, ev)
								}}
								accept=".jpg,.png,.pdf"
							/>
						</Form.Group>
						<DeleteAction />
					</div>
				</fieldset>
			);

		}

		/* */
		return (
			<div id="settings-app-cert" className="">

				{/* <input type="file" id="upload" accept="image/*" hidden={true} /> */}

				{/*  */}
				<div className="lz-m-10">

					{/*  */}
					<div className="row">
						<div className="col-xl-12">
							<fieldset className="table-wrap">
								<div className="appcert">
									<div id="appcert-form" className="appcert-itemlist">
										<input type="hidden" name="all_user_count" defaultValue={users.length} />
										<input type="hidden" name="action_name" defaultValue="update" />
										{rows}
									</div>
								</div>
							</fieldset>
						</div>
					</div>

				</div>

			</div>
		);

	}

	/** [Action] */
	openCreateFormDialog = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */
		let { create } = this.state;
		create.users = [];
		this.setState({ create });

		/* Show Modal */
		modal.props = {
			"show": true,
			"size": "xl",
			"dialogClassName": "app-cert-dialog",
		}

		/* */
		modal.title = "common:settings.appcert.application-select-user-title";

		/* */
		modal.body = this.UserSelectionBody;

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="ok" sx={{ml:1}} variant="contained" onClick={() => {
				if (this.state.create.users.length === 0) {
					let element = document.getElementById("error-message");
					element.innerText = t("common:general.error-message.select-user.none");
					return;
				}
				this.createFormDialog_DataForm()
			}}>
				<span>{t("common:general.next")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

		// this.userSelectionBody();

	}

	UserSelectionBody = () => {
		// let { modal } = this.state;

		/* */
		// modal.body = () => {
		return (
			<div id="settings-app-cert" className="">

				{/*  */}
				<div className="lz-m-10">

					{/* User-Filter
						<div className="row">
							<div className="col-xl-12">
								
								<Form.Group className="">
									<Form.Control type="search" />
								</Form.Group>

							</div>
						</div> */}

					{/* User-Selection */}
					<div className="row">
						<div className="col-xl-6 col-md-6 col-sm-6">
							<div className="user-list">
								{this.createFormDialog_userSelection()}
							</div>
						</div>
						<div className="col-xl-6 col-md-6 col-sm-6">
							<div className="user-list">
								{this.createFormDialog_userLookup()}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 col-md-6 col-sm-6">
						</div>
						<div className="col-xl-6 col-md-6 col-sm-6 user-selection-error">
							<label id="error-message" value="" />
						</div>
					</div>
				</div>

			</div>
		);
		// }

		// this.updateState({ modal });
		// this.setState({ FileboxStorage });
	}

	/** [Elements] */
	createFormDialog_userLookup = () => {

		/* Translate function */
		let { t } = this.props;
		/* */
		let { userlist } = this.state;

		if (!userlist) {
			return <Loading />
		}

		/* */
		let nodelist = [];
		let ignore = [];

		/* */
		for (let i = 0; i < this.state.create.users.length; i++) {
			ignore.push(this.state.create.users[i]['index_id']);
		}

		/* */
		for (let i = 0; i < userlist.length; i++) {

			/* Skip when user already exists in selection */
			if (ignore.includes(i)) {
				continue;
			}

			let user = userlist[i];

			/* */
			nodelist.push(
				<div className="user-list-item clickable" key={i} onClick={() => this.createFormDialog_addUser(i, user)}>
					<div className="lz-flex">
						<div className="lz-flex-0">
							<span className='iconImg'><AddCircleOutlineIcon color="primary"/></span>
						</div>
						<div className="lz-flex-1">
							<div className="user-title cheader">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.full_name}</span>
							</div>
							<div className="user-title cfooter smmallT">
								{/* <span>{t('common:settings.appcert.application-email')}</span>
								<span>：</span> */}
								<span>{user.email}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<div className="user-title smmallT cheader">
								<span>{t('common:settings.appcert.last-application-date')}</span>
								<span>：</span>
								<span>{user.application_date ? user.application_date : t('common:settings.appcert.last-application-date-none')}</span>
							</div>
							<div className="user-title smmallT cfooter">
								<span>{t('common:settings.appcert.cert-expire-date')}</span>
								<span>：</span>
								<span>{user.expire_date ? user.expire_date : t('common:settings.appcert.last-application-date-none')}</span>
							</div>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Elements] */
	createFormDialog_userSelection = () => {

		/* Translate function */
		let { t } = this.props;
		/* */
		let userlist = this.state.create.users;
		let nodelist = [];

		/* */
		for (let i = 0; i < userlist.length; i++) {

			/* */
			let user = userlist[i];

			/* */
			nodelist.push(
				<div className="user-list-item" key={i}>
					<div className="lz-flex">
						<div className="lz-flex-0">
							<span className='iconImg'><CheckCircleIcon color="success"/></span>
						</div>
						<div className="lz-flex-1">
							<div className="user-title cheader">
								<span>{user.user_type_name}</span>
								<span>：</span>
								<span>{user.name}</span>
							</div>
							<div className="user-title cfooter smmallT">
								<span>{user.email}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<div className="user-title smmallT cheader">
								<span>{t('common:settings.appcert.last-application-date')}</span>
								<span>：</span>
								<span>{user.application_date ? user.application_date : t('common:settings.appcert.last-application-date-none')}</span>
							</div>
							<div className="user-title smmallT cfooter">
								<span>{t('common:settings.appcert.cert-expire-date')}</span>
								<span>：</span>
								<span>{user.expire_date ? user.expire_date : t('common:settings.appcert.last-application-date-none')}</span>
							</div>
						</div>
						<div className="lz-flex-0">
							<IconButton
								size='small'
								onClick={() => { this.createFormDialog_removeUser(i) }} >
								 <HighlightOffIcon fontSize='small'/>
							</IconButton>
						</div>
					</div>
				</div>
			);

		}

		/* */
		return nodelist;

	}

	/** [Action] */
	createFormDialog_addUser = (user_id, user) => {

		/* */
		let { create } = this.state;

		/* */
		create.users.push({
			"index_id": user_id,
			"status": 0,
			"company_id": user.company_id,
			"tenant_id": user.tenant_id,
			"user_id": user.user_id,
			"user_type": user.user_type,
			"user_type_name": user.user_type_name,
			"email": user.email,
			"name": user.full_name,
			"name_kana": user.full_name_kana,
			"current_tenant_id": user.current_tenant_id,
			"tenant_name": user.tenant_name,
			"company_name": user.company_name,
			"tax_id": user.tax_id,
			"application_date": user.application_date,
			"expire_date": user.expire_date,
		});

		this.setState({ create });
		this.setCreateStateFields();
	}

	setCreateStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		/* */
		let users = this.state.create.users;
		/* Make today */
		let dateObj = new Date();
		let year = dateObj.getFullYear();
		let month = ("00" + (dateObj.getMonth() + 1)).slice(-2);
		let day = ("00" + dateObj.getDate()).slice(-2);;
		let today = year + "-" + month + "-" + day;

		// this.state.fields = {};
		for (let i = 0; i < users.length; i++) {
			let user = users[i];

			for (let key in keyFields) {
				let label = keyFields[key];
				fields[key + "-" + i] = {
					name: key + "-" + i,
					value: user[key],
					label: t(`common:settings.appcert.application-${label}`),
				};
				// if (formData[key + "-" + i]) {
				// 	fields[key + "-" + i].value = formData[key + "-" + i];
				// }
				if (key === "application_date") {
					formData[key + "-" + i] = today;
				} else {
					formData[key + "-" + i] = user[key];
				}
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Action] */
	createFormDialog_removeUser = (index) => {

		/* */
		let { create } = this.state;

		/* */
		create.users.splice(index, 1);

		/* */
		this.setState({ create });

	}

	/** [Action] */
	createFormDialog_DataForm = () => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		modal.form = {
			id: "appcert-form",
			onSubmit: this.formPrepareBeforeSubmit,
			noValidate: true,
		};
		/* */
		modal.body = this.CreateFormDialog_UploadTable;

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.action.push(
			<Button key="return" variant="primary" onClick={this.openCreateFormDialog_ReturnUserForm}>
				<span>{t("common:auth.general.back")}</span>
			</Button>
		);

		/* */
		modal.action.push(
			<Button key="ok" type="submit" sx={{ml:1}} variant="contained" >
				<span>{t("common:general.ok")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

	}

	/** [Action] */
	openCreateFormDialog_ReturnUserForm = (ev) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* */

		modal.form = {};

		/* Clear DialogFooter */
		modal.action = [];

		/* */
		modal.body = this.UserSelectionBody;

		/* */
		modal.action.push(
			<Button key="ok" sx={{ml:1}} variant="contained" onClick={() => {
				if (this.state.create.users.length === 0) {
					let element = document.getElementById("error-message");
					element.innerText = t("common:general.error-message.select-user.none");
					return;
				}
				this.createFormDialog_DataForm()
			}}>
				<span>{t("common:general.next")}</span>
			</Button>
		);

		/* */
		this.setState({ modal });

		// this.userSelectionBody();
	}

	/** [Element] */
	CreateFormDialog_UploadTable = () => {

		/* Translate function */
		let { t } = this.props;

		let { FieldControl } = this;

		let certTypeItems = this.CertTypeItems();
		let identityTypeItems = this.IdentityTypeItems();

		/* */
		let { formData } = this.state;

		/* */
		let users = this.state.create.users;
		let rows = [];

		// console.log("users", users);

		/* */
		for (let i = 0; i < users.length; i++) {

			/* */
			// let user = users[i];

			/* */
			rows.push(
				<fieldset id={`appcert-item-${i}`} className="appcert-item-row" key={i}>
					<div className="appcert-item">
						<Form.Row>
							<FieldControl name={`user_id-${i}`} xs={12} md={3} readOnly />
							<FieldControl name={`status_name-${i}`} xs={12} md={3} readOnly />
							<FieldControl type="date" name={`application_date-${i}`} xs={12} md={3} validate="true" />
							<FieldControl as="select" name={`application_certificate_type-${i}`} xs={12} md={3}>
								{certTypeItems}
							</FieldControl>
						</Form.Row>
						<Form.Row>
							<FieldControl name={`tax_id-${i}`} xs={12} md={4} readOnly />
							<FieldControl name={`company_name-${i}`} xs={12} md={4} readOnly />
							<FieldControl name={`company_name_romaji-${i}`} xs={12} md={4} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`name-${i}`} xs={12} md={4} validate="true" />
							<FieldControl name={`name_kana-${i}`} xs={12} md={4} validate="true" />
							<FieldControl name={`name_romaji-${i}`} xs={12} md={4} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`branch_and_department-${i}`} xs={12} md={6} validate="true" />
							<FieldControl name={`email-${i}`} xs={12} md={6} readOnly />
						</Form.Row>
						<Form.Row>
							<FieldControl name={`credentials-${i}`} xs={12} md={12} validate="true" />
						</Form.Row>
						<Form.Row>
							<FieldControl as="select" name={`identity_verification_documents-${i}`} xs={12} md={6}>
								{identityTypeItems}
							</FieldControl>
						</Form.Row>
						{/* <input type="hidden" name={`upload-${i}`} defaultValue={formData[`upload-` + i]} />
						<input type="hidden" name={`upload-file-name-${i}`} defaultValue={formData[`file_name-` + i]} /> */}
						<input type="hidden" name={`status-${i}`} defaultValue={formData[`status-` + i]} />
						<input type="hidden" name={`company_id-${i}`} defaultValue={formData[`company_id-` + i]} />
						<input type="hidden" name={`tenant_id-${i}`} defaultValue={formData[`tenant_id-` + i]} />
						<Form.Group as={Col} md={12} controlId={`file_name-${i}`} >
							<Form.Label>{t("common:settings.appcert.application-personal-document-upload")}</Form.Label>
							<Form.File
								id={`file_name-${i}`}
								name={`file_name-${i}`}
								label={formData['file_name-' + i] || t("common:settings.appcert.application-personal-document-upload")}
								custom
								onChange={(ev) => {
									this.uploadFile(formData, i, ev)
								}}
								accept=".jpg,.png,.pdf"
							/>
						</Form.Group>
						<input type="hidden" name={`tenant_id-${i}`} defaultValue={formData[`tenant_id-` + i]} />
					</div>
				</fieldset>
			);

		}

		/* */
		return (
			<div id="settings-app-cert" className="">

				{/*  */}
				<div className="lz-m-10">

					{/*  */}
					<div className="row">
						<div className="col-xl-12">
							<fieldset className="table-wrap">
								<div className="appcert">
									<input type="hidden" name="all_user_count" defaultValue={users.length} />
									<input type="hidden" name="action_name" defaultValue="create" />
									<div id="appcert-form" className="appcert-itemlist">
										{rows}
									</div>
								</div>
							</fieldset>
						</div>
					</div>

				</div>

			</div>
		);

	}

	/** */
	uploadFile = (formData, index, ev) => {

		// console.log(ev.target.files[0]);
		let self = this;

		let files = ev.target.files;

		if (files.length < 1) {
			return false;
		}

		formData['file_name-' + index] = files[0].name;
		self.setState({ formData });
	}

	/* */
	AppCertUpdate = async (ev) => {

		let form = ev.target;

		/* */
		let { modal, datalist } = this.state;

		if (form.all_user_count.value === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		let insert_data = { "update_data": [] };
		// let forObj = [];
		let allUserCount = form.all_user_count.value;

		for (let num = 0; num < allUserCount; num++) {

			insert_data["update_data"].push({
				"company_id": form['company_id-' + num]['value'],
				"tenant_id": form['tenant_id-' + num]['value'],
				"application_date": form['application_date-' + num]['value'],
				"user_id": form['user_id-' + num]['value'],
				"application_certificate_type": form['application_certificate_type-' + num]['value'],
				"status": form['status-' + num]['value'],
				"company_name": form['company_name-' + num]['value'],
				"company_name_romaji": form['company_name_romaji-' + num]['value'],
				"tax_id": form['tax_id-' + num]['value'],
				"name": form['name-' + num]['value'],
				"name_kana": form['name_kana-' + num]['value'],
				"name_romaji": form['name_romaji-' + num]['value'],
				"branch_and_department": form['branch_and_department-' + num]['value'],
				"email": form['email-' + num]['value'],
				"credentials": form['credentials-' + num]['value'],
				"identity_verification_documents": form['identity_verification_documents-' + num]['value'],
			});

			if (form.elements['file_name-' + num].files.length > 0) {
				insert_data["update_data"][num]["file_name"] = form.elements['file_name-' + num].files[0].name;
				insert_data["update_data"][num]["file_image"] = form.elements['file_name-' + num].files[0];
			}
		}

		for (let data of insert_data["update_data"]) {
			if (data["file_image"]) {

				data["file_image"] = await this.GetBase64(data["file_image"]).then((base64) => {
					return base64.replace(/^data:.+;base64,/, '');
				}).catch((error) => {
					//console.log("Error", error);
				});
			}
		}

		/* */
		Service.UpdateAppCert(insert_data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});

	}

	/* */
	AppCertSave = async (ev) => {

		let form = ev.target;

		/* */
		let { modal, datalist } = this.state;

		if (form.all_user_count.value === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ modal });

		/* Make today */
		let dateObj = new Date();
		let year = dateObj.getFullYear();
		let month = ("00" + (dateObj.getMonth() + 1)).slice(-2);
		let day = ("00" + dateObj.getDate()).slice(-2);;
		let today = year + "-" + month + "-" + day;
		/* */

		let insert_data = {
			"insert_data": [],
			"application_date": today,
			"user_ids": [],
			"support_user_ids": [],
		};

		// let reader = new FileReader();

		for (let i = 0; i < form.all_user_count.value; i++) {

			insert_data["insert_data"].push({
				"company_id": form['company_id-' + i]['value'],
				"tenant_id": form['tenant_id-' + i]['value'],
				"application_date": form['application_date-' + i]['value'],
				"user_id": form['user_id-' + i]['value'],
				"application_certificate_type": form['application_certificate_type-' + i]['value'],
				"status": form['status-' + i]['value'],
				"company_name": form['company_name-' + i]['value'],
				"company_name_romaji": form['company_name_romaji-' + i]['value'],
				"tax_id": form['tax_id-' + i]['value'],
				"name": form['name-' + i]['value'],
				"name_kana": form['name_kana-' + i]['value'],
				"name_romaji": form['name_romaji-' + i]['value'],
				"branch_and_department": form['branch_and_department-' + i]['value'],
				"email": form['email-' + i]['value'],
				"credentials": form['credentials-' + i]['value'],
				"identity_verification_documents": form['identity_verification_documents-' + i]['value'],
			});

			if (form.elements['file_name-' + i].files.length > 0) {
				insert_data["insert_data"][i]["file_name"] = form.elements['file_name-' + i].files[0].name;
				insert_data["insert_data"][i]["file_image"] = form.elements['file_name-' + i].files[0];
				// send_mail_user["support_user_ids"].push(form['user_id-' + i]['value']);
				insert_data["support_user_ids"].push(form['user_id-' + i]['value']);
			} else {
				// send_mail_user["user_ids"].push(form['user_id-' + i]['value']);
				insert_data["user_ids"].push(form['user_id-' + i]['value']);
			}
		}

		for (let data of insert_data["insert_data"]) {
			if (data["file_image"]) {

				data["file_image"] = await this.GetBase64(data["file_image"]).then((base64) => {
					return base64.replace(/^data:.+;base64,/, '');
				}).catch((error) => {
					//console.log("Error", error);
				});
			}
		}

		/* */
		Service.SaveAppCert(insert_data).then((response) => {

			/* */
			datalist = null;

			/* */
			modal.props = { "show": false };

			/* */
			this.setState({ modal, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});

	}

	DeleteAppCert = (formData, index, cnt, ev) => {

		/* */
		let { modal, datalist } = this.state;

		/* */
		modal.body = Loading;

		/* */
		this.setState({ modal });

		/* */
		let delete_data = {
			"user_id": formData["user_id-" + index],
			"application_certificate_type": formData["application_certificate_type-" + index],
		};

		/* */
		Service.DeleteAppCert(delete_data).then((response) => {

			if (cnt - 1 === 0) {
				/* */
				datalist = null;

				/* */
				modal.props = { "show": false };

				/* */
				this.setState({ modal, datalist });
			} else {
				let data = {
					"application_date": formData["application_date-" + index]
				};

				this.getDetailData(data);
			}

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});
	}

	/** [Action] */
	openResendEmailDialog = () => {

		let { t } = this.props;
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		modalTitle = "common:settings.appcert.resend-email-title";
		modalProps = {
			"show": true,
			"centered": true,
			"backdrop": true,
		}

		ModalBody = () => {
			return (
				<div>{t("common:settings.appcert.resend-email-confirm-message")}</div>
			);
		};

		ModalAction = [
			(<Button key="ok" sx={{ml:1}} variant="contained" onClick={() => {
				this.resendEmail();
			}}>
				<span>{t("common:general.confirm")}</span>
			</Button>),
		];

		this.updateState({ modalProps, modalTitle, ModalBody, ModalAction });

	}

	/* */
	resendEmail = (all_user_count) => {

		/* */
		let { ModalBody, modalProps, datalist } = this.state;

		/* */
		ModalBody = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		/* */
		this.setState({ ModalBody });

		/* */
		Service.appcertEmail().then((response) => {

			// console.log('response', response);
			// /* */
			// datalist = null;

			/* */
			modalProps = { "show": false };

			/* */
			this.setState({ modalProps, datalist });

		}).catch((error) => {

			/* */
			//console.log("error", error);

		});

	}

	/** */
	GetBase64(imageData) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(imageData);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

}

export default withTranslation()(AppCert);
