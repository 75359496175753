import React from 'react';
import Layout from '../../Layout';
import MainMenu from './Menu';
import { withTranslation } from "react-i18next";
import Service from '../../Service';
import Loading from '../Loading';
import Icons from '../Assets/MaterialIcons';
import SVGIcons from '../Assets/SVGIcons';
import {
	Form,
	Button,
	Col,
	Container,
	InputGroup,
} from 'react-bootstrap';
import SearchResult from '../Search/SearchResult';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

class Search extends Layout {

	constructor(props) {
		super(props);
		this.key = "search";
		this.name = "Search";
		this.state.page = "Search";
		// this.state.loading = true;
		this.state.data = null;
		this.state.info = null;
		this.state.services = null;
		this.state.accounts = null;
		this.state.allServiceLabel = "";
		this.state.formData = {};
		this.state.Currencies = [];
		this.state.PageData = {};
		this.state.formData.meta_attributes = [];
		this.state.searchTrue = false;
		this.state.keyFields = {
			"function_type": "function-type",
			"status": "status",
			"document_type": "type",
			"title": "title",
			"preservation_require": "preservation-require",
			"amount_from": "amount-from",
			"amount_to": "amount-to",
			"currency": "currency",
			"contract_date_from": "contract-date-from",
			"contract_date_to": "contract-date-to",
			"expire_date_from": "expire-date-from",
			"expire_date_to": "expire-date-to",
			"effective_date_from": "effective-date-from",
			"effective_date_to": "effective-date-to",
			"circle_finish_date_from": "circle-finish-date-from",
			"circle_finish_date_to": "circle-finish-date-to",
			"product_name": "product-name",
			"reference_document": "reference-document",
			"create_date_from": "create-date-from",
			"create_date_to": "create-date-to",
			"deal_date_from": "deal-date-from",
			"deal_date_to": "deal-date-to",
			"counter_party_name": "counter-party-name",
			"file_name": "file-name",
			"dateofnoti": "dateofnoti",
			"select_datenoti1": "select_datenoti1"
		};
	}

	Menu = () => {
		return (
			<MainMenu {...this.props} page={this.state.page} />
		);
	}

	componentDidMountExtends = () => {
		this.GetSearchDataAllItems();
		this.setStateFields();
	}

	componentDidUpdate = () => {
		// this.GetSearchDataAllItems();
	}

	ServiceIcon = (props) => {

		// console.log(props)
		if (props.code) {
			let Icon = SVGIcons[props.code];
			return <Icon {...props} />;
		}
		return null;
	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:documents.search.document-${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
			if (fields[key].label === "documents.search.document-select_datenoti1") {
				fields[key].label = "";
			}
		}

		this.setState({ fields }, cb);
	}

	setFieldValidity = (form, field_name, fieldValidate, message) => {
		form[field_name].setCustomValidity(message);
		fieldValidate[field_name] = message;
	}

	additionalValidity = (form) => {

		let { fieldValidate } = this.state;
		// console.log(form['amount-from']);
		// console.log(form['contract_date_from']);

		// Reverse check
		if (form['amount_from']['value'] !== "" && form['amount_to']['value'] !== "") {
			if (parseInt(form['amount_from']['value'].replaceAll(",", "")) > parseInt(form['amount_to']['value'].replaceAll(",", ""))) {
				this.setFieldValidity(form, 'amount_from', fieldValidate, 'message.search.amount-from.reverse-error');
				this.setFieldValidity(form, 'amount_to', fieldValidate, 'message.search.amount-to.reverse-error');
			}
		}
		if (form['contract_date_from']['value'] !== "" && form['contract_date_to']['value'] !== "") {

			let contract_date_from = new Date(form['contract_date_from']['value']);
			let contract_date_to = new Date(form['contract_date_to']['value']);

			if (contract_date_from.getTime() > contract_date_to.getTime()) {
				this.setFieldValidity(form, 'contract_date_from', fieldValidate, 'message.search.contract-date-from.reverse-error');
				this.setFieldValidity(form, 'contract_date_to', fieldValidate, 'message.search.contract-date-to.reverse-error');
			}
		}
		if (form['expire_date_from']['value'] !== "" && form['expire_date_to']['value'] !== "") {

			let expire_date_from = new Date(form['expire_date_from']['value']);
			let expire_date_to = new Date(form['expire_date_to']['value']);

			if (expire_date_from.getTime() > expire_date_to.getTime()) {
				this.setFieldValidity(form, 'expire_date_from', fieldValidate, 'message.search.expire-date-from.reverse-error');
				this.setFieldValidity(form, 'expire_date_to', fieldValidate, 'message.search.expire-date-to.reverse-error');
			}
		}
		if (form['effective_date_from']['value'] !== "" && form['effective_date_to']['value'] !== "") {

			let effective_date_from = new Date(form['effective_date_from']['value']);
			let effective_date_to = new Date(form['effective_date_to']['value']);

			if (effective_date_from.getTime() > effective_date_to.getTime()) {
				this.setFieldValidity(form, 'effective_date_from', fieldValidate, 'message.search.contract-date-from.reverse-error');
				this.setFieldValidity(form, 'effective_date_to', fieldValidate, 'message.search.contract-date-to.reverse-error');
			}
		}
		if (form['circle_finish_date_from']['value'] !== "" && form['circle_finish_date_to']['value'] !== "") {

			let circle_finish_date_from = new Date(form['circle_finish_date_from']['value']);
			let circle_finish_date_to = new Date(form['circle_finish_date_to']['value']);

			if (circle_finish_date_from.getTime() > circle_finish_date_to.getTime()) {
				this.setFieldValidity(form, 'circle_finish_date_from', fieldValidate, 'message.search.circle-finish-date-from.reverse-error');
				this.setFieldValidity(form, 'circle_finish_date_to', fieldValidate, 'message.search.circle-finish-date-to.reverse-error');
			}
		}
		if (form['create_date_from']['value'] !== "" && form['create_date_to']['value'] !== "") {

			let create_date_from = new Date(form['create_date_from']['value']);
			let create_date_to = new Date(form['create_date_to']['value']);

			if (create_date_from.getTime() > create_date_to.getTime()) {
				this.setFieldValidity(form, 'create_date_from', fieldValidate, 'message.search.create-date_from.reverse-error');
				this.setFieldValidity(form, 'create_date_to', fieldValidate, 'message.search.create-date-to.reverse-error');
			}
		}
		if (form['deal_date_from']['value'] !== "" && form['deal_date_to']['value'] !== "") {

			let deal_date_from = new Date(form['deal_date_from']['value']);
			let deal_date_to = new Date(form['deal_date_to']['value']);

			if (deal_date_from.getTime() > deal_date_to.getTime()) {
				this.setFieldValidity(form, 'deal_date_from', fieldValidate, 'message.search.deal-date_from.reverse-error');
				this.setFieldValidity(form, 'deal_date_to', fieldValidate, 'message.search.deal-date-to.reverse-error');
			}
		}

		this.setState({ fieldValidate });

		if (Object.keys(fieldValidate).length > 0) {
			return false;
		} else {
			return true;
		}
	};

	formSubmitCallback = (ev) => {
		const form = ev.target;
		if (this.additionalValidity(form) === false) {
			ev.stopPropagation();
		} else {
			// console.log(this.state.formData);
			this.setFormData(this.state.formData);
			this.formSubmitSearch();
		}
	}

	formPrepareBeforeSubmit = (ev) => {
		let form = ev.target;

		form['amount_from'].setCustomValidity("");
		form['amount_to'].setCustomValidity("");
		form['contract_date_from'].setCustomValidity("");
		form['contract_date_to'].setCustomValidity("");
		form['expire_date_from'].setCustomValidity("");
		form['expire_date_to'].setCustomValidity("");
		form['effective_date_from'].setCustomValidity("");
		form['effective_date_to'].setCustomValidity("");
		form['circle_finish_date_from'].setCustomValidity("");
		form['circle_finish_date_to'].setCustomValidity("");
		form['create_date_from'].setCustomValidity("");
		form['create_date_to'].setCustomValidity("");
		form['deal_date_from'].setCustomValidity("");
		form['deal_date_to'].setCustomValidity("");
		console.log(form);

		this.formSubmitHandler(ev, null, this.formSubmitCallback)
	}

	GetSearchDataAllItems = () => {
		let { Currencies } = this.state;

		Service.GetSearchData().then((response) => {

			let PageData = response;
			let { doc_types, function_types, statuses, preservation_require } = PageData;

			for (let i in PageData.currencies) {
				let currency = PageData.currencies[i];
				Currencies.push(currency);
			}

			/* Default structure */
			let { keyFields } = this.state;
			let { formData } = this.state;

			if (this.props.formData === undefined) {
				for (let key in keyFields) {
					formData[key] = "";
				}
			} else {
				formData = this.props.formData;
			}

			if (doc_types.length > 0) {
				formData.document_type = doc_types[0].id;
			}

			if (function_types.length > 0) {
				formData.function_type = function_types[0].id;
			}

			if (statuses.length > 0) {
				formData.status = statuses[0].id;
			}

			if (preservation_require.length > 0) {
				formData.preservation_require = preservation_require[0].id;
			}

			if (!formData.currency) {
				formData.currency = "NONE";
			}

			this.setState({ formData, Currencies, PageData, });

		}).catch((error) => {
			console.log(error.response);
		});
	}

	DocumentTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { doc_types } = PageData;
		let ItemElements = [];

		for (let i in doc_types) {
			let doc_type = doc_types[i];
			ItemElements.push(<option key={doc_type.id} value={doc_type.id}>{t("common:" + doc_type.label)}</option>);
		}

		return ItemElements;
	}

	renderCurrencyItems = () => {
		let { PageData } = this.state;
		let { currencies } = PageData;
		let CurrencyItems = [];

		for (let i in currencies) {
			let currency = currencies[i];
			CurrencyItems.push(<option key={i} value={currency.code}>{currency.label}</option>);
		}

		return CurrencyItems;
	}

	renderPreservationRequireItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { preservation_require } = PageData;

		let ItemElements = [];
		for (let i in preservation_require) {
			let preservation_require_item = preservation_require[i];
			ItemElements.push(<option key={preservation_require_item.id} value={preservation_require_item.id}>{t("common:" + preservation_require_item.label)}</option>);
		}

		return ItemElements;
	}

	renderDateofNoti = () => {
		let { t } = this.props;

		let items = ['', 60, 90, 120];
		let ItemElements = [];
		// ItemElements.push(<option key="empty" value="">{t("common:documents.document-info.date-noti-")}</option>);
		for (let i of items) {
			ItemElements.push(<option key={i} value={i}>{t(`common:documents.document-info.date-noti-${i}`)}</option>);
		}
		return ItemElements;
	}

	DocdateOfNotice = () => {
		let { t, name } = this.props;
		let items = [1, 60, 90, 120, 0];
		let ItemElements = [];
		let { formData } = this.state;

		for (let i of items) {
			let label = t(`common:documents.document-info.date-noti-${i}`);
			if (i == 0) {
				label = t(`common:documents.document-info.select_datenoti_setting`);
			} else if (i == 1) {
				label = t(`common:document.preservation-require.not-setting`);
			}
			let radioProps = {
				"name": name,
				"onChange": (ev) => {
					// let { FieldControl } = this.objMain;
					if (formData.dateofnoti === 0) {
						formData.select_datenoti = "";
					}
					formData.dateofnoti = parseInt(ev.target.value);
					this.setFormData(formData);

				},
			};
			ItemElements.push(
				<FormControlLabel key={i} value={i} label={label} className="date-noti label" control={<Radio {...radioProps} />} />
			);
		}

		return (
			<RadioGroup row name="dateofnoti" aria-labelledby="demo-row-radio-buttons-group-label" value={formData.dateofnoti}>
				{ItemElements}
			</RadioGroup>
		);
	}


	FunctionTypeItems = () => {
		// let { t } = this.props;
		let { PageData } = this.state;
		let { function_types } = PageData;
		let FunctionItemElements = [];

		for (let i in function_types) {
			let function_type = function_types[i];
			FunctionItemElements.push(<option key={function_type.id} value={function_type.id}>{function_type.name}</option>);
		}

		return FunctionItemElements;
	}

	StatusItems = () => {
		let { t } = this.props;
		let { PageData } = this.state;
		let { statuses } = PageData;
		let StatusItemElements = [];

		for (let i in statuses) {
			let status = statuses[i];
			StatusItemElements.push(<option key={status.id} value={status.id}>{t("common:" + status.label)}</option>);
		}

		return StatusItemElements;
	}

	/** */
	addAttribute = (ev) => {
		/* */
		// let key = "Note" + this.formData.doc_attributes.length;
		this.state.formData.meta_attributes.push({ key: "", value: "" });
		this.setFormData(this.state.formData);
	}

	removeAttribute = (ev) => {
		/* */
		// console.log(ev.target.closest(".remove").dataset);
		let row = parseInt(ev.target.closest(".remove").dataset.row);
		this.state.formData.meta_attributes.splice(row, 1);
		this.setFormData(this.state.formData);
	}

	setAttibute = (ev) => {
		// console.log(ev);
		// console.log(ev.target.value);
		let row = parseInt(ev.target.dataset.row);
		let type = ev.target.dataset.type;
		if (type === "key") {
			this.state.formData.meta_attributes[row].key = ev.target.value;
		} else {
			this.state.formData.meta_attributes[row].value = ev.target.value;
		}
		this.setFormData(this.state.formData);
	}

	renderAttributes = (ev) => {
		let { t } = this.props;
		let attrRows = [];
		// console.log(this.state.formData.meta_attributes);
		for (let i in this.state.formData.meta_attributes) {
			let attr = this.state.formData.meta_attributes[i];
			attrRows.push(
				<Form.Row key={i}>

					<Form.Group xs={3} as={Col} controlId={"key[" + i + "]"} >
						<Form.Control
							name={"key[" + i + "]"}
							value={attr.key}
							data-row={i}
							data-type="key"
							onChange={this.setAttibute}
							placeholder={t("common:documents.document-info.key")}
						/>
					</Form.Group>

					<Form.Group xs={9} as={Col} controlId={"value[" + i + "]"} >
						<InputGroup hasValidation>
							<Form.Control
								name={"value[" + i + "]"}
								value={attr.value}
								data-row={i}
								data-type="value"
								onChange={this.setAttibute}
								placeholder={t("common:documents.document-info.value")}
							/>
							<button type="button" data-row={i} className="btn-icon remove" onClick={this.removeAttribute}>
								<Icons className="file-remove" name={"delete"} />
							</button>
						</InputGroup>
					</Form.Group>

				</Form.Row >
			);
		}

		return attrRows;
	}

	render() {

		let { t } = this.props;
		let { formData, searchTrue } = this.state;
		let { DocumentTypeItems, FunctionTypeItems, StatusItems, FieldControl } = this;
		let CurrencyItems = this.renderCurrencyItems();
		let AttributesRows = this.renderAttributes();
		let PreservationRequire = this.renderPreservationRequireItems();
		let DateofNoti = this.renderDateofNoti();
		let { DocdateOfNotice } = this;
		const { Main } = this;

		let Amount = (this.state.formData.amount || "0").toString();
		Amount = Amount.split(".");
		Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		Amount = Amount.join(".") + " " + (this.state.formData.currency || "JPY");

		let chkDatenotiSelected = "none";

		if (formData.dateofnoti == '0') {
			chkDatenotiSelected = "block"
		}

		if (CurrencyItems.length === 0 || PreservationRequire === 0) {
			return <Main><Loading /></Main>;
		}

		if (searchTrue) {
			return <SearchResult searchOption={formData} />
		}

		return (
			<Main>

				< Container fluid="md" className={this.code + "-container form-wrapper container-fluid"} >

					<Form
						// validated={formValidate[this.name]}
						id={"form_" + this.name}
						onSubmit={(ev) => { this.formPrepareBeforeSubmit(ev) }}
						noValidate
						className="full-form"
					>

						<div className="layout-right-title form-header" >
							<h2>{this.headerInfo}</h2>
						</div>

						<div className="form-body">

							<Form.Row>
								<FieldControl as="select" name={`function_type`} xs={12} md={6}>
									<FunctionTypeItems />
								</FieldControl>
								<FieldControl as="select" name={`status`} xs={12} md={6}>
									<StatusItems />
								</FieldControl>
							</Form.Row>

							<Form.Row>
								<FieldControl as="select" name={`document_type`} xs={12} md={6}>
									<DocumentTypeItems />
								</FieldControl>
								<FieldControl name={`title`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl as="select" name={`preservation_require`} xs={12} md={4}>
									{PreservationRequire}
								</FieldControl>
							</Form.Row>

							<Form.Row>
								<FieldControl type="number" name={`amount_from`} xs={12} md={4} />
								<FieldControl type="number" name={`amount_to`} xs={12} md={4} />
								<FieldControl as="select" name={`currency`} xs={12} md={4}>
									{CurrencyItems}
								</FieldControl>
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`contract_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`contract_date_to`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`expire_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`expire_date_to`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`effective_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`effective_date_to`} xs={12} md={6} />
							</Form.Row>

							{/* <Form.Row>
								<FieldControl as="select" name={`dateofnoti`} xs={12} md={6}>
									{DateofNoti}
								</FieldControl>
							</Form.Row> */}

							<FormControl key="dateofnoti" className="date-noti label">
								<FormLabel >{t(`common:documents.document-info.select_datenoti`)}</FormLabel>
								<DocdateOfNotice />
							</FormControl>
							<Form.Row style={{ display: chkDatenotiSelected }}>
								<FieldControl type="date" name={`select_datenoti1`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`circle_finish_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`circle_finish_date_to`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl name={`product_name`} xs={12} md={6} />
								<FieldControl name={`reference_document`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl name={`counter_party_name`} xs={12} md={6} />
								<FieldControl name={`file_name`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`create_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`create_date_to`} xs={12} md={6} />
							</Form.Row>

							<Form.Row>
								<FieldControl type="date" name={`deal_date_from`} xs={12} md={6} />
								<FieldControl type="date" name={`deal_date_to`} xs={12} md={6} />
							</Form.Row>

							<Form.Row className="attributes-wrapper">

								<Form.Group as={Col} controlId="attributes">
									<Button variant="primary" onClick={this.addAttribute} className="btn-custom attributes" >
										<Icons name={"add"} />
										<span className="label">{t('common:documents.search.document-meta-attributes')}</span>
									</Button>
								</Form.Group>

							</Form.Row>

							<div className="attributes-wrapper">
								{AttributesRows}
							</div>

						</div>

						<div className="form-foot">

						</div>

						<div className="page-action-bottom-relative">

							<div className="wrapper-button-submit">

								<Button variant="primary" type="submit">
									<span className="btn-label">{t('common:documents.search.document-search')}</span>
								</Button>

							</div>

						</div>

					</Form>

				</Container >

			</Main>
		);

	}

	/** [Event] */
	formSubmitSearch = () => {
		// let { t } = this.props;
		let { formData } = this.state;
		formData["sorting_code"] = 2;
		formData["skip"] = 0;
		formData["take"] = 50;

		let searchTrue = true;
		this.setState({ searchTrue, formData });
	}
}

export default withTranslation()(Search);
