import DocumentInfo from '../Pages/Documents/DocumentInfo';
import DocumentMultipleInfo from '../Pages/Documents/DocumentMultipleInfo';
import HostSetting from '../Pages/Documents/HostSetting';
import GuestSetting from '../Pages/Documents/GuestSetting';
import ViewerSetting from '../Pages/Documents/ViewerSetting';
import DocumentPrepare from '../Pages/Documents/DocumentPrepare';
import Determine from '../Pages/Documents/Determine';
import VerificationUpload from '../Pages/Documents/VerificationUpload';
import TemplateSelection from '../Pages/Documents/TemplateSelection';
import TemplateInfo from '../Pages/Documents/TemplateInfo';
import TemplateData from '../Pages/Documents/TemplateData';
import TemplateConfirm from '../Pages/Documents/TemplateConfirm';

export default class DocumentStep {

    constructor() {
        this.document_info = {
            text: 'common:menu.documents.step.document-info',
            name: "DocumentInfo",
            page: DocumentInfo,
        };
        this.document_multiple_info = {
            text: 'common:menu.documents.step.document-info',
            name: "DocumentMultipleInfo",
            page: DocumentMultipleInfo,
        };
        this.host_setting = {
            text: 'common:menu.documents.step.host-setting',
            name: "HostSetting",
            page: HostSetting,
        };
        this.guest_setting = {
            text: 'common:menu.documents.step.guest-setting',
            name: "GuestSetting",
            page: GuestSetting,
        };
        this.viewer_setting = {
            text: 'common:menu.documents.step.viewer-setting',
            name: "ViewerSetting",
            page: ViewerSetting,
        };
        this.document_prepare = {
            text: 'common:menu.documents.step.document-prepare',
            name: "DocumentPrepare",
            page: DocumentPrepare,
        };
        this.document_upload = {
            text: 'common:menu.documents.step.document-upload',
            name: "VerificationUpload",
            page: VerificationUpload,
        };
        this.determine = {
            text: 'common:menu.documents.step.determine',
            name: "Determine",
            page: Determine,
        };
        this.template_selection = {
            text: 'common:menu.documents.step.template-selection',
            name: "TemplateSelection",
            page: TemplateSelection,
        };
        this.template_info = {
            text: 'common:menu.documents.step.template-info',
            name: "TemplateInfo",
            page: TemplateInfo,
        };
        this.template_data = {
            text: 'common:menu.documents.step.template-data',
            name: "TemplateData",
            page: TemplateData,
        };
        this.template_confirm = {
            text: 'common:menu.documents.step.template-confirm',
            name: "TemplateConfirm",
            page: TemplateConfirm,
        };
    }


    getStep(step, mode) {
        if (step === "signer") {
            if (mode === "by-template") {
                return {
                    // template_selection: this.template_selection,
                    template_info: this.template_info,
                    template_data: this.template_data,
                    // template_confirm: this.template_confirm,
                };
            } else {
                return {
                    document_info: this.document_info,
                    host_setting: this.host_setting,
                    guest_setting: this.guest_setting,
                    viewer_setting: this.viewer_setting,
                    document_prepare: this.document_prepare,
                    determine: this.determine,
                };
            }
        } else if (step === "sender") {
            if (mode === "by-template") {
                return {
                    // template_selection: this.template_selection,
                    template_info: this.template_info,
                    template_data: this.template_data,
                    // template_confirm: this.template_confirm,
                };
            } else {
                return {
                    document_info: this.document_info,
                    host_setting: this.host_setting,
                    guest_setting: this.guest_setting,
                    viewer_setting: this.viewer_setting,
                    document_prepare: this.document_prepare,
                    determine: this.determine,
                };
            }
        } else if (step === "circle") {
            return {
                document_info: this.document_info,
                host_setting: this.host_setting,
                guest_setting: this.guest_setting,
                viewer_setting: this.viewer_setting,
                document_prepare: this.document_prepare,
                determine: this.determine,
            };
        } else if (step === "verification") {
            if (mode === "multiple") {
                return {
                    document_upload: this.document_upload,
                    document_info: this.document_multiple_info,
                    viewer_setting: this.viewer_setting,
                    determine: this.determine,
                };
            } else {
                return {
                    document_upload: this.document_upload,
                    document_info: this.document_info,
                    viewer_setting: this.viewer_setting,
                    determine: this.determine,
                };
            }
        } else {
            return {
                document_prepare: this.document_prepare,
                determine: this.determine,
                host_setting: this.host_setting,
                guest_setting: this.guest_setting,
                viewer_setting: this.viewer_setting,
                document_info: this.document_info,
            };
        }
    }

}