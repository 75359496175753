import EnvParams from "./EnvParams";

const Cognito = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: EnvParams.cognito.identityPoolId,
        // REQUIRED - Amazon Cognito Region
        region: EnvParams.cognito.region,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: EnvParams.cognito.userPoolId,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: EnvParams.cognito.userPoolWebClientId,
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',
				domain: EnvParams.cognito.domain
    },
};

export default Cognito;
