import React from 'react';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserInfoContext } from "../../Context/UserInfoContext";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import GroupIcon from '@mui/icons-material/Group';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SendIcon from '@mui/icons-material/Send';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import HvacIcon from '@mui/icons-material/Hvac';
import HvacOutlinedIcon from '@mui/icons-material/HvacOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";


class Menu extends React.Component {
    static contextType = UserInfoContext;

    constructor(props) {
        super(props);
        // this.state.user_info = null;
        this.state = {
            user_info: null,
        }
    }

    componentDidMount() {
      const user_info = this.context.userInfoContent;
      this.setState({ user_info });
    }

    render() {
        let { t, page } = this.props;
        let { user_info } = this.state;

        if (!user_info) {
            // return <Loading />;
            return null;
        }

        let menuList = [];

        if (user_info.administrator_role === "1") {
            menuList = [
                { name: "profile", iconActive: <AccountCircleIcon/>, icon:<AccountCircleOutlinedIcon/> },
                { name: "tenant", iconActive: <DashboardCustomizeIcon/>, icon:<DashboardCustomizeOutlinedIcon/> },
                { name: "organization", iconActive: <GroupIcon/>, icon:<GroupOutlinedIcon/> },
                { name: "user", iconActive: <PersonIcon/>, icon:<PersonOutlineOutlinedIcon/> },
                { name: "guest", iconActive: <AccountBoxIcon/>, icon:<AccountBoxOutlinedIcon/> },
                { name: "application-certificate", iconActive: <SendIcon/>, icon:<SendOutlinedIcon/> },
                { name: "stamp", iconActive: <HvacIcon/>, icon:<HvacOutlinedIcon/> },
                { name: "counter-party", iconActive: <ViewListIcon/>, icon:<ViewListOutlinedIcon/> },
                { name: "sso-setting", iconActive: <AdminPanelSettingsIcon/>, icon:<AdminPanelSettingsOutlinedIcon/> },
                { name: "restrictIp-setting", iconActive: <AdminPanelSettingsIcon/>, icon:<AdminPanelSettingsOutlinedIcon/> },
                //{ name: "notification", icon: faBell },
            ];
        }
        let MenuItems = [];
        for (let menu of menuList) {
            let iconView = "";
            let active = "";
            iconView = menu.icon;
            if (page === menu.name) {
                active = " active";
                iconView = menu.iconActive;
            }
            
            MenuItems.push(
                <li key={menu.name} className={`menu-item-custom${active}`} data-nav={`${menu.name}`}>
                    <Link to={`/settings/${menu.name}`} className="avatar-custom">
                        <div className="icon-avatar">
                            {/* <Icon baseClassName={bcnProps}>{menu.icon}</Icon>    */}
                            {iconView}
                        </div>
                        <div className="text-avatar" >
                        {menu.name === 'sso-setting' ?
                          <span>SSO設定</span>:
													menu.name === 'restrictIp-setting'?
                          <span>IPアドレス制限</span>:
                          <span>{t(`common:menu.settings.${menu.name}`)}</span> 
                          }
                        </div>
                    </Link>
                </li>
            );
        }

        return (            
                <ul className="menu-custom">
                    {MenuItems}
                </ul>
            
        );
    }
}

export default withTranslation()(Menu);
