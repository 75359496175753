import React from 'react';
import { Link } from "react-router-dom";
import Layout from '../../Layout';
import MainMenu from './Menu';
import Service from '../../Service';
import BodyDetail from './BodyDetail';
import DocumentDetail from '../Documents/DocumentDetail';
import LoadingList from '../LoadingList';
import { Table } from 'react-bootstrap';
import { Box, Select, MenuItem } from '@mui/material'
import Error from '../Error';
import Loading from '../Loading';
import '../Assets/css/layout.css';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
//import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


class Main extends Layout {

	constructor(props) {
		super(props);
		this.state.documents = null;
		this.state.selectedDoc = null;
		this.state.showDetail = false;
		this.state.currentDocInfo = null;
		this.state.docStatus = {};
		this.state.downloading = {};
		// this.state.users = null;
		this.state.objExpired = {};
		// this.state.function_type = 1;
		this.state.showHiddenMenu = false;

		this.state.searchOption = {
			// function_type: 1,
			status_id: null,
			sorting_code: 2,
			skip: 0,
			take: 100,
			current: 1,
			page: 1,
			total_records: 0,
		}
	}

	Menu = () => {
		return (
			<MainMenu {...this.props} page={this.state.page} />
		);
	}

	showDetailPage = (ev, docInfo) => {
		this.setState({ showDetail: true, currentDocInfo: null, showHiddenMenu: false }, () => {
			// let { users, objExpired } = this.state;
			// console.log(users);

			// if (!users || objExpired["users"] < new Date()) {
			// 	await Service.GetUserList("user_type_name", ["hosts", "guests", "groups"]).then(resp => {
			// 		let curDate = new Date();
			// 		curDate = curDate.setMinutes(curDate.getMinutes() + 10);
			// 		objExpired["users"] = new Date(curDate);
			// 		users = resp;
			// 	}).catch(err => {
			// 		console.log(err.response);
			// 	});
			// }

			Service.getDocumentInfo(docInfo.document_id).then(resp => {
				// console.log(resp);
				// let { currentDocInfo } = this.state;
				// currentDocInfo = resp;
				this.setState({ currentDocInfo: resp });
			}).catch(err => {
				console.log(err.response);
			});

			// this.setState({ users, objExpired, currentDocInfo });
		});
	}

	getFileContent = () => {
		let { currentDocInfo, PDFFiles, SelectedFile } = this.state;
		let document_id = currentDocInfo.document_id;
		if (!PDFFiles[SelectedFile].file) {
			Service.getDocumentFile(document_id, SelectedFile).then(resp => {
				PDFFiles[SelectedFile].file = resp;
				this.setState({ PDFFiles });
			}).catch(err => {
				//console.log(err.response);
			});
		}
	}

	componentDidUpdate() {
		// let { showDetail, PDFFiles, SelectedFile } = this.state;
		// if (showDetail && PDFFiles) {
		// 	let PDFFile = PDFFiles[SelectedFile];
		// 	if (!PDFFile.file) {
		// 		this.getFileContent();
		// 	}
		// }
	}

	UpdateModalDialog = (options) => {
		let { modalTitle, ModalBody, ModalAction, modalProps } = this.state;
		if (options.title) {
			modalTitle = options.title;
		}
		if (options.body) {
			ModalBody = options.body;
		}
		if (options.action) {
			ModalAction = options.action;
		}
		if (options.props) {
			modalProps = options.props;
		}
		this.setState({ modalProps, modalTitle, ModalBody, ModalAction });
	}

	DeleteDocumentSubmit = (document_id) => {
		let { t } = this.props;
		Service.DeleteDocument(document_id).then(resp => {
			// console.log(resp);
			let { documents } = this.state;
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:" + resp.message)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						this.modalToggle(false);
						for (let i in documents) {
							let doc = documents[i];
							if (doc.id === document_id) {
								documents.splice(i, 1);
								this.setState({ documents });
								break;
							}
						}
					}}>
						<span>{t("common:general.ok")}</span>
					</Button>),
				],
			});
		}).catch(err => {
			//console.log(err.response);
			this.UpdateModalDialog({
				body: () => {
					return (
						<div>{t("common:" + err.response.data.error)}</div>
					);
				},
				action: [
					(<Button key="ok" sx={{ ml: 1 }} variant="contained" onClick={(ev) => {
						this.DeleteDocumentSubmit(document_id);
					}}>
						<span>{t("common:general.try-again")}</span>
					</Button>),
				],
			});
		})
		this.UpdateModalDialog({
			body: Loading,
			action: [],
		});
	}

	DeleteDocumentConfirmation = (ev, document_id) => {
		let { t } = this.props;
		this.UpdateModalDialog({
			title: "common:documents.delete.comfirm.header",
			body: () => {
				return (
					<div>{t("common:document.delete.comfirm.message")}</div>
				);
			},
			action: [
				(
					<Button
						key="ok"
						sx={{ ml: 1 }}
						variant="contained"
						onClick={(ev) => {
							this.DeleteDocumentSubmit(document_id);
						}}>
						<span>{t("common:general.yes-delete")}</span>
					</Button>
				),
			],
			props: {
				"show": true,
				"centered": true,
			},
		});
	}

	StatusBarGroup = (props) => {
		let { t } = this.props;
		let { doc } = props;
		//let { function_type } = this.state;

		if (doc.status_id === 14 && doc.function_type_id === 4) {
			// doc.status_label = "document.status.cannot-timestamp";
			// doc.status_code = "error"
			doc.status_label = "document.status.protected-PDF-supported";
			doc.status_code = "confirmed";
		}
		if (doc.remarks === "protected" && [1, 2, 3].includes(doc.function_type_id)) {
			doc.status_code = "error";
		}
		let StatusItems = [(
			<div key="doc-status" className={"document-progress " + doc.status_code}>
				<span className="status-label">{t(`common:${doc.status_label}`)}</span>
			</div>
		)];

		return StatusItems;
	}

	ItemsList = () => {
		let { t } = this.props;
		let { documents, function_type } = this.state;
		let { StatusBarGroup } = this;

		if (documents === null) {
			return <LoadingList />;
		}

		if (documents.length === 0) {
			return (
				<Table>
					<tbody>
						<tr className="document-row-list error-tr">
							<td className="record-not-found">
								<Error msg={t("common:message.record.not-found")} />
							</td>
						</tr>
					</tbody>
				</Table>
			);
		}

		let ItemsList = [];
		for (let doc of documents) {
			let btnDetailClass = ["sign-status"];

			// console.log(doc);

			let OptionalColumnAfterCreator = () => {
				if (function_type === 1) {
					return (
						<td key={7} className="sign-level-info clickable"
							onClick={(ev) => { this.showDetailPage(ev, doc); }}
						>
							<div className="inner-container">
								<span>{t(`common:documents.document-info.sign-level-${doc.sign_level}`)}</span>
							</div>
						</td>
					);
				} else if (function_type === 4) {

					let item_preservation_require = '';

					if (parseInt(doc.preservation_require) === 1) {
						item_preservation_require = 'common:documents.document-info.other';
					} else if (parseInt(doc.preservation_require) === 2) {
						item_preservation_require = 'common:documents.document-info.new-article-seven';
					} else if (parseInt(doc.preservation_require) === 3) {
						item_preservation_require = 'common:documents.document-info.scanner-storage';
					}

					return [(
						<td key={8} className="function-type-info clickable"
							onClick={(ev) => { this.showDetailPage(ev, doc); }}
						>
							<div className="inner-container">
								<span>{t(`common:${doc.function_type}`)}<br/>{doc.doc_type_name}</span>
								{/* <div>{doc.doc_type_name}</div> */}
							</div>
						</td>
					), (
						<td key={9} className="function-type-info clickable"
							onClick={(ev) => { this.showDetailPage(ev, doc); }}
						>
							<div className="inner-container">
								<span>{t(item_preservation_require)}</span>
							</div>
						</td>
					)];
				} else {
					return null;
				}
			}

			let DeleteAction = () => {
				if ([0, 5].includes(doc.doc_status) && doc.delete_permission === 1 && (function_type === doc.function_type_id || doc.remarks === "protected")) {
					return (
						<Tooltip title="下書きを削除する" arrow>
							<IconButton
								onClick={(ev) => {
									this.DeleteDocumentConfirmation(ev, doc.id);
								}}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					);
				} else {
					return null;
				}
			}

			// let DownloadAction = () => {
			// 	if ([2, 3].includes(doc.doc_status)) {
			// 		let { downloading } = this.state;
			// 		// console.log(downloading);
			// 		if (Object.keys(downloading).includes(doc.id.toString())) {
			// 			return (
			// 				<td className="document-downloading doc-row-action">
			// 					<FontAwesomeIcon icon={faSpinner} pulse className="action-button" />
			// 				</td>
			// 			);
			// 		} else {
			// 			return (
			// 				<td className="document-download doc-row-action">
			// 					<FontAwesomeIcon icon={faDownload} className="action-button" onClick={(ev) => {
			// 						let { downloading } = this.state;
			// 						downloading[doc.id] = null;
			// 						this.setState({ downloading }, () => {
			// 							this.onDownloadRequestHandler(ev, doc.id);
			// 						});
			// 					}} />
			// 				</td>
			// 			);
			// 		}
			// 	} else {
			// 		return null;
			// 	}
			// }

			let CounterParty = () => {
				if (function_type === 4) {
					if (doc.counter_party_name) {
						return (
							<td key={9} className="counter-party-info clickable"
								onClick={(ev) => { this.showDetailPage(ev, doc); }}>
								<div className="inner-container">
									<span>{doc.counter_party_name}</span>
								</div>
							</td>
						);
					} else {
						return (
							<td key={10} className="counter-party-info clickable">
								<div className="inner-container">
								</div>
							</td>
						);
					}
				} else {
					return null;
				}
			}


			let SignInfo = () => {
				if (doc.sign_permission !== 1 || doc.status_id === 14 || doc.status_id === 5 || doc.status_id === 0) return null;

				if (doc.signed === 0 && doc.status_code !== "draft") {
					return (
						<div key="doc-sign" >
							<Chip label={"署名してください"}
								variant="outlined"
								sx={{
									color: "error.dark",
									borderColor: "error.dark",
									borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
								}}
								size="small"
							/>
							{/* <span className="status-label">{t(`common:document.unsigned`)}</span> */}
						</div>
					);
				} else if (doc.signed === 1) {
					return (
						<div key="doc-sign">
							<Chip label={"署名済み"}
								variant="outlined"
								sx={{
									color: "success.dark",
									borderColor: "success.dark",
									borderWidth: "2px", fontWeight: 500, fontSize: "0.7rem"
								}}
								size="small"
							/>
						</div>
					);
					// }else if(function_type === 4){

					// 	if (doc.has_verification !== 1) return null;
					// 	if(doc.verification_status){
					// 		//"verified"
					// 		<div key="doc-sign">
					// 			<Chip label={"署名済み"}
					// 				variant="outlined"
					// 				sx = {{
					// 					color:"success.dark",
					// 					borderColor:"success.dark",
					// 					borderWidth:"2px",fontWeight:500,fontSize:"0.7rem"
					// 				}}
					// 				size="small"
					// 				/>
					// 		</div>
					// 	}else{
					// 		//"waiting";
					// 		<div key="doc-sign" >
					// 			<Chip label={"署名してください"}
					// 				variant="outlined"
					// 				sx = {{
					// 					color:"error.dark",
					// 					borderColor:"error.dark",
					// 					borderWidth:"2px",fontWeight:500,fontSize:"0.7rem"
					// 				}}
					// 				size="small"
					// 				/>
					// 		</div>
					// 	}


				} else {
					return null;
				}
			}

			let ButtonSign = () => {
				if (doc.sign_permission !== 1) return null;
				if (doc.signed === 0 && doc.status_code !== "draft") {
					btnDetailClass.push("wait-sign");
					return <CircleNotificationsIcon color="error" />;
				} else if (doc.signed === 1) {
					if (doc.status_code === "signed") {
						btnDetailClass.push("signed");
					}
					if (["signed", "send-sender", "confirmed"].includes(doc.status_code)) {
						return <CheckCircleIcon color="success" />;
					} else if (function_type === 4 && parseInt(doc.status_id) === 14) {
						return <CheckCircleIcon color="success" />;
					} else {
						return <CheckCircleIcon color="disabled" />;
					}
				} else {
					return null;
				}
			}
			let Amount = (doc.amount || "0").toString();
			Amount = Amount.split(".");
			Amount[0] = Amount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			Amount = Amount.join(".") + " " + (doc.currency || "JPY");

			let linkageClass = "";
			if (doc.master_document_id) {
				linkageClass = " has-linkage";
			}

			ItemsList.push(
				<tr key={doc.id} className={`document-row-list${linkageClass}`} onClick={(ev) => {
					// let target = ev.target.closest(".document-row-list");
					// if(target.classList.contains("selected")){
					// 	target.classList.remove("selected");
					// }else{
					// 	target.classList.add("selected");
					// }
				}}>
					<td key={0} className={"stamp-box clickable " + btnDetailClass.join(" ")}
						onClick={(ev) => { this.showDetailPage(ev, doc); }}
					>
						<ButtonSign />
						{/* <FontAwesome
								//className="action-button"
								//onClick={(ev) => { this.showDetailPage(ev, doc);}}
								//title={t("common:document.show-detail-page") + " " + doc.id}
								/> */}

					</td>
					<td key={1} className="creator-info clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}
					>
						<div className="inner-container">
							<span>{doc.full_name}</span>
						</div>
					</td>
					<OptionalColumnAfterCreator />
					{ function_type !== 4 && <td key={2} className="title-info clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}>
						<div className="inner-container">
							<span>{doc.doc_type_name}</span>
						</div>
					</td>}
					<td key={3} className="title-info clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}>
						<div className="inner-container">
							<span>{doc.id + " - " + doc.title}</span>
							<div className={"status-container"}>
								<StatusBarGroup doc={doc} />
							</div>
						</div>
					</td>
					<td key={4} className="sign-info clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}>
						<div className="inner-container">
							<div className="unsigned-container">
								<SignInfo />
							</div>
						</div>
					</td>

					<CounterParty />
					<td key={5} className="amount-info clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}>
						<div className="inner-container">
							<span>{Amount}</span>
						</div>
					</td>
					<td key={6} className="document-created-date clickable"
						onClick={(ev) => { this.showDetailPage(ev, doc); }}>
						<div className="inner-container">

							<span>{doc.received_date}</span>
						</div>
					</td>
					{/* <DownloadAction /> */}
					<td key={7} className="document-delete doc-row-action stamp-delete">
						<DeleteAction />
					</td>
				</tr>
			);


		}

		let OptionalColumnAfterCreatorHead = () => {
			if (function_type === 1) {
				return (
					<th key={27}>
						<div className="header-inner-container">
							<span>{t("common:documents.document-info.sign-level")}</span>
						</div>
					</th>
				);
			} else if (function_type === 4) {
				return [(
					<th key={28} className="th-function-type">
						<div className="header-inner-container">
							<span>{t("common:documents.document-info.function-type")}</span>
						</div>
					</th>
				), (
					<th key={29} className="th-preservation-require">
						<div className="header-inner-container">
							<span>{t("common:documents.document-info.preservation-require")}</span>
						</div>
					</th>
				)];
			} else {
				return null;
			}
		}

		let CounterPartyHead = () => {
			if (function_type === 4) {
				return (
					<th key={29} className="th-counter-party">
						<div className="header-inner-container">
							<span>{t("common:documents.document-info.counter-party")}</span>
						</div>
					</th>
				);

			} else {
				return null;
			}
		};
		console.log("function type: ", function_type);
		let HeadList = (
			<tr key="listheader">
				<th key={20} className="th-stamp-box"></th>
				<th key={21} className="th-creator">
					<div className="header-inner-container">
						<span>{t("common:documents.creator")}</span>
					</div>
				</th>

				<OptionalColumnAfterCreatorHead />
				{
					function_type !== 4 &&
					<th key={22} className="th-doctype">
						<div className="header-inner-container">
							<span>{t('common:documents.document-info.document-type')}</span>
						</div>
					</th>
				}
				<th key={23} className="th-imprints">
					<div className="header-inner-container">
						<span>{t('common:documents.document-info.imprints')}</span>
					</div>
				</th>
				<th key={24} className="th-action">
					<div className="header-inner-container">
						<span>{t('common:documents.document-info.action')}</span>
					</div>
				</th>
				<CounterPartyHead />

				<th key={25} className="th-amount-info">
					<div className="header-inner-container">
						<span>{t("common:documents.document-info.amount")}</span>
					</div>
				</th>
				<th key={26} className="th-document-created-date">
					<div className="header-inner-container">
						<span>{t("common:documents.issued-date")}</span>
					</div>
				</th>
				<th key={27} className="stamp-delete">
				</th>
			</tr>
		);

		return (
			<Table>
				<thead key={0} >
					{HeadList}
				</thead>
				<tbody key={1} >
					{ItemsList}
				</tbody>
			</Table>
		);
	}

	// HeadList

	GetAllUserList = () => {
		let { searchOption } = this.state;
		this.GetDocumentListData(searchOption);
	}

	componentDidMountExtends = async () => {
		let { function_type, docStatus } = this.state;
		// for fix bug status list on evaluation screen (search dialog)
		if ([4, 7].includes(function_type)) {
			await Service.getDocumentStatusList({ function_type }).then(resp => {
				// let { docStatus } = this.state;
				docStatus = {};
				for (let rec of resp) {
					docStatus[rec.id] = rec;
				}
			}).catch(err => {
				console.log(err.response);
			});
		}

		// console.log(docStatus);
		this.setState({ docStatus }, () => {
			if (this.componentDidMountOptional) {
				if (typeof this.componentDidMountOptional === "function") {
					this.componentDidMountOptional(this.GetAllUserList);
				}
			} else {
				this.GetAllUserList();
			}
		});
	}

	PageCalculation = (searchOption) => {
		searchOption.current = Math.ceil(searchOption.skip / searchOption.take) + 1;
		searchOption.page = Math.ceil(searchOption.total_records / searchOption.take);
		// console.log(searchOption);
		return searchOption;
	}

	GetDocumentListData = (searchOption) => {
		searchOption = this.PageCalculation(searchOption);
		this.setState({ documents: null, searchOption });
		// console.log(searchOption);
		Service.getDocumentList(searchOption).then(resp => {
			let { documents } = this.state;
			documents = resp.records;
			searchOption.total_records = resp.total_records;
			searchOption = this.PageCalculation(searchOption);
			// console.log(documents);
			this.setState({ documents, searchOption });
			let docFilterSigned = documents.filter(a=> a.signed === 0 && a.status_code !== 'draft' && a.status_code !== 'error').length;
			switch (searchOption.function_type) {
				case 1:
					if (docFilterSigned !== this.context.userInfoContent.signed_document_signer){
						this.context.setUserInfoContent({...this.context.userInfoContent, signed_document_signer: docFilterSigned})
					}
					break;
				case 2:
					if (docFilterSigned !== this.context.userInfoContent.signed_document_sender){
						this.context.setUserInfoContent({...this.context.userInfoContent, signed_document_sender: docFilterSigned})
					}
					break;
				case 3:
					if (docFilterSigned !== this.context.userInfoContent.signed_document_circle){
						this.context.setUserInfoContent({...this.context.userInfoContent, signed_document_circle: docFilterSigned})
					}
					break;
				case 4:
					if (docFilterSigned !== this.context.userInfoContent.signed_document_verification){
						this.context.setUserInfoContent({...this.context.userInfoContent, signed_document_verification: docFilterSigned})
					}
					break;
				default:
					break;
			}
		}).catch(err => {
			console.log(err.response);
		});
	}

	handleSearchDocumentMultiField = (text) => {
		const newSearchOption = this.state.searchOption;
		if (text.length === 0) {
		    delete newSearchOption.search;
		    this.setState({
		        searchOption: newSearchOption
		    }, () => this.GetDocumentListData(newSearchOption));
		} else {
		    this.GetDocumentListData({
		        ...this.state.searchOption,
		        search: text,
		    });
		}
	}

	onDownloadRequestHandler = (ev, document_id) => {
		Service.downloadAllAsZip(document_id).then(resp => {
			// console.log(resp);
			// let filename = new Date();
			let aLink = document.createElement("a");
			// aLink.download = filename.toISOString();
			aLink.href = resp.url;
			aLink.click();

			let { downloading } = this.state;
			// console.log(downloading);
			delete downloading[document_id];
			this.setState({ downloading });
		}).catch(err => {
			console.log(err.response);
		});
	}

	onListDownloadRequestHandler = () => {
		let { searchOption } = this.state;
		Service.getDocumentListDownload(searchOption).then(resp => {
			let aLink = document.createElement("a");
			aLink.href = resp.url;
			aLink.click();
		}).catch(err => {
			console.log(err.response);
		});
	}

	onPageChangeHandler = (ev) => {
		let page = parseInt(ev.target.value);
		let { searchOption } = this.state;
		let nextSkip = (page - 1) * searchOption.take;
		if (nextSkip !== searchOption.skip) {
			searchOption.skip = nextSkip;
			this.GetDocumentListData(searchOption);
		}
	}

	onRecordLimitChangeHandler = (ev) => {
		let limit = parseInt(ev.target.value);
		let { searchOption } = this.state;
		if (limit !== searchOption.take) {
			let cursor = (searchOption.current - 1) * searchOption.take;
			let page = Math.floor(cursor / limit);
			searchOption.skip = page * limit;
			searchOption.take = limit;
			this.GetDocumentListData(searchOption);
		}
	}

	onSortingHandler = (ev) => {
		let sort = parseInt(ev.target.value);
		let { searchOption } = this.state;
		if (sort !== searchOption.sorting_code) {
			searchOption.sorting_code = sort;
			this.GetDocumentListData(searchOption);
		}
	}

	onFunctionTypeChangeHandler = (ev) => {
		// console.log(selected);
		let selected = parseInt(ev.target.value) || null;

		let { searchOption } = this.state;
		if (selected !== searchOption.function_type) {
			searchOption.function_type = selected;
			this.GetDocumentListData(searchOption);
		}
	}

	onPreservationRequireChangeHandler = (ev) => {
		// console.log(selected);
		let selected = parseInt(ev.target.value) || null;

		let { searchOption } = this.state;
		if (selected !== searchOption.preservation_require) {
			searchOption.preservation_require = selected;
			this.GetDocumentListData(searchOption);
		}
	}

	onStatusChangeHandler = (ev) => {
		let status = ev.target.value;
		if (status !== "all") {
			status = parseInt(ev.target.value);
		} else {
			status = null;
		}
		let { searchOption } = this.state;
		if (status !== searchOption.status_id) {
			searchOption.status_id = status;
			this.GetDocumentListData(searchOption);
		}
	}

	prevPageData = (ev) => {
		let { searchOption, PossiblePrevPage } = this.state;
		let nextSkip = searchOption.skip - searchOption.take;
		if (nextSkip >= 0) {
			searchOption.skip = nextSkip;
			this.GetDocumentListData(searchOption);
			PossiblePrevPage = true;
		} else {
			PossiblePrevPage = false;
		}
		this.setState({ PossiblePrevPage });
	}

	nextPageData = (ev) => {
		let { searchOption, PossibleNextPage } = this.state;
		let nextSkip = searchOption.skip + searchOption.take;
		if (nextSkip < searchOption.total_records) {
			searchOption.skip = nextSkip;
			this.GetDocumentListData(searchOption);
			PossibleNextPage = true;
		} else {
			PossibleNextPage = false;
		}
		this.setState({ PossibleNextPage });
	}

	ClearSeletedDoc = (docInfo) => {
		this.setState({ showDetail: false });
		let { documents } = this.state;
		for (let doc of documents) {
			if (doc.id === docInfo.id) {
				let status = docInfo.status_list[docInfo.doc_status];
				doc.signed = docInfo.signed;
				doc.doc_status = status.id;
				doc.status_code = status.code;
				doc.status_label = status.label;
				break;
			}
		}
		// console.log(documents);
		this.setState({ documents, currentDocInfo: null, showHiddenMenu: false });
	}

	DocumentDetailBody = (props) => {
		// let { t } = this.props;
		let { currentDocInfo } = this.state;
		let { ReturnButton } = this;

		if (!currentDocInfo) {
			return <Loading />;
		}
		if (currentDocInfo === false) {
			return <Error />;
		}
		// console.log(currentDocInfo);
		return (
			<DocumentDetail docInfo={currentDocInfo} action={ReturnButton} />
		);
	}

	close = (ev, docInfo) => {
		ev.target.closest(".document-detail").classList.remove("active");
		this.ClearSeletedDoc(docInfo);
	}

	ReturnButton = (props) => {
		let { t } = this.props;
		let { docInfo } = props;
		// console.log(props);
		return (
			// <button className="btn-return" onClick={(ev) => {
			// 	this.close(ev, docInfo);
			// }}>
			//	<FontAwesomeIcon icon={faShareSquare} className="fa-flip-horizontal" title={t("common:document.return-to-list-page")} />
			//</button>
			<div>
				<Tooltip title={t("common:document.return-to-list-page")} arrow>
					<IconButton
						className='btn-action'
						onClick={(ev) => {
							this.close(ev, docInfo);
						}}>
						<ArrowBackOutlinedIcon fontSize='inherit' />
					</IconButton>
				</Tooltip>
			</div>
		);
	}

	DocumentDetailPage = (props) => {
		let { showDetail } = this.state;
		let { DocumentDetailBody } = this;
		return (
			<BodyDetail active={showDetail}>
				<DocumentDetailBody />
			</BodyDetail>
		);

	}

	RocordSorting = () => {
		let { t } = this.props;
		let { searchOption } = this.state;
		let sortList = {
			2: "common:search.sorting.decending.by-issued-date",
			1: "common:search.sorting.ascending.by-issued-date",
		};
		let sortItems = [];

		for (let i in sortList) {
			sortItems.push(
				<MenuItem value={i} key={i}>
					{t(sortList[i])}
				</MenuItem>
			);
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={searchOption.sorting_code}
					onChange={this.onSortingHandler}
					renderValue={() => t(sortList[searchOption.sorting_code])}
					variant={"standard"}
					style={{ color: "#007BFF" }}>
					{sortItems}
				</Select>
			</Box>
		);
	}

	FunctionTypeFilter = () => {
		let { t } = this.props;
		let { searchOption, function_type } = this.state;

		if (function_type !== 4) {
			return null;
		}

		let function_type_items = {
			0: "all",
			1: "signer",
			2: "sender",
			3: "circle",
			4: "verification",
		}

		let FilterItems = [];
		for (let key in function_type_items) {
			let label = function_type_items[key];
			FilterItems.push(
				<MenuItem value={key} key={key} data-label={label}>
					{t(`common:document.function-type.${label}`)}
				</MenuItem>
			);
		}

		let active = function_type_items[searchOption.function_type || 0];
		let title = t(`common:document.function-type.${active}`);

		return (
			<Box py={1} px={2}>
				<Select
					value={searchOption.function_type || 0}
					onChange={this.onFunctionTypeChangeHandler}
					renderValue={() => `${title}`}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{FilterItems}
				</Select>
			</Box>
		);
	}

	PreservationRequireFilter = () => {
		let { t } = this.props;
		let { searchOption, function_type } = this.state;

		if (function_type !== 4) {
			return null;
		}

		let preservation_require_items = {
			0: "all",
			1: "other",
			2: "new-article-seven",
			3: "scanner-storage",
		}

		let FilterItems = [];

		for (let key in preservation_require_items) {
			let label = preservation_require_items[key];
			FilterItems.push(
				<MenuItem value={key} key={key} data-label={label}>
					{t(`common:document.preservation-require.${label}`)}
				</MenuItem>
			);
		}

		let active = preservation_require_items[searchOption.preservation_require || 0];
		let title = t(`common:document.preservation-require.${active}`);

		return (
			<Box py={1} px={2}>
				<Select
					value={searchOption.preservation_require || 0}
					onChange={this.onPreservationRequireChangeHandler}
					renderValue={() => `${title}`}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{FilterItems}
				</Select>
			</Box>
		);
	}

	StatusListFilter = () => {
		let { t } = this.props;
		let { searchOption, docStatus, function_type } = this.state;

		if (function_type !== 4) {
			return null;
		}

		if (Object.keys(docStatus).length === 0) {
			return null;
		}

		let statusItems = [];
		statusItems.push(
			<MenuItem value={'all'} key={0}>
				{t("common:document.status.all")}
			</MenuItem>
		);
		for (let i in docStatus) {
			let status = docStatus[i];
			statusItems.push(
				<MenuItem value={status.id} key={i + 1}>
					{t(`common:${status.label}`)}
				</MenuItem>
			);
		}

		statusItems.push(
			<MenuItem value={'99'} key={99}>
				{t("common:document.status.protected-PDF-supported")}
			</MenuItem>
		);

		let active = searchOption.status_id;
		let title = t("common:document.status.all");
		if (active === null) {
			active = "all";
		} else if (active === 99) {
			active = "99";
			title = t("common:document.status.protected-PDF-supported");
		} else {
			let currentStatus = docStatus[active];
			title = t(`common:${currentStatus.label}`);
		}


		return (
			<Box py={1} px={2}>
				<Select
					value={active}
					onChange={this.onStatusChangeHandler}
					renderValue={() => title}
					variant={"standard"}
					style={{ color: "#007BFF" }}
				>
					{statusItems}
				</Select>
			</Box>
		);
	}

	PageListNavigator = () => {
		let { searchOption } = this.state;
		let navItems = [];
		let currentFrom = 0;
		let pageTo = 0;
		let pageFrom = 0;
		let currentTo = 0;
		let total = searchOption.total_records;
		let take = searchOption.take;
		let current = searchOption.current;
		if (!searchOption.total_records) { return <CircularProgress size="1rem" /> }
		for (let i = 0; i < searchOption.page; i++) {
			let page = i + 1;
			if (total === 0) {
				pageFrom = 0;
			} else {
				pageFrom = ((page - 1) * take) + 1;
			}
			if (total < page * take) {
				pageTo = total
			} else {
				pageTo = page * take;

			}
			navItems.push(
				<MenuItem value={page} key={page}>{`${pageFrom}-${pageTo}`}</MenuItem>
			);
		}
		if (total === 0) {
			currentFrom = 0;
		} else {
			currentFrom = ((current - 1) * take) + 1;
		}
		if (total < current * take) {
			currentTo = total
		} else {
			currentTo = current * take;
		}

		return (
			<Box py={1} px={2}>
				<Select
					value={current}
					onChange={this.onPageChangeHandler}
					renderValue={() => `${currentFrom} - ${currentTo} / ${searchOption.total_records}`}
					variant={"standard"}
					MenuProps={{
						PaperProps: {
							style: {
								maxHeight: 250,
								minWidth: 130
							},
						},
					}}
					style={{ color: "#007BFF" }}>
					{navItems}
				</Select>
			</Box>
		);
	}

	OperationButtons = () => {
		let { t } = this.props;
		let { code } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<Button
				key="document-create"
				sx={{ ml: 1, borderRadius: 19 }}
				variant="contained"
				startIcon={<AddIcon />}
				className="as-link"
				component={Link}
				to={`/${code}/documents/create`}
			>
				{t('common:main.general.create')}
			</Button>
		);

		return ButtonList;
	}

	AdditionalButtons = () => {
		//let { t } = this.props;
		let { searchOption, documents } = this.state;
		let ButtonList = [];

		ButtonList.push(
			<IconButton
				sx={{ ml: 2, mr: 2, mt: 1 }}
				key="list-reload"
				color="primary"
				style={{
					marginTop: "8px", marginLeft: "16px", padding: "3px"
				}}
				disabled={documents ? false : true}
				onClick={(ev) => {
					this.GetDocumentListData(searchOption);
				}}
			>
				{documents ? <RefreshIcon /> : <CircularProgress size="1em" />}
			</IconButton>
		);

		ButtonList.push(
			<Tooltip key={0} title="書類のリストをCSVでダウンロード" arrow>
				<Button
					sx={{ mr: 2, mt: 1 }}
					key="list-download"
					variant="outlined"
					size="small"
					startIcon={<DownloadIcon />}
					onClick={this.onListDownloadRequestHandler}
				>
					{"CSVダウンロード"}
					{/* {t('common:main.general.list-download')} */}
				</Button>
			</Tooltip>
		)


		return ButtonList;
	}

	HeaderRightNav = () => {
		let { t } = this.props;
		let { searchOption } = this.state;
		const { PageListNavigator, RocordSorting, FunctionTypeFilter, StatusListFilter, PreservationRequireFilter } = this;

		return (
			<div className="header-right">

				<FunctionTypeFilter />

				<StatusListFilter />

				<PreservationRequireFilter />

				<RocordSorting />

				<Box py={1} px={2}>
					<Select
						value={searchOption.take}
						onChange={this.onRecordLimitChangeHandler}
						renderValue={() => searchOption.take + " " + t("common:search.view.record-per-page")}
						variant={"standard"}
						style={{ color: "#007BFF" }}
					>
						{[100, 200, 500, 1000].map((count, index) =>
							<MenuItem value={count} key={index} >{count + t("common:search.view.record-per-page")}</MenuItem>
						)}
					</Select>
				</Box>

				<div className="pagination">

					<PageListNavigator />

					<IconButton
						onClick={this.prevPageData}
						disabled={searchOption.current === 1 ? true : false} >
						<ChevronLeftIcon />
					</IconButton>

					<IconButton
						onClick={this.nextPageData}
						disabled={searchOption.current === searchOption.page ? true : false}>
						<ChevronRightIcon />
					</IconButton>
				</div>

			</div>
		);
	}




	render() {

		// let { t } = this.props;
		let { code, showDetail } = this.state;
		const { Main, ItemsList, DocumentDetailPage, HeaderRightNav, OperationButtons, AdditionalButtons } = this;

		const isMobileResponsive = showDetail;
		return (
			<>
				<div className={isMobileResponsive ? "d-none d-sm-block" : ""}>
					<Main>
						<DocumentDetailPage />
						<div className={`${code}-container`}>
							<div className="header-wrapper above">
								<div className="header-left">
									<AdditionalButtons />
								</div>
								<div className="header-right"></div>
							</div>
							<div className="header-wrapper">
								<div className="header-left">
									<OperationButtons />
								</div>
								<HeaderRightNav />
							</div>

							<div className="body-wrapper">
								<ItemsList />
							</div>
						</div>
					</Main>
				</div>
				{isMobileResponsive && (
					// hide menu when showing detailInfo
					<div className="main contrainer-wrapper d-block d-sm-none">
						<div className="layout">
							<DocumentDetailPage />
						</div>
					</div>
				)}
			</>
		);

	}

}

export default Main;
