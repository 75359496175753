import React from 'react';
import { withTranslation } from "react-i18next";
import MenuConfig from '../../Config/Menu';
import { Link } from "react-router-dom";
import { UserInfoContext } from "../../Context/UserInfoContext";
import CountSignedDoc from './CountSignedDoc';

class Menu extends React.Component {
    static contextType = UserInfoContext;

    constructor(props) {
        super(props);
        // this.state.user_info = null;
        this.state = {
            user_info: null,
        }
    }

    componentDidMount() {
      const user_info = this.context.userInfoContent;
      this.setState({ user_info });
    }

    render() {
        let { t, page } = this.props;
        let { user_info } = this.state;

        if (!user_info) {
            // return <Loading />;
            return null;
        }

        let menuKeys = Object.keys(MenuConfig);
        // let { t, page } = props;

        let MenuComponent = menuKeys.map((key) => {
            let { user_info } = this.state;
            let Menu = MenuConfig[key];
            let IconMenu = Menu.icon;
            let classname = "menu-item-custom";
            if (page === Menu.name) {
                classname += " active";
            }

            if (user_info.partner_id === "1" && ["signer", "sender", "circle"].includes(key)) {
                return null;
            }

            if (key + "_role" in user_info && user_info[key + "_role"] === "0") {
                return null;
            }
            // console.log(Menu)

            return (
                <li key={Menu.text} className={classname}>
                    <Link to={Menu.link} className="avatar-custom">
                        <div className="icon-avatar">
                            <IconMenu />
                        </div>
                        <div className="text-avatar" >
                            <span>{t(Menu.text)}</span>
                        </div>
                    </Link>
                    <div className="document-count" >
                        <CountSignedDoc Menu={Menu}/>
                    </div>
                </li>
            );
        });

        return (
            <ul className="menu-custom">
                {MenuComponent}
            </ul>
        );
    }
}
export default withTranslation()(Menu);
