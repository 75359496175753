const EnvParams = {
    web: {
        domain: process.env.REACT_APP_PUBLIC_URL,
    },
    api: {
        domain: process.env.REACT_APP_API_DOMAIN,
        path: process.env.REACT_APP_API_PATH,
        media: process.env.REACT_APP_API_MEDIA,
        idp: process.env.REACT_APP_API_IDP,
    },
    cognito: {
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITYPOOLID,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
    }
};

export default EnvParams;
