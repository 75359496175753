import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
export default function LoadingList(props) {
	
	return (
		<Stack sx={{p:2}} spacing={2}>
            <Skeleton variant="text" width="100%"/>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Skeleton variant="rectangular" width="40%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="15%" height={50} />
                <Skeleton variant="rectangular" width="25%" height={50} />
            </Stack>
        </Stack>
	);
}
