import React from 'react';
import Layout from '../../Layout';
import MainMenu from './Menu';
import { withTranslation } from "react-i18next";
import Service from '../../Service';
import LoadingDashboard from '../LoadingDashboard.jsx';
import SVGIcons from '../Assets/SVGIcons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';

class Dashboard extends Layout {

	constructor(props) {
		super(props);
		this.state.page = "Dashboard";
		// this.state.loading = true;
		this.state.data = null;
		this.state.info = null;
		this.state.services = null;
		this.state.size = null;
		this.state.service_size = null;
		this.state.accounts = null;
		this.state.allServiceLabel = "";
	}

	Menu = () => {
		return (
			<MainMenu {...this.props} page={this.state.page} />
		);
	}

	componentDidMountExtends = () => {
		Service.getDashboardData().then(resp => {
			// console.log(resp);
			let { data, info, services, size, service_size, accounts } = this.state;
			data = resp;
			info = data.info;
			services = data.services;
			size = data.all_size;
			service_size = data.size;
			accounts = {};
			for (let acc of data.users) {
				accounts[acc.status] = acc;
			}
			this.setState({ data, info, services, size, service_size, accounts });
		}).catch(err => {
			//console.log(err.response);
		});
	}

	ServiceIcon = (props) => {
		let Icon = SVGIcons[props.code] || SVGIcons.help;
		return <Icon {...props} />;
	}

	ServiceDocumentSummary = () => {
		let { t } = this.props;
		let { data } = this.state;
		let { ServiceIcon } = this;
		let serviceItems = [];

		for (let service of data.documentCount) {
			serviceItems.push(
				<div key={`doc-summary-${service.function_type_id}`} className="list-stat-item active">
					<p className="label">
						<ServiceIcon code={service.function_type_code} className="icon" />
						<span>{t(`common:${service.function_type_label}`)}</span>
					</p>
					<p className="value">{service.documents_count}</p>
				</div>
			);
		}

		return serviceItems;
	}

	AllServiceLabel = () => {
		let { t } = this.props;
		let { services } = this.state;
		let { ServiceIcon } = this;
		// let serviceItems = [];
		let ServiceLabel = [];
		for (let service of services) {
			ServiceLabel.push(
				<p key={`service-${service.function_type_id}`} className="label active">
					<ServiceIcon code={service.function_type_code} className="icon" />
					<span>{t(`common:${service.function_type_label}`)}</span>
				</p>
			);
		}
		// ServiceLabel = ServiceLabel.join(", ");

		return ServiceLabel;
	}

	NumToString = (value) => {
		if (isNaN(parseFloat(value))) {
			return value;
		}

		let numOptions = {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		};

		return Number(value).toLocaleString(undefined, numOptions);
	}

	AllServiceSize = () => {
		let { t } = this.props;
		let { size, service_size } = this.state;
		let { ServiceIcon } = this;
		// let serviceItems = [];
		let serviceItems = [];

		serviceItems.push(
			<div key={`size-all`} className="list-stat-item active">
				<p className="label">
					<span>{t(`common:ALL-SIZE`)}</span>
				</p>
				<p className="value">
					{t('common:service.size.summary-unit', { size: this.NumToString(size.all) })}
				</p>
			</div>
		);

		for (let service of service_size) {
			serviceItems.push(
				<div key={`size-${service.function_type_id}`} className="list-stat-item active">
					<p className="label">
						<ServiceIcon code={service.code} className="icon" />
						<span>{t(`common:${service.label}`)}</span>
					</p>
					<p className="value">
						{t('common:service.size.summary-unit', { size: this.NumToString(service.size) })}
					</p>
				</div>
			);

		}
		// ServiceLabel = ServiceLabel.join(", ");

		return serviceItems;
	}

	render() {

		let { t } = this.props;
		let { data, info, services, accounts, UserInfo } = this.state;
		const { Main, AllServiceLabel, AllServiceSize, ServiceDocumentSummary } = this;

		if (data == null || UserInfo == null) {
			return (
				<Main>
					<LoadingDashboard />
				</Main>
			);
		}

		// console.log(accounts);

		let ServiceLabel = [];
		for (let service of services) {
			ServiceLabel.push(t(`common:${service.function_type_label}`));
		}
		ServiceLabel = ServiceLabel.join(", ");

		return (
			<Main>
				<div className="dashboard-container">

					<div className="container-fluid">
						<div className="item-dashboard company-info">
							<div className="title">
								<h2>{UserInfo.tenant_name}</h2>
							</div>
							<div className="tenant-info">
								<div className="tenant-info-item">
									<div className="label">{t("common:settings.tenant.list-tenant-id")}</div>
									<div className="value">{UserInfo.tenant_id}</div>
								</div>
								<div className="tenant-info-item">
									<div className="label">{t("common:general.contract-start-date")}</div>
									<div className="value">{info.contract_start_date}</div>
								</div>
								<div className="tenant-info-item">
									<div className="label">{t("common:general.contract-services")}</div>
									<div className="value">
										<AllServiceLabel />
									</div>
								</div>
								<div className="tenant-info-item">

									<div className="label">{t("common:general.contract-limit-person")}</div>
									<div className="value">
										<div>
											{info.limit_contract_person}
											<span className='text-unit'>{t("common:general.person-unit")}</span>
										</div>
										<Tooltip title="有効化されたカウント対象のユーザーです。「パスワード設定待ち」のユーザーは含まれません。" arrow>
											<div>
												<span className='tenant-info-item-small'>
													<AccountCircleIcon color="success" fontSize='small' />
												</span>
												<span className='tenant-info-item-small'>
													{t("common:general.active-user")}
												</span>
												<span className='tenant-info-item-small'>
													{accounts['active'].user_count}
												</span>
												<span className='text-unit'>{t("common:general.person-unit")}</span>
											</div>
										</Tooltip>
										<Tooltip title="無効化されたユーザーです。カウントの対象になりません。" arrow>
											<div>
												<span className='tenant-info-item-small'>

													<AccountCircleIcon color="disabled" fontSize='small' />
												</span>
												<span className='tenant-info-item-small'>
													{t("common:general.inactive-user")}
												</span>
												<span className='tenant-info-item-small'>
													{accounts['inactive'].user_count}
												</span>
												<span className='text-unit'>{t("common:general.person-unit")}</span>
											</div>
										</Tooltip>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="item-dashboard document-info">
							<div className="title">
								<h2>{t("common:service.document.summary")}</h2>
							</div>
							<div className="list-stat">
								<ServiceDocumentSummary />
							</div>
						</div>
					</div>

					<div className="container-fluid">
						<div className="item-dashboard document-info">
							<div className="title">
								<h2>{t("common:service.document.size")}</h2>
							</div>
							<div className="list-stat">
								<AllServiceSize />
							</div>
						</div>
					</div>

				</div>
			</Main>
		);

	}

}

export default withTranslation()(Dashboard);
