/**
 * @params t: function translate
 * @params translateName: translate name space
 * @params defaultText: default text translate if not found translate name
 */
export default function mappingTranslateDefault(t, translateName, defaultText) {
  return t(translateName) === translateName.split(":")[1]
    ? defaultText
    : t(translateName);
}
