import React from 'react';
import {
	Col,
	Form,
	InputGroup,
	ListGroup,
	Container,
	Tabs,
	Tab,
} from 'react-bootstrap';

import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

export default class ViewerSetting {

	static get key() {
		return "viewer-setting";
	}
	static get name() {
		return "ViewerSetting";
	}
	static get code() {
		return ViewerSetting.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = ViewerSetting.key;
		this.name = ViewerSetting.name;
		this.code = ViewerSetting.code;
		this.formData = {
			company_seal: "common:documents.host-setting.company-seal",
			verify_user: "common:documents.viewer-setting.verifier",
			viewers: "common:documents.viewer-setting.viewers",
		};
		this.initialValues = {
			verify_user: this.objMain.state.UserInfo.user_id,
			viewers: {},
		}

		this.objMain.setPageObject(this);
		if (this.objMain.state.creationType === "verification") {
			this.headerInfo = this.props.t("common:documents.viewer-setting.verification-header");
		}
	}

	formSubmitCallback = (ev) => {
		let { pageState, allHosts, creationType } = this.objMain.state;

		pageState[this.name].completed = true;

		if (creationType === "verification") {
			// console.log(this.formData.verify_user);
			let user_id = parseInt(this.formData.verify_user);
			let verifier = allHosts[user_id];
			verifier.self_certificate = false;
			verifier.certificate_type = 0;
			this.formData.signers = {}
			this.formData.signers[user_id] = verifier;

		}
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState: pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	};

	HostUserItems = () => {
		let { t } = this.props;
		let { allHosts } = this.objMain.state;
		let OptionItems = [];
		OptionItems.push(<option key="empty" value="">{t(`common:documents.viewer-setting.verifier`)}</option>);
		for (let i in allHosts) {
			let user = allHosts[i];
			OptionItems.push(<option key={user.id} value={user.id}>{`${user.name}`}</option>);
		}
		return OptionItems;
	}

	onVerifierChange = (ev) => {
		// let { formData } = this;
		let { allHosts, formData } = this.objMain.state;
		// console.log(formData);
		// let availableUsers = null;
		// let keyMap = this.objMapping(user_type);
		// let availableUsers = this.state[keyMap.available];
		// onVerifierChange
		// console.log(ev.target.value);
		let user_id = parseInt(ev.target.value);
		let verifier = allHosts[user_id];
		verifier.self_certificate = false;
		verifier.certificate_type = 0;
		formData.signers = {}
		formData.signers[user_id] = verifier;
		this.objMain.updateState({ formData });
	}

	VerificationForm = () => {
		// let { t } = this.props;
		let { FieldControl } = this.objMain;
		let { creationType } = this.objMain.state;

		// console.log(creationType);
		if (creationType === "verification") {
			return (
				<Form.Row>
					{FieldControl({
						name: "verify_user",
						as: "select",
						xs: 12, md: 6,
						required: true,
						validate: "true",
						children: this.HostUserItems(),
					})}
				</Form.Row>
			);
		} else {
			return null;
		}

	}

	render() {

		let { t } = this.props;
		let { searchAvailableViewerGroups, searchViewerText, searchViewerGroupsText } = this.objMain.state;
		let { AvailableUser, SelectedUsers, MultipleUserSelectionBox, SearchInputChange, SearchViewerGroupsInputChange } = this.objMain;
		let { VerificationForm } = this;
		let { viewers } = this.formData;
		let AvailableGroupProps = {
			users: searchAvailableViewerGroups,
			user_type: "group-viewer",
			group_id: "group",
			by_group: false,
		};

		let AvailableUserProps = {
			users: viewers,
			user_type: "viewer",
		};

		return (

			<Container fluid="md" className={this.key + "-container form-wrapper container-fluid"} >

				<Form
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="container-fluid full-form"
				>

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					<div className="form-body flex-column">

						<VerificationForm />

						<Form.Row className="flex-full">

							<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
								<Form.Label>{t("common:documents.viewer-setting.viewers-list")}</Form.Label>
								<SelectedUsers users={viewers} user_type="viewer" />
							</Form.Group>

							<Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
								<Form.Label>{t("common:documents.host-setting.available-viewers-list")}</Form.Label>


								<Tabs defaultActiveKey="veiwer-by-user" transition={false} id="viewers-selection">

									<Tab eventKey="veiwer-by-user" title={t("common:documents.host-setting.viewer-by-user")}>
										<InputGroup className="form-control">
											<div className="search-input-container">
												<TextField
													className="search-input-field"
													placeholder={t('common:documents.general.text-input-placeholder')}
													onChange={(e) => SearchInputChange(AvailableUserProps, e)}
													defaultValue={searchViewerText}
													sx={{
														"& fieldset": { border: 'none' },
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment>
																<IconButton>
																	<SearchIcon />
																</IconButton>
															</InputAdornment>
														),
														style: {
															height: 40,
															paddingLeft: 5,
															fontSize: 15,
														},
													}}
												/>
											</div>
											<AvailableUser users={viewers} user_type="viewer" />
										</InputGroup >
									</Tab>

									<Tab eventKey="viewer-by-group" title={t("common:documents.host-setting.viewer-by-group")}>
										<InputGroup className="form-control">
											<div className="search-input-container">
												<TextField
													className="search-input-field"
													placeholder={t('common:documents.general.text-input-placeholder')}
													onChange={(e) => SearchViewerGroupsInputChange(AvailableGroupProps, e)}
													defaultValue={searchViewerGroupsText}
													sx={{
														"& fieldset": { border: 'none' },
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment>
																<IconButton>
																	<SearchIcon />
																</IconButton>
															</InputAdornment>
														),
														style: {
															height: 40,
															paddingLeft: 5,
															fontSize: 15,
														},
													}}
												/>
											</div>
											<ListGroup as="ul" className="user-selection-box">
												<MultipleUserSelectionBox {...AvailableGroupProps} />
											</ListGroup>
										</InputGroup >
									</Tab>
								</Tabs>
							</Form.Group>

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'
									endIcon={<ChevronRightIcon />}
								>
									<span className="btn-label">{t('common:documents.general.next')}</span>
								</Button>
							</div>
						</div>

					</div>

				</Form>

			</Container>

		);

	}

}
