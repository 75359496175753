

import { withTranslation } from "react-i18next";

import DocumentStep from '../../Config/Documents';
import Layout from '../../Layout';
import Service from '../../Service';
import Error from '../Error';
import OtpDialog from '../Auth/OtpDialog';
import { Auth } from "aws-amplify";
// import SystemService from '../../Service/System';
// import Service from '../../Service';
// import PDFViewer from '../Fragments/PDFViewer';
import {
	ListGroup
} from 'react-bootstrap';

import {
	faAngleDoubleLeft, faAngleLeft,
	// faUser,
	// faUsers,
	faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PeopleIcon from '@mui/icons-material/People';
import ProfileAvatar from '../../Layout/ProfileAvatar';
import AuthService from '../../Service/Auth';

class Documents extends Layout {
	constructor(props) {
		super(props);

		this.state.openOtpDialog = false;
		this.state.isVerifySoftware = false;
		this.state.isVerifyEmail = false;

		this.state["PDFFiles"] = {};
		this.state.imprints = {};
		this.state.pagesImprints = {};
		this.state.docAttr = [];
		this.state.fileName = null;
		this.state.selectedFile = null;
		this.state.CurrentPDFElement = null;
		this.state.previewScale = 100;
		this.state.userGroupCollapse = "";
		this.state.allHosts = {};
		this.state.allGuests = {};
		this.state.allHostGroups = {};
		this.state.allHostGroupWithSeal = {};
		this.state.allViewUsers = {};
		this.state.allViewGroups = {};
		this.state.availableSigners = {};
		this.state.searchAvailableSigners = {};
		this.state.searchSignerText = "";
		this.state.availableGuests = {};
		this.state.searchAvailableGuests = {};
		this.state.searchGuestsText = "";
		this.state.availableViewerUsers = {};
		this.state.searchAvailableViewerUsers = {};
		this.state.searchViewerText = "";
		this.state.availableViewerGroups = {};
		this.state.searchAvailableViewerGroups = {};
		this.state.searchViewerGroupsText = "";
		this.state.availableHostGroups = {};
		this.state.searchAvailableHostGroups = {};
		this.state.CertificateType = {};
		this.state.AttachFiles = {};
		this.state.counterPartyNameList = [];
		this.state.currentPage = 0;
		this.state.PDFLoadSuccess = false;
		this.state.creationType = props.type || props.match.params.type || "sender";
		this.state.creationMode = props.mode || props.match.params.mode || "single";
		// this.state.function_type = props.type || props.match.params.type || "sender";
		this.state.document_id = props.document_id || props.match.params.document_id || null;

		// console.log(props);
		this.state.translateKeyPrefix = "common:documents.";
		this.state.DocumentStep = new DocumentStep();
		this.state.processStep = this.state.DocumentStep.getStep(this.state.creationType, this.state.creationMode);
		this.state.processStepNameString = {};
		for (let i in this.state.processStep) {
			let step = this.state.processStep[i];
			this.state.processStepNameString[step.name] = step.page;
			// this.state.pageState[step.name] = {
			// 	completed: false,
			// };
		}
		this.state.processStepIndex = Object.values(this.state.processStepNameString);
		this.state.activePage = this.state.processStepIndex[0];
		this.state.tempPDFPassword = null;
		this.state.keepPDFPassword = null;
		this.state.PageError = false;

		// let firstPageName = Object.keys(this.state.processStepNameString)[0];

		// console.log(this.state.activePage);
		// console.log(this.state.creationType);
		// console.log(props);
		// let initData = this.initialTempFormData();

		this.state.originalFormData = {};
		// this.state.formData = initData.formData;
		// this.state.allHosts = initData.allHosts;
		// this.state.allGuests = initData.allGuests;
		// this.state.availableSigners = initData.availableSigners;
		// this.state.availableGuests = initData.availableGuests;
		// this.state.availableViewers = initData.availableViewers;
		// this.changePage(null, this.state.activePage);

		this.state.PageProps = new this.state.activePage(this);
		this.state.loading = true;
		// this.setLocationHash(this.state.PageProps.name + "-new");

		this.state.Currencies = [];
		this.state.PageData = {};
		this.state.FileboxStorage = [];
		this.state.DocumentSavingLog = [];
		this.state.preservationRequire = [];
		// this.GetPageResources();
		// if (this.state.document_id) {
		this.setLocationHash(this.state.activePage.name);
		// } else {
		// 	this.setLocationHash("new");
		// }
		/* Temporary set fields value */
	}


	buildImprintProperty = (user, group, type, page, position) => {
		return {
			id: group + "-" + user.id,
			userID: user.id,
			type: type,
			group: group,
			page: page,
			top: position.top,
			left: position.left,
			defaultWidth: position.width,
			defaultHeight: position.height,
			user: user,
		};
	}

	GetPageResources = async () => {
		let {
			formData, originalFormData, pageState,
			creationType, document_id, processStepNameString,
			allHosts, allGuests, allHostGroups, allHostGroupWithSeal,
			availableSigners, searchAvailableSigners, availableGuests, searchAvailableGuests, availableViewerUsers,
			searchAvailableViewerUsers, availableViewerGroups, searchAvailableViewerGroups, availableHostGroups, searchAvailableHostGroups,
			CertificateType, Currencies, imprints, counterPartyNameList, allViewUsers, allViewGroups,
			preservationRequire,
			//  PageError,
		} = this.state;

		let allowType = ["signer", "sender", "circle", "verification"];
		if (!allowType.includes(creationType)) {
			this.setState({ PageError: true });
			return;
		}

		let PageData = await Service.GetPageData(creationType);
		let { hosts, guests, hosts_groups, doc_types, cert_types, counter_party, view_users, view_groups, preservation_require, } = PageData;
		// let Currencies = await SystemService.GetCurrenciesList();
		// console.log(PageData);
		for (let i in hosts) {
			let user = hosts[i];
			// console.log(user);
			user.id = user.user_id;
			user.name = `${user.family_name} ${user.first_name}`;
			allHosts[user.id] = user;
		}
		for (let i in hosts_groups) {
			let group = hosts_groups[i];
			group.id = group.user_id;
			group.name = group.full_name;
			allHostGroups[group.id] = group;
			if (group.has_company_seal === 1) {
				if (group.members) {
					let memberList = group.members;
					group.members = [];
					for (let id of memberList) {
						group.members.push(allHosts[id]);
					}
				}
				allHostGroupWithSeal[group.id] = group;
			}
		}
		// console.log(allHostGroupWithSeal);
		for (let i in guests) {
			let user = guests[i];
			user.id = user.user_id;
			user.name = `${user.family_name} ${user.first_name}`;
			allGuests[user.id] = user;
		}
		for (let i in cert_types) {
			let cert = cert_types[i];
			CertificateType[cert.id] = cert;
		}
		for (let i in PageData.currencies) {
			let currency = PageData.currencies[i];
			Currencies.push(currency);
		}
		for (let i in counter_party) {
			let cnt_party = counter_party[i];
			counterPartyNameList.push(cnt_party.counter_party_name);
		}
		for (let i in preservation_require) {
			let cnt_require = preservation_require[i];
			preservationRequire.push(cnt_require.name);
		}
		for (let i in view_users) {
			let view_user = view_users[i];
			// console.log(user);
			view_user.id = view_user.user_id;
			view_user.name = `${view_user.family_name} ${view_user.first_name}`;
			allViewUsers[view_user.id] = view_user;
		}
		for (let i in view_groups) {
			let view_group = view_groups[i];
			view_group.id = view_group.user_id;
			view_group.name = view_group.full_name;
			allViewGroups[view_group.id] = view_group;
		}

		availableSigners = JSON.parse(JSON.stringify(allHosts));
		availableGuests = JSON.parse(JSON.stringify(allGuests));
		// availableViewerUsers = JSON.parse(JSON.stringify(allHosts));
		// availableViewerGroups = JSON.parse(JSON.stringify(allHostGroups));
		availableViewerUsers = JSON.parse(JSON.stringify(allViewUsers));
		availableViewerGroups = JSON.parse(JSON.stringify(allViewGroups));
		availableHostGroups = JSON.parse(JSON.stringify(allHostGroupWithSeal));

		// console.log(document_id);
		if (document_id) {
			let docInfo = await Service.getDocumentInfo(document_id).catch(err => {
				//console.log(err.response);
			});
			// console.log(docInfo);
			if (docInfo === false) {
				this.setState({ PageError: true });
				return;
			}
			if (![0, 5].includes(docInfo.doc_status)) {
				this.setState({ PageError: true });
				return;
			}
			if (docInfo.function_code !== creationType) {
				this.setState({ PageError: true });
				return;
			}

			// if (docInfo) {
			formData.document_type = docInfo.document_type_id;
			formData.preservation_require = docInfo.preservation_require || "";
			formData.preservation_require = docInfo.preservation_require;
			formData.doc_title = docInfo.title;
			formData.amount = docInfo.amount;
			formData.currency = docInfo.currency;
			formData.sign_level = docInfo.sign_level_code;
			formData.display_imprint = true;
			formData.contract_date = docInfo.contract_date || "";
			formData.expiry_date = docInfo.expiry_date || "";
			formData.effective_date = docInfo.effective_date || "";
			formData.received_date = docInfo.received_date || "";
			formData.document_create_date = docInfo.document_create_date || "";
			formData.counter_party_name = docInfo.counter_party_name || "";
			formData.dateofnoti = docInfo.dateofnoti || "";
			if (creationType === "sender") {
				formData.supplier_counter_party_name = docInfo.counter_party_name || "";
			}
			formData.product = docInfo.product_name || "";
			formData.reference = docInfo.reference || "";
			formData.filebox_shortcut = docInfo.filebox_path || "";
			formData.use_digital_certificate = docInfo.use_digital_cert ? true : false;
			formData.content = docInfo.content || "";
			formData.doc_attributes = [];
			for (let key in docInfo.attributes) {
				formData.doc_attributes.push({
					key: key,
					value: docInfo.attributes[key],
				});
			}
			formData.signers = {};
			formData.guests = {};
			formData.viewers = {};
			imprints = {};
			for (let signer of docInfo.signers) {
				let group = "signers";
				if (signer.user_type === 1) {
					group = "guests";
					if (allGuests[signer.id]) {
						formData.guests[signer.id] = JSON.parse(JSON.stringify(allGuests[signer.id]));
						formData.guests[signer.id].self_certificate = signer.self_certificate ? true : false;
						formData.guests[signer.id].certificate_type = signer.certificate_type_id;
						delete availableGuests[signer.id];
					}
				} else if (signer.user_type === 2) {
					group = "group";
					if (allHostGroupWithSeal[signer.id]) {
						formData.signers[signer.id] = JSON.parse(JSON.stringify(allHostGroupWithSeal[signer.id]));
						delete availableHostGroups[signer.id];
					}
				} else {
					if (allHosts[signer.id]) {
						formData.signers[signer.id] = JSON.parse(JSON.stringify(allHosts[signer.id]));
						formData.signers[signer.id].self_certificate = signer.self_certificate ? true : false;
						formData.signers[signer.id].certificate_type = signer.certificate_type_id;
						delete availableSigners[signer.id];
						// formData.use_digital_certificate = signer.self_certificate;
					}
				}
				// console.log(signer.id, signer.sign_details);
				if (signer.sign_details) {
					if (signer.sign_details.signature) {
						let signature = signer.sign_details.signature;
						if (signature.position) {
							let fileKey = signature.file;
							let id = group + "-" + signer.id;
							if (!imprints.hasOwnProperty(fileKey)) {
								imprints[fileKey] = {};
							}

							imprints[fileKey][id] = this.buildImprintProperty(signer, group, "signature", signature.page, signature.position);
						}
					}
				}
			}

			if (docInfo.receivers) {
				for (let user of Object.values(docInfo.receivers)) {
					if (allGuests[user.id]) {
						formData.guests[user.id] = JSON.parse(JSON.stringify(allGuests[user.id]));
						delete availableGuests[user.id];
					}
				}
			}

			formData.viewers = {};
			for (let viewer of docInfo.viewers) {
				if (viewer.user_type === 2) {
					if (allViewGroups[viewer.id]) {
						formData.viewers[viewer.id] = JSON.parse(JSON.stringify(allViewGroups[viewer.id]));
						delete availableViewerGroups[viewer.id];
					}
				} else {
					if (allViewUsers[viewer.id]) {
						formData.viewers[viewer.id] = JSON.parse(JSON.stringify(allViewUsers[viewer.id]));
						delete availableViewerUsers[viewer.id];
					}
				}
			}
			formData.certificate_type = docInfo.certificate_type || 1;
			formData.company_seal = false;

			// console.log(this.state);
			pageState = {};

			for (let i in processStepNameString) {
				pageState[i] = { completed: true };
			}

			let { PDFFiles, PDFFile, selectedFile } = this.state;
			PDFFiles = docInfo.files;
			// for (let key in PDFFiles) {
			// 	Service.getDocumentFile(docInfo.document_id, key).then(resp={
			// 		let fileObject = await 
			// 		PDFFiles[key].file = fileObject;
			// 	}).catch(err => {
			// 		console.log(err.resonse);
			// 	});
			// }
			if (Object.keys(PDFFiles).length > 0) {
				selectedFile = Object.keys(docInfo.files)[0];
				PDFFile = PDFFiles[selectedFile];
				for (let i in PDFFiles) {
					PDFFiles[i].file = PDFFiles[i].url;
				}
			}


			// imprint[id] = {
			// };
			// console.log(imprints);

			this.setState({ PDFFiles, PDFFile, selectedFile, imprints });
			// }
			// document_id = null;
			// console.log(formData);
			// console.log(pageState);
		}

		if (!formData.document_type && doc_types.length > 0) {
			formData.document_type = doc_types[0].id;
		}
		if (!formData.currency) {
			formData.currency = "JPY";
		}
		if (!formData.certificate_type) {
			formData.certificate_type = 1;
		}
		if (!formData.signers) {
			formData.signers = {};
		}
		if (!formData.guests) {
			formData.guests = {};
		}
		if (!formData.viewers) {
			formData.viewers = {};
		}

		if (creationType === "signer") {
			formData.function_type = 1;
			formData.sign_level = formData.sign_level || this.checkSignLevel(formData.signers);
			if (formData.sign_level === 0) {
				formData.use_digital_certificate = false;
			} else if (formData.sign_level === 2) {
				formData.use_digital_certificate = true;
			}
		} else if (creationType === "sender") {
			formData.function_type = 2;
		} else if (creationType === "circle") {
			formData.function_type = 3;
			formData.sign_level = formData.sign_level || this.checkSignLevel(formData.signers);
			if (formData.sign_level === 0) {
				formData.use_digital_certificate = false;
			} else if (formData.sign_level === 2) {
				formData.use_digital_certificate = true;
			}
		} else if (creationType === "verification") {
			formData.function_type = 4;
			if (Object.keys(formData.signers).length > 0) {
				formData.verify_user = Object.keys(formData.signers)[0];
			}
			if (!formData.preservation_require) {
				formData.preservation_require = "2";
			}
		}

		originalFormData = JSON.parse(JSON.stringify(formData));
		searchAvailableSigners = { ...availableSigners };
		searchAvailableGuests = { ...availableGuests };
		searchAvailableViewerUsers = { ...availableViewerUsers };
		searchAvailableViewerGroups = { ...availableViewerGroups };
		searchAvailableHostGroups = { ...availableHostGroups };

		searchAvailableSigners = { ...availableSigners };
		searchAvailableGuests = { ...availableGuests };
		searchAvailableViewerUsers = { ...availableViewerUsers };
		searchAvailableViewerGroups = { ...availableViewerGroups };
		searchAvailableHostGroups = { ...availableHostGroups };

		this.setState({ formData, originalFormData, Currencies, counterPartyNameList, preservationRequire, PageData, pageState, loading: false });
		this.setState({ allHosts, allGuests, allHostGroups, CertificateType, allViewUsers, allViewGroups });
		this.setState({ availableSigners, availableGuests, availableViewerUsers, availableViewerGroups, availableHostGroups });
		this.setState({ searchAvailableSigners, searchAvailableGuests, searchAvailableViewerUsers, searchAvailableViewerGroups, searchAvailableHostGroups });
		// console.log(allHosts);
	}

	checkSignLevel = (signers) => {
		let cert_group = [];
		let self_cert = true;
		let users = [];
		for (let id in signers) {
			let signer = signers[id];

			if (signer.self_certificate === false) {
				self_cert = false;
			}

			if (!cert_group.includes(signer.certificate_type)) {
				cert_group.push(signer.certificate_type)
			}

			users.push(id);
		}

		let sign_level = 0; // Soft
		if (self_cert && users.length > 0) {
			sign_level = 2; // Strong
		} else if (cert_group.length > 1) {
			sign_level = 1; // Mixed
		}

		return sign_level;
	}

	initialTempFormData() {
		return {
			formData: {
				"document_type": 1,
				"doc_title": "Document Title",
				"amount": 1234234.234,
				"currency": "JPY",
				"display_imprint": true,
				"contract_date": "2021-07-16",
				"expiry_date": "2021-07-30",
				"product": "Product Name",
				"reference": "Ref. Document",
				"filebox_shortcut": "/deep_on_root/1/2/3/4/5/6",
				"doc_attributes": [
					{
						"key": "Note1",
						"value": "1-Note-value"
					},
					{
						"key": "Note2",
						"value": "2-Note-value"
					},
					{
						"key": "Note3",
						"value": "3-Note-value"
					}
				],
				"signers": {
					"26": {
						"user_id": "26",
						"email": "demo@mail.example",
						"family_name": "Example",
						"first_name": "Demo",
						"groups": [
							{
								"id": "34",
								"name": "AnotherGroup"
							},
							{
								"id": "32",
								"name": "Test Update"
							}
						],
						"profile_image": null,
						"id": "26",
						"name": "Example Demo"
					},
					"30": {
						"user_id": "30",
						"email": "wasan@s-s-c.co.th",
						"family_name": "sdfsdf",
						"first_name": "sdfsdf",
						"groups": [],
						"id": "30",
						"name": "sdfsdf sdfsdf"
					}
				},
				"guests": {
					"45": {
						"user_id": "45",
						"email": "Guest1@guest.com",
						"family_name": "f-Guest1",
						"first_name": "Guest1",
						"groups": [
							{
								"id": "58",
								"name": "Guest Group 1"
							}
						],
						"profile_image": null,
						"company_name": null,
						"id": "45",
						"name": "f-Guest1 Guest1"
					},
					"46": {
						"user_id": "46",
						"email": "Guest2@guest.com",
						"family_name": "f-Guest2",
						"first_name": "Guest2",
						"groups": [
							{
								"id": "58",
								"name": "Guest Group 1"
							}
						],
						"profile_image": null,
						"company_name": "Guest Company Co., Ltd",
						"id": "46",
						"name": "f-Guest2 Guest2"
					}
				},
				"viewers": {
					"33": {
						"user_id": 33,
						"full_name": "ParentGroupName",
						"user_type": 2,
						"members_count": 1,
						"has_company_seal": 1,
						"id": 33,
						"name": "ParentGroupName"
					},
					"34": {
						"user_id": 34,
						"full_name": "AnotherGroup",
						"user_type": 2,
						"members_count": 1,
						"has_company_seal": 1,
						"id": 34,
						"name": "AnotherGroup"
					},
					"49": {
						"user_id": 49,
						"full_name": "TestGroupName",
						"user_type": 2,
						"members_count": 0,
						"has_company_seal": 1,
						"id": 49,
						"name": "TestGroupName"
					},
					"50": {
						"user_id": 50,
						"full_name": "TestGroupName",
						"user_type": 2,
						"members_count": 0,
						"has_company_seal": 1,
						"id": 50,
						"name": "TestGroupName"
					}
				},
				"certificate_type": 1,
				"use_digital_certificate": true,
				"company_seal": false
			},
		};
	}

	changePage = (ev, PageClass) => {
		this.setState({ activePage: PageClass });
		let PageProps = new PageClass(this);
		this.setState({ PageProps: PageProps });
		this.setLocationHash(PageProps.name);
	}

	// componentDidMount() {
	componentDidMountExtends() {
		// console.log("Mount", this.state);
		this.GetPageResources();
		let { history } = this.props;
		// console.log("this.props", this.props);
		// console.log("history", history);
		this.unlisten = history.listen((location, action) => {
			// console.log("on route change", location, action);
			let { pageState, processStepNameString } = this.state;
			if (location.hash) {
				let pageName = location.hash.substr(1);
				let PageProps = this.state.activePage;
				if (Object.keys(pageState).includes(pageName)) {
					PageProps = processStepNameString[pageName];
				}
				this.changePage(null, PageProps);
			} else {
				console.log(history);
				if (history.action === "POP") {
					history.goBack();
				}
				this.unlisten();
			}
		});

		// window.addEventListener("beforeunload", this.onUnload);
		window.onbeforeunload = (ev) => {
			// ev.preventDefault();
			// ev.returnValue = 'Are you sure?';
			return "test";
		};
		// this.getCurrentUserAttributes();

	}

	objMapping = (type) => {
		let objMap = {
			"host": {
				"form": "signers",
				"available": "availableSigners",
				"searchAvailable": "searchAvailableSigners",
				"searchText": "searchSignerText",
			},
			"guest": {
				"form": "guests",
				"available": "availableGuests",
				"searchAvailable": "searchAvailableGuests",
				"searchText": "searchGuestsText",
			},
			"viewer": {
				"form": "viewers",
				"available": "availableViewerUsers",
				"searchAvailable": "searchAvailableViewerUsers",
				"searchText": "searchViewerText",
			},
			"group-viewer": {
				"form": "viewers",
				"available": "availableViewerGroups",
				"searchAvailable": "searchAvailableViewerGroups",
				"searchText": "searchViewerGroupsText",
			},
			"group-signer": {
				"form": "signers",
				"available": "availableHostGroups",
				"searchAvailable": "searchAvailableHostGroups",
			},
		};

		return objMap[type];
	}

	/** */
	assignUser = (ev, user_id, user_type, by_group = false) => {
		let { formData } = this.state;
		// console.log(formData);
		// let availableUsers = null;
		let keyMap = this.objMapping(user_type);
		let availableUsers = this.state[keyMap.available];
		let searchAvailableUsers = this.state[keyMap.searchAvailable];

		if (by_group) {
			for (let i in availableUsers) {
				let user = availableUsers[i];
				let groups = [];
				for (let group of user.groups) {
					groups.push(group.id);
				}
				if (groups.includes(user_id)) {
					// formData[keyMap.form][user.id] = JSON.parse(JSON.stringify(user));
					formData[keyMap.form][user.id] = user;
					formData[keyMap.form][user.id].self_certificate = formData.use_digital_certificate;
					formData[keyMap.form][user.id].certificate_type = formData.use_digital_certificate ? formData.certificate_type : 0;
					formData[keyMap.form][user.id].sign_seq = Object.keys(formData[keyMap.form]).length;
					delete availableUsers[i];
					delete searchAvailableUsers[i];
				}
			}
		} else {
			// formData[keyMap.form][user_id] = JSON.parse(JSON.stringify(availableUsers[user_id]));
			formData[keyMap.form][user_id] = availableUsers[user_id];
			formData[keyMap.form][user_id].self_certificate = formData.use_digital_certificate;
			formData[keyMap.form][user_id].certificate_type = formData.use_digital_certificate ? formData.certificate_type : 0;
			formData[keyMap.form][user_id].sign_seq = Object.keys(formData[keyMap.form]).length;
			delete availableUsers[user_id];
			delete searchAvailableUsers[user_id];
		}
		this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers });
		this.setState({ formData });
	}

	unAssignUser = (ev, user, user_type) => {
		let { formData } = this.state;
		if (user.user_type === 2) {
			if (user_type === "host") {
				user_type = "group-signer";
			} else if (user_type === "viewer") {
				user_type = "group-viewer";
			}
			//  else {
			// 	user_type = "group";
			// }
		}

		let keyMap = this.objMapping(user_type);
		let availableUsers = this.state[keyMap.available];
		let searchAvailableUsers = this.state[keyMap.searchAvailable];
		// console.log(keyMap);

		// availableUsers[user.id] = JSON.parse(JSON.stringify(formData[keyMap.form][user.id]));
		availableUsers[user.id] = formData[keyMap.form][user.id];
		searchAvailableUsers[user.id] = formData[keyMap.form][user.id];
		delete formData[keyMap.form][user.id];
		this.setState({ formData });
		this.setState({ [keyMap.available]: availableUsers, [keyMap.searchAvailable]: searchAvailableUsers });

	}

	UserSelectionBox = (props) => {
		let { user, user_type, info, children, by_group } = props;
		let dataset = {};
		let BtnIcon = faAngleLeft;
		// let by_group = true;
		if (["group-signer", "group-viewer"].includes(user_type)) {
			by_group = false;
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		} else if (by_group !== undefined) {
			// by_group = by_group;
			// do nothing
		} else {
			by_group = true;
		}

		if (by_group) {
			BtnIcon = faAngleDoubleLeft;
			dataset["data-group"] = props.group_id;
		}

		let OptionalAction = (props) => { return null; };
		if (props.hasOwnProperty("optional") && props.optional) {
			OptionalAction = props.optional;
		}
		// console.log(props.optional);
		// console.log(OptionalAction);

		// faExclamationTriangle
		return (
			<ListGroup.Item as="li" className="user-info" {...dataset} >
				<div className="action">
					<button type="button" className="btn-add-user" onClick={ev => { this.assignUser(ev, user.id, user_type, by_group) }}>
						<FontAwesomeIcon icon={BtnIcon} />
					</button>
				</div>
				<div className="info">
					<div className={info.class} onClick={info.click}>
						<div className="user-info-detail">
							<div className="top-info">
								<span>{info.top}</span>
							</div>
							<div className="bottom-info">
								<span>{info.bottom}</span>
							</div>
						</div>
						<div className="user-info-optional">
							<OptionalAction user={user} />
						</div>
					</div>
					{children}
				</div>
			</ListGroup.Item>
		);
	}

	MultipleUserSelectionBox = (props) => {
		let { t } = this.props;
		let { users, user_type, group_id, by_group, optional } = props;
		let { UserSelectionBox } = this;
		let UsersBoxElements = [];
		// console.log(UsersBoxElements);

		for (let i in users) {
			let user = users[i];
			let info = {
				class: "info-container",
				top: `${user.name} ${user.company_name || ""}`,
				bottom: user.email,
			}

			if (["group-signer", "group-viewer"].includes(user_type)) {
				info.top = user.name;
				info.bottom = t("common:general.total-members") + " " + user.members_count;
			}
			// if (group_id !== undefined) {
			// 	info = {
			// 		class: "info-container group",
			// 		top: user.name,
			// 		bottom: t("common:general.total-members") + " " + user.members_count,
			// 	};
			// }

			let userProps = {
				user: user,
				user_type: user_type,
				group_id: group_id,
				optional: optional,
				by_group: by_group,
				info: info,
			}
			UsersBoxElements.push(<UserSelectionBox key={group_id + "-" + i}  {...userProps} />);
		}

		return UsersBoxElements;
	}

	UserSelectionGroup = (props) => {
		let { users, group_id, show, user_type, by_group, optional } = props;
		let { MultipleUserSelectionBox } = this;
		let userRemain = Object.keys(users).length;
		let collapseClass = ["custom-collapse"];
		if (show === true) {
			collapseClass.push("show");
		}

		let userProps = {
			users: users,
			user_type: user_type,
			group_id: group_id,
			optional: optional,
			by_group: by_group,
		}

		return (
			<ListGroup as="ul" className={collapseClass} style={{ height: (userRemain * 54) + "px" }}>
				<MultipleUserSelectionBox {...userProps} />
			</ListGroup>
		);
	}

	setUserGroupCollapse = (ev) => {
		let parent = ev.target.closest(".user-info");
		if (parent.querySelector(".custom-collapse").classList.contains("show")) {
			this.setState({ userGroupCollapse: "" });
		} else {
			this.setState({ userGroupCollapse: parseInt(parent.dataset.group) });
		}
	}

	AvailableUser = (props) => {
		let { t } = this.props;
		let { users, user_type, optional } = props;
		let keyMap = this.objMapping(user_type);
		let searchAvailableUsers = this.state[keyMap.searchAvailable];
		let { formData, PageProps } = this.state;

		if (!searchAvailableUsers) {
			return null;
		}

		let groupBy = [];
		let groupInfo = {};
		let { userGroupCollapse } = this.state;
		let { UserSelectionGroup, UserSelectionBox } = this;
		let assigned = Object.keys(users);
		let noGroup = {}

		let strongSign = false;
		if (parseInt(formData.sign_level) === 2 || formData.use_digital_certificate) {
			strongSign = true;
		}

		for (let i in searchAvailableUsers) {
			let user = searchAvailableUsers[i];

			if (strongSign && [1, 2, 3].includes(parseInt(formData.function_type)) && parseInt(user.user_type) === 0 && PageProps.key === "host-setting") {
				if (!user.available_certs || !user.available_certs.includes(parseInt(formData.certificate_type))) {
					continue;
				}
			}

			if (!assigned.includes(i)) {
				if (user.groups.length > 0) {
					for (let group of user.groups) {
						if (!groupBy.hasOwnProperty(group.id)) {
							groupBy[group.id] = {};
							groupInfo[group.id] = group;
							groupInfo[group.id].user_type = 2;
						}
						// groupBy[group.id][i] = JSON.parse(JSON.stringify(user));
						groupBy[group.id][i] = user;
					}
				} else {
					// noGroup[i] = JSON.parse(JSON.stringify(user));
					noGroup[i] = user;
				}
			}
		}

		let UserGroupElements = [];
		for (let i in groupBy) {
			let group = groupInfo[i];
			let users = groupBy[group.id];
			let Remaining = Object.keys(users).length;

			group.members = users;
			let groupProps = {
				user: group,
				user_type: user_type,
				group_id: group.id,
				optional: optional,
				by_group: true,
				info: {
					class: "info-container group",
					click: this.setUserGroupCollapse,
					top: group.name,
					bottom: t("common:general.member-remaining") + " " + Remaining,
				}
			}
			let userProps = {
				users: users,
				user_type: user_type,
				group_id: group.id,
				optional: optional,
				by_group: false,
				show: (parseInt(userGroupCollapse) === parseInt(group.id)),
			}

			UserGroupElements.push(
				<UserSelectionBox key={group.id} {...groupProps}>
					<UserSelectionGroup {...userProps} />
				</UserSelectionBox>
			);
		}

		for (let i in noGroup) {
			let user = noGroup[i];
			let userProps = {
				user: user,
				user_type: user_type,
				group_id: "no-group",
				optional: optional,
				by_group: false,
				info: {
					top: `${user.name} ${user.company_name || ""}`,
					bottom: user.email,
					class: "info-container",
				}
			}
			UserGroupElements.push(<UserSelectionBox key={user.id} {...userProps} />);
		}

		return (
			<ListGroup as="ul" className="user-selection-box">
				{UserGroupElements}
			</ListGroup>
		);
	}

	SearchViewerGroupsInputChange = (props, e) => {
		let { user_type } = props;
		let keyMap = this.objMapping(user_type);
		let availableGroups = this.state[keyMap.available];
		if (e.target.value === '') {
			this.setState({ [keyMap.searchAvailable]: { ...availableGroups }, [keyMap.searchText]: e.target.value });
		}
		else {
			let searchAvailableGroups = {};
			for (const key in availableGroups) {
				if (availableGroups[key].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
					searchAvailableGroups[key] = availableGroups[key];
				}
			}
			this.setState({ [keyMap.searchAvailable]: { ...searchAvailableGroups }, [keyMap.searchText]: e.target.value });
		}
	}

	SearchInputChange = (props, e) => {
		let { user_type } = props;
		let keyMap = this.objMapping(user_type);
		let availableUsers = this.state[keyMap.available];
		console.log(keyMap.available);
		if (e.target.value === '') {
			this.setState({ [keyMap.searchAvailable]: { ...availableUsers }, [keyMap.searchText]: e.target.value });
		}
		else {
			let searchAvailableUsers = {};
			for (const key in availableUsers) {
				if (keyMap.available === 'availableGuests' && availableUsers[key].company_name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
					searchAvailableUsers[key] = availableUsers[key];
					continue;
				}
				if (availableUsers[key].email.indexOf(e.target.value) > -1 || availableUsers[key].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
					searchAvailableUsers[key] = availableUsers[key];
				} else {
					if (availableUsers[key].groups.length > 0) {
						for (let i = 0; i < availableUsers[key].groups.length; i++) {
							if (availableUsers[key].groups[i].name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
								if (searchAvailableUsers.hasOwnProperty(key)) {
									searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
								} else {
									searchAvailableUsers[key] = { ...availableUsers[key] };
									searchAvailableUsers[key].groups = [];
									searchAvailableUsers[key].groups.push(availableUsers[key].groups[i]);
								}
							}
						}
					}
				}
			}
			this.setState({ [keyMap.searchAvailable]: { ...searchAvailableUsers }, [keyMap.searchText]: e.target.value });
		}
	}

	SelectedUsers = (props) => {
		let { t } = this.props;
		// let { formData, fields } = this.state;
		let { users, user_type } = props;
		let objElements = [];
		// let { ProfileAvatar } = this;
		let sort_users = Object.values(users);

		sort_users.sort(function (a, b) {
			return a.sign_seq - b.sign_seq;
		});


		// console.log(users, user_type);
		for (let user of sort_users) {
			let iconProps = {};
			if (user.user_type === 2) {
				iconProps.icon = PeopleIcon;
			} else if (user.profile_image) {
				iconProps.avatar = user.profile_image;
			}

			let topInfo = `${user.name} ${user.company_name || ""}`;
			let bottomInfo = `${user.email || ""}`;
			if (user.user_type === 2) {
				bottomInfo = `${t("common:general.total-members")} ${user.members_count}`;
			}

			let OptionalAction = () => { return null; };
			if (props.hasOwnProperty("optional") && props.optional) {
				OptionalAction = props.optional;
			}

			objElements.push(
				<div key={user.id} className={`user-info user${user.user_type}`}>
					<div className="display-avatar">
						<ProfileAvatar {...iconProps} />
					</div>
					<div className="user-info-detail">
						<div className="top-info">
							<span>{topInfo} </span>
						</div>
						<div className="bottom-info">
							<span>{bottomInfo} </span>
						</div>
					</div>
					<div className="user-info-optional">
						<OptionalAction user={user} />
					</div>
					<div className="remove-user">
						<button type="button" className="btn-icon" onClick={(ev) => { this.unAssignUser(ev, user, user_type) }}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</div>
			);
		}

		return objElements;

	}

	componentWillUnmount() {
		// this.state = null;
		// console.log("Unmount", this.state);

	}

	componentDidUpdate(prevProps) {
		// let { PageProps } = this.state;
		// console.log(this.state);
		// console.log(this.props);
		// document.title = this.props.t(this.state.activePage.title);
		// console.log("componentDidUpdate", prevProps);
		// console.log(PageProps);

		// if (PageProps.name === "DocumentPrepare") {
		// let ctrlPreview = document.querySelector(".full-preview .control-preview");
		// let pdfDocument = document.querySelector(".full-preview .react-pdf__Document");
		// if (ctrlPreview && ctrlPreview) {
		// 	// console.log(ctrlPreview.offsetWidth, pdfDocument.offsetWidth);
		// 	if (ctrlPreview.offsetWidth < pdfDocument.offsetWidth) {
		// 		ctrlPreview.style.alignItems = "baseline";
		// 	} else {
		// 		ctrlPreview.style.alignItems = "center";
		// 	}
		// }
		// ctrlPreview.scrollTo(0, 300);
		// }
		// else if (PageProps.name === "Determine") {
		// 	let ctrlPreview = document.querySelector(".file-preview-zone");
		// 	let pdfDocument = document.querySelector(".file-preview-zone .react-pdf__Document");
		// 	let canvasDocument = document.querySelector(".file-preview-zone .react-pdf__Document .pdf-custom-page.active canvas");
		// 	if (pdfDocument && canvasDocument) {
		// 		let minHeight = canvasDocument.offsetHeight / canvasDocument.offsetWidth * pdfDocument.offsetWidth;
		// 		let minWidth = canvasDocument.offsetWidth / canvasDocument.offsetHeight * pdfDocument.offsetHeight;
		// 		// console.log(minHeight, minWidth);
		// 		// if (ctrlPreview.offsetWidth < pdfDocument.offsetWidth) {
		// 		// 	ctrlPreview.style.alignItems = "baseline";
		// 		// } else {
		// 		// 	ctrlPreview.style.alignItems = "center";
		// 		// }
		// 	}
		// }
	}

	Menu = () => {
		let { activePage, processStep, pageState } = this.state;
		let menuKeys = Object.keys(processStep);
		// console.log(activePage);
		let { t } = this.props;

		let MenuComponent = menuKeys.map((key) => {
			let Menu = processStep[key];
			// console.log(Menu);
			let pageName = Menu.name;
			let classlist = ["step-child"];
			if (Object.keys(pageState).includes(pageName)) {
				if (pageState[pageName].completed) {
					classlist.push("pass");
				}
			} else {
				classlist.push("disabled");
			}
			if (activePage === Menu.page) {
				classlist.push("active");
			}

			return (
				<li key={key} onClick={(ev) => {
					if (Object.keys(pageState).includes(pageName) && activePage !== Menu.page) {
						this.changePage(ev, Menu.page);
					}
				}} className={classlist.join(" ")}>
					<div className="step-circle">
						<div className="step-circle-dot"></div>
						<div className="step-access-ramp"></div>
					</div>
					<span className="step-label">{t(Menu.text)}</span>
				</li >
			);
		});

		return (
			<ul className="step-custom step-vertical">
				{MenuComponent}
			</ul>
		);
	}
	async getCurrentUserAttributes() {
		const user = await AuthService.GetCurrentLogin();
		const attributes = user.attributes;
		console.log(attributes)
		if (attributes['custom:mfa_type'] !== 'disable') {
			// console.log(attributes['custom:mfa_software_auth'].includes('document') && attributes['custom:mfa_type'] === 'software')
			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'software') {
				this.setState({
					isVerifySoftware: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}

			if (attributes['custom:mfa_software_auth']?.includes('document') && attributes['custom:mfa_type'] === 'email') {
				this.setState({
					isVerifyEmail: true
				});
				this.setState({
					openOtpDialog: true,
				});
			}
		}
	}
	async handleConfirmOtpDialog(otp) {
		const user = await AuthService.GetCurrentLogin();
		this.setState({
			openOtpDialog: false,
			isVerifySoftware: false
		});
	}

	render() {

		let { PageError, openOtpDialog, isVerifySoftware } = this.state;
		if (PageError) {
			return <Error code={404} />
		}
		// console.log(this.state);
		const { Main } = this;

		return <>
			<Main />
		</>;
	}

}
export default withTranslation()(Documents);
