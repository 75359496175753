import Main from "./Main";
import { withTranslation } from "react-i18next";

class Workflow extends Main {

	constructor(props) {
		super(props);
		this.state.page = "Workflow";
		this.state.code = "workflow";
		this.state.function_type = 12;
		this.state.searchOption.function_type = 12;
	}

	render() {

		// let { t } = this.props;
		const { Main } = this;

		return (
			<Main>
				TODO
			</Main>

		);

	}

}

export default withTranslation()(Workflow);