import React from 'react';
import {
	Col,
	Form,
	InputGroup,
	ListGroup,
	Container,
} from 'react-bootstrap';
import {
	faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
// import {
// 	no_avatar as NoAvatar,
// 	close as Close,
// } from '../Assets/SVGIcons';

export default class HostSetting {

	static get key() {
		return "host-setting";
	}
	static get name() {
		return "HostSetting";
	}
	static get code() {
		return HostSetting.name.toLowerCase();
	}

	constructor(objMain) {
		this.objMain = objMain;
		this.key = HostSetting.key;
		this.name = HostSetting.name;
		this.code = HostSetting.code;
		this.formData = {
			certificate_type: "common:documents.host-setting.certificate-type",
			use_digital_certificate: "common:documents.host-setting.digital-certificate",
			company_seal: "common:documents.host-setting.company-seal",
			sign_level: "common:documents.document-info.sign-level",
			signers: "common:documents.host-setting.signers",
		};
		this.initialValues = {
			use_digital_certificate: true,
			company_seal: false,
			signers: {},
		}
		this.selectedLabel = "common:documents.host-setting.signers-list";

		// Personal Cert, Company Seal
		this.objMain.setPageObject(this);
		// let {formData}=this.objMain.state;
		// console.log(this.objMain.formData);
		if (this.formData.sign_level.toString() === "0") {
			this.formData.use_digital_certificate = false;
			this.objMain.state.formData.use_digital_certificate = false;
		} else if (this.formData.sign_level.toString() === "2") {
			this.formData.use_digital_certificate = true;
			this.objMain.state.formData.use_digital_certificate = true;
		}
	}

	formSubmitCallback = (ev) => {
		// console.log(ev);
		let { pageState } = this.objMain.state;
		pageState[this.name].completed = true;
		this.objMain.setFormData(this.formData);
		this.objMain.updateState({ pageState: pageState });
		this.objMain.nextPageStep(ev, this.constructor);
	};

	onCertificateTypeChangeHandler = (event) => {
		let { CertificateType, availableHostGroups, availableSigners, allHostGroupWithSeal, allHosts } = this.objMain.state;
		// let key = event.target.name;
		let val = event.target.value;
		let cert = CertificateType[val];
		// console.log(key, val, cert);
		this.formData.certificate_type = val;
		if (this.formData.company_seal) {
			this.formData.signers = {};
			availableSigners = JSON.parse(JSON.stringify(allHosts));
			availableHostGroups = JSON.parse(JSON.stringify(allHostGroupWithSeal));
			this.objMain.updateState({ availableSigners, availableHostGroups });
		}

		if (cert.code === "company-seal") {
			this.formData.company_seal = true;
			this.selectedLabel = "common:documents.host-setting.responsible-list";
			this.formData.signers = {};
			availableSigners = JSON.parse(JSON.stringify(allHosts));
			availableHostGroups = JSON.parse(JSON.stringify(allHostGroupWithSeal));
			this.objMain.updateState({ availableSigners, availableHostGroups });
		} else {
			this.formData.company_seal = false;
			this.selectedLabel = "common:documents.host-setting.signers-list";
		}
		// console.log(this.formData);
		this.objMain.setFormData(this.formData);
	}

	setCompanySealFlag = (ev, UserID) => {
		// console.log(ev);
		// let { availableSigners } = this.objMain.state;
		// this.formData.signers[UserID] = JSON.parse(JSON.stringify(availableSigners[UserID]));
		// this.formData.signers[UserID].use_digital_certificate = this.formData.use_digital_certificate;
		// delete availableSigners[UserID];
		// this.objMain.setFormData(this.formData);
	}

	setDigitalCertificateFlag = (ev, UserID) => {
		let signer = this.formData.signers[UserID];
		let digiCert = ![true, "true"].includes(signer.use_digital_certificate);
		this.formData.signers[UserID].use_digital_certificate = digiCert;
		this.objMain.setFormData(this.formData);
	}

	renderDigitalSignatureControl = (signer) => {
		if (this.formData.company_seal) {
			return "";
		} else {
			let has_digital_certificate = false;
			let use_digital_certificate = false;
			if (signer.hasOwnProperty("has_digital_certificate")) {
				if ([true, "true"].includes(signer.has_digital_certificate)) {
					has_digital_certificate = true;
					if ([true, "true"].includes(signer.use_digital_certificate)) {
						use_digital_certificate = true;
					}
				}
			}
			return (
				<Form.Check
					type="switch"
					value={"digital-signature-" + signer.id}
					onChange={ev => { this.setDigitalCertificateFlag(ev, signer.id) }}
					id={"digital-signature-" + signer.id}
					label={"JCAN"}
					checked={use_digital_certificate}
					disabled={!has_digital_certificate}
				/>
			);
		}
	}

	CertificateTypeItems = () => {
		let { t } = this.props;
		let { PageData } = this.objMain.state;
		let { cert_types } = PageData;
		let CertificateTypeItems = [];
		for (let i in cert_types) {
			let cert_type = cert_types[i];
			CertificateTypeItems.push(<option key={cert_type.id} value={cert_type.id}>{t(`common:documents.host-setting.options-${cert_type.label}`)}</option>);
		}

		return CertificateTypeItems;
	}


	// AvailableHostGroup = (props) => {
	// 	let { CertificateTypeItems, MultipleUserSelectionBox, AvailableHostGroup } = this;
	// 	return (
	// 		<ListGroup as="ul" className="user-selection-box">
	// 			<MultipleUserSelectionBox {...props} />
	// 		</ListGroup>
	// 	);
	// };
	AvailableHostGroup = (props) => {
		let { MultipleUserSelectionBox } = this.objMain;
		// console.log(props);
		return (
			<ListGroup as="ul" className="user-selection-box">
				<MultipleUserSelectionBox {...props} />
			</ListGroup>
		);
	}

	UseSelfCertificateHandler = (ev) => {
		// console.log(ev.target);
		// console.log(id, this.formData.signers[id].self_certificate);
		let id = ev.target.dataset.id;
		this.formData.signers[id].self_certificate = ev.target.checked;
		this.objMain.setFormData(this.formData);
	}

	CertificateFormOption = () => {
		let { fieldValidate, creationType } = this.objMain.state;
		let { sign_level, company_seal, use_digital_certificate } = this.formData;
		let { CertificateTypeItems } = this;

		// console.log(sign_level);
		if (["signer", "circle"].includes(creationType) && sign_level.toString() === "0") {
			return null;
		}

		let showDigiCertField = sign_level.toString() === "2" ? "none" : "block";
		let showCertTypeField = use_digital_certificate ? "block" : "none";

		return (
			<Form.Row>
				{/* style={{ display: showDigiCertField }} */}
				<Form.Group xs={12} md={6} as={Col} controlId="use_digital_certificate" style={{ display: showDigiCertField }}>
					<Form.Label>{this.fields["use_digital_certificate"].label}</Form.Label>
					<Form.Check
						type="switch"
						name='use_digital_certificate'
						value='use_digital_certificate'
						onChange={this.objMain.onChangeHandler}
						label={this.fields["use_digital_certificate"].label}
						checked={[true, "true"].includes(this.formData["use_digital_certificate"])}
						disabled={company_seal}
					/>
				</Form.Group>

				<Form.Group xs={12} md={6} as={Col} controlId="certificate_type" className={fieldValidate[this.name]} style={{ display: showCertTypeField }}>
					<Form.Label>{this.fields["certificate_type"].label}</Form.Label>
					<Form.Control as="select" className="no-validate"
						name='certificate_type'
						value={this.formData["certificate_type"]}
						onChange={this.onCertificateTypeChangeHandler}
						required
					>
						<CertificateTypeItems />
					</Form.Control>
				</Form.Group>

			</Form.Row>
		);
	}

	SelectedUserPropsOptional = (props) => {
		let { t } = this.props;
		let { user } = props;
		let { formData } = this.objMain.state;
		let cert_type = parseInt(formData.certificate_type);
		// let { AvailableUserPropsOptional } = this;
		// console.log(user, cert_type);
		if (cert_type === 2) {
			return null;
		}
		if (!formData.use_digital_certificate) {
			return null;
		}
		if (!user.available_certs.includes(cert_type)) {
			return null;
		}
		return (
			<Form.Check key="user-self-cert"
				type="switch"
				id={`self_certificate_${user.id}`}
				name={`self_certificate_${user.id}`}
				value='self_certificate'
				data-id={user.id}
				onChange={this.UseSelfCertificateHandler}
				label={<div>&nbsp;</div>}
				checked={[true, "true"].includes(formData.signers[user.id].self_certificate)}
				title={t("common:documents.host-setting.use-self-certificate")}
			/>
		);
	}

	AvailableUserPropsOptional = (props) => {
		// let { t } = this.props;
		let { user } = props;
		let { formData } = this.objMain.state;
		let cert_type = parseInt(formData.certificate_type);
		let showWarning = false;
		if (formData.use_digital_certificate) {
			let members = {};
			members[user.id] = user;
			if (user.user_type === 2) {
				members = user.members;
			}

			for (let id in members) {
				let member = members[id];
				if (!member.available_certs.includes(cert_type)) {
					showWarning = true;
					break;
				}
			}
		}

		if (showWarning) {
			return <FontAwesomeIcon icon={faExclamationTriangle} className="no-certificate-warning" />;
		} else {
			return null;
		}
	}

	render() {

		let { t } = this.props;
		let { AvailableUser, SelectedUsers, SearchInputChange } = this.objMain;
		let { AvailableHostGroup, CertificateFormOption } = this;
		let { creationType, availableHostGroups, searchSignerText } = this.objMain.state;
		let { signers, company_seal, sign_level } = this.formData;
		let SelectedUserProps = {
			users: signers,
			user_type: "host",
			optional: this.SelectedUserPropsOptional,
		};
		let AvailableUserProps = {
			users: signers,
			user_type: "host",
			optional: this.AvailableUserPropsOptional,
		};

		if (["signer", "circle"].includes(creationType) && sign_level.toString() === "2" && !company_seal) {
			SelectedUserProps.optional = this.AvailableUserPropsOptional;
		}

		let AvailableUserObject = AvailableUser;
		// console.log(formData);
		// console.log(company_seal);
		if (company_seal) {
			AvailableUserProps = {
				users: availableHostGroups,
				user_type: "group-signer",
				group_id: "group",
				by_group: false,
			};
			// console.log(AvailableUserProps);
			AvailableUserObject = AvailableHostGroup;
		}

		return (

			<Container fluid="md" className={this.key + "-container form-wrapper container-fluid"}>

				<Form
					id={"form_" + this.name}
					onSubmit={(ev) => { this.objMain.formSubmitHandler(ev, this, this.formSubmitCallback) }}
					noValidate
					className="container-fluid full-form"
				>

					<div className="layout-right-title form-header" >
						<h2>{this.headerInfo}</h2>
					</div>

					<div className="form-body flex-column">

						<CertificateFormOption />

						<Form.Row className="flex-full">

							<Form.Group xs={12} md={6} as={Col} controlId="user_list" className="signer-list-wrapper">
								<Form.Label>{t(this.selectedLabel)}</Form.Label>
								<SelectedUsers {...SelectedUserProps} />
							</Form.Group>

							<Form.Group xs={12} md={6} as={Col} controlId="all_user_list" className="user-selection-container">
								<Form.Label>{t("common:documents.host-setting.available-user-list")}</Form.Label>
								<InputGroup className="form-control">
									<div className="search-input-container">
										<TextField
											className="search-input-field"
											placeholder={t('common:documents.general.text-input-placeholder')}
											onChange={(e) => SearchInputChange(AvailableUserProps, e)}
											defaultValue={searchSignerText}
											sx={{
												"& fieldset": { border: 'none' },
											}}
											InputProps={{
												startAdornment: (
													<InputAdornment>
														<IconButton>
															<SearchIcon />
														</IconButton>
													</InputAdornment>
												),
												style: {
													height: 40,
													paddingLeft: 5,
													fontSize: 15,
												},
											}}
										/>
									</div>
									<AvailableUserObject {...AvailableUserProps} />
								</InputGroup >
							</Form.Group>

						</Form.Row>

					</div>

					<div className="form-foot">

						<div className="page-action-bottom-relative">
							<div className="wrapper-button-cancel">
								<Button
									variant="contained"
									color="info"
									size='large'
									startIcon={<ChevronLeftIcon />}
									onClick={(ev) => this.objMain.prevPageStep(ev, this.constructor)}>
									<span className="btn-label">{t('common:documents.general.back')}</span>
								</Button>
							</div>
							<div className="wrapper-button-submit">
								<Button
									variant="contained"
									type="submit"
									size='large'
									endIcon={<ChevronRightIcon />}
								>
									<span className="btn-label">{t('common:documents.general.next')}</span>
								</Button>
							</div>
						</div>

					</div>

				</Form>

			</Container>

		);

	}

}
