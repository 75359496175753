import '../Assets/css/settings.css';
import Layout from '../../Layout';
import Menu from './Menu';
import Loading from '../Loading';
import LoadingList from '../LoadingList';
import Service from '../../Service';
import OrganizationService from '../../Service/Organization';
import { withTranslation } from "react-i18next";
import { Form, } from 'react-bootstrap';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Moment from 'moment';
import logo from "../Assets/img/logo_figure.svg";

/** */
class Guest extends Layout {

	/** Constructor */
	constructor(props) {
		super(props);
		this.state.page = "guest";
		this.state.activeTab = 0;
		this.state.tabs = [
			{ "status": 0, "label": "common:settings.guest.active" },
			{ "status": 1, "label": "common:settings.guest.inactive" },
		];

		/* For Panigation */
		this.state.pagination = {
			"page": 1,
			"take": 10,
			"total": 0,
		};

		/* For SearchBox */
		this.state.search = "";
		this.state.datalist = null;
		this.state.organizations = null;

		this.state.fields = {};
		this.state.keyFields = {
			"family_name": "family_name",
			"first_name": "first_name",
			"family_name_kana": "family_name_kana",
			"first_name_kana": "first_name_kana",
			"company_name": "company_name",
			"mobile_number": "mobile",
			"email": "email",
		}

	}

	setStateFields = (cb) => {
		let { t } = this.props;
		let { keyFields, fields, formData } = this.state;
		// this.state.fields = {};
		for (let key in keyFields) {
			let label = keyFields[key];
			fields[key] = {
				name: key,
				value: "",
				label: t(`common:settings.guest.${label}`),
			};
			if (formData[key]) {
				fields[key].value = formData[key];
			}
		}

		this.setState({ fields }, cb);
	}

	/** [Layout] Update LayoutMenu */
	Menu = () => {

		/* Load Menu from Pages/Settings/Menu.jsx */
		return (<Menu {...this.props} page={this.state.page} />);

	}

	/** [Init] HTTP-Request API */
	componentDidMountExtends = () => {

		this.setStateFields();
		//
	}

	componentDidUpdate = () => {

		/* */
		if (this.state.datalist === null) {

			/* */
			let tab = this.state.activeTab;
			let page = this.state.pagination.page;
			let take = this.state.pagination.take;
			let skip = (page - 1) * take;

			/* */
			let data = {
				"tenant_id": null,
				"status": this.state.tabs[tab]['status'],
				"search": this.state.search,
				"skip": skip,
				"take": take,
			}

			/* */
			Service.getUserGuestList(data).then((response) => {

				/* */
				let { datalist, pagination } = this.state;

				/* */
				datalist = response.records;

				/* */
				pagination.total = response.total;

				/* */
				this.setState({ datalist, pagination });

			}).catch((error) => {
				console.log(error.response);
			});

		}

		/* */
		if (this.state.organizations === null) {
			OrganizationService.getList().then((response) => {

				/* */
				let { organizations } = this.state;

				/* */
				organizations = response;

				/* */
				this.setState({ organizations });

			}).catch((error) => {
				console.log("OrganizationService.getList.error", error);
			});
		}

	}

	/** [Render] */
	render() {

		/* Search Function */
		let { t } = this.props;

		/* */
		const { Main, CsvFileUpload } = this;

		/* */
		const searchAttr = {
			"placeholder": t('common:settings.guest.search'),
			"onKeyPress": (e) => {
				if (e.code === "Enter") {

					/* */
					let { search, datalist } = this.state;

					/* */
					search = e.target.value;
					datalist = null;

					/* */
					this.setState({ search, datalist });

					// console.log("enter", e.target.value);

				}
			},
		}

		/* */
		return (
			<Main>

				<div id="page-settings-guest" className="page-settings">

					{/* FilterTabs */}
					<div className="lz-flex">

						<div className="lz-flex-1">
							<div className="lz-m-10 lz-mb-0">
								<Button
									key="list-download"
									variant="outlined"
									size='small'
									disabled={!this.state.datalist}
									startIcon={<UploadIcon />}
									onClick={(ev) => {
										CsvFileUpload();
									}} className="btn-custom new-document" >
									{t('common:settings.general.list-upload')}
								</Button>
							</div>
						</div>

						<div className="lz-flex-0">

							<div className="lz-m-10 lz-mb-0">
								<this.FilterTabs />
							</div>

						</div>

					</div>

					{/* SearchForm & Paginate */}
					<div className="lz-flex stickyHeader">

						<div className="lz-flex-1">

							<div className="lz-m-10">

								<div className="lz-flex">

									{/* Add-Guest Button */}

									<Button
										sx={{ borderRadius: 19 }}
										title="Action"
										variant="contained"
                    className="btn-addGuest"
										startIcon={<AddIcon />}
										disabled={!this.state.datalist}
										onClick={() => { this.openGuestFormDialog() }}>
										<span>{t('common:settings.guest.add_guest')}</span>
									</Button>

									{/* Search-TextBox */}
									<div className="input-textbox">
										<input type="search" {...searchAttr} />
									</div>

								</div>

							</div>

						</div>

						<div className="ki-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

					{/* DataTable */}
					<div className="">
						<this.IndexTable />
					</div>

					{/* BottomPagination */}
					<div className="lz-flex" hidden>

						<div className="lz-flex-1"></div>

						<div className="lz-flex-0">
							<div className="lz-m-10">
								<this.Pagination />
							</div>
						</div>

					</div>

				</div>

			</Main>
		);

	}

	/** */
	CsvFileUpload = (validateMessage = null) => {

		/* Prepare Modal */
		let { modal } = this.state;

		/* Translate function */
		let { t } = this.props;

		/* Show Modal */
		modal.props = {
			"show": true,
			"dialogClassName": "profile-dialog",
			"centered": true,
		}

		/* */
		modal.title = "common:settings.general.csv-upload";

		let ValidateMessage = () => {
			if (validateMessage) {
				let messageArr = [];
				let messageKeys = Object.keys(validateMessage);
				let messageKey = messageKeys[0];
				let headerErrKey = messageKeys[1];
				let itemMap = validateMessage[messageKey];

				if (validateMessage[headerErrKey] !== '') {
					messageArr.push(
						<div key={headerErrKey}>
							<span>
								{t(`common:${validateMessage[headerErrKey]}`)}
							</span>
						</div>
					);
				} else {
					for (let [itemKey, itemLines] of Object.entries(itemMap)) {
						if (itemLines.length !== 0) {
							messageArr.push(
								<div key={itemKey}>
									<span>
										{t(`common:${messageKey}`, { item_name: t(`common:${itemKey}`), line: itemLines.join(',') })}
									</span>
								</div>
							);
						}
					}
				}
				return messageArr;
			} else {
				return null;
			}
		}

		/* Update body */
		modal.body = () => {
			return (
				<div>
					{/* */}
					<div className="">
						<Form.Group className="">
							<Form.Label>{t("common:settings.general.csv-file-select")}</Form.Label>
							<Form.Control type="file" id="csv-upload" name="upload" accept=".csv" />
						</Form.Group>
					</div>
					<div>

						<ValidateMessage />
					</div>
				</div>
			);
		}

		modal.form = {};
		modal.close = true;

		/* Add SubmitButton */
		modal.action = [(
			<Button sx={{ ml: 1 }} variant="contained" key="ok" onClick={(ev) => {
				this.GuestUserCsvFileUpload();
			}}>
				<span>{t("common:general.ok")}</span>
			</Button>
		)];

		/* */
		this.setState({ modal });
	}

	/** [Element] */
	FilterTabs = () => {

		/* Translate Function */
		let { t } = this.props;

		/* */
		let tabs = [];

		/* */
		for (let i = 0; i < this.state.tabs.length; i++) {

			/* */
			let c = "select-tab-item";

			/* */
			if (i === this.state.activeTab) {
				c = "select-tab-item active";
			}

			/* */
			let clickEvent = () => {

				/* */
				let { activeTab, pagination, datalist } = this.state;

				/* */
				activeTab = i
				pagination.page = 1;
				datalist = null;

				/* */
				this.setState({ activeTab, pagination, datalist });

			};

			/* */
			tabs.push(
				<div key={i} className={c} onClick={clickEvent}>
					<span>{t(this.state.tabs[i]['label'])}</span>
				</div>
			);
		}

		/* */
		return (
			<div className="select-tab">{tabs}</div>
		);

	}

	/** [Element] */
	Pagination = () => {

		let { pagination, datalist } = this.state;

		/* clear */
		datalist = null;

		/* Calculate MaxPage */
		let max = Math.ceil(pagination.total / pagination.take);

		/* */
		let prev = {
			"type": "button",
			"className": "paginate-prev",
			"onClick": () => {
				pagination.page = (pagination.page - 1);
				this.setState({ pagination, datalist });
			},
		};

		/* */
		let next = {
			"type": "button",
			"className": "paginate-next",
			"onClick": () => {
				pagination.page = (pagination.page + 1);
				this.setState({ pagination, datalist });
			},
		}

		/* */
		if (pagination.page === 1) {
			prev["disabled"] = true;
		}

		/* */
		if (pagination.page === max || max === 0) {
			next["disabled"] = true;
		}

		/* */
		let start_list = 0;
		let last_list = 0;
		if (pagination.total > 0) {
			start_list = 1 + ((pagination.page - 1) * pagination.take);
			if (pagination.page === max) {
				last_list = pagination.total
			} else {
				last_list = start_list + (pagination.take - 1);
			}
		}

		/* */
		return (
			<div className="paginate">
				<div className="paginate-info">
					<span>{start_list}</span>
					<span>{last_list}</span>
					<span>{pagination.total}</span>
				</div>
				<div className="paginate-nav">
					<IconButton key="prev" {...prev}>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton key="next" {...next}>
						<ChevronRightIcon />
					</IconButton>
				</div>
			</div>
		);

	}

	getCertificateInfo = (certificates, code) => {
		let cert = certificates.find(item => item.code === code);
		if (cert['user_id'] !== null) {
			return (<div>
				<div className='text-ellipsis certificates-name'>
					{cert['cert_name'] ?? ''}
				</div>
				<div className='text-ellipsis certificates-date'>
					{cert['cert_exp_date'] && cert['cert_start_date'] ?
						`${Moment(cert['cert_start_date']).format('YYYY.MM.DD')} - ${Moment(cert['cert_exp_date']).format('YYYY.MM.DD')}` : ''}
				</div>
			</div>)
		}
	}

	/** [Element] */
	IndexTable = () => {

		/* Search Function */
		let { t } = this.props;

		/* */
		let { datalist } = this.state;
		/* */
		if (datalist === null) {
			return <LoadingList />;
		}

		/* Prepare TableRows */
		let rows = [];

		/* Update TableRows with data from state */
		for (let i = 0; i < datalist.length; i++) {

			/* */
			let guest = datalist[i];

			/* */
			if (guest['additional_info'] == null) {
				guest['additional_info'] = {
					'company_name': '',
				};
			}

			let GuestLinkage = () => {
				if (guest.linkage_tenant_id && guest.linkage_user_id) {
					return (
						<div className="user-linkage-container">
							<img src={logo} className="user-linkage-icon" alt="paperlogic" />
						</div>
					);
				} else {
					return null;
				}
			}

			/* */
			rows.push(
				<tr className="item document-row-list clickable" key={i} onClick={() => { this.openGuestFormDialog(guest) }}>
					<td>
            <div>
              <div className="text-ellipsis">
                {guest['user_id']}
              </div>
            </div>
					</td>
					<td>
						<GuestLinkage />
					</td>
					<td>
            <div>
              <div className="text-ellipsis">
              {guest['family_name']} {guest['first_name']}
              </div>
            </div>
					</td>
					<td>
            <div>
              <div className="text-ellipsis">{guest['additional_info']['company_name']}
              </div>
            </div>
					</td>
					<td>
            <div>
              <div className="text-ellipsis">
                {guest['email']}
              </div>
            </div>
					</td>
					<td>
						{this.getCertificateInfo(guest['certificates'], 'personal-jcan')}
					</td>
					<td>
						{this.getCertificateInfo(guest['certificates'], 'custom')}
					</td>
					<td></td>
				</tr>
			)

		}

		/* */
		if (rows.length < 1) {
			rows.push(
				<tr key="empty">
					<td colSpan="4">
						<div className="lz-txt-c lz-p-40">
							<span>{t('common:settings.user.empty')}</span>
						</div>
					</td>
				</tr>
			);
		}

		/* */
		return (
			<table id="datalist" className='guest-settings-list '>
				<thead>
					<tr>
						<th className='guest-user-id'>
							<div>{t('common:settings.guest.id')}</div>
						</th>
						<th>
							<div></div>
						</th>
						<th className='guest-user-name'>
							<div>{t('common:settings.guest.name')}</div>
						</th>
						<th className='guest-company-name'>
							<div>{t('common:settings.guest.company_name')}</div>
						</th>
						<th className='guest-user-email'>
							<div>{t('common:settings.guest.email')}</div>
						</th>
						<th className='guest-personal-jcan'>
							<div>{t('common:certificate.title.personal-jcan')}</div>
						</th>
						<th className='guest-custom-certificate'>
							<div>{t('common:certificate.title.custom-certificate')}</div>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		)

	}



	/** [Element] */
	OrganizationSelector = () => {
		let { t } = this.props;
		let { formData, organizations } = this.state;

		if (organizations === null) {
			return <Loading />;
		}

		let OrgItems = [];

		/* */
		for (let item of organizations) {

			if (item.user_type !== 2) {
				continue;
			}

			let checked = false;
			if (formData.groups.includes(item['user_id'].toString())) {
				checked = true;
			}

			/* */
			OrgItems.push(
				<div className="org-selector-item" key={item['user_id']}>
					<label className="checkboxBtn">
						<input type="checkbox" name="organizations[]" value={item['user_id']} checked={checked} onChange={(ev) => {
							let { formData } = this.state;
							if (ev.target.checked) {
								formData.groups.push(ev.target.value);
							} else {
								var index = formData.groups.indexOf(ev.target.value);
								if (index !== -1) {
									formData.groups.splice(index, 1);
								}
							}
							this.setState({ formData });
						}} />
						<div>{item['name']}</div>
					</label>
				</div>
			);
		}

		/* */
		return (
			<div className="org-selector">
				<div className="org-selector-label">
					<div>{t('common:settings.user.organization')}</div>
				</div>
				<div className="org-selector-itemlist">
					{OrgItems}
				</div>
			</div>
		);

	}



	/** [Action] */
	openGuestFormDialog = (guest = null) => {
		// let { t } = this.props;
		let { modal } = this.state;

		modal = {
			props: {
				show: true,
				dialogClassName: "profile-dialog",
				centered: true,
			},
			title: "common:settings.guest.add_guest",
			body: this.GuestForm,
			action: this.GuestModalAction,
			form: {
				id: "settings-user-form",
				onSubmit: (ev) => { this.formSubmitHandler(ev, null, this.formSubmitCallback) },
				noValidate: true,
			},
		};

		/* Default structure */
		let { keyFields } = this.state;
		let formData = {};
		formData["user_id"] = null;
		formData["tenant_id"] = null;
		formData["status"] = 0;
		formData["groups"] = [];
		for (let key in keyFields) {
			formData[key] = "";
		}

		if (guest != null) {
			formData["user_id"] = guest["user_id"];
			formData["tenant_id"] = guest["tenant_id"];
			formData["status"] = guest["status"];
			let groups = guest["groups"];
			if (typeof groups === "string") {
				groups = JSON.parse(guest["groups"]);
			}
			for (let group of groups) {
				formData["groups"].push(group.id);
			}
			for (let key in keyFields) {
				formData[key] = guest[key];
			}
			formData["company_name"] = guest.additional_info.company_name || "";
			modal.title = "common:settings.guest.update_guest";
		}

		this.setState({ formData }, this.setStateFields(() => {
			this.setState({ modal, formValidate: false });
		}));

	}

	formSubmitCallback = (ev) => {
		this.submitGuestForm();
	}

	GuestModalAction = () => {
		let { t } = this.props;

		return [
			(
				<Button sx={{ ml: 1 }} key="ok" type="submit" variant="contained" >
					<span>{t("common:general.ok")}</span>
				</Button>
			)
		];
	}

	GuestModalActionTryAgain = () => {
		let { t } = this.props;

		return [
			(
				<Button sx={{ ml: 1 }} key="ok" variant="contained" onClick={(ev) => {
					let { modal } = this.state;
					modal.body = this.GuestForm;
					modal.action = this.GuestModalAction;
					this.setState({ modal });
				}}>
					<span>{t("common:general.try-again")}</span>
				</Button>
			)
		];
	}

	GuestForm = () => {
		let { t } = this.props;
		let { formData } = this.state;
		let { FieldControl, OrganizationSelector } = this;

		/* Lock Email TextBox */
		let emailReadOnly = false;
		if (formData['user_id']) {
			emailReadOnly = true;
		}

		return (
			<div className="form-container">
				<Form.Control type="hidden" name="user_id" defaultValue={formData['user_id']} />
				<Form.Control type="hidden" name="tenant_id" defaultValue={formData['tenant_id']} />

				<Form.Row>
					<FieldControl name="family_name" xs={12} md={6} required validate="true" />
					<FieldControl name="first_name" xs={12} md={6} required validate="true" />
				</Form.Row>

				<Form.Row>
					<FieldControl name="family_name_kana" xs={12} md={6} />
					<FieldControl name="first_name_kana" xs={12} md={6} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="company_name" xs={12} md={6} />
					<FieldControl name="mobile_number" xs={12} md={6} />
				</Form.Row>

				<Form.Row>
					<FieldControl name="email" type="email" xs={12} md={12} required validate="true" readOnly={emailReadOnly} />
				</Form.Row>

				<Form.Row>
					<div className="col-xl-12">
						<OrganizationSelector />
					</div>
				</Form.Row>

				<Form.Row>
					<Form.Group className="lz-mt-10 status" controlId="status">
						<Form.Check
							type="switch"
							name='status'
							value='status'
							onChange={this.onChangeHandler}
							label={t('common:settings.guest.active')}
							checked={[true, "true", 0, "0"].includes(formData["status"])}
						/>
					</Form.Group>
				</Form.Row>

			</div>
		);
	}

	/** [Event] */
	submitGuestForm = () => {
		let { t } = this.props;
		let { formData, datalist, modal } = this.state;

		/* */
		// let form = document.querySelector("form#settings-user-form");
		// let fields = form.elements;

		/* Preprae RequestBody */
		let body = {
			"user_id": formData["user_id"],
			"family_name": formData["family_name"],
			"first_name": formData["first_name"],
			"family_name_kana": formData["family_name_kana"],
			"first_name_kana": formData["first_name_kana"],
			"company_name": formData["company_name"],
			"mobile_number": formData["mobile_number"],
			"email": formData["email"],
			"organizations": formData["groups"],
			"status": '1',
		};

		/* */
		if ([true, "true", 0, "0"].includes(formData["status"])) {
			body["status"] = '0';
		}

		modal.body = Loading;
		modal.action = null;
		modal.close = false;

		/* */
		this.setState({ modal }, () => {
			let { modal } = this.state;
			Service.setUserGuest(body).then(resp => {
				datalist = null;
				modal.props.show = false;
				// modal.close = true;
				this.setState({ datalist, modal });
			}).catch(err => {
				console.log("error", err.response);

				let errMessage = err.response.data.message;
				if (err.response.data.hasOwnProperty("error")) {
					errMessage = err.response.data.error;
				}

				modal.body = t(`common:${errMessage}`);
				modal.action = this.GuestModalActionTryAgain;
				modal.close = true;

				this.setState({ modal });
			});
		});

	}

	/* */
	GuestUserCsvFileUpload = async () => {

		/* */
		let { modal, datalist } = this.state;
		/* */
		let form = document.querySelector("#csv-upload");

		/* File required */
		if (form['files']['length'] === 0) {
			return false;
		}

		/* */
		modal.body = () => {
			return (
				<div className="lz-m-20">
					<Loading />
				</div>
			);
		};

		modal.action = [];
		modal.close = false;

		/* */
		this.setState({ modal });
		/* */
		let reader = new FileReader();

		new Promise((resolve) => {
			/* */
			reader.onload = (event) => {
				resolve((event.target.result).split(',')[1]);
			};

			/* */
			reader.readAsDataURL(form.files[0]);

		}).then((result) => {

			/* */
			let upload_data = { "upload_file": result };
			/* */
			Service.UploadCsvGuestUser(upload_data).then((response) => {

				if ('error' in response) {
					/* */
					this.CsvFileUpload(response.error);
				} else {
					/* */
					datalist = null;
					/* */
					modal.props = { "show": false };
					/* */
					this.setState({ modal, datalist });
				}

			}).catch((error) => {
				/* */
				console.log("error", error);
				modal.close = true;
				/* */
				this.setState({ modal });
			});
		});
	}

}

export default withTranslation()(Guest);
