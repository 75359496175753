import React from "react";
import AuthService from "../../Service/Auth";


class Logout extends React.Component {
  async componentDidMount() {
    await AuthService.Logout()
  }

  render() {
    return <></>;
  }
}

export default Logout;
