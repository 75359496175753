import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../../Context/UserInfoContext";

export default function CountSignedDoc({ Menu }) {
  const context = useContext(UserInfoContext);
  const [signedCount, setSignedCount] = useState(0);
  const mappedSignerValue = (val) => {
    switch (val) {
      case "/signer":
        return "signed_document_signer";
      case "/verification":
        return "signed_document_verification";
      case "/sender":
        return "signed_document_sender";
      case "/circle":
        return "signed_document_circle";
      default:
        return null;
    }
  };
  useEffect(() => {
    let keyMap = mappedSignerValue(Menu.link);

    if (keyMap) {
      setSignedCount(context.userInfoContent[keyMap]);
    }
  }, [context, context.userInfoContent]);
  return (
    <span className={signedCount === 0 ? "is-zero" : ""}>
      {["/signer", "/verification", "/sender", "/circle"].includes(Menu.link)
        ? signedCount
        : null}
    </span>
  );
}
