import "../Assets/css/settings.css";
import React from "react";
import Layout from "../../Layout";
import Menu from "./Menu";
import Loading from "../Loading";
import { withTranslation } from "react-i18next";
import { Col, Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SsoService from "../../Service/Sso";

class Sso extends Layout {
  constructor(props) {
    super(props);
    this.state.page = "sso-setting";
    this.state.ssoLoading = true;
    this.state.tabs = [
      { status: 0, label: "XML URLで指定" },
      { status: 1, label: "サービスエンドポイントURLで指定" },
      // { status: 0, label: "common:settings.sso.service-endpoint-url" },
      // { status: 1, label: "common:settings.sso.xml-url" },
    ];
    this.state.activeTab = "0";

    this.state.use_sso = false;

    this.state.fields = {};
    this.state.keyFields = {
      entity_id: "エンティティID",
      domain: "識別子(ドメイン)",
      endpoint_url: "エンドポイントURL",
      public_key: "公開鍵",
      logout_url: "ログアウト遷移URL",
      xml_url: "XML URL",
      // endpoint_url: "endpoint_url",
      // public_key: "public_key",
      // logout_url: "logout_url",
      // xml_url: "xml_url",
    };
  }

  setStateFields = (cb) => {
    // let { t } = this.props;
    let { keyFields, fields, formData } = this.state;
    for (let key in keyFields) {
      let label = keyFields[key];
      fields[key] = {
        name: key,
        value: "",
        label,
        // label: t(`common:settings.sso.${label}`),
      };
      if (formData[key]) {
        fields[key].value = formData[key];
      }
    }

    this.setState({ fields }, cb);
  };

  Menu = () => {
    return <Menu {...this.props} page={this.state.page} />;
  };

  componentDidMountExtends = async () => {
    const idpData = await SsoService.GetIdp();
    if (idpData) {
      this.setState({ use_sso: true });
      const formData = {
        entity_id: idpData.entity_id,
        domain: idpData.domain,
        endpoint_url: idpData.endpoint_url,
        public_key: idpData.public_key,
        logout_url: idpData.logout_url,
        xml_url: idpData.xml_url,
      };
      this.setState({ formData, activeTab: formData.xml_url ? "0" : "1" });
    }
    this.setState({ ssoLoading: false });
    this.setStateFields();
  };

  componentDidUpdate = () => {};

  validateUrl = (url) => {
    // eslint-disable-next-line no-useless-escape
    return /https?:\/\/[\w\/:%#\$&\?\(\)~\.=\+\-\[\]]+/.test(url);
  };
  validateDomain = (domain) => {
    return true
  }
  
  DomainForm = () => {
    // const { t } = this.props;
    const { formData } = this.state;
    const { FieldControl } = this;
    return (
      <FieldControl
        name="domain"
        type="domain"
        placeholder={"識別子(ドメイン)"}
        // placeholder={t("common:settings.sso.domain")}
        isInvalid={!formData["domain"]}
        onChange={this.onChangeHandler}
      />
    );
  }

  RequireFormLabel = () => {
    return (
      <Form.Group as={Col} xs={1} style={{ color: "red", marginTop: "30px" }}>
        必須
      </Form.Group>
    )
  }
  ServiceEndpointForm = () => {
    // const { t } = this.props;
    const { formData } = this.state;
    const { FieldControl, DomainForm, RequireFormLabel } = this;
    return (
      <>
        <div className="form-container">
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <FieldControl
                name="entity_id"
                type="entity_id"
                placeholder={"エンティティId"}
                // placeholder={t("common:settings.sso.entity_id")}
                isInvalid={!formData["entity_id"]}
                validate={formData["entity_id"]}
                onChange={this.onChangeHandler}
              />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <FieldControl
                name="endpoint_url"
                type="endpoint_url"
                placeholder={"エンドポイントURL"}
                // placeholder={t("common:settings.sso.endpoint_url")}
                isInvalid={!this.validateUrl(formData["endpoint_url"])}
                validate={`${this.validateUrl(formData["endpoint_url"])}`}
                onChange={this.onChangeHandler}
              />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <div
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  marginBottom: "11px",
                }}
              >
                <Form.Label>{"公開鍵"}</Form.Label>
                <Form.Control
                  as="textarea"
                  value={formData["public_key"] || ""}
                  rows={10}
                  placeholder={"公開鍵"}
                  name="public_key"
                  type="public_key"
                  isInvalid={!formData["public_key"]}
                  validate={formData["public_key"]}
                  onChange={this.onChangeHandler}
                />
              </div>
            </Form.Group>

            <RequireFormLabel />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={11} controlId="formGridCity">
              <FieldControl
                name="logout_url"
                type="logout_url"
                placeholder={"ログアウト遷移URL"}
                // placeholder={t("common:settings.sso.logout_url")}
                isInvalid={!formData["logout_url"]}
                validate={`${this.validateUrl(formData["logout_url"])}`}
                onChange={this.onChangeHandler}
              />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={11} controlId="formGridCity">
              <DomainForm />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>

          <Form.Row className="justify-content-center">
            <Button
              className="text-center"
              style={{ padding: "6px 48px" }}
              variant="contained"
              onClick={() => this.Save("service")}
            >
              <span className="label">確定</span>
              {/* <span className="label">{t("common:settings.sso.confirm")}</span> */}
            </Button>
          </Form.Row>
        </div>
      </>
    );
  };

  XmlUrlForm = () => {
    // const { t } = this.props;
    const { formData } = this.state;
    const { FieldControl, DomainForm, RequireFormLabel } = this;
    return (
      <>
        <div className="form-container">
          <Form.Row>
            <Form.Group as={Col} controlId="formGridCity">
              <FieldControl
                name="xml_url"
                type="xml_url"
                placeholder={"XML URL"}
                // placeholder={t("common:settings.sso.xml_url")}
                isInvalid={!this.validateUrl(formData["xml_url"])}
                validate={`${this.validateUrl(formData["xml_url"])}`}
                onChange={this.onChangeHandler}
              />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={11} controlId="formGridCity">
              <DomainForm />
            </Form.Group>
            <RequireFormLabel />
          </Form.Row>

          <Form.Row className="justify-content-center">
            <Button
              className="text-center"
              style={{ padding: "6px 48px" }}
              variant="contained"
              onClick={() => this.Save("xml")}
            >
              <span className="label">確定</span>
              {/* <span className="label">{t("common:settings.sso.confirm")}</span> */}
            </Button>
          </Form.Row>
        </div>
      </>
    );
  };

  validateInputData = (type, data) => {
    const validateMessages = []
    if (type === "service") {
      if (!data.entity_id) {
        validateMessages.push("エンティティIDが未入力です。");
      }
      if (!data.endpoint_url) {
        validateMessages.push("エンドポイントURLが未入力です。");
      } else if (!this.validateUrl(data.endpoint_url)) {
        validateMessages.push("エンドポイントURLが不正です。");
      }
      if (!data.public_key) {
        validateMessages.push("公開鍵が未入力です。");
      }
      if (!data.logout_url) {
        validateMessages.push("ログアウト遷移URLが未入力です。");
      } else if (data.logout_url && !this.validateUrl(data.logout_url)) {
        validateMessages.push("ログアウト遷移URLが不正です。");
      }
    } else {
      if (!data.xml_url) {
        validateMessages.push("XML URLが未入力です。");
      } else if (!this.validateUrl(data.xml_url)) {
        validateMessages.push("XML URLが不正です。");
      }
    }
    if (!data.domain) {
      validateMessages.push("識別子(ドメイン)が未入力です。");
    } 
    return validateMessages
  }

  Save = async (type) => {
    // type is 'service' or 'xml'
    let { modalTitle, ModalBody, modalProps, formData } = this.state;

    modalProps = { ...modalProps, show: true, centered: true };

    modalTitle = "";
    ModalBody = Loading;

    this.setState({ modalTitle, ModalBody, modalProps });

    let data = {};
    let validateMessages = [];
    if (type === "service") {
      // service endpoint url
      data = {
        entity_id: formData.entity_id,
        endpoint_url: formData.endpoint_url,
        public_key: formData.public_key,
        logout_url: formData.logout_url,
        domain: formData.domain,
      };
      validateMessages = this.validateInputData(type, data)
    } else {
      // xml url
      data = { xml_url: formData.xml_url, domain: formData.domain };
      validateMessages = this.validateInputData(type, data);
    }
    if (validateMessages.length > 0) {
      const ModalBody = () => (
        <>
          {validateMessages.map((m, i) => (
            <p key={i}>{m}</p>
          ))}
        </>
      );
      this.setState({ modalTitle: "エラー", ModalBody });
      return;
    }
    
    try {
      // regist service endpoint url data
      if (await SsoService.GetIdp()) {
        // if ServiceEndpoint data exists
        await SsoService.UpdateIdp(data);
      } else {
        await SsoService.SaveIdp(data);
      }
      const ModalBody = () => (
        <>登録しました。</>
      );
      this.setState({ modalTitle: "完了", ModalBody });
		} catch (e) {
			const errorMsg = e.response.data.message
			let message
			if (errorMsg?.includes(`IdP Identifier ${formData.domain} is already associated with a different identity provider`)) {
				message = 'この識別子は使用されていて登録できません。'
			} else {
				message = 'エラーが発生しました。入力内容を確認してください。'
			}
			const ModalBody = () => <>{ message }</>
      this.setState({ modalTitle: "エラー", ModalBody });
      
    }
  };

  handleUseSsoSwitch = async () => {
    const { t } = this.props;
    const { use_sso } = this.state;
    const use_sso_to = !use_sso;

    // when not to use sso, delete both sso data
    if (use_sso_to === false) {
      let { modalTitle, ModalBody, modalProps, ModalAction } = this.state;

      const closeModal = () => {
        modalProps = { show: false };
        this.setState({ modalProps });
      };
      const deleteData = async () => {
        await SsoService.DeleteIdp();
        this.setState({ formData: {} });
        this.setStateFields();
      };

      modalProps = { ...modalProps, show: true, centered: true };

      this.setState({ modalTitle: "", ModalBody: Loading, modalProps });
      if (!(await SsoService.GetIdp())) {
        this.setState({ use_sso: use_sso_to });
        closeModal();
        return;
      }

      modalTitle = "確認";
      ModalBody = () => (
        <>
          SSO設定情報を削除します。
          <br />
          よろしいですか？
        </>
      );
      ModalAction = [
        <Button
          key="ok"
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={async () => {
            this.setState({ modalTitle: "削除中", ModalBody: Loading, ModalAction: [] }, async () => {
              await deleteData();
              ModalBody = () => (
                <>
                  削除しました
                </>
              );
               this.setState({
                 modalTitle: "完了",
                 ModalBody,
                 ModalAction: [],
               });
              this.setState({ use_sso: use_sso_to });
            });
          }}
        >
          <span>{t("common:general.ok")}</span>
        </Button>,
      ];

      this.setState({ modalTitle, ModalBody, modalProps, ModalAction });
    } else {
      this.setState({ use_sso: use_sso_to });
    }
  };

  render() {
    const { activeTab, tabs, use_sso, ssoLoading } = this.state;
    let { t } = this.props;

    const { Main, ServiceEndpointForm, XmlUrlForm } = this;

    const handleChange = (_, newValue) => {
      const activeTab = newValue;
      this.setState({ activeTab });
    };

    return (
      <Main>
        <div className="setting-sso p-4">
          <Form.Check
            id="use-sso-switch"
            key="use-sso-switch"
            type="switch"
            onChange={this.handleUseSsoSwitch}
            checked={use_sso}
            disabled={ssoLoading}
            label={"SSOを使用する"}
            // label={t("common:settings.sso.use-sso")}
          />
          {use_sso && (
            <Box sx={{ p: "10" }}>
              <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    {tabs.map((menu, index) => (
                      <Tab
                        label={t(menu.label)}
                        key={index}
                        value={`${index}`}
                      />
                    ))}
                  </TabList>
                </Box>
                <TabPanel value="0">
                  <XmlUrlForm />
                </TabPanel>
                <TabPanel value="1">
                  <ServiceEndpointForm />
                </TabPanel>
              </TabContext>
            </Box>
          )}
        </div>
      </Main>
    );
  }
}

export default withTranslation()(Sso);
